var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var nL,pL,rL,uL,xL;$CLJS.mL=function(a){return $CLJS.Xh($CLJS.q($CLJS.$B),a,$CLJS.gG)};nL=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.oL=new $CLJS.M(null,"operators","operators",-2064102509);pL=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.qL=new $CLJS.M(null,"stage-number","stage-number",-1752729638);
rL=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.sL=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.tL=new $CLJS.M(null,"dimensions","dimensions",-254818097);uL=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.vL=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.wL=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);
xL=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.X(pL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gt,$CLJS.qE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iG,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.XG],null)],null)],null));$CLJS.X(xL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gt,pL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kB,$CLJS.uE],null)],null)],null));$CLJS.X(rL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.cG],null),xL,$CLJS.lE],null));
$CLJS.X(uL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.cG],null),pL,$CLJS.WK],null));
$CLJS.DG.g($CLJS.cG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.cG],null),pL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,$CLJS.WK,$CLJS.lE],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,2,[$CLJS.Si,function(a){return $CLJS.wd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.YB(a)):null},$CLJS.ht,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.cB,uL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bB,rL],null)],null)],null));$CLJS.jF($CLJS.cG,$CLJS.gG);$CLJS.FE.m(null,$CLJS.cG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.tA($CLJS.Ii,$CLJS.kB)(b);return $CLJS.n(a)?a:$CLJS.SE});$CLJS.qF($CLJS.hB,$CLJS.H([$CLJS.lE]));$CLJS.FE.m(null,$CLJS.hB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.tA($CLJS.Ii,$CLJS.kB)(b);return $CLJS.n(a)?a:$CLJS.SE});
$CLJS.jF($CLJS.hB,$CLJS.gG);$CLJS.X(nL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gt,$CLJS.qE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.lE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gE,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.lE],null)],null)],null));$CLJS.DG.g($CLJS.SF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.SF],null),nL,$CLJS.ek],null));
$CLJS.FE.m(null,$CLJS.SF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.tA($CLJS.Ii,$CLJS.kB)(b);return $CLJS.n(a)?a:$CLJS.SE});$CLJS.jF($CLJS.SF,$CLJS.gG);$CLJS.qF($CLJS.AF,$CLJS.H([$CLJS.at,$CLJS.Qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.GK],null)],null)]));$CLJS.jF($CLJS.AF,$CLJS.gG);
$CLJS.qF($CLJS.YF,$CLJS.H([$CLJS.at,$CLJS.SE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EK],null)],null)]));$CLJS.jF($CLJS.YF,$CLJS.gG);
$CLJS.X($CLJS.gG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,$CLJS.HE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.kt,function(){return["Valid reference, must be one of these clauses: ",$CLJS.tt(", ",$CLJS.oA($CLJS.Jd,$CLJS.rl.g($CLJS.q($CLJS.$B),$CLJS.gG)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.mL(a)}],null)],null));