var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var xfa,yfa,zfa,Afa,Bfa,Cfa,Dfa,IG,Efa,Ffa,Gfa,Hfa,Ifa,Jfa,Kfa,Lfa,KG,Mfa;$CLJS.EG=new $CLJS.M(null,"supported-field","supported-field",-2061545519);xfa=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.FG=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);yfa=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
zfa=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.GG=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);Afa=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.HG=new $CLJS.M(null,"display-info","display-info",-816930907);Bfa=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);Cfa=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
Dfa=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);IG=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);Efa=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);Ffa=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);Gfa=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);Hfa=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);
Ifa=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);Jfa=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);Kfa=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.JG=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);Lfa=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);KG=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.LG=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);Mfa=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.sF($CLJS.Jx,$CLJS.H([$CLJS.at,$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RE],null)],null)],null)],null)]));$CLJS.sF($CLJS.dG,$CLJS.H([$CLJS.at,$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RE],null)],null)],null)],null)]));
$CLJS.qF($CLJS.XF,$CLJS.H([$CLJS.at,$CLJS.ID,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));$CLJS.qF($CLJS.HF,$CLJS.H([$CLJS.at,$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RE],null)],null)]));$CLJS.qF($CLJS.lG,$CLJS.H([$CLJS.at,$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.QE],null)],null)]));
$CLJS.qF($CLJS.pk,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XE],null)],null)]));$CLJS.jF($CLJS.pk,$CLJS.WE);$CLJS.qF($CLJS.qG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));$CLJS.jF($CLJS.qG,$CLJS.WE);$CLJS.qF($CLJS.go,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XE],null)],null)]));$CLJS.jF($CLJS.go,$CLJS.WE);
$CLJS.X(IG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.ht,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.ht,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.qF($CLJS.OF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IG],null)]));$CLJS.jF($CLJS.OF,$CLJS.WE);
$CLJS.qF($CLJS.jG,$CLJS.H([$CLJS.at,$CLJS.ID,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.QE],null)],null)]));$CLJS.qF($CLJS.sG,$CLJS.H([$CLJS.at,$CLJS.ID,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));$CLJS.qF($CLJS.pG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));
$CLJS.qF($CLJS.zG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));$CLJS.jF($CLJS.pG,$CLJS.WE);$CLJS.jF($CLJS.zG,$CLJS.WE);$CLJS.qF($CLJS.xG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.QE],null)],null)]));$CLJS.jF($CLJS.xG,$CLJS.WE);
$CLJS.qF($CLJS.IF,$CLJS.H([$CLJS.at,$CLJS.ID,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));$CLJS.X(KG,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.ms,Lfa,Jfa,Cfa,yfa,xfa,Bfa,Dfa,Efa,Ffa,Ifa,Gfa,Mfa,Kfa,zfa,Hfa,$CLJS.Kl],null));$CLJS.X($CLJS.FG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.go,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KG],null)],null));
$CLJS.MG=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.WF,$CLJS.Jx,$CLJS.GG,!1,$CLJS.JG,$CLJS.wF,$CLJS.HG,function(){return new $CLJS.h(null,3,[$CLJS.gE,$CLJS.DE("Count of rows"),$CLJS.NF,$CLJS.DE("Count"),$CLJS.Ct,$CLJS.DE("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.WF,$CLJS.pG,$CLJS.EG,$CLJS.lk,$CLJS.GG,!0,$CLJS.JG,$CLJS.wF,$CLJS.HG,function(){return new $CLJS.h(null,3,[$CLJS.gE,$CLJS.DE("Sum of ..."),$CLJS.NF,$CLJS.DE("Sum"),$CLJS.Ct,$CLJS.DE("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.WF,$CLJS.XF,$CLJS.EG,$CLJS.lk,$CLJS.GG,!0,$CLJS.JG,$CLJS.wF,$CLJS.HG,function(){return new $CLJS.h(null,3,[$CLJS.gE,$CLJS.DE("Average of ..."),$CLJS.NF,$CLJS.DE("Average"),$CLJS.Ct,$CLJS.DE("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.WF,$CLJS.qG,$CLJS.EG,$CLJS.lk,$CLJS.GG,!0,$CLJS.JG,$CLJS.ZF,$CLJS.HG,function(){return new $CLJS.h(null,3,[$CLJS.gE,$CLJS.DE("Median of ..."),$CLJS.NF,$CLJS.DE("Median"),$CLJS.Ct,$CLJS.DE("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.WF,$CLJS.HF,$CLJS.EG,$CLJS.ys,$CLJS.GG,!0,$CLJS.JG,$CLJS.wF,$CLJS.HG,function(){return new $CLJS.h(null,3,[$CLJS.gE,$CLJS.DE("Number of distinct values of ..."),$CLJS.NF,$CLJS.DE("Distinct values"),$CLJS.Ct,$CLJS.DE("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.WF,$CLJS.zG,$CLJS.EG,$CLJS.lk,$CLJS.GG,!0,$CLJS.JG,$CLJS.wF,$CLJS.HG,function(){return new $CLJS.h(null,3,[$CLJS.gE,$CLJS.DE("Cumulative sum of ..."),
$CLJS.NF,$CLJS.DE("Sum"),$CLJS.Ct,$CLJS.DE("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.WF,$CLJS.dG,$CLJS.GG,!1,$CLJS.JG,$CLJS.wF,$CLJS.HG,function(){return new $CLJS.h(null,3,[$CLJS.gE,$CLJS.DE("Cumulative count of rows"),$CLJS.NF,$CLJS.DE("Count"),$CLJS.Ct,$CLJS.DE("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.WF,$CLJS.sG,$CLJS.EG,$CLJS.lk,$CLJS.GG,
!0,$CLJS.JG,$CLJS.mG,$CLJS.HG,function(){return new $CLJS.h(null,3,[$CLJS.gE,$CLJS.DE("Standard deviation of ..."),$CLJS.NF,$CLJS.DE("SD"),$CLJS.Ct,$CLJS.DE("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.WF,$CLJS.go,$CLJS.EG,$CLJS.cj,$CLJS.GG,!0,$CLJS.JG,$CLJS.wF,$CLJS.HG,function(){return new $CLJS.h(null,3,[$CLJS.gE,$CLJS.DE("Minimum of ..."),$CLJS.NF,$CLJS.DE("Min"),$CLJS.Ct,$CLJS.DE("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.WF,$CLJS.pk,$CLJS.EG,$CLJS.cj,$CLJS.GG,!0,$CLJS.JG,$CLJS.wF,$CLJS.HG,function(){return new $CLJS.h(null,3,[$CLJS.gE,$CLJS.DE("Maximum of ..."),$CLJS.NF,$CLJS.DE("Max"),$CLJS.Ct,$CLJS.DE("Maximum value of a column")],null)}],null)],null);
$CLJS.X(Afa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.LG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WF,$CLJS.fg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.js],null),$CLJS.jf.h($CLJS.WF),$CLJS.MG)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EG,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.bj],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GG,$CLJS.xs],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.JG,$CLJS.bj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HG,$CLJS.md],null)],null));