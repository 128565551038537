var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var m5,cra,dra,era,o5,p5,q5,fra,gra,t5,r5,s5,u5,v5,hra,ira,jra,w5,kra,lra,x5,mra,nra;m5=function(a,b){if(b=$CLJS.y(b)){var c=$CLJS.y(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.y(c)?(c=m5($CLJS.J.g(a,b),c),$CLJS.td(c)?$CLJS.Ok.g(a,b):$CLJS.R.j(a,b,c)):$CLJS.Ok.g(a,b)}return a};
cra=function(a,b,c,d){return function t(f,k,l,m){var u=$CLJS.y(k);k=$CLJS.z(u);u=$CLJS.B(u);var v=$CLJS.mm(f,k);return $CLJS.n(v)?u?$CLJS.R.j(f,k,t($CLJS.Fb(v),u,l,m)):$CLJS.R.j(f,k,$CLJS.Te.j(l,$CLJS.Fb(v),m)):f}(a,b,c,d)};dra=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return cra(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.w(b.slice(3),0,null):null)};$CLJS.n5=function(a){return $CLJS.sE.h($CLJS.zE(a))};
era=function(a,b,c,d){var e=$CLJS.E.g($CLJS.JP,$CLJS.z(b))?$CLJS.jW(d,$CLJS.iW(c)):d;return dra(a,b,function(f){return $CLJS.Ef(function(){return function m(l){return new $CLJS.oe(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.re(v);a:for(var A=0;;)if(A<v){var C=$CLJS.kd(u,A);C=$CLJS.E.g($CLJS.n5(C),$CLJS.n5(c))?e:C;x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.ue($CLJS.we(x),m($CLJS.mc(t))):$CLJS.ue($CLJS.we(x),null)}x=$CLJS.z(t);return $CLJS.fe($CLJS.E.g($CLJS.n5(x),
$CLJS.n5(c))?e:x,m($CLJS.Lc(t)))}return null}},null,null)}(f)}())})};
o5=function(a,b,c,d){var e=$CLJS.uA(a,b);if($CLJS.n(e)){var f=$CLJS.n5(c),k=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.z(b),$CLJS.id(b)],null);c=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);e=$CLJS.fg.j($CLJS.Df,$CLJS.Xk.h($CLJS.Wk.g($CLJS.dh([f]),$CLJS.n5)),e);e=$CLJS.E.g(b,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.LE],null))&&$CLJS.Re(function(l){return $CLJS.rY(l,$CLJS.hB)},e)?null:e;if($CLJS.y(e))return $CLJS.GL(a,b,e);if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nN,$CLJS.hL],null),new $CLJS.P(null,2,5,$CLJS.Q,
[c,k],null)))throw $CLJS.ji($CLJS.DE("Cannot remove the final join condition"),new $CLJS.h(null,5,[$CLJS.uy,$CLJS.uW,$CLJS.hL,$CLJS.uA(a,b),$CLJS.vW,$CLJS.uA(a,$CLJS.rd(b)),$CLJS.qL,d,$CLJS.wW,a],null));return $CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nN,$CLJS.LE],null),new $CLJS.P(null,2,5,$CLJS.Q,[c,k],null))?$CLJS.gX.v(a,$CLJS.rd(b),$CLJS.Ok,k):m5(a,b)}return a};
p5=function(a,b){var c=$CLJS.N3.g(a,b),d=$CLJS.mh(0,$CLJS.D(c));return $CLJS.hf.l(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.yR],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.RF],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.TV],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.LE],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.SF],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.JP],null)],null),function(){return function k(f){return new $CLJS.oe(null,function(){for(var l=f;;)if(l=$CLJS.y(l)){if($CLJS.Ad(l)){var m=
$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.re(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),A=$CLJS.F(c,x);$CLJS.LE.h(A)instanceof $CLJS.M||u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nN,x,$CLJS.LE],null));v+=1}else{m=!0;break a}return m?$CLJS.ue($CLJS.we(u),k($CLJS.mc(l))):$CLJS.ue($CLJS.we(u),null)}u=$CLJS.z(l);m=$CLJS.F(c,u);if($CLJS.LE.h(m)instanceof $CLJS.M)l=$CLJS.Lc(l);else return $CLJS.fe(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nN,u,$CLJS.LE],null),k($CLJS.Lc(l)))}else return null},null,null)}(d)}(),$CLJS.H([function(){return function k(f){return new $CLJS.oe(null,
function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.re(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nN,x,$CLJS.hL],null));v+=1}else{m=!0;break a}return m?$CLJS.ue($CLJS.we(u),k($CLJS.mc(l))):$CLJS.ue($CLJS.we(u),null)}u=$CLJS.z(l);return $CLJS.fe(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nN,u,$CLJS.hL],null),k($CLJS.Lc(l)))}return null}},null,null)}(d)}()]))};
q5=function(a,b,c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);e=$CLJS.O(e);var f=$CLJS.J.g(e,$CLJS.iG),k=$CLJS.J.g(e,$CLJS.eR),l=$CLJS.I(c,2,null);return $CLJS.z($CLJS.nH(function(m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);$CLJS.I(m,0,null);$CLJS.I(m,1,null);m=$CLJS.I(m,2,null);return $CLJS.E.g($CLJS.z(m),d)&&$CLJS.E.g($CLJS.iG.h($CLJS.hd(m)),f)&&$CLJS.E.g($CLJS.eR.h($CLJS.hd(m)),k)&&$CLJS.E.g($CLJS.id(m),l)},$CLJS.km($CLJS.Bs,$CLJS.yR.h($CLJS.pW(a,b)))))};
fra=function(a,b,c,d){c=q5(a,b,c);return $CLJS.n(c)?$CLJS.AW.l(a,b,$CLJS.gX,$CLJS.H([new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.yR,c,2,1],null),$CLJS.Wk.g(function(e){return $CLJS.g1($CLJS.Hl,e)},$CLJS.nl),d])):a};gra=function(a,b,c){c=q5(a,b,c);return $CLJS.n(c)?$CLJS.AW.l(a,b,o5,$CLJS.H([new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.yR],null),$CLJS.uA($CLJS.pW(a,b),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yR,c],null)),b])):a};
t5=function(a,b,c,d,e,f){var k=$CLJS.AW.l(a,b,f,$CLJS.H([d,e])),l=$CLJS.n5(e);return $CLJS.Uk.g(a,k)?$CLJS.z($CLJS.y($CLJS.mf($CLJS.Xa,function(m,t){try{if($CLJS.zd(t)&&1===$CLJS.D(t))try{var u=$CLJS.F(t,0);if($CLJS.ie(u,$CLJS.JP))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var K=$CLJS.iW(e);K=r5.W?r5.W(k,b,c,$CLJS.hB,$CLJS.N,K):r5.call(null,k,b,c,$CLJS.hB,$CLJS.N,K);return s5.v?s5.v(K,b,c,l):s5.call(null,K,b,c,l)}()],null);throw $CLJS.Z;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Z)try{u=
$CLJS.F(t,0);if($CLJS.ie(u,$CLJS.SF))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var S=r5.W?r5.W(k,b,c,$CLJS.SF,$CLJS.N,l):r5.call(null,k,b,c,$CLJS.SF,$CLJS.N,l);return s5.v?s5.v(S,b,c,l):s5.call(null,S,b,c,l)}()],null);throw $CLJS.Z;}catch(S){if(S instanceof Error){var v=S;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw S;}else throw m;else throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Z)try{if($CLJS.zd(t)&&1===$CLJS.D(t))try{var x=$CLJS.F(t,0);if($CLJS.ie(x,$CLJS.RF))return new $CLJS.P(null,
1,5,$CLJS.Q,[s5.v?s5.v(k,b,c,l):s5.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Z)try{x=$CLJS.F(t,0);if($CLJS.ie(x,$CLJS.LE))return new $CLJS.P(null,1,5,$CLJS.Q,[s5.v?s5.v(k,b,c,l):s5.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(V){if(V instanceof Error){var A=V;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw V;}else throw v;else throw S;}else throw $CLJS.Z;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Z)try{if($CLJS.zd(t)&&3===$CLJS.D(t))try{var C=$CLJS.F(t,
0);if($CLJS.ie(C,$CLJS.nN))try{var G=$CLJS.F(t,2);if($CLJS.ie(G,$CLJS.LE))return new $CLJS.P(null,1,5,$CLJS.Q,[s5.v?s5.v(k,b,c,l):s5.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(V){if(V instanceof Error){A=V;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw V;}else throw $CLJS.Z;}catch(V){if(V instanceof Error){A=V;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw V;}else throw $CLJS.Z;}catch(V){if(V instanceof Error){A=V;if(A===$CLJS.Z)return new $CLJS.P(null,1,5,$CLJS.Q,[k],null);throw A;}throw V;}else throw v;
else throw S;}else throw m;else throw K;}}($CLJS.Df,d)))):k};
r5=function(a,b,c,d,e,f){var k=$CLJS.pW(a,b),l=$CLJS.kf(function(m){var t=$CLJS.uA(k,m);return $CLJS.n(t)?$CLJS.jm(function(u){return $CLJS.z($CLJS.y($CLJS.mf($CLJS.Xa,function C(x,A){try{if($CLJS.zd(A)&&3===$CLJS.D(A))try{var G=$CLJS.F(A,0);if($CLJS.E.g(G,d))try{var K=$CLJS.F(A,1);if($CLJS.td(e)||$CLJS.f1($CLJS.gh(e),$CLJS.gh(K)))try{var S=$CLJS.F(A,2);if($CLJS.E.g(S,f))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null)],null);throw $CLJS.Z;}catch(Y){if(Y instanceof Error){var V=
Y;if(V===$CLJS.Z)throw $CLJS.Z;throw V;}throw Y;}else throw $CLJS.Z;}catch(Y){if(Y instanceof Error){V=Y;if(V===$CLJS.Z)throw $CLJS.Z;throw V;}throw Y;}else throw $CLJS.Z;}catch(Y){if(Y instanceof Error){V=Y;if(V===$CLJS.Z)throw $CLJS.Z;throw V;}throw Y;}else throw $CLJS.Z;}catch(Y){if(Y instanceof Error){V=Y;if(V===$CLJS.Z)return $CLJS.kV(C,x,A);throw V;}throw Y;}}($CLJS.Df,u))))},t):null},$CLJS.H([p5(a,b)]));return $CLJS.eb(function(m,t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return t5(m,b,
c,u,t,function(v,x,A){return o5(v,x,A,b)})},a,l)};s5=function(a,b,c,d){b=$CLJS.q2(c,b);if($CLJS.n(b)){var e=$CLJS.pW(c,b);c=$CLJS.Se(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.FK),l=$CLJS.J.g(f,$CLJS.S0);return $CLJS.E.g($CLJS.hZ,k)&&$CLJS.E.g(d,l)?$CLJS.uZ.h(f):null},$CLJS.z0.j(c,b,e));return $CLJS.n(c)?r5(a,b,a,$CLJS.cG,$CLJS.N,c):a}return a};
u5=function(a,b,c,d,e){var f=$CLJS.bW.h(c),k=$CLJS.pW(a,b);c=$CLJS.nH(function(t){var u=$CLJS.uA(k,t);if($CLJS.n(u)){var v=$CLJS.n5(f);return $CLJS.n($CLJS.Se($CLJS.Wk.j($CLJS.dh([v]),$CLJS.sE,$CLJS.hd),u))?t:null}return null},p5(a,b));var l=(d=$CLJS.E.g($CLJS.BG,d))?$CLJS.bW.h(e):null;e=d?function(t,u,v){return era(t,u,v,l)}:function(t,u,v){return o5(t,u,v,b)};var m=$CLJS.E.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.RF],null),c);a=d&&m&&$CLJS.E.g($CLJS.z(f),$CLJS.z(l))&&$CLJS.E.g($CLJS.id(f),$CLJS.id(l))?
fra(a,b,f,$CLJS.lm($CLJS.hd(l),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eR,$CLJS.iG],null))):m?gra(a,b,f):a;return $CLJS.n(c)?t5(a,b,a,c,f,e):a};v5=function(a,b){return $CLJS.rY(a,$CLJS.cG)&&$CLJS.E.g($CLJS.u1(a),b)};hra=function(a,b,c){return function k(e,f){try{if(v5(f,b))return $CLJS.s3(f,c);throw $CLJS.Z;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.Z)return $CLJS.JW(k,e,f);throw l;}throw m;}}($CLJS.Df,a)};
ira=function(a,b,c){var d=$CLJS.nN.h(a),e=-1<b&&b<$CLJS.D(d)?new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.uA(d,new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.PE],null))],null):null;b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.n($CLJS.n(b)?$CLJS.Uk.g(e,c):b)){var f=$CLJS.sW();$CLJS.o3(f,$CLJS.jf.g($CLJS.PE,d));c=f(c);return hra($CLJS.GL(a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nN,b,$CLJS.PE],null),c),e,c)}return a};
jra=function(a,b,c){if($CLJS.Fd(c))return c;var d=function(){var e=$CLJS.dh([c]);return"string"===typeof c?$CLJS.Wk.g(e,$CLJS.PE):e}();return $CLJS.Se(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return $CLJS.n(d.h?d.h(e):d.call(null,e))?f:null},$CLJS.km($CLJS.Bs,$CLJS.nN.h($CLJS.pW(a,b))))};
w5=function(a,b,c,d){b=$CLJS.Yt.g($CLJS.gh($CLJS.z0.j(b,c,$CLJS.pW(b,c))),$CLJS.gh($CLJS.z0.j(a,c,$CLJS.pW(a,c))));return $CLJS.eb(function(e,f){return $CLJS.Te.N(r5,e,c,a,d.h?d.h(f):d.call(null,f))},a,b)};kra=function(a,b,c){a=w5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,new $CLJS.h(null,1,[$CLJS.dP,$CLJS.JZ.h(d)],null),$CLJS.dj.h(d)],null)});c=$CLJS.q2(a,c);return $CLJS.n(c)?w5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,$CLJS.N,$CLJS.uZ.h(d)],null)}):a};
lra=function(a,b,c){return $CLJS.Fd(c)?$CLJS.uA($CLJS.pW(a,b),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nN,c,$CLJS.PE],null)):$CLJS.xd(c)?$CLJS.PE.h(c):c};x5=function(a,b,c,d){var e=lra(a,b,c);return $CLJS.n(e)?(c=function(){var f=(0,$CLJS.AW)(a,b,function(k){var l=$CLJS.nN.h(k);l=d.g?d.g(l,e):d.call(null,l,e);return $CLJS.yE(k,$CLJS.nN,l)});return(0,$CLJS.AW)(f,b,function(k){return $CLJS.EW(k,$CLJS.nN,function(l){return $CLJS.Yk.g(function(m){return $CLJS.A3(f,b,m)},l)})})}(),kra(c,a,b)):a};
mra=function(a,b){return null!=$CLJS.z($CLJS.y($CLJS.mf($CLJS.Xa,function f(d,e){try{if(v5(e,b))return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Z;}catch(l){if(l instanceof Error){var k=l;if(k===$CLJS.Z)return $CLJS.kV(f,d,e);throw k;}throw l;}}($CLJS.Df,a))))};nra=new $CLJS.M(null,"remove","remove",-131428414);$CLJS.z5=function(){function a(d,e,f){return $CLJS.xd(f)&&$CLJS.E.g($CLJS.Qt.h(f),$CLJS.$K)?$CLJS.y5.j?$CLJS.y5.j(d,e,f):$CLJS.y5.call(null,d,e,f):u5(d,e,f,nra,null)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.B5=function(){function a(d,e,f,k){return $CLJS.xd(f)&&$CLJS.E.g($CLJS.Qt.h(f),$CLJS.$K)?$CLJS.A5.v?$CLJS.A5.v(d,e,f,k):$CLJS.A5.call(null,d,e,f,k):u5(d,e,f,$CLJS.BG,k)}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.ora=function(){function a(d,e,f,k){f=jra(d,e,f);return $CLJS.n(f)?$CLJS.AW.l(d,e,ira,$CLJS.H([f,k])):d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.y5=function(){function a(d,e,f){try{return x5(d,e,f,function(u,v){return $CLJS.Ge($CLJS.N0(function(x){return!($CLJS.E.g($CLJS.PE.h(x),v)||mra(x,v))},u))})}catch(u){var k=u,l=$CLJS.O($CLJS.om(k)),m=$CLJS.J.g(l,$CLJS.qL),t=$CLJS.J.g(l,$CLJS.uy);l=$CLJS.J.g(l,$CLJS.vW);if($CLJS.E.g(t,$CLJS.uW))return d=c.j?c.j(d,m,l):c.call(null,d,m,l),c.j?c.j(d,e,f):c.call(null,d,e,f);throw k;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.A5=function(){function a(d,e,f,k){return null==k?$CLJS.y5.j(d,e,f):x5(d,e,f,function(l,m){return $CLJS.Yk.g(function(t){return $CLJS.E.g($CLJS.PE.h(t),m)?k:t},l)})}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();