var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var GW,tla,IW,KW,LW,ula,NW,OW,FW,PW,vla,wla,xla,yla,SW,TW,VW,WW,XW,YW,ZW,$W,HW,Ala,Bla,cX,Cla,dX,Dla,fX,Ela,Fla,Gla,Hla,Ila,CW;$CLJS.DW=function(a,b){if("string"===typeof b)return CW(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.EW=function(a,b,c){var d=$CLJS.mm(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Fb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
GW=function(a){var b=FW;return $CLJS.zA(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};tla=function(a){var b=HW;return $CLJS.zA(function(c){return function(d,e,f){f=b.h?b.h(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};IW=function(a){if($CLJS.md(a))return a;throw $CLJS.ji("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.Ki,a],null));};
$CLJS.JW=function(a,b,c){return $CLJS.xd(c)?$CLJS.fg.g(c,function(){return function f(e){return new $CLJS.oe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.re(m);return function(){for(var A=0;;)if(A<m){var C=$CLJS.kd(l,A),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,V=G;G=$CLJS.ce.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.P(null,2,5,S,[V,G],null));A+=1}else return!0}()?$CLJS.ue($CLJS.we(t),f($CLJS.mc(k))):$CLJS.ue($CLJS.we(t),
null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var A=$CLJS.ce.g(b,v),C=x;return a.g?a.g(A,C):a.call(null,A,C)}()],null),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):$CLJS.wd(c)?$CLJS.Yk.g($CLJS.We(a,$CLJS.z(c)instanceof $CLJS.M?$CLJS.ce.g(b,$CLJS.z(c)):b),c):c};KW=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.je(a):b;return $CLJS.n(b)?[$CLJS.je(a),"/",$CLJS.hh(a)].join(""):$CLJS.hh(a)};
LW=function(a,b){var c=$CLJS.wd(b)&&!$CLJS.Af(b)&&$CLJS.z(b)instanceof $CLJS.M;return c?$CLJS.ud(a)?(b=$CLJS.z(b),a=$CLJS.gh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c};
ula=function(a,b){return $CLJS.kf(function(c){var d=$CLJS.mf;a:try{if($CLJS.zd(c)&&1<=$CLJS.D(c))try{var e=$CLJS.cl.j(c,0,1);if($CLJS.zd(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.We($CLJS.E,a)(f))){var k=$CLJS.cl.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Z;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw t;}else throw $CLJS.Z;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw t;}else throw $CLJS.Z;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Z){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.z($CLJS.y(d($CLJS.Xa,l)))},$CLJS.H([b]))};
NW=function(a,b){for(;;)switch(b=$CLJS.pl.h($CLJS.mf($CLJS.Xa,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.z(b),MW.h?MW.h(a):MW.call(null,a);default:if($CLJS.n($CLJS.Se($CLJS.We(LW,a),b))){var c=a;b=ula(a,b);a=c}else{c=$CLJS.jf.g(MW,b);if($CLJS.E.g(c,b))return $CLJS.fg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};OW=function(a){var b=$CLJS.wd(a);return b?(b=!$CLJS.Af(a))?$CLJS.tA($CLJS.he,$CLJS.Za)($CLJS.z(a)):b:b};
FW=function(a){return $CLJS.n($CLJS.tA($CLJS.he,$CLJS.Za)(a))?$CLJS.Ah.h($CLJS.xA(KW(a).toLowerCase(),/_/,"-")):a};PW=function(a,b){var c=OW(b);return $CLJS.n(c)?(b=FW($CLJS.z(b)),$CLJS.ud(a)?(a=$CLJS.gh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};vla=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.ei,$CLJS.Td,$CLJS.rj,FW,$CLJS.AN,FW],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Fe([a,b]);b=QW.g?QW.g(b,RW):QW.call(null,b,RW);return d.call(c,b,a)})};
wla=function(a){a=$CLJS.fg.j($CLJS.N,$CLJS.jf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=FW(c);var d=vla(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.rj.h(a),$CLJS.vF)&&$CLJS.Ua($CLJS.AN.h(a))?$CLJS.R.j(a,$CLJS.AN,$CLJS.wG):a};xla=function(a){return $CLJS.fg.j($CLJS.N,$CLJS.jf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=KW(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(wla(b),$CLJS.T,c)],null)}),a)};
yla=function(a){a=QW.h?QW.h(a):QW.call(null,a);return HW.h?HW.h(a):HW.call(null,a)};SW=function(a){return $CLJS.EW($CLJS.EW($CLJS.eb(function(b,c){return $CLJS.EW(b,c,$CLJS.Ah)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ZC,$CLJS.Xea,$CLJS.nO,zla,$CLJS.Sz,$CLJS.ti],null)),$CLJS.ZB,yla),$CLJS.CN,$CLJS.fV)};TW=function(a){return $CLJS.Fd(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,a,null],null):a};VW=function(a){return UW.h(TW(a))};
WW=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);return MW($CLJS.fg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.jf.g(UW,b)))};XW=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,VW(b)],null),$CLJS.jf.g(UW,c))};YW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,VW(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
ZW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,VW(a)],null)};$W=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,UW.h(a)],null)};
HW=function(a){return aX(function(b){if($CLJS.xd(b))return tla(b);if($CLJS.Ua(OW(b)))return b;try{return UW.h(b)}catch(f){if(f instanceof Error){var c=f,d=$CLJS.DA($CLJS.uy);if($CLJS.n($CLJS.CA("metabase.mbql.normalize",d))){var e=$CLJS.DE("Invalid clause:");e instanceof Error?$CLJS.BA("metabase.mbql.normalize",d,$CLJS.sw.l($CLJS.H([b])),e):$CLJS.BA("metabase.mbql.normalize",d,$CLJS.sw.l($CLJS.H([e,b])),null)}throw $CLJS.ii($CLJS.bE("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.cV(c)])),new $CLJS.h(null,
1,[$CLJS.JR,b],null),c);}throw f;}},a)};Ala=function(a){return $CLJS.Ef($CLJS.jm(HW,bX(a)))};Bla=function(a){for(;;)if($CLJS.n(OW(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Ge($CLJS.Yk.g(TW,a))};cX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.vF);return $CLJS.n(b)?$CLJS.Zk.j(a,$CLJS.vF,UW):a};
Cla=function(a){return $CLJS.fg.g($CLJS.N,function(){return function d(c){return new $CLJS.oe(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.re(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,cX(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.ue($CLJS.we(l),d($CLJS.mc(e))):$CLJS.ue($CLJS.we(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.fe(new $CLJS.P(null,
2,5,$CLJS.Q,[l,cX(f)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};dX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.pQ);return $CLJS.n(b)?$CLJS.Zk.j(a,$CLJS.pQ,Cla):a};Dla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.TP);b=$CLJS.Ua(a)?eX.h?eX.h(b):eX.call(null,b):b;return $CLJS.n(a)?dX(b):b};fX=function(a){return $CLJS.ud(a)?$CLJS.y(a):null!=a};
Ela=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.yF);var c=$CLJS.J.g(b,$CLJS.eN),d=$CLJS.J.g(b,$CLJS.BO),e=$CLJS.J.g(b,$CLJS.TP);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.BO);var l=$CLJS.GL($CLJS.Ok.g(f,$CLJS.BO),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yF,$CLJS.BO],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.Zk.j(l,$CLJS.yF,eX):l,t=$CLJS.n(c)?$CLJS.Zk.j(m,$CLJS.eN,$CLJS.We($CLJS.Yk,HW)):m,u=$CLJS.n(e)?$CLJS.Zk.j(t,$CLJS.TP,dX):t;return HW(u)}catch(v){if(v instanceof Error)throw m=v,$CLJS.ii($CLJS.bE("Error canonicalizing query: {0}",
$CLJS.H([$CLJS.cV(m)])),new $CLJS.h(null,1,[$CLJS.yF,a],null),m);throw v;}};
Fla=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.yF),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.RF);c=$CLJS.J.g(c,$CLJS.LE);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.fg.j($CLJS.ch,$CLJS.kC,$CLJS.y($CLJS.mf($CLJS.Xa,function k(e,f){try{if($CLJS.zd(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.ie(l,$CLJS.cG)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,m,$CLJS.Ok.g(t,$CLJS.iG)],null)],null)],null)}throw $CLJS.Z;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw u;}else throw $CLJS.Z;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)return $CLJS.kV(k,e,f);throw l;}throw u;}}($CLJS.Df,b)))),$CLJS.gX.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yF,$CLJS.LE],null),$CLJS.Wk.g($CLJS.Ef,$CLJS.We($CLJS.Xk,b)))):a};
Gla=function(a){try{return Fla(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.ii($CLJS.DE("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.yF,a],null),b);}throw c;}};
Hla=function(a,b){var c=$CLJS.fg.g($CLJS.jd(a),function(){return function f(e){return new $CLJS.oe(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.re(m);return function(){for(var A=0;;)if(A<m){var C=$CLJS.kd(l,A),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.ce.g(b,G);C=hX.g?hX.g(C,K):hX.call(null,C,K);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));A+=1}else return!0}()?$CLJS.ue($CLJS.we(t),f($CLJS.mc(k))):$CLJS.ue($CLJS.we(t),
null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var A=x,C=$CLJS.ce.g(b,v);return hX.g?hX.g(A,C):hX.call(null,A,C)}();if(null!=u)return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};Ila=function(a,b){a=$CLJS.Yk.g(function(c){var d=$CLJS.ce.g(b,iX);return hX.g?hX.g(c,d):hX.call(null,c,d)},a);return $CLJS.n($CLJS.Se($CLJS.Xa,a))?a:null};
CW=function CW(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Ef(d);return $CLJS.fe(f,new $CLJS.oe(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),CW.g?CW.g(a,k):CW.call(null,a,k)):null},null,null))};
$CLJS.gX=function gX(a){switch(arguments.length){case 3:return gX.j(arguments[0],arguments[1],arguments[2]);case 4:return gX.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return gX.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return gX.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return gX.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.gX.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.z(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.gX.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.gX.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.z(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.gX.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.gX.N=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.z(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.gX.N($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.gX.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.z(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.gX.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.gX.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.z(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Te.l($CLJS.gX,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Te.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.gX.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);var e=$CLJS.B(d);d=$CLJS.z(e);var f=$CLJS.B(e);e=$CLJS.z(f);var k=$CLJS.B(f);f=$CLJS.z(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.gX.A=6;
var aX=function aX(a,b){return $CLJS.dV($CLJS.We(aX,a),$CLJS.Td,a.h?a.h(b):a.call(null,b))},MW=function MW(a){for(;;){if($CLJS.xd(a))return $CLJS.st(a,MW);if($CLJS.Dd(a))a=$CLJS.Ef(a);else if($CLJS.zd(a)){if($CLJS.Ua($CLJS.Se($CLJS.Xa,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.z(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.T:null;switch(v){case "not":if($CLJS.Tl(m)){var x=$CLJS.z(m),A=x instanceof $CLJS.M?x.T:null;switch(A){case "not":a=$CLJS.hd(m);continue;case "and":return NW($CLJS.ms,
$CLJS.jf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,C],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));case "or":return NW($CLJS.rs,$CLJS.jf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,C],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));default:return a}}else return a;case "and":return NW($CLJS.rs,t);case "or":return NW($CLJS.ms,t);default:return $CLJS.Yk.g(MW,a)}}else return a}},Jla=new $CLJS.M(null,"value_field","value_field",
-980977878),jX=new $CLJS.M(null,"ascending","ascending",-988350486),kX=new $CLJS.M(null,"named","named",-422393479),lX=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),mX=new $CLJS.M(null,"descending","descending",-24766135),nX=new $CLJS.M(null,"datetime-field","datetime-field",21731696),Kla=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),oX=new $CLJS.M(null,"field-literal","field-literal",-1295883554),pX=new $CLJS.M(null,"viz-settings","viz-settings",
256055379),Lla=new $CLJS.M(null,"rows","rows",850049680),Mla=new $CLJS.M(null,"special-fn","special-fn",1290649344),iX=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),RW=new $CLJS.M(null,"ignore-path","ignore-path",944069061),Nla=new $CLJS.M(null,"label_field","label_field",-1573182765),Ola=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),Pla=new $CLJS.M(null,"joined-field","joined-field",-2048778268),zla=new $CLJS.M(null,"visibility_type","visibility_type",
-508434247);var qX,Qla=$CLJS.Ye($CLJS.N),Rla=$CLJS.Ye($CLJS.N),Sla=$CLJS.Ye($CLJS.N),Tla=$CLJS.Ye($CLJS.N),Ula=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));qX=new $CLJS.fi($CLJS.Gh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Wk.g(FW,$CLJS.z),Ula,Qla,Rla,Sla,Tla);qX.m(null,$CLJS.SF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SF,b],null);return null!=a?$CLJS.ce.g(b,a):b});
qX.m(null,$CLJS.hB,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hB,a instanceof $CLJS.M?KW(a):a],null)});qX.m(null,$CLJS.mV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.ce.g(qX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mV,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mV,QW.g?QW.g(b,RW):QW.call(null,b,RW),FW(c)],null)});
qX.m(null,$CLJS.cG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=QW.g?QW.g(a,RW):QW.call(null,a,RW);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,b,function(){var d=$CLJS.n($CLJS.kB.h(c))?$CLJS.Zk.j(c,$CLJS.kB,$CLJS.Ah):c;d=$CLJS.n($CLJS.iG.h(c))?$CLJS.Zk.j(d,$CLJS.iG,$CLJS.Ah):d;return $CLJS.n($CLJS.eR.h(c))?$CLJS.Zk.j(d,$CLJS.eR,function(e){return $CLJS.n($CLJS.wE.h(e))?$CLJS.Zk.j(e,$CLJS.wE,$CLJS.Ah):e}):d}()],null)});
qX.m(null,oX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[oX,b instanceof $CLJS.M?KW(b):b,$CLJS.Ah.h(a)],null)});qX.m(null,nX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[nX,QW.g?QW.g(b,RW):QW.call(null,b,RW),$CLJS.Zs,FW(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[nX,QW.g?QW.g(b,RW):QW.call(null,b,RW),FW(c)],null)});
qX.m(null,$CLJS.oG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.ce.g(qX.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.oG,b,c,d],null)),QW.g?QW.g(a,RW):QW.call(null,a,RW)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.oG,QW.g?QW.g(b,RW):QW.call(null,b,RW),$CLJS.Fd(c)?c:FW(c),FW(d)],null)});
qX.m(null,$CLJS.SI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SI,b,FW(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SI,$CLJS.Lv],null)});qX.m(null,$CLJS.QI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QI,b,FW(a)],null)});
qX.m(null,$CLJS.$I,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.$I,QW.g?QW.g(b,RW):QW.call(null,b,RW),c,FW(a)],null)});qX.m(null,$CLJS.fJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fJ,QW.g?QW.g(b,RW):QW.call(null,b,RW),c,FW(a)],null)});
qX.m(null,$CLJS.YI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YI,QW.g?QW.g(b,RW):QW.call(null,b,RW),FW(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YI,QW.g?QW.g(b,RW):QW.call(null,b,RW)],null)});
qX.m(null,$CLJS.dJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dJ,QW.g?QW.g(b,RW):QW.call(null,b,RW),FW(c),FW(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dJ,QW.g?QW.g(b,RW):QW.call(null,b,RW),FW(c)],null)});
qX.m(null,$CLJS.PI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.PI,QW.g?QW.g(b,RW):QW.call(null,b,RW),QW.g?QW.g(c,RW):QW.call(null,c,RW),FW(a)],null)});qX.m(null,$CLJS.Ij,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ij,b,a],null)});
qX.m(null,$CLJS.ei,function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);return $CLJS.fg.j(new $CLJS.P(null,1,5,$CLJS.Q,[FW(a)],null),$CLJS.jf.h(function(c){return QW.g?QW.g(c,RW):QW.call(null,c,RW)}),b)});
var rX=function rX(a){if($CLJS.n($CLJS.tA($CLJS.he,$CLJS.Za)(a))){var c=FW(a);var d=new $CLJS.bh(null,new $CLJS.h(null,18,[$CLJS.go,null,$CLJS.Ur,null,$CLJS.sG,null,$CLJS.lG,null,$CLJS.at,null,$CLJS.dG,null,$CLJS.xG,null,$CLJS.yG,null,$CLJS.HF,null,$CLJS.OF,null,$CLJS.Tr,null,$CLJS.IF,null,$CLJS.qG,null,$CLJS.jG,null,$CLJS.pk,null,$CLJS.Jx,null,$CLJS.XF,null,$CLJS.pG,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(OW(a))?(a=$CLJS.z(a),rX.h?rX.h(a):rX.call(null,
a)):null},Vla=new $CLJS.h(null,8,[$CLJS.rj,FW,$CLJS.TP,function(a){a=GW(a);return $CLJS.y($CLJS.pQ.h(a))?$CLJS.Zk.j(a,$CLJS.pQ,xla):a},$CLJS.yF,new $CLJS.h(null,6,[$CLJS.SF,function sX(a){if($CLJS.n($CLJS.tA($CLJS.he,$CLJS.Za)(a)))return FW(a);if($CLJS.n(PW(kX,a))){a=$CLJS.y(a);$CLJS.z(a);var c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[kX,sX.h?sX.h(a):sX.call(null,a)],null),c)}return $CLJS.n($CLJS.n(OW(a))?rX($CLJS.hd(a)):null)?$CLJS.Yk.g(sX,a):QW.g?QW.g(a,
RW):QW.call(null,a,RW)},$CLJS.JP,function(a){return $CLJS.fg.g($CLJS.N,function(){return function d(c){return new $CLJS.oe(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.re(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[KW(u),QW.g?QW.g(t,RW):QW.call(null,t,RW)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.ue($CLJS.we(l),d($CLJS.mc(e))):$CLJS.ue($CLJS.we(l),null)}f=
$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[KW(l),QW.g?QW.g(f,RW):QW.call(null,f,RW)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.yR,function(a){return $CLJS.Ef(function(){return function d(c){return new $CLJS.oe(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.re(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=$CLJS.n(OW(t))?qX.h(t):$CLJS.de(qX.h($CLJS.de(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.ue($CLJS.we(l),d($CLJS.mc(e))):$CLJS.ue($CLJS.we(l),null)}l=$CLJS.z(e);return $CLJS.fe($CLJS.n(OW(l))?qX.h(l):$CLJS.de(qX.h($CLJS.de(l))),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.rQ,function(a){a=GW(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.TP);if($CLJS.n(b))return a=$CLJS.jV(a,new $CLJS.h(null,1,[$CLJS.TP,$CLJS.yF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.TP],null),a=QW.g?QW.g(a,b):QW.call(null,a,b),$CLJS.jV(a,new $CLJS.h(null,1,[$CLJS.yF,$CLJS.TP],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.yF],null);return QW.g?QW.g(a,b):QW.call(null,a,b)},$CLJS.BO,new $CLJS.h(null,1,[iX,SW],null),$CLJS.nN,new $CLJS.h(null,1,[iX,function(a){a=QW.g?QW.g(a,$CLJS.yF):QW.call(null,a,$CLJS.yF);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.wE),d=$CLJS.J.g(b,$CLJS.LE);a=$CLJS.J.g(b,$CLJS.PE);b=$CLJS.n(c)?$CLJS.Zk.j(b,$CLJS.wE,FW):b;d=$CLJS.n($CLJS.tA($CLJS.he,$CLJS.Za)(d))?$CLJS.Zk.j(b,$CLJS.LE,FW):b;return $CLJS.n(a)?$CLJS.Zk.j(d,$CLJS.PE,KW):d}],null)],null),$CLJS.yy,
new $CLJS.h(null,1,[$CLJS.ZN,$CLJS.Td],null),$CLJS.eN,new $CLJS.h(null,1,[iX,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.rj),d=$CLJS.J.g(b,$CLJS.kJ),e=$CLJS.J.g(b,$CLJS.dj);a=$CLJS.J.g(b,lX);b=$CLJS.n(e)?$CLJS.Zk.j(b,$CLJS.dj,KW):b;c=$CLJS.n(c)?$CLJS.Zk.j(b,$CLJS.rj,FW):b;d=$CLJS.n(d)?$CLJS.Zk.j(c,$CLJS.kJ,function(f){return QW.g?QW.g(f,RW):QW.call(null,f,RW)}):c;d=$CLJS.n(a)?$CLJS.gX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[lX,Nla],null),function(f){return QW.g?QW.g(f,RW):QW.call(null,f,RW)}):d;return $CLJS.n(a)?
$CLJS.gX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[lX,Jla],null),function(f){return QW.g?QW.g(f,RW):QW.call(null,f,RW)}):d}],null),$CLJS.$M,function(a){return null==a?null:FW(a)},$CLJS.BO,new $CLJS.h(null,1,[iX,SW],null),pX,FW],null),QW=function QW(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return QW.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
QW.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.Ef(b);b=$CLJS.y(c)?$CLJS.uA(Vla,c):null;try{return $CLJS.md(b)?b.h?b.h(a):b.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?$CLJS.fg.g($CLJS.N,function(){return function k(f){return new $CLJS.oe(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.re(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),A=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);A=FW(A);A=new $CLJS.P(null,
2,5,$CLJS.Q,[A,QW.l(x,$CLJS.H([$CLJS.ce.g($CLJS.Ef(c),A)]))],null);u.add(A);v+=1}else{m=!0;break a}return m?$CLJS.ue($CLJS.we(u),k($CLJS.mc(l))):$CLJS.ue($CLJS.we(u),null)}m=$CLJS.z(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=FW(u);return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[u,QW.l(m,$CLJS.H([$CLJS.ce.g($CLJS.Ef(c),u)]))],null),k($CLJS.Lc(l)))}return null}},null,null)}(a)}()):$CLJS.n(OW(a))?qX.h(a):$CLJS.wd(a)?$CLJS.Yk.g(function(e){return QW.l(e,$CLJS.H([$CLJS.ce.g($CLJS.Ef(c),iX)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.ii($CLJS.bE("Error normalizing form: {0}",$CLJS.H([$CLJS.cV(d)])),new $CLJS.h(null,3,[$CLJS.Qz,a,$CLJS.vl,c,Mla,b],null),d);}throw e;}};QW.A=1;QW.B=function(a){var b=$CLJS.z(a);a=$CLJS.B(a);return this.l(b,a)};
var UW=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.fi($CLJS.Gh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(OW(f))?$CLJS.z(f):null},e,a,b,c,d)}();UW.m(null,$CLJS.ei,function(a){return a});
UW.m(null,$CLJS.cG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(PW($CLJS.cG,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return UW.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,c,$CLJS.Ge($CLJS.nl.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,b,$CLJS.Ge(a)],null)});
UW.m(null,$CLJS.SF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.td(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SF,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SF,b,a],null)});UW.m(null,$CLJS.MK,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(OW(a))?UW.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,a,null],null)});
UW.m(null,oX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,b,new $CLJS.h(null,1,[$CLJS.kB,a],null)],null)});UW.m(null,Ola,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=VW(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=VW(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,a,$CLJS.R.j(c,$CLJS.oN,b)],null)});
UW.m(null,Pla,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=VW(a);return $CLJS.Te.v($CLJS.rV,a,$CLJS.R,$CLJS.H([$CLJS.dP,b]))});
UW.m(null,nX,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=VW(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.kB);$CLJS.Ua(d)||$CLJS.vS.g(d,b)?a=$CLJS.Te.v($CLJS.rV,a,$CLJS.R,$CLJS.H([$CLJS.iG,b])):(c=$CLJS.DA($CLJS.wy),$CLJS.n($CLJS.CA("metabase.mbql.util",c))&&(b=$CLJS.bE("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.Nh.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.BA("metabase.mbql.util",c,$CLJS.sw(),b):$CLJS.BA("metabase.mbql.util",c,$CLJS.sw.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),UW.h(new $CLJS.P(null,3,5,$CLJS.Q,[nX,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
UW.m(null,$CLJS.mV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=VW(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,b,$CLJS.R.j(e,$CLJS.eR,$CLJS.nl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.wE,c],null),$CLJS.n(d)?$CLJS.Fe([c,d]):null,a])))],null)});
for(var tX=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,$CLJS.ms,$CLJS.os],null)),uX=null,vX=0,wX=0;;)if(wX<vX){var xX=uX.X(null,wX);UW.m(null,xX,function(){return function(a){return WW(a)}}(tX,uX,vX,wX,xX));wX+=1}else{var yX=$CLJS.y(tX);if(yX){var zX=yX;if($CLJS.Ad(zX)){var AX=$CLJS.lc(zX),Wla=$CLJS.mc(zX),Xla=AX,Yla=$CLJS.D(AX);tX=Wla;uX=Xla;vX=Yla}else{var BX=$CLJS.z(zX);UW.m(null,BX,function(){return function(a){return WW(a)}}(tX,uX,vX,wX,BX,zX,yX));tX=$CLJS.B(zX);uX=null;vX=0}wX=0}else break}
UW.m(null,$CLJS.KF,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.fg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KF,VW(a),VW(b)],null),c)});UW.m(null,$CLJS.oG,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=VW(a);a=$CLJS.n(LW($CLJS.cG,a))?$CLJS.rV.l(c,$CLJS.Ok,$CLJS.H([$CLJS.iG])):c;return $CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oG,a],null),b)});
for(var CX=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.UF,$CLJS.nG,$CLJS.bG,$CLJS.VF,$CLJS.Rr,$CLJS.uG,$CLJS.Or,$CLJS.Qr,$CLJS.Kr,$CLJS.Mr,$CLJS.FF,$CLJS.MF,$CLJS.DF,$CLJS.PF,$CLJS.EF],null)),DX=null,EX=0,FX=0;;)if(FX<EX){var GX=DX.X(null,FX);UW.m(null,GX,function(){return function(a){return XW(a)}}(CX,DX,EX,FX,GX));FX+=1}else{var HX=$CLJS.y(CX);if(HX){var IX=HX;if($CLJS.Ad(IX)){var JX=$CLJS.lc(IX),Zla=$CLJS.mc(IX),$la=JX,ama=$CLJS.D(JX);CX=Zla;DX=$la;EX=ama}else{var KX=$CLJS.z(IX);UW.m(null,KX,
function(){return function(a){return XW(a)}}(CX,DX,EX,FX,KX,IX,HX));CX=$CLJS.B(IX);DX=null;EX=0}FX=0}else break}UW.m(null,Lla,function(){return null});UW.m(null,$CLJS.eM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eM,UW.h(b),a],null)});
UW.m(null,kX,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=UW.h;var e=$CLJS.Q;b=UW.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,Kla)?new $CLJS.h(null,1,[$CLJS.T,a],null):new $CLJS.h(null,1,[$CLJS.gE,a],null);return c.call(UW,new $CLJS.P(null,3,5,e,[$CLJS.eM,b,a],null))});
for(var LX=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jx,$CLJS.dG],null)),MX=null,NX=0,OX=0;;)if(OX<NX){var PX=MX.X(null,OX);UW.m(null,PX,function(){return function(a){return YW(a)}}(LX,MX,NX,OX,PX));OX+=1}else{var QX=$CLJS.y(LX);if(QX){var RX=QX;if($CLJS.Ad(RX)){var SX=$CLJS.lc(RX),bma=$CLJS.mc(RX),cma=SX,dma=$CLJS.D(SX);LX=bma;MX=cma;NX=dma}else{var TX=$CLJS.z(RX);UW.m(null,TX,function(){return function(a){return YW(a)}}(LX,MX,NX,OX,TX,RX,QX));LX=$CLJS.B(RX);MX=null;NX=0}OX=0}else break}
for(var UX=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.XF,$CLJS.zG,$CLJS.HF,$CLJS.sG,$CLJS.pG,$CLJS.go,$CLJS.pk,$CLJS.qG,$CLJS.IF],null)),VX=null,WX=0,XX=0;;)if(XX<WX){var YX=VX.X(null,XX);UW.m(null,YX,function(){return function(a){return ZW(a)}}(UX,VX,WX,XX,YX));XX+=1}else{var ZX=$CLJS.y(UX);if(ZX){var $X=ZX;if($CLJS.Ad($X)){var aY=$CLJS.lc($X),ema=$CLJS.mc($X),fma=aY,gma=$CLJS.D(aY);UX=ema;VX=fma;WX=gma}else{var bY=$CLJS.z($X);UW.m(null,bY,function(){return function(a){return ZW(a)}}(UX,VX,WX,XX,
bY,$X,ZX));UX=$CLJS.B($X);VX=null;WX=0}XX=0}else break}UW.m(null,$CLJS.OF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OF,VW(b),a],null)});
for(var cY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jG,$CLJS.lG],null)),dY=null,eY=0,fY=0;;)if(fY<eY){var gY=dY.X(null,fY);UW.m(null,gY,function(){return function(a){return $W(a)}}(cY,dY,eY,fY,gY));fY+=1}else{var hY=$CLJS.y(cY);if(hY){var iY=hY;if($CLJS.Ad(iY)){var jY=$CLJS.lc(iY),hma=$CLJS.mc(iY),ima=jY,jma=$CLJS.D(jY);cY=hma;dY=ima;eY=jma}else{var kY=$CLJS.z(iY);UW.m(null,kY,function(){return function(a){return $W(a)}}(cY,dY,eY,fY,kY,iY,hY));cY=$CLJS.B(iY);dY=null;eY=0}fY=0}else break}
UW.m(null,$CLJS.xG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xG,UW.h(b),UW.h(a)],null)});
UW.m(null,$CLJS.LF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.ce.g(UW.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LF,b],null)),QW.l(a,$CLJS.H([RW]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LF,$CLJS.Ef(function(){return function e(d){return new $CLJS.oe(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.re(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[UW.h(v),UW.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.ue($CLJS.we(m),e($CLJS.mc(f))):$CLJS.ue($CLJS.we(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[UW.h(m),UW.h(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}(b)}())],null)});
UW.m(null,$CLJS.uF,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.fg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uF,UW.h(a),$CLJS.E.g(0,b)?1:UW.h(b)],null),$CLJS.jf.g(UW,c))});
var bX=function bX(a){return function f(d,e){try{if($CLJS.n(function(){var x=IW($CLJS.Dd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.Ef(e));throw $CLJS.Z;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.Z)try{if($CLJS.n(function(){var A=IW($CLJS.he);return A.h?A.h(e):A.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Z;}catch(A){if(A instanceof Error)if(d=A,d===$CLJS.Z)try{if($CLJS.zd(e)&&2<=$CLJS.D(e))try{var k=$CLJS.cl.j(e,
0,2);if($CLJS.zd(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.$D($CLJS.he,$CLJS.Ue(new $CLJS.bh(null,new $CLJS.h(null,5,[$CLJS.Ur,null,$CLJS.at,null,$CLJS.yG,null,$CLJS.Tr,null,kX,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(rX(m)))return $CLJS.fg.j($CLJS.Df,$CLJS.aE(bX),a);throw $CLJS.Z;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;
}else throw $CLJS.Z;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Z)try{if($CLJS.zd(e)&&1<=$CLJS.D(e))try{var u=$CLJS.cl.j(e,0,1);if($CLJS.zd(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Z;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){v=
G;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Z)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw A;}else throw d;else throw x;}}($CLJS.Df,a)},lY=function lY(a){return function f(d,e){try{var k=IW($CLJS.Dd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.Ef(e));throw $CLJS.Z;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Z)try{if($CLJS.zd(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.ie(m,
$CLJS.$F)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$F,t],null))}throw $CLJS.Z;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ie(m,$CLJS.TF))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TF,t],null));throw $CLJS.Z;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ie(m,jX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$F,t],null));throw $CLJS.Z;}catch(S){if(S instanceof Error)if(l=
S,l===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ie(m,mX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TF,t],null));throw $CLJS.Z;}catch(V){if(V instanceof Error)if(m=V,m===$CLJS.Z)try{var v=$CLJS.F(e,0);if($CLJS.ie(v,jX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$F,t],null));throw $CLJS.Z;}catch(Y){if(Y instanceof Error)if(m=Y,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ie(v,mX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TF,t],null));throw $CLJS.Z;}catch(ha){if(ha instanceof
Error)if(m=ha,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ie(v,$CLJS.$F))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$F,VW(t)],null);throw $CLJS.Z;}catch(ta){if(ta instanceof Error)if(m=ta,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ie(v,$CLJS.TF))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TF,VW(t)],null);throw $CLJS.Z;}catch(Ta){if(Ta instanceof Error){t=Ta;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw Ta;}else throw m;else throw ta;}else throw m;else throw ha;}else throw m;else throw Y;
}else throw m;else throw V;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Z)try{if($CLJS.zd(e)&&0<=$CLJS.D(e))try{var x=$CLJS.cl.j(e,0,0);if($CLJS.zd(x)&&0===$CLJS.D(x))try{var A=$CLJS.cl.g(e,0);if($CLJS.E.g(A,a))return $CLJS.Ef($CLJS.pl.h($CLJS.jf.g(lY,a)));throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof
Error){u=K;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)return $CLJS.JW(f,d,e);throw u;}throw K;}else throw l;else throw G;}else throw l;else throw C;}}($CLJS.Df,a)},eX=$CLJS.Wk.g(HW,function(a){var b=fX($CLJS.SF.h(a))?$CLJS.Zk.j(a,$CLJS.SF,Ala):a;b=fX($CLJS.RF.h(a))?$CLJS.Zk.j(b,$CLJS.RF,Bla):b;b=fX($CLJS.LE.h(a))?$CLJS.Zk.j(b,$CLJS.LE,$CLJS.We($CLJS.Yk,TW)):b;b=fX($CLJS.yR.h(a))?$CLJS.Zk.j(b,$CLJS.yR,lY):b;return fX($CLJS.rQ.h(a))?
$CLJS.Zk.j(b,$CLJS.rQ,Dla):b}),kma=new $CLJS.h(null,3,[$CLJS.TP,$CLJS.Td,$CLJS.yF,new $CLJS.h(null,2,[$CLJS.rQ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.TP);if($CLJS.n(b))return a=$CLJS.jV(a,new $CLJS.h(null,1,[$CLJS.TP,$CLJS.yF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.TP],null),a=hX.g?hX.g(a,b):hX.call(null,a,b),$CLJS.jV(a,new $CLJS.h(null,1,[$CLJS.yF,$CLJS.TP],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.yF],null);return hX.g?hX.g(a,b):hX.call(null,a,b)},$CLJS.nN,new $CLJS.h(null,1,
[iX,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.yF],null);return hX.g?hX.g(a,b):hX.call(null,a,b)}],null)],null),pX,$CLJS.Td],null),hX=function hX(a){switch(arguments.length){case 1:return hX.h(arguments[0]);case 2:return hX.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};hX.h=function(a){return hX.g(a,$CLJS.Df)};
hX.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.uA(kma,b):null;return $CLJS.md(c)?c.h?c.h(a):c.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?Hla(a,b):$CLJS.wd(a)?Ila(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.ii("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.Qz,a,$CLJS.vl,b],null),d);throw d;}};hX.A=2;
$CLJS.mY=function(){var a=$CLJS.Wk.l(hX,Gla,Ela,$CLJS.H([QW]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.ii($CLJS.bE("Error normalizing query: {0}",$CLJS.H([$CLJS.cV(c)])),new $CLJS.h(null,1,[$CLJS.yF,b],null),c);}throw d;}}}();$CLJS.nY=function nY(a,b){if($CLJS.Ua($CLJS.y(a)))a=(0,$CLJS.mY)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.qt(a);b=$CLJS.Fe([$CLJS.id(a),b]);f=nY.g?nY.g(f,b):nY.call(null,f,b);a=e.call(d,f,$CLJS.id(a))}return a};