var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.cache.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var tna,A_,una,C_,D_,E_,F_,a0,xna,wna,yna,vna,g0,T_,i0,O_,zna,V_;tna=function(a){return $CLJS.y(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};A_=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Hd($CLJS.tea,a)?a:"_":$CLJS.n(b)?encodeURIComponent(a):a};
una=function(a){var b=$CLJS.O(new $CLJS.h(null,1,[$CLJS.mB,!0],null)),c=$CLJS.J.g(b,$CLJS.sea),d=$CLJS.J.g(b,$CLJS.mB);return $CLJS.y(a)?(b=$CLJS.gV(function(){return function k(f){return new $CLJS.oe(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.re(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);x=A_(x,$CLJS.Ua(d));u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.ue($CLJS.we(u),k($CLJS.mc(l))):$CLJS.ue($CLJS.we(u),null)}u=$CLJS.z(l);return $CLJS.fe(A_(u,
$CLJS.Ua(d)),k($CLJS.Lc(l)))}return null}},null,null)}(tna($CLJS.SA(a)))}()),$CLJS.n(c)?$CLJS.gV($CLJS.bf(c,b)):b):null};$CLJS.B_=function(a){return $CLJS.PO.h($CLJS.z($CLJS.eL.h(a)))};C_=function(a,b){if(null!=a&&null!=a.Ag)a=a.Ag(a,b);else{var c=C_[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=C_._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.table",a);}return a};
D_=function(a,b){if(null!=a&&null!=a.rg)a=a.rg(a,b);else{var c=D_[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=D_._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.card",a);}return a};E_=function(a,b){if(null!=a&&null!=a.vg)a=a.vg(a,b);else{var c=E_[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=E_._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.metric",a);}return a};
F_=function(a,b){if(null!=a&&null!=a.ug)a=a.ug(a,b);else{var c=F_[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=F_._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.fields",a);}return a};$CLJS.G_=function(a){return $CLJS.fZ(a)?a:$CLJS.cE.h(a)};$CLJS.H_=function(a,b){return C_($CLJS.G_(a),b)};$CLJS.I_=function(a,b){return F_($CLJS.G_(a),b)};$CLJS.J_=function(a,b){return D_($CLJS.G_(a),b)};$CLJS.K_=function(a,b){return E_($CLJS.G_(a),b)};
$CLJS.L_=function(a,b,c){if($CLJS.Ya(b)||$CLJS.xd(b)){$CLJS.n(b.__mbcache)||(b.__mbcache=$CLJS.Ye($CLJS.N));var d=b.__mbcache;if($CLJS.n(d)){var e=$CLJS.J.g($CLJS.q(d),a);if($CLJS.n(e))return e;b=c.h?c.h(b):c.call(null,b);$CLJS.Ph.v(d,$CLJS.R,a,b);return b}}return c.h?c.h(b):c.call(null,b)};
a0=function(a,b,c){var d=$CLJS.M_.j(a,b,c);return $CLJS.nl.l($CLJS.H([$CLJS.lm(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,$CLJS.gE,$CLJS.ak],null)),function(){var e=N_.h(c);return $CLJS.n(e)?new $CLJS.h(null,2,[$CLJS.gE,e,O_,!0],null):null}(),function(){var e=$CLJS.P_.v(a,b,c,$CLJS.Q_);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.R_,e],null):null}(),function(){var e=$CLJS.tA($CLJS.Ii,$CLJS.kB)(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Ii,e],null):null}(),function(){var e=$CLJS.QK.h(d);return $CLJS.n(e)?
new $CLJS.h(null,1,[$CLJS.yW,$CLJS.S_.j(a,b,$CLJS.Fd(e)?$CLJS.H_(a,e):"string"===typeof e?$CLJS.J_(a,$CLJS.qW(e)):null)],null):null}(),function(){var e=$CLJS.FK.h(d);return $CLJS.n(e)?new $CLJS.h(null,6,[T_,$CLJS.E.g(e,$CLJS.hZ),$CLJS.U_,$CLJS.E.g(e,$CLJS.rZ),V_,$CLJS.E.g(e,$CLJS.oZ),$CLJS.W_,$CLJS.E.g(e,$CLJS.qZ),vna,$CLJS.E.g(e,$CLJS.YK),wna,$CLJS.E.g(e,$CLJS.SK)],null):null}(),function(){var e=$CLJS.wZ.h(d);return null==e?null:new $CLJS.h(null,1,[$CLJS.X_,e],null)}(),$CLJS.lm(d,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.Y_,$CLJS.Z_,$CLJS.$_],null))]))};$CLJS.b0=new $CLJS.M(null,"fk-join-alias","fk-join-alias",997510084);$CLJS.$_=new $CLJS.M(null,"filter-positions","filter-positions",378749375);$CLJS.c0=new $CLJS.M(null,"direction","direction",-633359395);$CLJS.W_=new $CLJS.M(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);$CLJS.Y_=new $CLJS.M(null,"breakout-position","breakout-position",-760153191);
$CLJS.d0=new $CLJS.M(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);$CLJS.X_=new $CLJS.M(null,"selected","selected",574897764);xna=new $CLJS.M(null,"is-order-by-column","is-order-by-column",1320802549);wna=new $CLJS.M(null,"is-breakout","is-breakout",-1878069983);yna=new $CLJS.r("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);
$CLJS.e0=new $CLJS.M("metabase.lib.metadata.calculation","source-field-id","metabase.lib.metadata.calculation/source-field-id",352667413);$CLJS.f0=new $CLJS.M("metabase.lib.metadata.calculation","source-join-alias","metabase.lib.metadata.calculation/source-join-alias",-1432523829);vna=new $CLJS.M(null,"is-aggregation","is-aggregation",872502629);g0=new $CLJS.M("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);
$CLJS.h0=new $CLJS.M(null,"include-joined?","include-joined?",-410632824);T_=new $CLJS.M(null,"is-from-previous-stage","is-from-previous-stage",956780376);i0=new $CLJS.M(null,"is-source-table","is-source-table",1559075056);O_=new $CLJS.M(null,"named?","named?",-213401078);$CLJS.j0=new $CLJS.M(null,"unique-name-fn","unique-name-fn",-755402870);$CLJS.U_=new $CLJS.M(null,"is-from-join","is-from-join",-1107917905);$CLJS.k0=new $CLJS.M(null,"requires-column","requires-column",934105295);
$CLJS.Z_=new $CLJS.M(null,"order-by-position","order-by-position",-1307229997);zna=new $CLJS.M(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.R_=new $CLJS.M(null,"long-display-name","long-display-name",1550784464);$CLJS.l0=new $CLJS.M(null,"include-implicitly-joinable-for-source-card?","include-implicitly-joinable-for-source-card?",1240062794);$CLJS.Q_=new $CLJS.M(null,"long","long",-171452093);$CLJS.m0=new $CLJS.M(null,"short-name","short-name",-1767085022);
$CLJS.n0=new $CLJS.M(null,"include-expressions?","include-expressions?",-1817639288);V_=new $CLJS.M(null,"is-calculated","is-calculated",-86687269);var N_,Bna,Cna,Dna,Ena,Fna;$CLJS.o0=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.$B],null),$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.fi($CLJS.Gh.g("metabase.lib.metadata.calculation","display-name-method"),function(f,k,l){return $CLJS.YB(l)},e,a,b,c,d)}();
$CLJS.p0=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.$B],null),$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.fi($CLJS.Gh.g("metabase.lib.metadata.calculation","column-name-method"),function(f,k,l){return $CLJS.YB(l)},e,a,b,c,d)}();
$CLJS.P_=function(){function a(f,k,l,m){var t=$CLJS.tA($CLJS.gE,$CLJS.kE)($CLJS.zE(l));if($CLJS.n(t))return t;try{return $CLJS.o0.v(f,k,l,m)}catch(u){if(u instanceof Error)throw k=u,$CLJS.ii($CLJS.bE("Error calculating display name for {0}: {1}",$CLJS.H([$CLJS.Nh.l($CLJS.H([l])),$CLJS.cV(k)])),new $CLJS.h(null,2,[$CLJS.yF,f,$CLJS.tE,l],null),k);throw u;}}function b(f,k,l){return e.v?e.v(f,k,l,$CLJS.ei):e.call(null,f,k,l,$CLJS.ei)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){return e.g?
e.g(f,f):e.call(null,f,f)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();
$CLJS.q0=function(){function a(d,e,f){var k=$CLJS.T.h($CLJS.zE(f));if($CLJS.n(k))return k;try{return $CLJS.p0.j(d,e,f)}catch(l){if(l instanceof Error)throw k=l,$CLJS.ii($CLJS.bE("Error calculating column name for {0}: {1}",$CLJS.H([$CLJS.Nh.l($CLJS.H([f])),$CLJS.cV(k)])),new $CLJS.h(null,3,[$CLJS.tE,f,$CLJS.yF,d,$CLJS.qL,e],null),k);throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,
d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.o0.m(null,$CLJS.ei,function(a,b,c){a=$CLJS.DA($CLJS.wy);$CLJS.n($CLJS.CA("metabase.lib.metadata.calculation",a))&&$CLJS.BA("metabase.lib.metadata.calculation",a,$CLJS.eW("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.H([$CLJS.Nh.l($CLJS.H([c])),yna,$CLJS.YB(c)])),null);return $CLJS.zd(c)&&$CLJS.z(c)instanceof $CLJS.M?$CLJS.hh($CLJS.z(c)):$CLJS.Nh.l($CLJS.H([c]))});
$CLJS.p0.m(null,$CLJS.ei,function(a,b,c){return una($CLJS.xA($CLJS.P_.j(a,b,c),/[\(\)]/,""))});$CLJS.r0=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.$B],null),$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.fi($CLJS.Gh.g("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,k,l){return l},e,a,b,c,d)}();
$CLJS.Ana=function(){function a(d,e,f){return $CLJS.r0.j(d,e,$CLJS.Ah.h(f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.s0=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.$B],null),$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.fi($CLJS.Gh.g("metabase.lib.metadata.calculation","type-of-method"),function(f,k,l){return $CLJS.YB(l)},e,a,b,c,d)}();
$CLJS.t0=function(){function a(d,e,f){var k=$CLJS.zE(f);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.iG),m=$CLJS.Ii.h(k);if($CLJS.n(m))return m;l=$CLJS.n($CLJS.n(l)?$CLJS.Hd($CLJS.kH,l):l)?$CLJS.Bj:null;if($CLJS.n(l))return l;k=$CLJS.kB.h(k);if($CLJS.n(k))return k;d=$CLJS.s0.j(d,e,f);return $CLJS.lC(d,$CLJS.Fj)?d:$CLJS.Fj}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.s0.m(null,$CLJS.ei,function(a,b,c){return $CLJS.GE(c)});$CLJS.s0.m(null,$CLJS.WE,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.t0.j(a,b,c)});$CLJS.s0.m(null,$CLJS.NI,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);return"string"===typeof d?$CLJS.GE(c):$CLJS.t0.j(a,b,d)});
$CLJS.u0=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.$B],null),$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.fi($CLJS.Gh.g("metabase.lib.metadata.calculation","metadata-method"),function(f,k,l){return $CLJS.YB(l)},e,a,b,c,d)}();
$CLJS.u0.m(null,$CLJS.ei,function(a,b,c){try{return new $CLJS.h(null,4,[$CLJS.Qt,$CLJS.DK,$CLJS.kB,$CLJS.t0.j(a,b,c),$CLJS.T,$CLJS.q0.j(a,b,c),$CLJS.gE,$CLJS.P_.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.ii($CLJS.bE("Error calculating metadata for {0}: {1}",$CLJS.H([$CLJS.Nh.l($CLJS.H([$CLJS.YB(c)])),$CLJS.cV(d)])),new $CLJS.h(null,3,[$CLJS.yF,a,$CLJS.qL,b,$CLJS.tE,c],null),d);}throw e;}});
$CLJS.M_=function(){function a(e,f,k){return $CLJS.u0.j(e,f,k)}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return d.j?d.j(e,-1,e):d.call(null,e,-1,e)}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();
$CLJS.v0=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.$B],null),$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.fi($CLJS.Gh.g("metabase.lib.metadata.calculation","display-info-method"),function(f,k,l){return $CLJS.YB(l)},e,a,b,c,d)}();
$CLJS.X(g0,new $CLJS.P(null,20,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gE,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.ek],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.R_,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.ek],null),new $CLJS.P(null,3,5,$CLJS.Q,[O_,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.xs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yW,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,g0],null)],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[T_,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.xs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U_,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.xs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[V_,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.xs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.W_,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ls,$CLJS.xs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[i0,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.xs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[zna,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.xs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[xna,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.xs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,
1,[$CLJS.Cs,!0],null),$CLJS.ek],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NF,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.ek],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ct,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.ek],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.m0,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.ek],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.k0,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.xs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.X_,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.xs],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ei,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.xs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.c0,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,$CLJS.$F,$CLJS.TF],null)],null)],null));
$CLJS.S_=function(){function a(d,e,f){return $CLJS.L_($CLJS.Ah.g("display-info",["stage-",$CLJS.p.h(e)].join("")),f,function(k){try{return $CLJS.v0.j(d,e,k)}catch(m){if(m instanceof Error){var l=m;throw $CLJS.ii($CLJS.bE("Error calculating display info for {0}: {1}",$CLJS.H([$CLJS.YB(k),$CLJS.cV(l)])),new $CLJS.h(null,3,[$CLJS.yF,d,$CLJS.qL,e,$CLJS.tE,k],null),l);}throw m;}})}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();Bna=$CLJS.Ye($CLJS.N);Cna=$CLJS.Ye($CLJS.N);Dna=$CLJS.Ye($CLJS.N);Ena=$CLJS.Ye($CLJS.N);Fna=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.$B],null),$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));N_=new $CLJS.fi($CLJS.Gh.g("metabase.lib.metadata.calculation","custom-name-method"),$CLJS.YB,Fna,Bna,Cna,Dna,Ena);
N_.m(null,$CLJS.ei,function(a){return $CLJS.hW(a)?$CLJS.tA($CLJS.gE,$CLJS.kE)($CLJS.zE(a)):null});$CLJS.v0.m(null,$CLJS.ei,function(a,b,c){return a0(a,b,c)});$CLJS.v0.m(null,$CLJS.KK,function(a,b,c){return $CLJS.nl.l($CLJS.H([a0(a,b,c),new $CLJS.h(null,1,[i0,$CLJS.E.g($CLJS.B_(a),$CLJS.dj.h(c))],null)]))});
$CLJS.w0=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.$B],null),$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.fi($CLJS.Gh.g("metabase.lib.metadata.calculation","returned-columns-method"),function(f,k,l){return $CLJS.YB(l)},e,a,b,c,d)}();$CLJS.w0.m(null,$CLJS.nB,function(){return $CLJS.Df});
$CLJS.x0=function(){function a(f,k,l,m){m=$CLJS.nl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.j0,$CLJS.sW()],null),m]));return $CLJS.w0.v(f,k,l,m)}function b(f,k,l){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.pW(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,
f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();$CLJS.y0=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.$B],null),$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.fi($CLJS.Gh.g("metabase.lib.metadata.calculation","visible-columns-method"),function(f,k,l){return $CLJS.YB(l)},e,a,b,c,d)}();$CLJS.y0.m(null,$CLJS.nB,function(){return $CLJS.Df});
$CLJS.y0.m(null,$CLJS.ei,function(a,b,c,d){return $CLJS.w0.v(a,b,c,d)});
$CLJS.z0=function(){function a(f,k,l,m){m=$CLJS.nl.l($CLJS.H([$CLJS.nl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.j0,$CLJS.sW()],null),new $CLJS.h(null,4,[$CLJS.h0,!0,$CLJS.n0,!0,$CLJS.d0,!0,$CLJS.l0,!0],null)])),m]));return $CLJS.y0.v(f,k,l,m)}function b(f,k,l){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.pW(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,
f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();module.exports={display_name:$CLJS.P_};