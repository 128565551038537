var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var eZ,Yma,Zma,$ma,ana,bna,kZ,cna,dna,ena,nZ,fna,gna,hna,sZ,tZ,ina,jna,kna,CZ,lna,mna,nna,FZ,ona,pna,qna,rna;eZ=function(){};$CLJS.fZ=function(a){return null!=a?$CLJS.Bc===a.mi?!0:a.Wc?!1:$CLJS.$a(eZ,a):$CLJS.$a(eZ,a)};Yma=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.gZ=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Zma=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);$ma=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.hZ=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.iZ=new $CLJS.M(null,"database-id","database-id",1883826326);ana=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.jZ=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);bna=new $CLJS.M(null,"human-readable-values","human-readable-values",-624842907);kZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);cna=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);dna=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
ena=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.lZ=new $CLJS.M("metabase.lib.schema.metadata","column","metabase.lib.schema.metadata/column",-570344650);$CLJS.mZ=new $CLJS.M(null,"dataset","dataset",1159262238);nZ=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);fna=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.oZ=new $CLJS.M("source","expressions","source/expressions",-458367840);
$CLJS.pZ=new $CLJS.M(null,"definition","definition",-1198729982);gna=new $CLJS.M(null,"state","state",-1988618099);$CLJS.qZ=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);hna=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.rZ=new $CLJS.M("source","joins","source/joins",1225821486);sZ=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);
tZ=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.uZ=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.vZ=new $CLJS.M(null,"fk-target-field-id","fk-target-field-id",2019750287);ina=new $CLJS.M("lib","external-remap","lib/external-remap",543358316);$CLJS.wZ=new $CLJS.M(null,"selected?","selected?",-742502788);
jna=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);kna=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.xZ=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.yZ=new $CLJS.M("source","card","source/card",-139977973);$CLJS.zZ=new $CLJS.M(null,"active","active",1895962068);$CLJS.AZ=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);
$CLJS.BZ=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);CZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);lna=new $CLJS.M(null,"details","details",1956795411);$CLJS.DZ=new $CLJS.M("source","fields","source/fields",-649667981);mna=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.EZ=new $CLJS.M("source","native","source/native",-1444604147);
nna=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);FZ=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.GZ=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);ona=new $CLJS.M("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);pna=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);
qna=new $CLJS.M("lib","internal-remap","lib/internal-remap",-220033801);rna=new $CLJS.M("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);$CLJS.HZ=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.IZ=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.X(sZ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.js,$CLJS.yZ,$CLJS.EZ,$CLJS.hZ,$CLJS.jZ,$CLJS.DZ,$CLJS.YK,$CLJS.SK,$CLJS.rZ,$CLJS.oZ,$CLJS.qZ],null));$CLJS.X(CZ,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,rna],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.LK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.lE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MK,$CLJS.WK],null)],null));
$CLJS.X(kZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,ona],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.LK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.lE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,$CLJS.ys],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[bna,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,$CLJS.ys],null)],null)],null));
$CLJS.X($CLJS.lZ,new $CLJS.P(null,19,5,$CLJS.Q,[$CLJS.ok,new $CLJS.h(null,1,[$CLJS.ht,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.DK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.ek],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kB,$CLJS.uE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dj,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.WK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gE,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ls,$CLJS.ek],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ii,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.uE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PK,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.WK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vZ,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.WK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HZ,
new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.lE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kE,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.lE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FK,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sZ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GZ,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.NK],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gZ,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.lE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uZ,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.h(null,2,[$CLJS.go,1,$CLJS.pk,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wZ,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.xs],null),new $CLJS.P(null,3,5,$CLJS.Q,
[ina,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CZ],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[qna,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kZ],null)],null)],null)],null));
$CLJS.X(FZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xZ,$CLJS.lE],null),new $CLJS.P(null,2,5,$CLJS.Q,[fna,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[ena,$CLJS.lE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kB,$CLJS.uE],null)],null)],null)],null)],null)],null));
$CLJS.X(nZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zZ,$CLJS.xs],null),new $CLJS.P(null,2,5,$CLJS.Q,[gna,$CLJS.lE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xZ,$CLJS.lE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pZ,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FZ],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CK,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,
$CLJS.lE],null)],null)],null));
$CLJS.X(mna,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ok,new $CLJS.h(null,1,[$CLJS.ht,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.XK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.NK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.lE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iZ,$CLJS.OK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AZ,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.ok],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BZ,
new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,$CLJS.ok],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mZ,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.xs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QK,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.IK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$ma,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,nZ],null)],null)],null)],null));
$CLJS.X(jna,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ok,new $CLJS.h(null,1,[$CLJS.ht,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.HK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.GK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.lE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QK,$CLJS.IK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.ok],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Ct,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.lE],null)],null)],null));
$CLJS.X(kna,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ok,new $CLJS.h(null,1,[$CLJS.ht,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.JK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.EK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.lE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QK,$CLJS.IK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.ok],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Ct,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.lE],null)],null)],null));
$CLJS.X(nna,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ok,new $CLJS.h(null,1,[$CLJS.ht,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.KK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.IK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.lE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gE,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.lE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xl,new $CLJS.h(null,
1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.lE],null)],null)],null));
$CLJS.X(Zma,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ok,new $CLJS.h(null,1,[$CLJS.ht,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.UK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.OK],null),new $CLJS.P(null,3,5,$CLJS.Q,[dna,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.ok],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[lna,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.ok],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.IZ,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.bj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yz,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,$CLJS.bj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[hna,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.xs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RK,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.ok],null)],null)],null));
$CLJS.X(tZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.ht,"Valid MetadataProvider"],null),new $CLJS.Gc(function(){return $CLJS.fZ},ana,$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,$CLJS.vi,$CLJS.qk,$CLJS.uk],[Yma,pna,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Pq],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.fZ)?$CLJS.fZ.H:null]))],null));
$CLJS.X(cna,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.h(null,1,[$CLJS.ht,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tZ],null)],null)],null)],null));