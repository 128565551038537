var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.drill_thru.automatic_insights.js");require("./metabase.lib.drill_thru.column_filter.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.drill_thru.distribution.js");require("./metabase.lib.drill_thru.fk_details.js");require("./metabase.lib.drill_thru.fk_filter.js");require("./metabase.lib.drill_thru.object_details.js");require("./metabase.lib.drill_thru.pivot.js");require("./metabase.lib.drill_thru.pk.js");require("./metabase.lib.drill_thru.quick_filter.js");require("./metabase.lib.drill_thru.sort.js");require("./metabase.lib.drill_thru.summarize_column.js");require("./metabase.lib.drill_thru.summarize_column_by_time.js");require("./metabase.lib.drill_thru.underlying_records.js");require("./metabase.lib.drill_thru.zoom.js");require("./metabase.lib.drill_thru.zoom_in_bins.js");require("./metabase.lib.drill_thru.zoom_in_geographic.js");require("./metabase.lib.drill_thru.zoom_in_timeseries.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var hsa,b8,c8,d8,f8,g8,h8,isa,i8,jsa,ksa,j8,k8,lsa,l8,m8,msa,n8,nsa,osa,o8,p8,q8,r8,psa,s8,qsa,rsa,ssa,tsa,t8,usa,vsa,u8,wsa,xsa,ysa,zsa,Asa,v8,Bsa,Csa,Dsa,Esa,Fsa,Gsa,Hsa,w8,Isa,Jsa,Ksa,Lsa,Msa,Nsa,Osa,Psa,Qsa,Rsa,Ssa,Tsa,Usa,Vsa,Wsa,Xsa,Ysa,x8,y8,Zsa,$sa,ata,z8,bta,cta,dta,eta,A8,fta,gta,hta,B8,ita,jta,kta,lta,mta;$CLJS.a8=function(a){return $CLJS.gc($CLJS.eb(function(b,c){return $CLJS.ig.j(b,c,$CLJS.J.j(b,c,0)+1)},$CLJS.ec($CLJS.N),a))};
hsa=function(a){var b=$CLJS.pW(a,0),c=$CLJS.O(b),d=$CLJS.J.g(c,$CLJS.PO),e=$CLJS.J.g(c,$CLJS.yV);return $CLJS.Ed(function(){var f=$CLJS.k1(a);if($CLJS.n(f)){f=$CLJS.n(d)?$CLJS.H_(a,d):d;if($CLJS.n(f))return f;f=$CLJS.n(e)?$CLJS.J_(a,e):e;return $CLJS.n(f)?f:$CLJS.E.g($CLJS.Qt.h(c),$CLJS.MV)}return f}())};b8=function(a){var b=$CLJS.B_(a);return $CLJS.n(b)?$CLJS.mf($CLJS.o_,$CLJS.I_(a,b)):$CLJS.Df};
c8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Xi),e=$CLJS.J.g(c,$CLJS.U5),f=$CLJS.J.g(c,$CLJS.tL),k=$CLJS.J.g(c,$CLJS.Ij);return $CLJS.n(function(){var l=$CLJS.K5(a,b);return l?(l=$CLJS.Ge(f),$CLJS.n(l)?(l=$CLJS.n(d)?null!=k:d,l=$CLJS.n(l)?l:null==d&&null==k,$CLJS.n(l)?$CLJS.Ua($CLJS.n(d)?$CLJS.p_(d):d):l):l):l}())?new $CLJS.h(null,6,[$CLJS.Qt,$CLJS.V6,$CLJS.rj,$CLJS.M6,$CLJS.G6,"number"!==typeof k||0>k?2:k,$CLJS.xZ,function(){var l=null==a?null:$CLJS.B_(a);l=null==l?null:$CLJS.H_(a,l);
$CLJS.n(l)||(l=null==a?null:$CLJS.U1(a),l=null==l?null:$CLJS.J_(a,l));return $CLJS.n(l)?$CLJS.P_.j(a,b,l):null}(),$CLJS.tL,f,$CLJS.U5,e],null):null};
d8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Xi),e=$CLJS.J.g(c,$CLJS.U5),f=$CLJS.J.g(c,$CLJS.tL),k=$CLJS.J.g(c,$CLJS.Ij);return $CLJS.n(function(){var l=$CLJS.K5(a,b);return l?(l=$CLJS.Ua(d)||null!=k)?(l=$CLJS.w7(a,$CLJS.Nra),$CLJS.n(l)?$CLJS.Ge(f):l):l:l}())?new $CLJS.h(null,4,[$CLJS.Qt,$CLJS.V6,$CLJS.rj,$CLJS.q6,$CLJS.U5,e,$CLJS.tL,f],null):null};$CLJS.e8=function(a){return $CLJS.Zk.v(a,$CLJS.eL,$CLJS.ce,new $CLJS.h(null,1,[$CLJS.Qt,$CLJS.FV],null))};
f8=function(a,b,c){var d=$CLJS.q2(a,$CLJS.oW(a,b)),e=$CLJS.Uk.g($CLJS.FK.h(c),$CLJS.YK)?new $CLJS.h(null,2,[$CLJS.yF,a,$CLJS.qL,b],null):$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.yF,a,$CLJS.qL,d],null):new $CLJS.h(null,2,[$CLJS.yF,$CLJS.e8(a),$CLJS.qL,-1],null),f=$CLJS.m3.g($CLJS.yF.h(e),$CLJS.qL.h(e));a=function(){var k=$CLJS.d2.v($CLJS.yF.h(e),$CLJS.qL.h(e),$CLJS.aW(c),f);if($CLJS.n(k))return k;k=$CLJS.S0.h(c);return $CLJS.n(k)?$CLJS.nH(function(l){return $CLJS.E.g($CLJS.S0.h(l),$CLJS.S0.h(c))},f):k}();
return $CLJS.R.j(e,$CLJS.Xi,a)};g8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Xi);d=$CLJS.J.g(d,$CLJS.Ij);var e=$CLJS.K5(a,b);d=e?$CLJS.n(c)?null==d&&!$CLJS.p_(c):c:e;return $CLJS.n(d)?(d=$CLJS.h_(c)?null:$CLJS.R.j($CLJS.z($CLJS.C2(c)),$CLJS.Qt,$CLJS.IJ),$CLJS.nl.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.Qt,$CLJS.V6,$CLJS.rj,$CLJS.k6,$CLJS.i6,d],null),f8(a,b,c)]))):null};
h8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Xi);d=$CLJS.J.g(d,$CLJS.Ij);var e=$CLJS.K5(a,b);a=e?$CLJS.n(c)?null==d&&$CLJS.Uk.g($CLJS.FK.h(c),$CLJS.YK)&&!$CLJS.o_(c)&&!$CLJS.p_(c)&&!$CLJS.y_(c)&&!$CLJS.m_(c)&&$CLJS.Ua($CLJS.y($CLJS.I5.j(a,b,c))):c:e;return $CLJS.n(a)?new $CLJS.h(null,3,[$CLJS.Qt,$CLJS.V6,$CLJS.rj,$CLJS.K6,$CLJS.Xi,c],null):null};
isa=function(a,b,c){c=$CLJS.O(c);b=$CLJS.J.g(c,$CLJS.Xi);c=$CLJS.J.g(c,$CLJS.Ij);return $CLJS.n_(b)&&null!=c&&$CLJS.Uk.g(c,$CLJS.R6)?new $CLJS.h(null,5,[$CLJS.Qt,$CLJS.V6,$CLJS.rj,$CLJS.J6,$CLJS.Xi,b,$CLJS.V5,c,$CLJS.O5,1<$CLJS.D(b8(a))],null):null};
i8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Xi);d=$CLJS.J.g(d,$CLJS.Ij);if($CLJS.n($CLJS.n(c)?null!=d&&$CLJS.Uk.g(d,$CLJS.R6)&&!$CLJS.o_(c)&&$CLJS.n_(c):c)){var e=function(){var f=function(){var k=null==a?null:$CLJS.B_(a);return null==k?null:$CLJS.H_(a,k)}();if($CLJS.n(f))return f;f=function(){var k=null==a?null:$CLJS.U1(a);return null==k?null:$CLJS.J_(a,k)}();return $CLJS.n(f)?f:$CLJS.pW(a,0)}();return new $CLJS.h(null,5,[$CLJS.Qt,$CLJS.V6,$CLJS.rj,$CLJS.o6,$CLJS.GF,$CLJS.$V(new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.Rr,$CLJS.N,$CLJS.aW(c),d],null)),$CLJS.NF,$CLJS.P_.v(a,b,c,$CLJS.Q_),$CLJS.xZ,$CLJS.P_.j(a,0,e)],null)}return null};
jsa=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.Xi),f=$CLJS.J.g(d,$CLJS.Ij),k=$CLJS.J.g(d,$CLJS.T5);if(null!=f&&$CLJS.K5(a,b)&&1<$CLJS.D(b8(a))&&!$CLJS.n_(e)){if($CLJS.o_(e))return null!=f&&$CLJS.Uk.g(f,$CLJS.R6)?new $CLJS.h(null,3,[$CLJS.Qt,$CLJS.V6,$CLJS.rj,$CLJS.y6,$CLJS.tL,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Xi,e,$CLJS.Ij,f],null)],null)],null):null;var l=b8(a);a=function(){return function u(t){return new $CLJS.oe(null,function(){for(var v=t;;){var x=$CLJS.y(v);if(x){var A=
x;if($CLJS.Ad(A)){var C=$CLJS.lc(A),G=$CLJS.D(C),K=$CLJS.re(G);return function(){for(var Y=0;;)if(Y<G){var ha=$CLJS.kd(C,Y),ta=$CLJS.Ij.h($CLJS.nH(function(Ta,Cb,Oa){return function(Wa){return $CLJS.E.g($CLJS.T.h($CLJS.Xi.h(Wa)),$CLJS.T.h(Oa))}}(Y,v,ha,C,G,K,A,x,l,c,d,d,e,f,k),k));$CLJS.n(ta)&&$CLJS.ve(K,new $CLJS.h(null,2,[$CLJS.Xi,ha,$CLJS.Ij,ta],null));Y+=1}else return!0}()?$CLJS.ue($CLJS.we(K),u($CLJS.mc(A))):$CLJS.ue($CLJS.we(K),null)}var S=$CLJS.z(A),V=$CLJS.Ij.h($CLJS.nH(function(Y,ha){return function(ta){return $CLJS.E.g($CLJS.T.h($CLJS.Xi.h(ta)),
$CLJS.T.h(ha))}}(v,S,A,x,l,c,d,d,e,f,k),k));if($CLJS.n(V))return $CLJS.fe(new $CLJS.h(null,2,[$CLJS.Xi,S,$CLJS.Ij,V],null),u($CLJS.Lc(A)));v=$CLJS.Lc(A)}else return null}},null,null)}(l)}();return $CLJS.y(a)?new $CLJS.h(null,3,[$CLJS.Qt,$CLJS.V6,$CLJS.rj,$CLJS.y6,$CLJS.tL,$CLJS.Ef($CLJS.p1(function(m){return $CLJS.uA(m,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xi,$CLJS.dj],null))},a))],null):null}return null};
ksa=function(a,b,c){var d=$CLJS.O(c);b=$CLJS.J.g(d,$CLJS.Xi);c=$CLJS.J.g(d,$CLJS.Ij);d=$CLJS.J.g(d,$CLJS.T5);if(null==c||1<$CLJS.D(b8(a)))return null;if($CLJS.o_(b))return $CLJS.E.g(c,$CLJS.R6)?null:new $CLJS.h(null,5,[$CLJS.Qt,$CLJS.V6,$CLJS.rj,$CLJS.c7,$CLJS.Xi,b,$CLJS.V5,c,$CLJS.O5,!1],null);a=b8(a);var e=$CLJS.I(a,0,null);a=$CLJS.Ij.h($CLJS.nH(function(f){return $CLJS.E.g($CLJS.T.h($CLJS.Xi.h(f)),$CLJS.T.h(e))},d));return $CLJS.n(a)?new $CLJS.h(null,5,[$CLJS.Qt,$CLJS.V6,$CLJS.rj,$CLJS.c7,$CLJS.Xi,
e,$CLJS.V5,a,$CLJS.O5,!1],null):null};j8=function(a,b,c){return $CLJS.Se(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)},new $CLJS.P(null,3,5,$CLJS.Q,[isa,jsa,ksa],null))};k8=function(a,b,c,d){var e=$CLJS.O(c);c=$CLJS.J.g(e,$CLJS.Xi);e=$CLJS.J.g(e,$CLJS.Ij);var f=$CLJS.K5(a,b);c=f?$CLJS.n(c)?null!=e&&$CLJS.E.g($CLJS.FK.h(c),$CLJS.YK):c:f;return $CLJS.n(c)?$CLJS.mf(d,$CLJS.H5.g(a,b)):null};
lsa=function(a,b){var c=$CLJS.a8($CLJS.jf.g(function(d){d=$CLJS.M_.j(a,b,d);return $CLJS.h_(d)?$CLJS.lP:$CLJS.r_(d)?$CLJS.J7:$CLJS.l_(d)?$CLJS.wG:null},$CLJS.F5.g(a,b)));return $CLJS.E.g(new $CLJS.h(null,1,[$CLJS.lP,1],null),c)?new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.wG,null,$CLJS.e6,null],null),null):$CLJS.E.g(new $CLJS.h(null,2,[$CLJS.lP,1,$CLJS.wG,1],null),c)?new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.wG,null,$CLJS.e6,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.J7,1],null),c)?new $CLJS.bh(null,
new $CLJS.h(null,2,[$CLJS.wG,null,$CLJS.xy,null],null),null):$CLJS.E.g($CLJS.N,c)?new $CLJS.bh(null,new $CLJS.h(null,3,[$CLJS.wG,null,$CLJS.xy,null,$CLJS.e6,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.wG,1],null),c)?new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.wG,null,$CLJS.xy,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.wG,2],null),c)?new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.wG,null,$CLJS.xy,null],null),null):$CLJS.ch};
l8=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.Xi);c=$CLJS.J.g(d,$CLJS.tL);var f=$CLJS.J.g(d,$CLJS.Ij);if($CLJS.n(function(){var m=$CLJS.K5(a,b);return m?$CLJS.n(e)?null!=f&&$CLJS.E.g($CLJS.FK.h(e),$CLJS.YK)&&0<$CLJS.D($CLJS.q4.g(a,b)):e:m}())){var k=lsa(a,b),l=$CLJS.fg.g($CLJS.N,function(){return function u(t){return new $CLJS.oe(null,function(){for(var v=t;;)if(v=$CLJS.y(v)){if($CLJS.Ad(v)){var x=$CLJS.lc(v),A=$CLJS.D(x),C=$CLJS.re(A);a:for(var G=0;;)if(G<A){var K=$CLJS.kd(x,G),S=$CLJS.J.g($CLJS.K7,
K);S=k8(a,b,d,S);$CLJS.n($CLJS.Ge(S))&&C.add(new $CLJS.P(null,2,5,$CLJS.Q,[K,S],null));G+=1}else{x=!0;break a}return x?$CLJS.ue($CLJS.we(C),u($CLJS.mc(v))):$CLJS.ue($CLJS.we(C),null)}C=$CLJS.z(v);x=$CLJS.J.g($CLJS.K7,C);x=k8(a,b,d,x);if($CLJS.n($CLJS.Ge(x)))return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[C,x],null),u($CLJS.Lc(v)));v=$CLJS.Lc(v)}else return null},null,null)}(k)}());return $CLJS.td(l)?null:new $CLJS.h(null,4,[$CLJS.Qt,$CLJS.V6,$CLJS.rj,$CLJS.U6,$CLJS.tL,c,$CLJS.a6,l],null)}return null};
m8=function(a,b){return $CLJS.$V($CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.N],null),b))};
msa=function(a,b){var c=$CLJS.aW(a);if($CLJS.p_(a))return $CLJS.Df;if($CLJS.E.g(b,$CLJS.R6))return new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.T,"\x3d",$CLJS.GF,m8($CLJS.DF,$CLJS.H([c]))],null),new $CLJS.h(null,2,[$CLJS.T,"≠",$CLJS.GF,m8($CLJS.PF,$CLJS.H([c]))],null)],null);if($CLJS.i_(a)||$CLJS.h_(a))return a=function f(e){return new $CLJS.oe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.re(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,
u),x=$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);x=new $CLJS.h(null,2,[$CLJS.T,v,$CLJS.GF,m8(x,$CLJS.H([c,b]))],null);t.add(x);u+=1}else{l=!0;break a}return l?$CLJS.ue($CLJS.we(t),f($CLJS.mc(k))):$CLJS.ue($CLJS.we(t),null)}l=$CLJS.z(k);t=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.fe(new $CLJS.h(null,2,[$CLJS.T,l,$CLJS.GF,m8(t,$CLJS.H([c,b]))],null),f($CLJS.Lc(k)))}return null}},null,null)},a(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Or,"\x3c"],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.Kr,"\x3e"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uG,"≠"],null)],null));if($CLJS.j_(a))return a=function f(e){return new $CLJS.oe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.re(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u),x=$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);x=new $CLJS.h(null,2,[$CLJS.T,v,$CLJS.GF,m8(x,$CLJS.H([c,b]))],null);t.add(x);u+=1}else{l=!0;break a}return l?
$CLJS.ue($CLJS.we(t),f($CLJS.mc(k))):$CLJS.ue($CLJS.we(t),null)}l=$CLJS.z(k);t=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.fe(new $CLJS.h(null,2,[$CLJS.T,l,$CLJS.GF,m8(t,$CLJS.H([c,b]))],null),f($CLJS.Lc(k)))}return null}},null,null)},a(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uG,"≠"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bG,"contains"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VF,"does-not-contain"],null)],null));
a=function f(e){return new $CLJS.oe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.re(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u),x=$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);x=new $CLJS.h(null,2,[$CLJS.T,v,$CLJS.GF,m8(x,$CLJS.H([c,b]))],null);t.add(x);u+=1}else{l=!0;break a}return l?$CLJS.ue($CLJS.we(t),f($CLJS.mc(k))):$CLJS.ue($CLJS.we(t),null)}l=$CLJS.z(k);t=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.fe(new $CLJS.h(null,2,[$CLJS.T,l,
$CLJS.GF,m8(t,$CLJS.H([c,b]))],null),f($CLJS.Lc(k)))}return null}},null,null)};return a(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uG,"≠"],null)],null))};
n8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Xi);d=$CLJS.J.g(d,$CLJS.Ij);var e=(e=$CLJS.K5(a,b))?$CLJS.n(c)?null!=d&&!$CLJS.o_(c)&&!$CLJS.n_(c):c:e;return $CLJS.n(e)?(a=f8(a,b,c),$CLJS.nl.l($CLJS.H([new $CLJS.h(null,4,[$CLJS.Qt,$CLJS.V6,$CLJS.rj,$CLJS.S5,$CLJS.oL,msa($CLJS.Xi.h(a),d),$CLJS.Ij,d],null),a]))):null};nsa=function(a,b,c){return $CLJS.d2.v(a,b,$CLJS.aW(c),$CLJS.Q7.g(a,b))};
osa=function(a,b,c){a=$CLJS.S7(a,b,c);return $CLJS.n(a)?(b=$CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),b):null};
o8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Xi),e=$CLJS.J.g(c,$CLJS.Ij);if($CLJS.n(function(){var k=$CLJS.K5(a,b);return k?$CLJS.n(d)?null==e&&!$CLJS.p_(d):d:k}())&&$CLJS.n(nsa(a,b,d))){var f=osa(a,b,d);return new $CLJS.h(null,4,[$CLJS.Qt,$CLJS.V6,$CLJS.rj,$CLJS.u6,$CLJS.Xi,d,$CLJS.x6,function(){switch(f instanceof $CLJS.M?f.T:null){case "asc":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.TF],null);case "desc":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.$F],null);default:return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.$F,$CLJS.TF],null)}}()],null)}return null};
p8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Xi);d=$CLJS.J.g(d,$CLJS.Ij);var e=$CLJS.K5(a,b);a=e?$CLJS.n(c)?null==d&&!$CLJS.p_(c)&&$CLJS.Uk.g($CLJS.FK.h(c),$CLJS.YK)&&$CLJS.Ua($CLJS.y($CLJS.I5.j(a,b,c))):c:e;return $CLJS.n(a)?(a=$CLJS.hf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.HF],null),$CLJS.k_(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pG,$CLJS.XF],null):null),new $CLJS.h(null,4,[$CLJS.Qt,$CLJS.V6,$CLJS.rj,$CLJS.v6,$CLJS.Xi,c,$CLJS.A6,a],null)):null};
q8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Xi);d=$CLJS.J.g(d,$CLJS.Ij);var e=$CLJS.K5(a,b);d=e?$CLJS.n(c)?null==d&&!$CLJS.p_(c)&&$CLJS.k_(c)&&$CLJS.Uk.g($CLJS.FK.h(c),$CLJS.YK):c:e;if($CLJS.n(d)&&(d=$CLJS.nH($CLJS.h_,$CLJS.H5.g(a,b)),$CLJS.n(d)&&(e=$CLJS.nH($CLJS.ei,$CLJS.M0.j(a,b,d)),$CLJS.n(e)))){var f=$CLJS.p3(d,e);return $CLJS.n($CLJS.tV($CLJS.jf.g($CLJS.aW,$CLJS.fe(f,$CLJS.F5.g(a,b)))))?new $CLJS.h(null,5,[$CLJS.Qt,$CLJS.V6,$CLJS.rj,$CLJS.Q6,$CLJS.Xi,c,$CLJS.RF,d,$CLJS.ti,$CLJS.J0.h(e)],
null):null}return null};
r8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Xi);d=$CLJS.J.g(d,$CLJS.Ij);if($CLJS.n($CLJS.n(c)?d:c))if(b=$CLJS.z($CLJS.I5.j(a,b,c)),$CLJS.n(b))if(b=$CLJS.f7(b),$CLJS.n(b))if(a=$CLJS.y7(a,c,d),$CLJS.n(a)){var e=$CLJS.O(a);a=$CLJS.J.g(e,$CLJS.sL);var f=$CLJS.J.g(e,$CLJS.vL);e=$CLJS.J.g(e,$CLJS.vE);var k=$CLJS.wE.h(b);k=k instanceof $CLJS.M?k.T:null;switch(k){case "num-bins":case "default":return new $CLJS.h(null,6,[$CLJS.Qt,$CLJS.V6,$CLJS.rj,$CLJS.z6,$CLJS.Xi,c,$CLJS.sL,d,$CLJS.vL,d+e,$CLJS.r6,
new $CLJS.h(null,1,[$CLJS.wE,$CLJS.ei],null)],null);case "bin-width":return new $CLJS.h(null,6,[$CLJS.Qt,$CLJS.V6,$CLJS.rj,$CLJS.z6,$CLJS.Xi,c,$CLJS.sL,a,$CLJS.vL,f,$CLJS.r6,$CLJS.Zk.j(b,$CLJS.vE,function(l){return l/10})],null);default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}else return null;else return null;else return null;else return null};
psa=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.T5);b=$CLJS.z0.j(a,b,$CLJS.pW(a,b));a=$CLJS.nH($CLJS.w_,b);if($CLJS.n(a)&&(b=$CLJS.nH($CLJS.x_,b),$CLJS.n(b))){var e=function(f){return $CLJS.Se(function(k){var l=$CLJS.Xi.h(k);l=$CLJS.n($CLJS.dj.h(f))?$CLJS.E.g($CLJS.dj.h(f),$CLJS.dj.h(l)):$CLJS.E.g($CLJS.uZ.h(f),$CLJS.uZ.h(l));return $CLJS.n(l)?$CLJS.Ij.h(k):null},d)};return $CLJS.R.l(c,$CLJS.W7,a,$CLJS.H([$CLJS.X7,b,$CLJS.Y7,e(a),$CLJS.V7,e(b)]))}return null};
s8=function(a,b){var c=$CLJS.O(a);a=$CLJS.J.g(c,$CLJS.Xi);var d=$CLJS.J.g(c,$CLJS.Ij),e=$CLJS.J.g(c,$CLJS.W7);c=$CLJS.J.g(c,$CLJS.X7);return $CLJS.n(d)?new $CLJS.h(null,7,[$CLJS.Qt,$CLJS.V6,$CLJS.rj,$CLJS.Z6,$CLJS.Z5,$CLJS.m6,$CLJS.Xi,a,$CLJS.Ij,d,$CLJS.p6,new $CLJS.h(null,2,[$CLJS.Xi,e,$CLJS.vE,b],null),$CLJS.n6,new $CLJS.h(null,2,[$CLJS.Xi,c,$CLJS.vE,b],null)],null):null};qsa=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.Xi);return $CLJS.n(null==b?null:$CLJS.u_(b))?s8(a,10):null};
rsa=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.Xi);return $CLJS.n(null==b?null:$CLJS.t_(b))?s8(a,1):null};ssa=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.Xi);return $CLJS.n(null==b?null:$CLJS.s_(b))?s8(a,.1):null};
tsa=function(a,b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.W7);var d=$CLJS.J.g(c,$CLJS.X7),e=$CLJS.J.g(c,$CLJS.Y7);c=$CLJS.J.g(c,$CLJS.V7);if($CLJS.n($CLJS.n(e)?c:e)){var f=$CLJS.y7(a,b,e);if($CLJS.n(f)&&(f=$CLJS.O(f),f=$CLJS.J.g(f,$CLJS.vE),a=$CLJS.y7(a,d,c),$CLJS.n(a))){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.vE);var k=20<=f&&20<=a?new $CLJS.P(null,2,5,$CLJS.Q,[10,10],null):new $CLJS.P(null,2,5,$CLJS.Q,[f/10,a/10],null),l=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return new $CLJS.h(null,5,[$CLJS.Qt,$CLJS.V6,$CLJS.rj,
$CLJS.Z6,$CLJS.Z5,$CLJS.Q5,$CLJS.p6,new $CLJS.h(null,4,[$CLJS.Xi,b,$CLJS.vE,l,$CLJS.go,e,$CLJS.pk,e+f],null),$CLJS.n6,new $CLJS.h(null,4,[$CLJS.Xi,d,$CLJS.vE,k,$CLJS.go,c,$CLJS.pk,c+a],null)],null)}}return null};t8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Ij);if($CLJS.n(d)){var e=psa(a,b,c);return $CLJS.n(e)?$CLJS.Se(function(f){return f.h?f.h(e):f.call(null,e)},new $CLJS.P(null,4,5,$CLJS.Q,[qsa,rsa,ssa,$CLJS.We(tsa,a)],null)):null}return null};
usa=function(a,b,c){return $CLJS.z(function(){return function f(e){return new $CLJS.oe(null,function(){for(var k=e;;){var l=$CLJS.y(k);if(l){var m=l,t=$CLJS.z(m);if($CLJS.n(function(){var u=$CLJS.rY(t,$CLJS.cG);return u?$CLJS.y2(t):u}())&&(l=$CLJS.y(function(u,v,x,A){return function K(G){return new $CLJS.oe(null,function(S,V){return function(){for(var Y=G;;)if(Y=$CLJS.y(Y)){if($CLJS.Ad(Y)){var ha=$CLJS.lc(Y),ta=$CLJS.D(ha),Ta=$CLJS.re(ta);return function(){for(var fb=0;;)if(fb<ta){var Va=$CLJS.kd(ha,
fb),nb=$CLJS.O(Va);Va=nb;nb=$CLJS.J.g(nb,$CLJS.Xi);var Ja=$CLJS.d2.g(V,new $CLJS.P(null,1,5,$CLJS.Q,[nb],null));nb=$CLJS.n(Ja)?$CLJS.E.g($CLJS.y2(V),$CLJS.y2(nb)):Ja;$CLJS.n(nb)&&(Va=$CLJS.R.j(Va,$CLJS.U5,V),Ta.add(Va));fb+=1}else return!0}()?$CLJS.ue($CLJS.we(Ta),K($CLJS.mc(Y))):$CLJS.ue($CLJS.we(Ta),null)}var Cb=$CLJS.z(Y),Oa=Cb=$CLJS.O(Cb),Wa=$CLJS.J.g(Cb,$CLJS.Xi);if($CLJS.n(function(){var fb=$CLJS.d2.g(V,new $CLJS.P(null,1,5,$CLJS.Q,[Wa],null));return $CLJS.n(fb)?$CLJS.E.g($CLJS.y2(V),$CLJS.y2(Wa)):
fb}()))return $CLJS.fe($CLJS.R.j(Oa,$CLJS.U5,V),K($CLJS.Lc(Y)));Y=$CLJS.Lc(Y)}else return null}}(u,v,x,A),null,null)}}(k,t,m,l)(c))))return $CLJS.hf.g(l,f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null}},null,null)}($CLJS.F5.g(a,b))}())};
vsa=function(a){a=a instanceof $CLJS.M?a.T:null;switch(a){case "quarter":return $CLJS.DE("See this year by quarter");case "month":return $CLJS.DE("See this quarter by month");case "week":return $CLJS.DE("See this month by week");case "day":return $CLJS.DE("See this week by day");case "hour":return $CLJS.DE("See this day by hour");case "minute":return $CLJS.DE("See this hour by minute");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}};
u8=function(a,b,c){c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.tL);var d=(d=$CLJS.K5(a,b))?$CLJS.Ge(c):d;return $CLJS.n(d)&&(a=usa(a,b,c),$CLJS.n(a)&&(a=$CLJS.O(a),b=$CLJS.J.g(a,$CLJS.Ij),$CLJS.n(b)))?(b=$CLJS.Xi.h(a),b=$CLJS.J0.h(b),b=$CLJS.n(b)?$CLJS.Hd($CLJS.gh($CLJS.Z7),b)?$CLJS.$7.h?$CLJS.$7.h(b):$CLJS.$7.call(null,b):null:null,$CLJS.n(b)?new $CLJS.h(null,5,[$CLJS.Qt,$CLJS.V6,$CLJS.gE,vsa(b),$CLJS.rj,$CLJS.X6,$CLJS.vF,a,$CLJS.P6,b],null):null):null};
wsa=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.tL);return $CLJS.Ge(function(){return function f(e){return new $CLJS.oe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.re(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.nl.l($CLJS.H([b,v]));t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.ue($CLJS.we(t),f($CLJS.mc(k))):$CLJS.ue($CLJS.we(t),null)}t=$CLJS.z(k);return $CLJS.fe($CLJS.nl.l($CLJS.H([b,t])),f($CLJS.Lc(k)))}return null}},null,
null)}(c)}())};xsa=new $CLJS.r(null,"metabase.lib.drill-thru.pivot","metabase.lib.drill-thru.pivot",109208756,null);ysa=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-bins","metabase.lib.drill-thru.zoom-in-bins",477390405,null);zsa=new $CLJS.r("metabase.lib.drill-thru.zoom-in-bins","zoom-in-binning-drill","metabase.lib.drill-thru.zoom-in-bins/zoom-in-binning-drill",-862934837,null);
Asa=new $CLJS.r(null,"metabase.lib.drill-thru.summarize-column-by-time","metabase.lib.drill-thru.summarize-column-by-time",-369504986,null);v8=new $CLJS.r(null,"stage-number","stage-number",-112198111,null);Bsa=new $CLJS.r(null,"underlying-records-drill","underlying-records-drill",713740052,null);Csa=new $CLJS.r("metabase.lib.drill-thru.zoom-in-timeseries","zoom-in-timeseries-drill","metabase.lib.drill-thru.zoom-in-timeseries/zoom-in-timeseries-drill",313862423,null);
Dsa=new $CLJS.r(null,"zoom-in-timeseries-drill","zoom-in-timeseries-drill",-1304664195,null);Esa=new $CLJS.r(null,"distribution-drill","distribution-drill",-898205041,null);Fsa=new $CLJS.r(null,"sort-drill","sort-drill",600387798,null);Gsa=new $CLJS.M(null,"drill","drill",-1704624793);Hsa=new $CLJS.r("metabase.lib.drill-thru.quick-filter","quick-filter-drill","metabase.lib.drill-thru.quick-filter/quick-filter-drill",457872114,null);w8=new $CLJS.r(null,"_context","_context",-1238791322,null);
Isa=new $CLJS.r(null,"metabase.lib.drill-thru.distribution","metabase.lib.drill-thru.distribution",-514507852,null);Jsa=new $CLJS.r(null,"zoom-in-binning-drill","zoom-in-binning-drill",1550598377,null);Ksa=new $CLJS.r(null,"metabase.lib.drill-thru.object-details","metabase.lib.drill-thru.object-details",1788168194,null);Lsa=new $CLJS.r(null,"automatic-insights-drill","automatic-insights-drill",-538289251,null);
Msa=new $CLJS.r("metabase.lib.drill-thru.zoom-in-geographic","zoom-in-geographic-drill","metabase.lib.drill-thru.zoom-in-geographic/zoom-in-geographic-drill",754461586,null);Nsa=new $CLJS.r("metabase.lib.drill-thru.distribution","distribution-drill","metabase.lib.drill-thru.distribution/distribution-drill",-2103693273,null);Osa=new $CLJS.r(null,"fk-filter-drill","fk-filter-drill",-1258190784,null);
Psa=new $CLJS.r(null,"metabase.lib.drill-thru.summarize-column","metabase.lib.drill-thru.summarize-column",490384430,null);Qsa=new $CLJS.r(null,"pivot-drill","pivot-drill",-1794856768,null);Rsa=new $CLJS.r(null,"metabase.lib.drill-thru.quick-filter","metabase.lib.drill-thru.quick-filter",671398986,null);Ssa=new $CLJS.r(null,"metabase.lib.drill-thru.underlying-records","metabase.lib.drill-thru.underlying-records",-827008688,null);
Tsa=new $CLJS.r("metabase.lib.drill-thru.summarize-column-by-time","summarize-column-by-time-drill","metabase.lib.drill-thru.summarize-column-by-time/summarize-column-by-time-drill",391970943,null);Usa=new $CLJS.r(null,"summarize-column-drill","summarize-column-drill",-1961542613,null);Vsa=new $CLJS.r(null,"column-filter-drill","column-filter-drill",-1088669153,null);
Wsa=new $CLJS.r("metabase.lib.drill-thru.underlying-records","underlying-records-drill","metabase.lib.drill-thru.underlying-records/underlying-records-drill",1176519262,null);Xsa=new $CLJS.r(null,"metabase.lib.drill-thru.sort","metabase.lib.drill-thru.sort",961185366,null);Ysa=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-geographic","metabase.lib.drill-thru.zoom-in-geographic",-1562146002,null);x8=new $CLJS.r(null,"dimensions","dimensions",1385713430,null);
y8=new $CLJS.M(null,"return-drills-for-dimensions?","return-drills-for-dimensions?",-1759804348);Zsa=new $CLJS.r("metabase.lib.drill-thru.column-filter","column-filter-drill","metabase.lib.drill-thru.column-filter/column-filter-drill",-1130871894,null);$sa=new $CLJS.r(null,"object-detail-drill","object-detail-drill",-613919053,null);ata=new $CLJS.r(null,"metabase.lib.drill-thru.column-filter","metabase.lib.drill-thru.column-filter",23504270,null);
z8=new $CLJS.r(null,"context","context",810340414,null);bta=new $CLJS.r(null,"zoom-in-geographic-drill","zoom-in-geographic-drill",312914971,null);cta=new $CLJS.r("metabase.lib.drill-thru.automatic-insights","automatic-insights-drill","metabase.lib.drill-thru.automatic-insights/automatic-insights-drill",1471807986,null);dta=new $CLJS.r(null,"summarize-column-by-time-drill","summarize-column-by-time-drill",1117784387,null);
eta=new $CLJS.r("metabase.lib.drill-thru.object-details","object-detail-drill","metabase.lib.drill-thru.object-details/object-detail-drill",-1376576725,null);A8=new $CLJS.r(null,"column-ref","column-ref",-636247393,null);fta=new $CLJS.r("metabase.lib.drill-thru.pivot","pivot-drill","metabase.lib.drill-thru.pivot/pivot-drill",1004065694,null);gta=new $CLJS.r(null,"metabase.lib.drill-thru.fk-filter","metabase.lib.drill-thru.fk-filter",-1939155669,null);
hta=new $CLJS.r("metabase.lib.drill-thru.summarize-column","summarize-column-drill","metabase.lib.drill-thru.summarize-column/summarize-column-drill",-894275347,null);B8=new $CLJS.r(null,"query","query",352022017,null);ita=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-timeseries","metabase.lib.drill-thru.zoom-in-timeseries",105846904,null);jta=new $CLJS.r(null,"metabase.lib.drill-thru.automatic-insights","metabase.lib.drill-thru.automatic-insights",912989182,null);
kta=new $CLJS.r("metabase.lib.drill-thru.fk-filter","fk-filter-drill","metabase.lib.drill-thru.fk-filter/fk-filter-drill",-716335012,null);lta=new $CLJS.r("metabase.lib.drill-thru.sort","sort-drill","metabase.lib.drill-thru.sort/sort-drill",2019212116,null);mta=new $CLJS.r(null,"quick-filter-drill","quick-filter-drill",534264366,null);var nta;$CLJS.v0.m(null,$CLJS.V6,function(a,b,c){return $CLJS.N5.j(a,b,c)});
nta=new $CLJS.P(null,14,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Ts,new $CLJS.Gc(function(){return d8},cta,$CLJS.Jg([$CLJS.xl,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.no,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Kj,$CLJS.wL,$CLJS.zi,$CLJS.d6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.t6],null)],null),jta,Lsa,"metabase/lib/drill_thru/automatic_insights.cljc",34,1,11,11,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[B8,v8,new $CLJS.h(null,
1,[$CLJS.up,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Rz,A8,x8,$CLJS.Zi],null)],null)],null)),'Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref dimensions value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru]\n          \n\n  Automatic insights appears:\n  - When clicking on a value with a breakout - eg. a point in a time series, a cell of a table, a bar or pie slice\n  - Or when clicking a pivot cell, with a value but no column.\n  - Or when clicking a chart legend, in which case there\'s no column or value set.\n  - There must be at least 1 breakout\n  - X-rays must be enabled (check the settings)\n\n  There are two forms: X-ray, and "Compare to the rest". This is a simple user choice and does not need extra data.',
$CLJS.n(d8)?d8.H:null])),y8,!1],null),new $CLJS.h(null,2,[$CLJS.Ts,new $CLJS.Gc(function(){return h8},Nsa,$CLJS.Jg([$CLJS.xl,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.no,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Kj,$CLJS.wL,$CLJS.zi,$CLJS.d6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.Y5],null)],null),Isa,Esa,"metabase/lib/drill_thru/distribution.cljc",28,1,16,16,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[B8,v8,new $CLJS.h(null,
1,[$CLJS.up,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rz,$CLJS.Zi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.distribution]\n          \n\n  Select a column and see a histogram of how many rows fall into an automatic set of bins/buckets.\n  - For dates, breaks out by month by default.\n  - For numeric values, by an auto-selected set of bins\n  - For strings, by each distinct value (which might be \x3d the number of rows)",
$CLJS.n(h8)?h8.H:null])),y8,!0],null),new $CLJS.h(null,2,[$CLJS.Ts,new $CLJS.Gc(function(){return g8},Zsa,$CLJS.Jg([$CLJS.xl,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.no,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Kj,$CLJS.wL,$CLJS.zi,$CLJS.d6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.g6],null)],null),ata,Vsa,"metabase/lib/drill_thru/column_filter.cljc",29,1,56,56,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[B8,v8,new $CLJS.h(null,
1,[$CLJS.up,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rz,$CLJS.Zi],null)],null)],null)),'Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.column-filter]\n          \n\n  Filtering at the column level, based on its type. Displays a submenu of eg. "Today", "This Week", etc. for date\n  columns.\n\n  Note that if the clicked column is an aggregation, filtering by it will require a new stage. Therefore this drill\n  returns a possibly-updated `:query` and `:stage-number` along with a `:column` referencing that later stage.',
$CLJS.n(g8)?g8.H:null])),y8,!0],null),new $CLJS.h(null,2,[$CLJS.Ts,new $CLJS.Gc(function(){return i8},kta,$CLJS.Jg([$CLJS.xl,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.no,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Kj,$CLJS.wL,$CLJS.zi,$CLJS.d6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.N6],null)],null),gta,Osa,"metabase/lib/drill_thru/fk_filter.cljc",25,1,15,15,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[B8,v8,new $CLJS.h(null,
2,[$CLJS.up,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rz,$CLJS.Zi],null),$CLJS.Zs,w8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.fk-filter]\n          \n\n  When clicking on a foreign key value, filter this query by that column.\n\n  This has the same effect as the `\x3d` filter on a generic field (ie. not a key), but renders differently.\n\n  Contrast [[metabase.lib.drill-thru.object-details/object-detail-drill]], which shows the details of the foreign\n  object.",
$CLJS.n(i8)?i8.H:null])),y8,!1],null),new $CLJS.h(null,2,[$CLJS.Ts,new $CLJS.Gc(function(){return j8},eta,$CLJS.Jg([$CLJS.xl,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.no,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Kj,$CLJS.wL,$CLJS.zi,$CLJS.d6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ms,$CLJS.R5,$CLJS.O6,$CLJS.D6],null)],null)],null),Ksa,$sa,"metabase/lib/drill_thru/object_details.cljc",
29,1,10,10,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[B8,v8,z8],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int context :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe\n           [:or\n            :metabase.lib.schema.drill-thru/drill-thru.pk\n            :metabase.lib.schema.drill-thru/drill-thru.zoom\n            :metabase.lib.schema.drill-thru/drill-thru.fk-details]]\n          \n\n  When clicking a foreign key or primary key value, drill through to the details for that specific object.\n\n  Contrast [[metabase.lib.drill-thru.fk-filter/fk-filter-drill]], which filters this query to only those rows with a\n  specific value for a FK column.",
$CLJS.n(j8)?j8.H:null])),y8,!1],null),new $CLJS.h(null,2,[$CLJS.Ts,new $CLJS.Gc(function(){return l8},fta,$CLJS.Jg([$CLJS.xl,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.no,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Kj,$CLJS.wL,$CLJS.zi,$CLJS.d6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.b6],null)],null),xsa,Qsa,"metabase/lib/drill_thru/pivot.cljc",21,1,76,76,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[B8,v8,new $CLJS.h(null,
2,[$CLJS.up,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rz,x8,$CLJS.Zi],null),$CLJS.Zs,z8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column dimensions value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.pivot]\n          \n\n  Return all possible pivoting options on the given column and value.\n\n  See `:pivots` key, which holds a map `{t [breakouts...]}` where `t` is `:category`, `:location`, or `:time`.\n  If a key is missing, there are no breakouts of that kind.",
$CLJS.n(l8)?l8.H:null])),y8,!1],null),new $CLJS.h(null,2,[$CLJS.Ts,new $CLJS.Gc(function(){return n8},Hsa,$CLJS.Jg([$CLJS.xl,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.no,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Kj,$CLJS.wL,$CLJS.zi,$CLJS.d6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.E6],null)],null),Rsa,mta,"metabase/lib/drill_thru/quick_filter.cljc",28,1,54,54,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[B8,v8,new $CLJS.h(null,
2,[$CLJS.up,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rz,$CLJS.Zi],null),$CLJS.Zs,w8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.quick-filter]\n          \n\n  Filter the current query based on the value clicked.\n\n  The options vary depending on the type of the field:\n  - `:is-null` and `:not-null` for a `NULL` value;\n  - `:\x3d` and `:!\x3d` for everything else;\n  - plus `:\x3c` and `:\x3e` for numeric and date columns.\n\n  Note that this returns a single `::drill-thru` value with 1 or more `:operators`; these are rendered as a set of small\n  buttons in a single row of the drop-down.",
$CLJS.n(n8)?n8.H:null])),y8,!1],null),new $CLJS.h(null,2,[$CLJS.Ts,new $CLJS.Gc(function(){return o8},lta,$CLJS.Jg([$CLJS.xl,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.no,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Kj,$CLJS.wL,$CLJS.zi,$CLJS.d6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.$6],null)],null),Xsa,Fsa,"metabase/lib/drill_thru/sort.cljc",20,1,34,34,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[B8,v8,new $CLJS.h(null,
2,[$CLJS.up,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rz,$CLJS.Zi],null),$CLJS.Zs,w8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.sort]\n          \n\n  Sorting on a clicked column.",$CLJS.n(o8)?o8.H:null])),y8,!0],null),new $CLJS.h(null,2,[$CLJS.Ts,new $CLJS.Gc(function(){return p8},hta,$CLJS.Jg([$CLJS.xl,$CLJS.aj,$CLJS.T,$CLJS.vk,
$CLJS.wi,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.no,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Kj,$CLJS.wL,$CLJS.zi,$CLJS.d6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.j6],null)],null),Psa,Usa,"metabase/lib/drill_thru/summarize_column.cljc",32,1,11,11,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[B8,v8,new $CLJS.h(null,1,[$CLJS.up,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rz,$CLJS.Zi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.summarize-column]\n          \n\n  A set of possible aggregations that can summarize this column: distinct values, sum, average.\n  Separate from [[summarize-column-by-time-drill]] which breaks out a column over time.",
$CLJS.n(p8)?p8.H:null])),y8,!0],null),new $CLJS.h(null,2,[$CLJS.Ts,new $CLJS.Gc(function(){return q8},Tsa,$CLJS.Jg([$CLJS.xl,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.no,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Kj,$CLJS.wL,$CLJS.zi,$CLJS.d6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.h6],null)],null),Asa,dta,"metabase/lib/drill_thru/summarize_column_by_time.cljc",40,1,15,15,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,
[B8,v8,new $CLJS.h(null,1,[$CLJS.up,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rz,$CLJS.Zi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.summarize-column-by-time]\n          \n\n  A breakout summarizing a column over time.\n  Separate from single-value [[summarize-column-drill]] for sum, average, and distinct value count.",$CLJS.n(q8)?
q8.H:null])),y8,!0],null),new $CLJS.h(null,2,[$CLJS.Ts,new $CLJS.Gc(function(){return c8},Wsa,$CLJS.Jg([$CLJS.xl,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.no,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Kj,$CLJS.wL,$CLJS.zi,$CLJS.d6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.Y6],null)],null),Ssa,Bsa,"metabase/lib/drill_thru/underlying_records.cljc",34,1,19,19,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[B8,v8,new $CLJS.h(null,
2,[$CLJS.up,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Rz,A8,x8,$CLJS.Zi],null),$CLJS.Zs,w8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref dimensions value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.underlying-records]\n          \n\n  When clicking on a particular broken-out group, offer a look at the details of all the rows that went into this\n  bucket. Eg. distribution of People by State, then click New York and see the table of all People filtered by\n  `STATE \x3d 'New York'`.\n\n  There is another quite different case: clicking the legend of a chart with multiple bars or lines broken out by\n  category. Then `column` is nil!",
$CLJS.n(c8)?c8.H:null])),y8,!1],null),new $CLJS.h(null,2,[$CLJS.Ts,new $CLJS.Gc(function(){return u8},Csa,$CLJS.Jg([$CLJS.xl,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.no,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Kj,$CLJS.wL,$CLJS.zi,$CLJS.d6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.S6],null)],null),ita,Dsa,"metabase/lib/drill_thru/zoom_in_timeseries.cljc",34,1,56,56,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[B8,v8,
new $CLJS.h(null,2,[$CLJS.up,new $CLJS.P(null,1,5,$CLJS.Q,[x8],null),$CLJS.Zs,w8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [dimensions], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries]\n          \n\n  Zooms in on some window, showing it in finer detail.\n\n  For example: The month of a year, days or weeks of a quarter, smaller lat/long regions, etc.\n\n  This is different from the `:drill-thru/zoom` type, which is for showing the details of a single object.",
$CLJS.n(u8)?u8.H:null])),y8,!1],null),new $CLJS.h(null,2,[$CLJS.Ts,new $CLJS.Gc(function(){return t8},Msa,$CLJS.Jg([$CLJS.xl,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.no,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Kj,$CLJS.wL,$CLJS.zi,$CLJS.d6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.T6],null)],null),Ysa,bta,"metabase/lib/drill_thru/zoom_in_geographic.cljc",34,1,137,137,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[B8,
v8,new $CLJS.h(null,2,[$CLJS.up,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Zi],null),$CLJS.Zs,z8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.geographic]\n          \n\n  Return a `:drill-thru/zoom-in.geographic` drill if appropriate. See docstring\n  for [[metabase.lib.drill-thru.zoom-in-geographic]] for more information on what circumstances this is returned in\n  and what it means to apply this drill.",
$CLJS.n(t8)?t8.H:null])),y8,!0],null),new $CLJS.h(null,2,[$CLJS.Ts,new $CLJS.Gc(function(){return r8},zsa,$CLJS.Jg([$CLJS.xl,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.no,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Kj,$CLJS.wL,$CLJS.zi,$CLJS.d6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.W6],null)],null),ysa,Jsa,"metabase/lib/drill_thru/zoom_in_bins.cljc",31,1,54,54,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[B8,v8,new $CLJS.h(null,
2,[$CLJS.up,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rz,$CLJS.Zi],null),$CLJS.Zs,w8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.binning]\n          \n\n  Return a drill thru that 'zooms in' on a breakout that uses `:binning` if applicable.\n  See [[metabase.lib.drill-thru.zoom-in-bins]] docstring for more information.",
$CLJS.n(r8)?r8.H:null])),y8,!0],null)],null);
$CLJS.ota=function(){function a(d,e,f){try{return $CLJS.fg.g($CLJS.Df,hsa(d)?function(){var l=wsa(f);return function u(t){return new $CLJS.oe(null,function(){for(var v=t;;){var x=$CLJS.y(v);if(x){var A=x,C=$CLJS.z(A),G=$CLJS.O(C),K=$CLJS.J.g(G,$CLJS.Ts),S=$CLJS.J.g(G,y8);if(x=$CLJS.y(function(V,Y,ha,ta,Ta,Cb,Oa,Wa){return function nb(Va){return new $CLJS.oe(null,function(Ja,bc,$d,Id){return function(){for(var sd=Va;;)if(sd=$CLJS.y(sd)){if($CLJS.Ad(sd)){var wl=$CLJS.lc(sd),K8=$CLJS.D(wl),op=$CLJS.re(K8);
a:for(var tQ=0;;)if(tQ<K8){var xB=$CLJS.kd(wl,tQ);xB=Id.j?Id.j(d,e,xB):Id.call(null,d,e,xB);$CLJS.n(xB)&&op.add(xB);tQ+=1}else{wl=!0;break a}return wl?$CLJS.ue($CLJS.we(op),nb($CLJS.mc(sd))):$CLJS.ue($CLJS.we(op),null)}op=$CLJS.z(sd);op=Id.j?Id.j(d,e,op):Id.call(null,d,e,op);if($CLJS.n(op))return $CLJS.fe(op,nb($CLJS.Lc(sd)));sd=$CLJS.Lc(sd)}else return null}}(V,Y,ha,ta,Ta,Cb,Oa,Wa),null,null)}}(v,C,G,K,S,A,x,l)($CLJS.n(function(){var V=S;return $CLJS.n(V)?l:V}())?l:new $CLJS.P(null,1,5,$CLJS.Q,[f],
null))))return $CLJS.hf.g(x,u($CLJS.Lc(v)));v=$CLJS.Lc(v)}else return null}},null,null)}(nta)}():null)}catch(l){var k=l;throw $CLJS.ii(["Error getting available drill thrus for query: ",$CLJS.p.h($CLJS.cV(k))].join(""),new $CLJS.h(null,3,[$CLJS.yF,d,$CLJS.qL,e,$CLJS.$M,f],null),k);}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();
$CLJS.pta=function(){function a(d,e){return b.j?b.j(d,-1,e):b.call(null,d,-1,e)}var b=null,c=function(){function d(f,k,l,m){var t=null;if(3<arguments.length){t=0;for(var u=Array(arguments.length-3);t<u.length;)u[t]=arguments[t+3],++t;t=new $CLJS.w(u,0,null)}return e.call(this,f,k,l,t)}function e(f,k,l,m){var t=$CLJS.DA($CLJS.yy);$CLJS.n($CLJS.CA("metabase.lib.drill-thru",t))&&$CLJS.BA("metabase.lib.drill-thru",t,$CLJS.eW("Applying drill thru: %s",$CLJS.H([$CLJS.fW(new $CLJS.h(null,4,[$CLJS.yF,f,$CLJS.qL,
k,Gsa,l,$CLJS.Ns,m],null))])),null);return $CLJS.Te.N($CLJS.M5,f,k,l,m)}d.A=3;d.B=function(f){var k=$CLJS.z(f);f=$CLJS.B(f);var l=$CLJS.z(f);f=$CLJS.B(f);var m=$CLJS.z(f);f=$CLJS.Lc(f);return e(k,l,m,f)};d.l=e;return d}();b=function(d,e,f,k){switch(arguments.length){case 2:return a.call(this,d,e);default:var l=null;if(3<arguments.length){l=0;for(var m=Array(arguments.length-3);l<m.length;)m[l]=arguments[l+3],++l;l=new $CLJS.w(m,0,null)}return c.l(d,e,f,l)}throw Error("Invalid arity: "+arguments.length);
};b.A=3;b.B=c.B;b.g=a;b.l=c.l;return b}();