var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var NU,OU,PU,RU,SU,TU,UU,VU,WU,XU;NU=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);OU=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);PU=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.QU=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);RU=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);SU=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);TU=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
UU=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);VU=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);WU=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);XU=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.X(NU,$CLJS.fg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.ht,"Valid template tag :widget-type"],null),$CLJS.Xz],null),$CLJS.Yg($CLJS.IU)));$CLJS.X(RU,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.js,$CLJS.qM,$CLJS.fN,$CLJS.vF,$CLJS.Kt,$CLJS.oB,$CLJS.lP],null));
$CLJS.X(UU,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.lE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gE,$CLJS.lE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dj,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,$CLJS.lE,$CLJS.ik],null)],null)],null));
$CLJS.X(VU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ei,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.Kl],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HN,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.xs],null)],null)],null));
$CLJS.X(SU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VU],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.vF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kj,new $CLJS.h(null,1,[$CLJS.Cs,!0],
null),$CLJS.ok],null)],null)],null));
$CLJS.X(TU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UU],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.qM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cM,$CLJS.lE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DP,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.VK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EP,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.OK],null)],null)],null));
$CLJS.X(XU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.fN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NQ,$CLJS.NK],null)],null)],null));$CLJS.X(WU,$CLJS.fg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.js],null),$CLJS.wU));
$CLJS.X(OU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WU],null)],null)],null)],null));
$CLJS.X(PU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RU],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,1,[$CLJS.Si,$CLJS.rj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fN,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gp,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OU],null)],null)],null)],null));
$CLJS.X($CLJS.QU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,$CLJS.lE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.ht,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Re(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.T.h(b))},a)}],null)],null));