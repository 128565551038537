var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var tta,uta,wta,xta,yta,zta;tta=function(a,b){return $CLJS.zA(function(c){return function(d,e,f){return $CLJS.n(a.h?a.h(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};uta=function(a,b){return $CLJS.gc($CLJS.eb(function(c,d){return $CLJS.ig.j(c,a.h?a.h(d):a.call(null,d),d)},$CLJS.ec($CLJS.N),b))};wta=function(a){return $CLJS.fg.j($CLJS.ch,$CLJS.Wk.g($CLJS.aE(function(b){return $CLJS.DW(b,a)}),$CLJS.jf.h($CLJS.hd)),vta)};
xta=function(a){return new $CLJS.h(null,3,[$CLJS.rj,$CLJS.oB,$CLJS.T,a,$CLJS.dj,$CLJS.p.h($CLJS.CE())],null)};
yta=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.T);return $CLJS.nl.l($CLJS.H([a,function(){var c=$CLJS.oh(/^#(\d+)(-[a-z0-9-]*)?$/,b);$CLJS.n(c)?($CLJS.I(c,0,null),c=$CLJS.I(c,1,null),c=$CLJS.dW(c)):c=null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.rj,$CLJS.fN,$CLJS.NQ,c],null):null}(),function(){var c=0==b.lastIndexOf("snippet:",0)?(0,$CLJS.ma)(b.substring(8)):null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.rj,$CLJS.qM,$CLJS.cM,c],null):null}(),$CLJS.n($CLJS.gE.h(a))?null:new $CLJS.h(null,
1,[$CLJS.gE,$CLJS.s1.g($CLJS.r1,b)],null)]))};$CLJS.H8=function(a){a=$CLJS.k1(a);return $CLJS.n($CLJS.uA(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yz,zta],null)))?$CLJS.ce.g($CLJS.ch,$CLJS.WN):$CLJS.ch};$CLJS.I8=function(a,b){var c=$CLJS.H8(a);return(0,$CLJS.AW)(a,0,function(d){var e=$CLJS.Yt.g(Ata,c);d=$CLJS.Te.j($CLJS.Ok,d,e);d=$CLJS.nl.l($CLJS.H([d,$CLJS.lm(b,c)]));$CLJS.Yt.g(c,$CLJS.gh($CLJS.Yg(b)));$CLJS.pW(a,0);return d})};$CLJS.J8=function(a){return $CLJS.Ge($CLJS.lm($CLJS.pW(a,0),$CLJS.H8(a)))};
$CLJS.L8=function(a){return $CLJS.TP.h($CLJS.pW(a,0))};zta=new $CLJS.M(null,"native-requires-specified-collection","native-requires-specified-collection",1239149925);$CLJS.Bta=new $CLJS.M(null,"write","write",-1857649168);$CLJS.Cta=new $CLJS.M(null,"native-permissions","native-permissions",1277672164);var vta,Ata;vta=new $CLJS.P(null,3,5,$CLJS.Q,[/\{\{\s*([A-Za-z0-9_\.]+)\s*\}\}/,/\{\{\s*(snippet:\s*[^}]+)\s*\}\}/,/\{\{\s*(#([0-9]*)(-[a-z0-9-]*)?)\s*\}\}/],null);
$CLJS.M8=function(){function a(d,e){d=$CLJS.Ge(wta(d));var f=$CLJS.Ge($CLJS.gh($CLJS.Yg(e)));if($CLJS.n($CLJS.n(d)?d:f)){var k=$CLJS.Yt.g(d,f);d=$CLJS.Yt.g(f,d);if($CLJS.E.l(1,$CLJS.D(k),$CLJS.H([$CLJS.D(d)]))){d=$CLJS.z(d);k=$CLJS.z(k);f=$CLJS.J.g(e,d);var l=$CLJS.E.g($CLJS.gE.h(f),$CLJS.s1.g($CLJS.r1,d))?$CLJS.s1.g($CLJS.r1,k):$CLJS.gE.h(f);f=$CLJS.R.l($CLJS.Ok.l(f,$CLJS.cM,$CLJS.H([$CLJS.NQ,$CLJS.DP])),$CLJS.gE,l,$CLJS.H([$CLJS.T,k]));e=$CLJS.R.j($CLJS.Ok.g(e,d),k,f)}else e=$CLJS.nl.l($CLJS.H([tta($CLJS.Ue(d),
e),uta($CLJS.T,$CLJS.jf.g(xta,k))]));e=$CLJS.st(e,yta)}else e=$CLJS.N;return e}function b(d){return $CLJS.M8.g?$CLJS.M8.g(d,null):$CLJS.M8.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();Ata=new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.WN,null],null),null);
$CLJS.X(new $CLJS.M("metabase.lib.native","native-extras","metabase.lib.native/native-extras",1125545077),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WN,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.lE],null)],null));
$CLJS.N8=function(){function a(d,e,f,k){var l=$CLJS.M8.h(e);return $CLJS.I8($CLJS.n1.g(d,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.Qt,$CLJS.MV,$CLJS.YU,f,$CLJS.pQ,l,$CLJS.TP,e],null)],null)),k)}function b(d,e){return $CLJS.N8.v?$CLJS.N8.v(d,e,null,null):$CLJS.N8.call(null,d,e,null,null)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
$CLJS.O8=function(){function a(d,e,f){$CLJS.pW(d,0);return $CLJS.I8($CLJS.n1.g(e,$CLJS.eL.h(d)),f)}function b(d,e){return $CLJS.O8.j?$CLJS.O8.j(d,e,null):$CLJS.O8.call(null,d,e,null)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.m1.m(null,$CLJS.MV,function(a){return $CLJS.f1($CLJS.H8(a),$CLJS.gh($CLJS.Yg($CLJS.J8(a))))&&!$CLJS.yA($CLJS.L8(a))});