var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var oY,lma,qY,mma,nma,oma,sY,qma,rma,sma,tma,uY,vY,wY,yY,uma,zY,AY,vma,wma,xma,BY,pma,CY,DY,yma,FY;oY=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.cf(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
lma=function(){return function(){function a(k,l,m){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj.j?$CLJS.rj.j(k,l,m):$CLJS.rj.call(null,k,l,m),$CLJS.tl.j?$CLJS.tl.j(k,l,m):$CLJS.tl.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj.g?$CLJS.rj.g(k,l):$CLJS.rj.call(null,k,l),$CLJS.tl.g?$CLJS.tl.g(k,l):$CLJS.tl.call(null,k,l)],null)}function c(k){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj.h?$CLJS.rj.h(k):$CLJS.rj.call(null,k),$CLJS.tl.h?$CLJS.tl.h(k):$CLJS.tl.call(null,
k)],null)}function d(){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj.o?$CLJS.rj.o():$CLJS.rj.call(null),$CLJS.tl.o?$CLJS.tl.o():$CLJS.tl.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var A=Array(arguments.length-3);x<A.length;)A[x]=arguments[x+3],++x;x=new $CLJS.w(A,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Te.N($CLJS.rj,m,t,u,v),$CLJS.Te.N($CLJS.tl,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.z(m);m=$CLJS.B(m);var u=$CLJS.z(m);m=$CLJS.B(m);var v=$CLJS.z(m);m=$CLJS.Lc(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.w(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};
$CLJS.pY=function(a){return function(b){var c=$CLJS.af(-1);return function(){function d(l,m){var t=c.Dd(null,c.Qb(null)+1);m=a.g?a.g(t,m):a.call(null,t,m);return null==m?l:b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=f;k.h=e;k.g=d;return k}()}};
qY=function(a){return $CLJS.AL($CLJS.WV)(a,$CLJS.Df,$CLJS.Df)};mma=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.SF,b),$CLJS.Ys(2,2,c))};nma=function(a,b){var c=$CLJS.mm(a,$CLJS.pQ);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Fb(c);b=$CLJS.st.g?$CLJS.st.g(c,b):$CLJS.st.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.pQ,b)}return a};oma=function(a){return $CLJS.hf.g($CLJS.wV(a),$CLJS.xV(a))};
$CLJS.rY=function(a,b){return $CLJS.hW(a)&&$CLJS.E.g($CLJS.z(a),b)};sY=function(a,b,c){var d=new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.Fs,null,$CLJS.Al,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.nu(2,c):$CLJS.nu(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?oY(2,c):oY(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.gX.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.zd(k)?$CLJS.fg.j($CLJS.Df,$CLJS.Xk.h($CLJS.Hl),l):$CLJS.xd(k)?$CLJS.AA($CLJS.Xa,l):l}):$CLJS.Ok.g(a,f)};
qma=function(a){return $CLJS.eb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return sY(b,pma,d)},a,oma(a))};
rma=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.eL.h(a),b);a:{var d=c;for(var e=$CLJS.Df;;){var f=$CLJS.z($CLJS.jf.g(lma(),$CLJS.mf($CLJS.Wk.j(tY,$CLJS.z,$CLJS.tl),$CLJS.Js.h(qY(d)))));if($CLJS.n(f)){var k=f;f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);var l=sY(d,f,k),m=$CLJS.DA($CLJS.wy);if($CLJS.n($CLJS.CA("metabase.lib.convert",m))){var t=$CLJS.sB.l($CLJS.H([$CLJS.rz,$CLJS.Nh.l($CLJS.H([k]))])),u=$CLJS.sB,v=u.l,x=$CLJS.Nh,A=x.l;var C=f;C=$CLJS.n(C)?C:$CLJS.FL(qY(d));$CLJS.BA("metabase.lib.convert",
m,$CLJS.eW("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.H([t,v.call(u,$CLJS.H([$CLJS.rz,A.call(x,$CLJS.H([C]))])),$CLJS.sB.l($CLJS.H([$CLJS.gz,$CLJS.fW($CLJS.z($CLJS.Vt(d,l)))]))])),null)}if($CLJS.E.g(l,d))break a;d=l;e=$CLJS.ce.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null))}else break a}}d=qma(d);if($CLJS.E.g(c,d)){if($CLJS.E.g(b,$CLJS.D($CLJS.eL.h(a))-1))return a;b+=1}else a=$CLJS.Zk.N(a,$CLJS.eL,$CLJS.R,b,d)}};
sma=function(a){var b=$CLJS.sW();return $CLJS.Yk.g(function(c){return $CLJS.E.g($CLJS.PE.h(c),"__join")?$CLJS.Zk.j(c,$CLJS.PE,b):c},a)};tma=function(a){return"string"===typeof $CLJS.PO.h(a)?$CLJS.Ok.g($CLJS.R.j(a,$CLJS.yV,$CLJS.qW($CLJS.PO.h(a))),$CLJS.PO):a};uY=function(){return $CLJS.Xk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.ne(b))?(b=$CLJS.je(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};
vY=function(a){return $CLJS.fg.j($CLJS.N,uY(),a)};wY=function(a){return $CLJS.Ge($CLJS.fg.j($CLJS.N,$CLJS.Wk.g(uY(),$CLJS.Xk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.Ii)})),a))};
yY=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);var d=$CLJS.fg.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.jf.h($CLJS.xY),c);a=$CLJS.E.g(a,$CLJS.LF)&&$CLJS.B(c)?$CLJS.ce.g($CLJS.rd(d),new $CLJS.h(null,1,[$CLJS.ei,$CLJS.qd(d)],null)):d;b=$CLJS.Ge(wY(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eM,a,b],null):a};uma=function(a){return $CLJS.fg.j($CLJS.Df,$CLJS.Wk.g($CLJS.jf.h(function(b){return $CLJS.vA(b,$CLJS.yB)}),$CLJS.jf.h($CLJS.xY)),$CLJS.mW.h(a))};
zY=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.eL);a=$CLJS.z($CLJS.eb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.xY.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.rQ,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.BO,uma(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.YU.h(c)],null)},null,a));return $CLJS.n($CLJS.TP.h(a))?$CLJS.jV(a,new $CLJS.h(null,1,[$CLJS.TP,$CLJS.yF],null)):a};
AY=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.EW(a,b,$CLJS.Wk.g($CLJS.xY,$CLJS.z)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.EW(d,b,function(e){return $CLJS.fg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.rs],null),$CLJS.jf.h($CLJS.xY),e)}):d;return $CLJS.jV(a,$CLJS.Fe([b,c]))};vma=function(a){var b=$CLJS.yV.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Ok.g(a,$CLJS.yV),$CLJS.PO,["card__",$CLJS.p.h(b)].join("")):a};
wma=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);xma=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);BY=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);pma=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);CY=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
DY=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.EY=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);yma=new $CLJS.M(null,"m","m",1632677161);FY=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var tY,zma,Ama,Bma,Cma,Dma,Ema,Fma,Gma,Hma,Ima;$CLJS.GY=$CLJS.N;$CLJS.HY=$CLJS.N;tY=new $CLJS.bh(null,new $CLJS.h(null,7,[$CLJS.TV,null,$CLJS.LE,null,$CLJS.nN,null,$CLJS.JP,null,$CLJS.RF,null,$CLJS.yR,null,$CLJS.SF,null],null),null);zma=$CLJS.Ye($CLJS.N);Ama=$CLJS.Ye($CLJS.N);Bma=$CLJS.Ye($CLJS.N);Cma=$CLJS.Ye($CLJS.N);Dma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.$B],null),$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));
$CLJS.IY=new $CLJS.fi($CLJS.Gh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.YB,Dma,zma,Ama,Bma,Cma);$CLJS.IY.m(null,$CLJS.ei,function(a){if($CLJS.zd(a)&&$CLJS.z(a)instanceof $CLJS.M){var b=$CLJS.qd(a);var c=$CLJS.xd(b);a=c?$CLJS.rd(a):a;var d=$CLJS.y(a);a=$CLJS.z(d);d=$CLJS.B(d);b=$CLJS.$V($CLJS.fg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.jf.h($CLJS.IY),d))}else b=a;return b});$CLJS.IY.m(null,$CLJS.SV,function(a){return a});
$CLJS.IY.m(null,$CLJS.FV,function(a){var b=$CLJS.IY.h($CLJS.SF.h(a)),c=$CLJS.Ge($CLJS.Yk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.jW($CLJS.IY.h(t),u)},$CLJS.JP.h(a))),d=$CLJS.fg.j($CLJS.N,$CLJS.nm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.sE);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.fg.j($CLJS.N,$CLJS.nm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.sE);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.HY,k=$CLJS.GY;$CLJS.HY=d;$CLJS.GY=e;try{var l=mma(tma(a),b,$CLJS.H([$CLJS.JP,c])),m=$CLJS.eb(function(t,u){return $CLJS.Ua($CLJS.J.g(t,u))?t:$CLJS.Zk.j(t,u,$CLJS.IY)},l,$CLJS.Pk.l(tY,$CLJS.SF,$CLJS.H([$CLJS.JP])));return $CLJS.n($CLJS.nN.h(m))?$CLJS.Zk.j(m,$CLJS.nN,sma):m}finally{$CLJS.GY=k,$CLJS.HY=f}});$CLJS.IY.m(null,$CLJS.MV,function(a){return nma(a,function(b){return $CLJS.EW(b,$CLJS.vF,$CLJS.IY)})});
$CLJS.IY.m(null,$CLJS.$K,function(a){a=$CLJS.Zk.j($CLJS.Zk.j(a,$CLJS.hL,$CLJS.IY),$CLJS.eL,$CLJS.IY);var b=$CLJS.n($CLJS.LE.h(a))?$CLJS.Zk.j(a,$CLJS.LE,function(c){return $CLJS.Tl(c)?$CLJS.Yk.g($CLJS.IY,c):$CLJS.Ah.h(c)}):a;return $CLJS.Ua($CLJS.PE.h(a))?$CLJS.R.j(b,$CLJS.PE,"__join"):b});$CLJS.IY.m(null,$CLJS.eB,function(a){return $CLJS.Yk.g($CLJS.IY,a)});
$CLJS.IY.m(null,$CLJS.gB,function(a){return $CLJS.n($CLJS.rj.h(a))?rma($CLJS.R.j($CLJS.Zk.j($CLJS.nW(a),$CLJS.eL,function(b){return $CLJS.Yk.g($CLJS.IY,b)}),$CLJS.EY,!0)):$CLJS.st(a,$CLJS.IY)});$CLJS.IY.m(null,$CLJS.cG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.$V(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,a,b],null))});
$CLJS.IY.m(null,$CLJS.Ij,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.jV(a,new $CLJS.h(null,3,[$CLJS.ZC,$CLJS.kB,$CLJS.nO,$CLJS.ak,$CLJS.GR,$CLJS.nE],null));var c=$CLJS.R.j;var d=$CLJS.Ii.h(a);$CLJS.n(d)||(d=$CLJS.kB.h(a),d=$CLJS.n(d)?d:$CLJS.GE(b));a=c.call($CLJS.R,a,$CLJS.Ii,d);return $CLJS.$V(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ij,a,b],null))});
$CLJS.IY.m(null,$CLJS.LF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.ei.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LF,$CLJS.Ok.g(c,$CLJS.ei),$CLJS.Yk.g($CLJS.IY,b)],null);b=$CLJS.$V(b);return null!=a?$CLJS.ce.g(b,$CLJS.IY.h(a)):b});$CLJS.IY.m(null,$CLJS.hB,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.$V(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.IY.m(null,$CLJS.SF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null),d=$CLJS.I(a,2,null),e=$CLJS.Q;var f=$CLJS.J.g($CLJS.HY,c);if(!$CLJS.n(f))throw $CLJS.ji(["Error converting :aggregation reference: no aggregation at index ",$CLJS.p.h(c)].join(""),new $CLJS.h(null,1,[$CLJS.JR,a],null));return $CLJS.$V(new $CLJS.P(null,3,5,e,[b,d,f],null))});
$CLJS.IY.m(null,$CLJS.eM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.IY.h(b);var c=$CLJS.y(b);b=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);d=$CLJS.B(d);return $CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.nl.l($CLJS.H([c,a]))],null),d)});Ema=$CLJS.Ye($CLJS.N);Fma=$CLJS.Ye($CLJS.N);Gma=$CLJS.Ye($CLJS.N);Hma=$CLJS.Ye($CLJS.N);Ima=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.$B],null),$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));
$CLJS.xY=new $CLJS.fi($CLJS.Gh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.YB,Ima,Ema,Fma,Gma,Hma);
$CLJS.xY.m(null,$CLJS.ei,function(a){if($CLJS.zd(a)&&$CLJS.z(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);if($CLJS.xd(a)){c=$CLJS.fg.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.hf.g;d=$CLJS.jf.g($CLJS.xY,d);a=wY(a);a=c.call($CLJS.fg,b,e.call($CLJS.hf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.fg.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.jf.g($CLJS.xY,$CLJS.fe(a,d)))}else a=$CLJS.xd(a)?$CLJS.st(vY(a),$CLJS.xY):
a;return a});for(var JY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[FY,DY],null)),KY=null,LY=0,MY=0;;)if(MY<LY){var Jma=KY.X(null,MY);$CLJS.jF(Jma,CY);MY+=1}else{var NY=$CLJS.y(JY);if(NY){var OY=NY;if($CLJS.Ad(OY)){var PY=$CLJS.lc(OY),Kma=$CLJS.mc(OY),Lma=PY,Mma=$CLJS.D(PY);JY=Kma;KY=Lma;LY=Mma}else{var Nma=$CLJS.z(OY);$CLJS.jF(Nma,CY);JY=$CLJS.B(OY);KY=null;LY=0}MY=0}else break}
for(var QY=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Jx,$CLJS.XF,$CLJS.lG,$CLJS.HF,$CLJS.pk,$CLJS.qG,$CLJS.go,$CLJS.OF,$CLJS.jG,$CLJS.sG,$CLJS.pG,$CLJS.xG],null)),RY=null,SY=0,TY=0;;)if(TY<SY){var Oma=RY.X(null,TY);$CLJS.jF(Oma,FY);TY+=1}else{var UY=$CLJS.y(QY);if(UY){var VY=UY;if($CLJS.Ad(VY)){var WY=$CLJS.lc(VY),Pma=$CLJS.mc(VY),Qma=WY,Rma=$CLJS.D(WY);QY=Pma;RY=Qma;SY=Rma}else{var Sma=$CLJS.z(VY);$CLJS.jF(Sma,FY);QY=$CLJS.B(VY);RY=null;SY=0}TY=0}else break}
for(var XY=$CLJS.y($CLJS.zf([$CLJS.Tr,$CLJS.at,$CLJS.Ur,$CLJS.yG,$CLJS.LF,$CLJS.fG,$CLJS.CF,$CLJS.AG,$CLJS.tF,$CLJS.QF,$CLJS.xF,$CLJS.aG,$CLJS.hG,$CLJS.zF,$CLJS.QI,$CLJS.SI,$CLJS.xy,$CLJS.MI,$CLJS.UI,$CLJS.GI,$CLJS.YI,$CLJS.LI,$CLJS.EI,$CLJS.XI,$CLJS.eJ,$CLJS.aJ,$CLJS.KI,$CLJS.hJ,$CLJS.$I,$CLJS.fJ,$CLJS.BF,$CLJS.uF,$CLJS.BG,$CLJS.RH,$CLJS.eG,$CLJS.Hx,$CLJS.tG,$CLJS.vG,$CLJS.CG,$CLJS.vt,$CLJS.Bt],!0)),YY=null,ZY=0,$Y=0;;)if($Y<ZY){var Tma=YY.X(null,$Y);$CLJS.jF(Tma,DY);$Y+=1}else{var aZ=$CLJS.y(XY);
if(aZ){var bZ=aZ;if($CLJS.Ad(bZ)){var cZ=$CLJS.lc(bZ),Uma=$CLJS.mc(bZ),Vma=cZ,Wma=$CLJS.D(cZ);XY=Uma;YY=Vma;ZY=Wma}else{var Xma=$CLJS.z(bZ);$CLJS.jF(Xma,DY);XY=$CLJS.B(bZ);YY=null;ZY=0}$Y=0}else break}$CLJS.xY.m(null,CY,function(a){return yY(a)});$CLJS.xY.m(null,$CLJS.gB,function(a){return $CLJS.fg.j($CLJS.N,$CLJS.Wk.g(uY(),$CLJS.jf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.xY.h(b)],null)})),a)});
$CLJS.xY.m(null,$CLJS.SF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.xd(b))try{var d=wY(b);b=$CLJS.Q;var e=$CLJS.GY,f=$CLJS.J.j(e,c,BY);if($CLJS.E.g(f,BY))throw $CLJS.ji(["Unable to find key ",$CLJS.Nh.l($CLJS.H([c]))," in map."].join(""),new $CLJS.h(null,2,[yma,e,$CLJS.Nw,c],null));var k=new $CLJS.P(null,2,5,b,[$CLJS.SF,f],null);return $CLJS.n(d)?$CLJS.ce.g(k,d):k}catch(l){throw c=l,d=$CLJS.cV(c),d=$CLJS.fa.g?$CLJS.fa.g("Error converting aggregation reference to pMBQL: %s",
d):$CLJS.fa.call(null,"Error converting aggregation reference to pMBQL: %s",d),$CLJS.ii(d,new $CLJS.h(null,1,[$CLJS.W,a],null),c);}else return a});$CLJS.xY.m(null,$CLJS.eB,function(a){return $CLJS.Yk.g($CLJS.xY,a)});
$CLJS.xY.m(null,$CLJS.cG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,$CLJS.xY.h(a),wY(b)],null)});
$CLJS.xY.m(null,$CLJS.Ij,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=wY($CLJS.jV(b,new $CLJS.h(null,3,[$CLJS.kB,$CLJS.ZC,$CLJS.ak,$CLJS.nO,$CLJS.nE,$CLJS.GR],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ij,a,b],null)});
$CLJS.xY.m(null,$CLJS.$K,function(a){var b=vY(a);a=0==$CLJS.PE.h(a).lastIndexOf("__join",0)?$CLJS.Ok.g(b,$CLJS.PE):b;return $CLJS.nl.l($CLJS.H([$CLJS.st($CLJS.Ok.l(a,$CLJS.eL,$CLJS.H([$CLJS.hL])),$CLJS.xY),AY($CLJS.lm(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.hL],null)),$CLJS.hL,$CLJS.AR),zY(a)]))});
$CLJS.xY.m(null,$CLJS.FV,function(a){var b=$CLJS.SF.h(a),c=$CLJS.fg.j($CLJS.N,$CLJS.nm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.sE);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.fg.j($CLJS.N,$CLJS.nm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.sE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.HY,e=$CLJS.GY;$CLJS.HY=c;$CLJS.GY=b;try{return $CLJS.eb(function(f,k){return $CLJS.EW(f,
k,$CLJS.xY)},AY($CLJS.EW($CLJS.EW(vma(vY(a)),$CLJS.SF,function(f){return $CLJS.Yk.g(yY,f)}),$CLJS.JP,function(f){return $CLJS.fg.g($CLJS.N,function(){return function m(l){return new $CLJS.oe(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.re(v);a:for(var A=0;;)if(A<v){var C=$CLJS.kd(u,A),G=$CLJS.xY.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iW(C),$CLJS.E.g($CLJS.Ij,$CLJS.z(G))?$CLJS.hd(G):G],null);x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.ue($CLJS.we(x),
m($CLJS.mc(t))):$CLJS.ue($CLJS.we(x),null)}x=$CLJS.z(t);u=$CLJS.xY.h(x);return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iW(x),$CLJS.E.g($CLJS.Ij,$CLJS.z(u))?$CLJS.hd(u):u],null),m($CLJS.Lc(t)))}return null}},null,null)}(f)}())}),$CLJS.TV,$CLJS.GF),$CLJS.Pk.l(tY,$CLJS.SF,$CLJS.H([$CLJS.TV,$CLJS.JP])))}finally{$CLJS.GY=e,$CLJS.HY=d}});$CLJS.xY.m(null,$CLJS.MV,function(a){return $CLJS.st(vY(a),$CLJS.xY)});
$CLJS.xY.m(null,$CLJS.SV,function(a){try{var b=vY(a),c=$CLJS.eN.h(b),d=zY(b),e=$CLJS.E.g($CLJS.Qt.h($CLJS.id($CLJS.eL.h(a))),$CLJS.MV)?$CLJS.TP:$CLJS.yF;return $CLJS.nl.l($CLJS.H([$CLJS.st($CLJS.Ok.l(b,$CLJS.eL,$CLJS.H([$CLJS.eN,$CLJS.EY])),$CLJS.xY),function(){var k=$CLJS.Fe([$CLJS.rj,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.eN,c):k}()]))}catch(k){var f=k;throw $CLJS.ii(function(){var l=$CLJS.cV(f);return $CLJS.fa.g?$CLJS.fa.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.fa.call(null,
"Error converting MLv2 query to legacy query: %s",l)}(),new $CLJS.h(null,1,[$CLJS.yF,a],null),f);}});
$CLJS.dZ=function(){function a(d,e,f){f=$CLJS.nY(null,$CLJS.Ez(f,$CLJS.H([$CLJS.Ci,!0])));var k=$CLJS.pW(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.SF);k=$CLJS.fg.j($CLJS.N,$CLJS.nm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.sE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.fg.j($CLJS.N,$CLJS.nm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.sE);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.HY,t=$CLJS.GY;$CLJS.HY=k;$CLJS.GY=l;try{try{return $CLJS.IY.h(f)}catch(v){var u=v;throw $CLJS.ii(function(){var x=$CLJS.cV(u);return $CLJS.fa.g?$CLJS.fa.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.fa.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.yF,d,$CLJS.qL,e,xma,f,wma,$CLJS.HY],null),u);}}finally{$CLJS.GY=t,$CLJS.HY=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();