var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./inflections.core.js");require("./medley.core.js");require("./metabase.lib.card.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var ypa,zpa,Apa,Bpa,t3,u3,v3,w3,Cpa,Dpa,Epa,Fpa,Gpa,Hpa,Ipa,Jpa,Kpa,G3,H3,Lpa,Mpa,Npa,F3,E3,Opa,I3;$CLJS.o3=function(a,b){$CLJS.eb(function(c,d){return a.h?a.h(d):a.call(null,d)},null,b)};
ypa=function(a){return function(b){var c=$CLJS.af(!0);return function(){function d(l,m){var t=$CLJS.q(c);if($CLJS.n($CLJS.n(t)?a.h?a.h(m):a.call(null,m):t))return l;$CLJS.Nl(c,null);return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=
f;k.h=e;k.g=d;return k}()}};zpa=function(a,b){return function f(d,e){return new $CLJS.oe(null,function(){var k;a:{var l=d;for(k=e;;){var m=l;l=$CLJS.I(m,0,null);if(m=$CLJS.y(m)){var t=a.h?a.h(l):a.call(null,l);if($CLJS.Hd(k,t))l=$CLJS.Lc(m);else{k=$CLJS.fe(l,f($CLJS.Lc(m),$CLJS.ce.g(k,t)));break a}}else{k=null;break a}}}return k},null,null)}(b,$CLJS.ch)};
Apa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;c=arguments[0];d=arguments[1];var e=arguments[2];b=3<b.length?new $CLJS.w(b.slice(3),0,null):null;return $CLJS.Ua($CLJS.y($CLJS.uA(c,d)))?c:$CLJS.Te.N($CLJS.gX,c,d,e,b)};$CLJS.p3=function(a,b){return $CLJS.I0.g(a,b instanceof $CLJS.M?b:$CLJS.ti.h(b))};
$CLJS.q3=function(a,b,c,d){if($CLJS.y(c)){d=$CLJS.Yk.g($CLJS.aW,d);var e=$CLJS.fg.j($CLJS.ch,$CLJS.jf.h(function(f){return $CLJS.d2.v(a,b,f,c)}),d);return $CLJS.Yk.g(function(f){return $CLJS.R.j(f,$CLJS.wZ,$CLJS.Hd(e,f))},c)}return null};$CLJS.r3=function(a){return $CLJS.E.g($CLJS.YB(a),$CLJS.$K)};Bpa=function(a,b){return $CLJS.n($CLJS.LE.h(a))?$CLJS.Zk.j(a,$CLJS.LE,function(c){return $CLJS.wd(c)?$CLJS.Yk.g(function(d){return $CLJS.s3.g?$CLJS.s3.g(d,b):$CLJS.s3.call(null,d,b)},c):c}):a};
t3=function(a){if($CLJS.n(a)){var b=$CLJS.mf;a:try{if($CLJS.zd(a)&&4===$CLJS.D(a))try{if($CLJS.F(a,0)instanceof $CLJS.M)try{var c=$CLJS.F(a,2);if($CLJS.zd(c)&&3===$CLJS.D(c))try{var d=$CLJS.F(c,0);if($CLJS.ie(d,$CLJS.cG))try{var e=$CLJS.F(a,3);if($CLJS.zd(e)&&3===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.ie(f,$CLJS.cG)){$CLJS.F(e,1);$CLJS.F(e,2);$CLJS.F(c,1);$CLJS.F(c,2);$CLJS.F(a,0);$CLJS.F(a,1);var k=new $CLJS.P(null,1,5,$CLJS.Q,[!0],null)}else throw $CLJS.Z;}catch(m){if(m instanceof Error){var l=
m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof
Error){l=m;if(l===$CLJS.Z){k=new $CLJS.P(null,1,5,$CLJS.Q,[!1],null);break a}throw l;}throw m;}a=$CLJS.z($CLJS.y(b($CLJS.Xa,k)))}else a=null;return a};u3=function(a){if($CLJS.n(t3(a))){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);$CLJS.I(a,3,null);return b}return null};v3=function(a){return $CLJS.n(t3(a))?($CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),$CLJS.I(a,3,null)):null};
w3=function(a,b){var c=$CLJS.s3;if($CLJS.Ua(t3(a)))return a;var d=$CLJS.I(a,0,null),e=$CLJS.I(a,1,null),f=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[d,e,f,$CLJS.Te.j(c,a,b)],null)};
Cpa=function(a,b,c){return $CLJS.td($CLJS.hL.h(a))?a:$CLJS.n(b)?Apa(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.hL],null),function(d){return function l(f,k){try{if($CLJS.zd(k)&&3===$CLJS.D(k))try{var m=$CLJS.F(k,0);if($CLJS.ie(m,$CLJS.cG))try{var t=$CLJS.F(k,1);if(null!=t?t.C&256||$CLJS.Bc===t.mf||(t.C?0:$CLJS.$a($CLJS.wb,t)):$CLJS.$a($CLJS.wb,t))try{var u=$CLJS.J.j(t,$CLJS.dP,$CLJS.nV);if($CLJS.E.g(u,b))return $CLJS.F(k,2),$CLJS.s3.g?$CLJS.s3.g(k,c):$CLJS.s3.call(null,k,c);throw $CLJS.Z;}catch(x){if(x instanceof
Error){var v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)return $CLJS.JW(l,f,k);throw v;}throw x;}}($CLJS.Df,d)}):$CLJS.Zk.j(a,$CLJS.hL,function(d){return $CLJS.Yk.g(function(e){return w3(e,$CLJS.H([c]))},d)})};
$CLJS.s3=function(a,b){var c=$CLJS.YB(a);switch(c instanceof $CLJS.M?c.T:null){case "field":return $CLJS.AE(a,$CLJS.yE,$CLJS.H([$CLJS.dP,b]));case "metadata/column":return $CLJS.yE(a,$CLJS.JZ,b);case "mbql/join":return c=$CLJS.u1(a),Cpa(Bpa($CLJS.yE(a,$CLJS.PE,b),b),c,b);default:return b=$CLJS.DA($CLJS.uy),$CLJS.n($CLJS.CA("metabase.lib.join",b))&&$CLJS.BA("metabase.lib.join",b,$CLJS.sw.l($CLJS.H(["with-join-value should not be called with",$CLJS.Nh.l($CLJS.H([a]))])),null),a}};
$CLJS.x3=function(a,b,c){var d=$CLJS.R.l,e=$CLJS.tA($CLJS.gZ,$CLJS.T)(c);a=$CLJS.v1($CLJS.PE.h(a),$CLJS.tA($CLJS.gZ,$CLJS.T)(c));b=b.h?b.h(a):b.call(null,a);return d.call($CLJS.R,c,$CLJS.gZ,e,$CLJS.H([$CLJS.uZ,b]))};Dpa=function(a,b){return $CLJS.Ua(b)?a:$CLJS.Yk.g(function(c){var d=v3(c);d=$CLJS.n(d)?$CLJS.n($CLJS.u1(d))?null:w3(c,$CLJS.H([b])):null;return $CLJS.n(d)?d:c},a)};$CLJS.y3=function(a,b){b=Dpa($CLJS.Yk.g($CLJS.bW,b),$CLJS.u1(a));return $CLJS.yE(a,$CLJS.hL,$CLJS.Ge(b))};
$CLJS.z3=function(a,b){b=b instanceof $CLJS.M?b:$CLJS.E.g(b,$CLJS.Df)?$CLJS.Xz:$CLJS.Ge($CLJS.fg.j($CLJS.Df,$CLJS.Wk.g($CLJS.jf.h($CLJS.aW),function(){var c=$CLJS.u1(a);return $CLJS.n(c)?$CLJS.jf.h(function(d){return $CLJS.s3(d,c)}):$CLJS.Td}()),b));return $CLJS.yE(a,$CLJS.LE,b)};
Epa=function(a,b){b=$CLJS.jm(function(d){return $CLJS.d2.g(d,a)},b);var c=$CLJS.nH($CLJS.tA($CLJS.n_,$CLJS.o_),b);if($CLJS.n(c))return c;b=$CLJS.z(b);if($CLJS.n(b))return b;b=$CLJS.nH($CLJS.n_,a);if($CLJS.n(b))return b;b=$CLJS.nH($CLJS.o_,a);return $CLJS.n(b)?b:$CLJS.z(a)};Fpa=function(a){return"string"===typeof a?(0,$CLJS.ma)($CLJS.xA(a,/ id$/i,"")):null};
Gpa=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.Kh(function(){return $CLJS.A2(b)});var d=new $CLJS.Kh(function(){return $CLJS.A2(a)});return $CLJS.E.g(a,b)||$CLJS.E.g(a,$CLJS.q(c))||$CLJS.E.g($CLJS.q(d),b)||$CLJS.E.g($CLJS.q(d),$CLJS.q(c))}return c}return c};
Hpa=function(a,b,c){var d=$CLJS.P_.g($CLJS.E.g($CLJS.Qt.h(b),$CLJS.SV)?b:a,b),e=$CLJS.n(c)?Fpa($CLJS.P_.g(a,c)):null,f=Gpa(d,e);return function(){var k=function(){if($CLJS.n(d)){if($CLJS.n(e)){var l=$CLJS.Ua($CLJS.oh(/id/i,e));return l?(l=!f)?[$CLJS.p.h(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.n(k)?k:$CLJS.n(d)?d:$CLJS.n(e)?e:"source"}()};
Ipa=function(a,b,c,d,e){return function m(k,l){try{if($CLJS.rY(l,$CLJS.cG)&&$CLJS.Ed($CLJS.d2.v(a,b,l,e)))return $CLJS.s3(l,d);throw $CLJS.Z;}catch(u){if(u instanceof Error){var t=u;if(t===$CLJS.Z)return $CLJS.JW(m,k,l);throw t;}throw u;}}($CLJS.Df,c)};
Jpa=function(a,b,c,d,e,f){c=Ipa(a,b,c,d,f);return function t(l,m){try{if($CLJS.zd(m)&&4===$CLJS.D(m))try{var u=$CLJS.F(m,2);if($CLJS.rY(u,$CLJS.cG))try{var v=$CLJS.F(m,3);if($CLJS.rY(v,$CLJS.cG)){var x=$CLJS.F(m,3),A=$CLJS.F(m,2),C=$CLJS.F(m,0),G=$CLJS.F(m,1),K=$CLJS.u1(A),S=$CLJS.u1(x);if($CLJS.Ua($CLJS.n(K)?K:S))return $CLJS.n($CLJS.d2.v(a,b,x,e))?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,$CLJS.s3(A,d),x],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,A,$CLJS.s3(x,d)],null);if($CLJS.E.g(K,d)&&$CLJS.E.g(S,d)){var V=
$CLJS.AE(A,$CLJS.Ok,$CLJS.H([$CLJS.dP])),Y=$CLJS.AE(x,$CLJS.Ok,$CLJS.H([$CLJS.dP])),ha=null==$CLJS.d2.v(a,b,V,e);var ta=ha?$CLJS.d2.v(a,b,Y,e):ha;return $CLJS.n(ta)?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,A,Y],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,V,x],null)}return m}throw $CLJS.Z;}catch(Cb){if(Cb instanceof Error){var Ta=Cb;if(Ta===$CLJS.Z)throw $CLJS.Z;throw Ta;}throw Cb;}else throw $CLJS.Z;}catch(Cb){if(Cb instanceof Error){Ta=Cb;if(Ta===$CLJS.Z)throw $CLJS.Z;throw Ta;}throw Cb;}else throw $CLJS.Z;
}catch(Cb){if(Cb instanceof Error){Ta=Cb;if(Ta===$CLJS.Z)return $CLJS.JW(t,l,m);throw Ta;}throw Cb;}}($CLJS.Df,c)};Kpa=function(a,b){var c=$CLJS.sW();$CLJS.o3(c,b);return c(a)};
$CLJS.A3=function(a,b,c){if($CLJS.Hd(c,$CLJS.PE))return c;var d=$CLJS.pW(a,b),e=$CLJS.z0.j(a,b,d),f=$CLJS.y($CLJS.mf($CLJS.Xa,function v(t,u){try{if($CLJS.zd(u)&&1<=$CLJS.D(u))try{var x=$CLJS.cl.j(u,0,1);if($CLJS.zd(x)&&1===$CLJS.D(x))try{var A=$CLJS.F(x,0);if($CLJS.ie(A,$CLJS.cG))return new $CLJS.P(null,1,5,$CLJS.Q,[u],null);throw $CLJS.Z;}catch(G){if(G instanceof Error){var C=G;if(C===$CLJS.Z)throw $CLJS.Z;throw C;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Z)throw $CLJS.Z;
throw C;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Z)return $CLJS.kV(v,t,u);throw C;}throw G;}}($CLJS.Df,$CLJS.hL.h(c))));f=Epa(e,f);var k=Kpa(Hpa(a,c,f),$CLJS.jm($CLJS.PE,$CLJS.nN.h(d))),l=$CLJS.x0.h($CLJS.n1.g(a,$CLJS.eL.h(c)));return $CLJS.s3($CLJS.Zk.j(c,$CLJS.hL,function(t){return $CLJS.Yk.g(function(u){return Jpa(a,b,u,k,e,l)},t)}),k)};$CLJS.B3=function(a){return $CLJS.hL.h(a)};
$CLJS.C3=function(a){return $CLJS.nl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.Qt,$CLJS.iL,$CLJS.wE,a],null),$CLJS.E.g(a,$CLJS.UE)?new $CLJS.h(null,1,[$CLJS.ei,!0],null):null]))};$CLJS.D3=function(a,b){b=$CLJS.z($CLJS.eL.h(b));return $CLJS.n($CLJS.yV.h(b))?$CLJS.J_(a,$CLJS.yV.h(b)):$CLJS.n($CLJS.PO.h(b))?$CLJS.H_(a,$CLJS.PO.h(b)):null};
G3=function(a){a=$CLJS.mH(function(d){return $CLJS.o_(d)?E3:$CLJS.n_(d)?F3:$CLJS.Mt},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,E3);var c=$CLJS.J.g(b,F3);b=$CLJS.J.g(b,$CLJS.Mt);return $CLJS.hf.l(a,c,$CLJS.H([b]))};H3=function(a,b,c,d){return $CLJS.Ua(c)?d:$CLJS.Yk.g(function(e){return $CLJS.n($CLJS.wZ.h(e))?$CLJS.p3(e,$CLJS.y2(c)):e},$CLJS.q3(a,b,d,new $CLJS.P(null,1,5,$CLJS.Q,[c],null)))};
Lpa=function(a,b,c,d){var e=new $CLJS.Kh(function(){return $CLJS.z0.v(a,b,d,new $CLJS.h(null,2,[$CLJS.d0,!1,$CLJS.l0,!1],null))});return $CLJS.Ge($CLJS.fg.j($CLJS.Df,$CLJS.dm(function(f){var k=$CLJS.O(f),l=$CLJS.J.g(k,$CLJS.vZ);return $CLJS.n(function(){var m=$CLJS.n_(k);return m?l:m}())?(f=$CLJS.nH(function(m){return $CLJS.E.g(l,$CLJS.dj.h(m))},$CLJS.q(e)),$CLJS.n(f)?$CLJS.R.j(k,I3,f):null):null}),$CLJS.z0.j(a,b,c)))};Mpa={};
Npa=new $CLJS.M("metabase.lib.join","join-fields","metabase.lib.join/join-fields",-2085751377);F3=new $CLJS.M(null,"fk","fk",398442651);E3=new $CLJS.M(null,"pk","pk",-771936732);Opa=new $CLJS.r("metabase.lib.stage","ensure-previous-stages-have-metadata","metabase.lib.stage/ensure-previous-stages-have-metadata",2048342183,null);I3=new $CLJS.M("metabase.lib.join","target","metabase.lib.join/target",203858838);$CLJS.o0.m(null,$CLJS.$K,function(a,b,c){b=$CLJS.O(c);b=$CLJS.J.g(b,$CLJS.eL);b=$CLJS.I(b,0,null);b=$CLJS.O(b);c=$CLJS.J.g(b,$CLJS.PO);b=$CLJS.J.g(b,$CLJS.yV);c=$CLJS.n(c)?$CLJS.gE.h($CLJS.H_(a,c)):null;if($CLJS.n(c))return c;$CLJS.n(b)?(c=$CLJS.J_(a,b),a=$CLJS.n(c)?$CLJS.P_.j(a,0,c):$CLJS.z1(b)):a=null;return $CLJS.n(a)?a:$CLJS.DE("Native Query")});$CLJS.v0.m(null,$CLJS.$K,function(a,b,c){a=$CLJS.P_.j(a,b,c);c=$CLJS.PE.h(c);return new $CLJS.h(null,2,[$CLJS.T,$CLJS.n(c)?c:a,$CLJS.gE,a],null)});
$CLJS.u0.m(null,$CLJS.$K,function(){throw $CLJS.ji("You can't calculate a metadata map for a join! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.o0.m(null,$CLJS.iL,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.wE);a=a instanceof $CLJS.M?a.T:null;switch(a){case "left-join":return $CLJS.DE("Left outer join");case "right-join":return $CLJS.DE("Right outer join");case "inner-join":return $CLJS.DE("Inner join");case "full-join":return $CLJS.DE("Full outer join");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});
$CLJS.v0.m(null,$CLJS.iL,function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.wE);c=$CLJS.J.g(d,$CLJS.ei);a=new $CLJS.h(null,2,[$CLJS.m0,$CLJS.RA(e),$CLJS.gE,$CLJS.P_.j(a,b,d)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.ei,!0):a});
$CLJS.w0.m(null,$CLJS.$K,function(a,b,c,d){var e=$CLJS.O(c),f=$CLJS.J.g(e,$CLJS.PE),k=$CLJS.J.j(e,$CLJS.LE,$CLJS.Xz),l=$CLJS.J.g(e,$CLJS.eL);c=$CLJS.O(d);var m=$CLJS.J.g(c,$CLJS.j0);if($CLJS.E.g(k,$CLJS.Xz))return null;var t="undefined"!==typeof $CLJS.QL&&"undefined"!==typeof Mpa&&"undefined"!==typeof $CLJS.Ppa&&"undefined"!==typeof $CLJS.J3?new $CLJS.Gc(function(){return $CLJS.J3},$CLJS.od(Opa,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),null):null,u=function(){var v=$CLJS.R.j(a,$CLJS.eL,l);return $CLJS.n(t)?
t.g?t.g(v,-1):t.call(null,v,-1):v}();c=$CLJS.E.g(k,$CLJS.oy)?$CLJS.x0.v(u,-1,$CLJS.qd(l),c):function(){return function A(x){return new $CLJS.oe(null,function(){for(;;){var C=$CLJS.y(x);if(C){if($CLJS.Ad(C)){var G=$CLJS.lc(C),K=$CLJS.D(G),S=$CLJS.re(K);a:for(var V=0;;)if(V<K){var Y=$CLJS.kd(G,V);Y=$CLJS.AE(Y,$CLJS.Ok,$CLJS.H([$CLJS.dP]));Y=$CLJS.M_.j(u,-1,Y);S.add(Y);V+=1}else{G=!0;break a}return G?$CLJS.ue($CLJS.we(S),A($CLJS.mc(C))):$CLJS.ue($CLJS.we(S),null)}S=$CLJS.z(C);S=$CLJS.AE(S,$CLJS.Ok,$CLJS.H([$CLJS.dP]));
return $CLJS.fe($CLJS.M_.j(u,-1,S),A($CLJS.Lc(C)))}return null}},null,null)}(k)}();return $CLJS.Yk.g(function(v){v=$CLJS.R.j(v,$CLJS.HZ,f);v=$CLJS.s3($CLJS.R.l(v,$CLJS.gE,$CLJS.P_.j(a,b,v),$CLJS.H([$CLJS.FK,$CLJS.rZ])),f);return $CLJS.x3(e,m,v)},c)});$CLJS.y0.m(null,$CLJS.$K,function(a,b,c,d){return $CLJS.x0.v(a,b,$CLJS.R.j(c,$CLJS.LE,$CLJS.oy),d)});
var K3,Qpa=$CLJS.Ye($CLJS.N),Rpa=$CLJS.Ye($CLJS.N),Spa=$CLJS.Ye($CLJS.N),Tpa=$CLJS.Ye($CLJS.N),Upa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.$B],null),$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));K3=new $CLJS.fi($CLJS.Gh.g("metabase.lib.join","join-clause-method"),$CLJS.YB,Upa,Qpa,Rpa,Spa,Tpa);K3.m(null,$CLJS.$K,function(a){return a});K3.m(null,$CLJS.SV,function(a){return $CLJS.$V(new $CLJS.h(null,2,[$CLJS.Qt,$CLJS.$K,$CLJS.eL,$CLJS.eL.h($CLJS.nW(a))],null))});
K3.m(null,$CLJS.FV,function(a){return $CLJS.$V(new $CLJS.h(null,2,[$CLJS.Qt,$CLJS.$K,$CLJS.eL,new $CLJS.P(null,1,5,$CLJS.Q,[a],null)],null))});K3.m(null,$CLJS.XK,function(a){return $CLJS.$V(new $CLJS.h(null,2,[$CLJS.Qt,$CLJS.$K,$CLJS.eL,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.yV,$CLJS.dj.h(a),$CLJS.Qt,$CLJS.FV],null)],null)],null))});
K3.m(null,$CLJS.KK,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.JZ);a=$CLJS.J.g(b,Npa);b=K3.h(new $CLJS.h(null,3,[$CLJS.Qt,$CLJS.FV,$CLJS.lB,new $CLJS.h(null,1,[$CLJS.sE,$CLJS.p.h($CLJS.CE())],null),$CLJS.PO,$CLJS.dj.h(b)],null));c=$CLJS.n(c)?$CLJS.s3(b,c):b;return $CLJS.n(a)?$CLJS.z3.g?$CLJS.z3.g(c,a):$CLJS.z3.call(null,c,a):c});
$CLJS.L3=function(){function a(d,e){return $CLJS.y3(c.h?c.h(d):c.call(null,d),e)}function b(d){return $CLJS.Q1(K3.h(d),$CLJS.LE,$CLJS.oy)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Vpa=function(){function a(d,e,f){var k=$CLJS.L3.h(f);f=$CLJS.td($CLJS.B3.h?$CLJS.B3.h(k):$CLJS.B3.call(null,k))?function(){var m=$CLJS.D3.g?$CLJS.D3.g(d,k):$CLJS.D3.call(null,d,k);return $CLJS.M3.j?$CLJS.M3.j(d,e,m):$CLJS.M3.call(null,d,e,m)}():null;f=$CLJS.y(f)?$CLJS.y3(k,f):k;var l=$CLJS.A3(d,e,f);return $CLJS.AW.l(d,e,$CLJS.Zk,$CLJS.H([$CLJS.nN,function(m){return $CLJS.ce.g($CLJS.Ef(m),l)}]))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.N3=function(){function a(d,e){return $CLJS.Ge($CLJS.J.g($CLJS.pW(d,e),$CLJS.nN))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Wpa=function(){function a(d){d=$CLJS.k1(d);d=$CLJS.Yz.h(d);return $CLJS.fg.j($CLJS.Df,$CLJS.Wk.g($CLJS.lf($CLJS.We($CLJS.Hd,d)),$CLJS.jf.h($CLJS.C3)),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.UE,$CLJS.KE,$CLJS.YE,$CLJS.aF],null))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Xpa=function(){function a(d,e,f,k){var l=$CLJS.r3(f)?$CLJS.u1(f):null,m=$CLJS.fg.j($CLJS.ch,$CLJS.Wk.g($CLJS.jf.h($CLJS.u1),ypa(function(t){return $CLJS.Uk.g(t,l)})),$CLJS.N3.g(d,e));f=$CLJS.n(k)?k:$CLJS.r3(f)?u3($CLJS.z($CLJS.B3(f))):null;return G3(H3(d,e,f,$CLJS.Xk.g(function(t){t=$CLJS.u1(t);return $CLJS.n(t)?$CLJS.Hd(m,t):null},$CLJS.z0.v(d,e,$CLJS.pW(d,e),new $CLJS.h(null,1,[$CLJS.d0,!1],null)))))}function b(d,e,f,k){return c.N?c.N(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();
$CLJS.Ypa=function(){function a(d,e,f,k,l){k=$CLJS.r3(f)?$CLJS.D3(d,f):f;var m=$CLJS.r3(f)?$CLJS.u1(f):null;f=$CLJS.n(l)?l:$CLJS.r3(f)?v3($CLJS.z($CLJS.B3(f))):null;f=$CLJS.n(f)?$CLJS.Ua(m)?$CLJS.AE(f,$CLJS.Ok,$CLJS.H([$CLJS.dP])):f:null;return G3(H3(d,e,f,$CLJS.jf.g(function(t){t=$CLJS.R.j(t,$CLJS.FK,$CLJS.rZ);return $CLJS.n(m)?$CLJS.s3(t,m):t},$CLJS.z0.v(d,e,k,new $CLJS.h(null,1,[$CLJS.d0,!1],null)))))}function b(d,e,f,k){return c.N?c.N(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();$CLJS.Zpa=function(){function a(c,d,e){return b.v?b.v(c,-1,d,e):b.call(null,c,-1,d,e)}var b=null;b=function(c,d,e,f){switch(arguments.length){case 3:return a.call(this,c,d,e);case 4:return $CLJS.i2}throw Error("Invalid arity: "+arguments.length);};b.j=a;b.v=function(){return $CLJS.i2};return b}();
$CLJS.M3=function(){function a(d,e,f){function k(u,v){u=$CLJS.Ok.g(u,$CLJS.B1);v=$CLJS.Ok.g(v,$CLJS.B1);return $CLJS.n3.l($CLJS.g2.h($CLJS.Rr),u,$CLJS.H([v]))}function l(u,v){return $CLJS.Ge(zpa(function(x){return $CLJS.dj.h(I3.h(x))},Lpa(d,e,u,v)))}var m=$CLJS.pW(d,e),t=function(){var u=l(m,f);return $CLJS.n(u)?$CLJS.Yk.g(function(v){return k(v,I3.h(v))},u):null}();if($CLJS.n(t))return t;t=l(f,m);return $CLJS.n(t)?$CLJS.Yk.g(function(u){return k(I3.h(u),u)},t):null}function b(d,e){return c.j?c.j(d,
-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.$pa=function(){function a(e,f,k,l){l=$CLJS.n(l)?l:$CLJS.r3(k)?u3($CLJS.z($CLJS.B3(k))):null;$CLJS.n(l)?(l=$CLJS.S_.j(e,f,l),l=$CLJS.uA(l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yW,$CLJS.gE],null))):l=null;if($CLJS.n(l))return l;if(l=0===$CLJS.oW(e,f)){l=$CLJS.N3.g(e,f);var m=$CLJS.td(l);k=m?m:$CLJS.r3(k)?$CLJS.E.g($CLJS.PE.h(k),$CLJS.PE.h($CLJS.z(l))):null;k=$CLJS.n(k)?$CLJS.B_(e):k}else k=l;$CLJS.n(k)?(k=$CLJS.B_(e),k=$CLJS.H_(e,k),e=$CLJS.P_.j(e,f,k)):e=null;return $CLJS.n(e)?e:$CLJS.DE("Previous results")}
function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.j?d.j(e,f,null):d.call(null,e,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();
$CLJS.aqa=function(){function a(d,e,f,k){f=$CLJS.bW.h(f);$CLJS.I(f,0,null);$CLJS.I(f,1,null);var l=$CLJS.I(f,2,null),m=$CLJS.I(f,3,null);k=k instanceof $CLJS.M?k:$CLJS.ti.h(k);e=$CLJS.oW(d,e);l=$CLJS.M0.j(d,e,l);d=$CLJS.M0.j(d,e,m);m=null==k||$CLJS.Hd($CLJS.gh($CLJS.jf.g($CLJS.ti,l)),k);d=null==k||$CLJS.Hd($CLJS.gh($CLJS.jf.g($CLJS.ti,d)),k);f=m?$CLJS.Zk.v(f,2,$CLJS.p3,k):f;return d?$CLJS.Zk.v(f,3,$CLJS.p3,k):f}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,
e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.r0.m(null,$CLJS.nN,function(a,b){var c=$CLJS.Ge($CLJS.N3.g(a,b));c=null==c?null:$CLJS.jf.g(function(d){return $CLJS.P_.j(a,b,d)},c);return null==c?null:$CLJS.tt(" + ",c)});