var $CLJS = module.exports = {};
/*

 Copyright The Closure Library Authors.
 SPDX-License-Identifier: Apache-2.0
*/
'use strict';var ba,ca,da,ea,ia,ja;ba=function(){var a=$CLJS.aa.navigator;return a&&(a=a.userAgent)?a:""};ca=function(a){return-1!=ba().indexOf(a)};da=function(){return ca("Firefox")||ca("FxiOS")};ea=function(){return(ca("Chrome")||ca("CriOS"))&&!ca("Edge")||ca("Silk")};
$CLJS.fa=function(a,b){const c=Array.prototype.slice.call(arguments),d=c.shift();if("undefined"==typeof d)throw Error("[goog.string.format] Template required");return d.replace(/%([0\- \+]*)(\d+)?(\.(\d+))?([%sfdiu])/g,function(e,f,k,l,m,t,u,v){if("%"==t)return"%";const x=c.shift();if("undefined"==typeof x)throw Error("[goog.string.format] Not enough arguments");arguments[0]=x;return $CLJS.fa.Ac[t].apply(null,arguments)})};ia=function(){return ca("iPhone")&&!ca("iPod")&&!ca("iPad")};
ja=function(){ia()||ca("iPad")||ca("iPod")};$CLJS.ka=$CLJS.ka||{};$CLJS.aa=global;$CLJS.la="closure_uid_"+(1E9*Math.random()>>>0);$CLJS.aa=global;$CLJS.ma=String.prototype.trim?function(a){return a.trim()}:function(a){return/^[\s\xa0]*([\s\S]*?)[\s\xa0]*$/.exec(a)[1]};var na=String.prototype.repeat?function(a,b){return a.repeat(b)}:function(a,b){return Array(b+1).join(a)};$CLJS.oa=class{constructor(a,b){this.name=a;this.value=b}toString(){return this.name}};$CLJS.pa=new $CLJS.oa("OFF",Infinity);$CLJS.qa=[$CLJS.pa,new $CLJS.oa("SHOUT",1200),new $CLJS.oa("SEVERE",1E3),new $CLJS.oa("WARNING",900),new $CLJS.oa("INFO",800),new $CLJS.oa("CONFIG",700),new $CLJS.oa("FINE",500),new $CLJS.oa("FINER",400),new $CLJS.oa("FINEST",300),new $CLJS.oa("ALL",0)];$CLJS.fa.Ac={};$CLJS.fa.Ac.s=function(a,b,c){return isNaN(c)||""==c||a.length>=Number(c)?a:a=-1<b.indexOf("-",0)?a+na(" ",Number(c)-a.length):na(" ",Number(c)-a.length)+a};
$CLJS.fa.Ac.f=function(a,b,c,d,e){d=a.toString();isNaN(e)||""==e||(d=parseFloat(a).toFixed(e));let f;f=0>Number(a)?"-":0<=b.indexOf("+")?"+":0<=b.indexOf(" ")?" ":"";0<=Number(a)&&(d=f+d);if(isNaN(c)||d.length>=Number(c))return d;d=isNaN(e)?Math.abs(Number(a)).toString():Math.abs(Number(a)).toFixed(e);a=Number(c)-d.length-f.length;0<=b.indexOf("-",0)?d=f+d+na(" ",a):(b=0<=b.indexOf("0",0)?"0":" ",d=f+na(b,a)+d);return d};
$CLJS.fa.Ac.d=function(a,b,c,d,e,f,k,l){return $CLJS.fa.Ac.f(parseInt(a,10),b,c,d,0,f,k,l)};$CLJS.fa.Ac.i=$CLJS.fa.Ac.d;$CLJS.fa.Ac.u=$CLJS.fa.Ac.d;ca("Opera");ca("Trident")||ca("MSIE");ca("Edge");!ca("Gecko")||-1!=ba().toLowerCase().indexOf("webkit")&&!ca("Edge")||ca("Trident")||ca("MSIE")||ca("Edge");-1!=ba().toLowerCase().indexOf("webkit")&&!ca("Edge")&&ca("Mobile");ca("Macintosh");ca("Windows");ca("Linux")||ca("CrOS");var ra=$CLJS.aa.navigator||null;ra&&(ra.appVersion||"").indexOf("X11");ca("Android");ia();ca("iPad");ca("iPod");ja();ba().toLowerCase().indexOf("kaios");Date.now();da();ia()||ca("iPod");ca("iPad");!ca("Android")||ea()||da()||ca("Opera")||ca("Silk");ea();!ca("Safari")||ea()||ca("Coast")||ca("Opera")||ca("Edge")||ca("Edg/")||ca("OPR")||da()||ca("Silk")||ca("Android")||ja();