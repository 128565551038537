var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var vz=function(){},wz=function(a){return $CLJS.J.g($CLJS.ky,a).value},Qda=function(a){var b=wz(a);return $CLJS.n(function(){var c=wz($CLJS.By);return $CLJS.Rk.g?$CLJS.Rk.g(c,b):$CLJS.Rk.call(null,c,b)}())?$CLJS.gz:$CLJS.n(function(){var c=wz($CLJS.Ay);return $CLJS.Rk.g?$CLJS.Rk.g(c,b):$CLJS.Rk.call(null,c,b)}())?$CLJS.qz:$CLJS.n(function(){var c=wz($CLJS.yy);return $CLJS.Rk.g?$CLJS.Rk.g(c,b):$CLJS.Rk.call(null,c,b)}())?$CLJS.jz:$CLJS.n(function(){var c=wz($CLJS.sy);return $CLJS.Rk.g?
$CLJS.Rk.g(c,b):$CLJS.Rk.call(null,c,b)}())?$CLJS.pz:$CLJS.n(function(){var c=wz($CLJS.zy);return $CLJS.Rk.g?$CLJS.Rk.g(c,b):$CLJS.Rk.call(null,c,b)}())?$CLJS.rz:$CLJS.n(function(){var c=wz($CLJS.vy);return $CLJS.Rk.g?$CLJS.Rk.g(c,b):$CLJS.Rk.call(null,c,b)}())?$CLJS.mz:$CLJS.n(function(){var c=wz($CLJS.py);return $CLJS.Rk.g?$CLJS.Rk.g(c,b):$CLJS.Rk.call(null,c,b)}())?$CLJS.nz:$CLJS.oz},xz=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(b),$CLJS.p.h(" ")].join(""),
a],null)},yz=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.ce.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.uz,c))].join(""),$CLJS.H(["color:black"]))],null)},zz=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.ce.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.uz,$CLJS.sz)),";background-color:",$CLJS.p.h($CLJS.J.g($CLJS.uz,
c))].join(""),$CLJS.H(["color:black;background-color:inherit"]))],null)},Rda=function(a){function b(c,d){return d>=c}a=wz(a);if(b(wz($CLJS.By),a))return"error";if(b(wz($CLJS.Ay),a))return"warn";if(b(wz($CLJS.yy),a))return"info";b(wz($CLJS.sy),a);return"log"},Az=function(a){return function(b){var c=$CLJS.O(b),d=$CLJS.J.g(c,$CLJS.qy),e=$CLJS.J.g(c,$CLJS.At);b=$CLJS.J.g(c,$CLJS.Cy);e=Rda(e);e=$CLJS.Ca(console,e);e=$CLJS.n(e)?e:console.log;$CLJS.Te.g(e,a.h?a.h(c):a.call(null,c));return $CLJS.n(b)?(c=
["[",$CLJS.p.h(d),"]"].join(""),d=$CLJS.p.h(b),b=b.stack,e.v?e.v(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},Bz=function Bz(a,b){for(;;){if($CLJS.E.g($CLJS.fz,b))return yz(a,", ",$CLJS.oz);if($CLJS.E.g($CLJS.hz,b))return xz(a);if(b instanceof $CLJS.M)return yz(a,b,$CLJS.jz);if(b instanceof $CLJS.r)return yz(a,b,$CLJS.pz);if("string"===typeof b)return yz(a,$CLJS.Nh.l($CLJS.H([b])),$CLJS.iz);if($CLJS.Af(b)){var d=xz(function(){var f=a,k=$CLJS.Eb(b);return Bz.g?Bz.g(f,k):Bz.call(null,f,k)}()),e=$CLJS.Fb(b);
return Bz.g?Bz.g(d,e):Bz.call(null,d,e)}if(b instanceof $CLJS.h||b instanceof $CLJS.Ig)return d=a,d=yz(d,"{",$CLJS.tz),d=$CLJS.eb(Bz,d,$CLJS.gf($CLJS.fz,b)),yz(d,"}",$CLJS.tz);if($CLJS.xd(b))return d=a,d=yz(d,["#",$CLJS.p.h(function(){var f=$CLJS.ab(b),k=f.name;return $CLJS.td(k)?$CLJS.Nh.l($CLJS.H([f])):k}())," "].join(""),$CLJS.kz),d=yz(d,"{",$CLJS.tz),d=$CLJS.eb(Bz,d,$CLJS.gf($CLJS.fz,b)),yz(d,"}",$CLJS.tz);if($CLJS.vd(b))return d=a,d=yz(d,"#{",$CLJS.tz),d=$CLJS.eb(Bz,d,$CLJS.gf($CLJS.hz,b)),yz(d,
"}",$CLJS.tz);if($CLJS.zd(b))return d=a,d=yz(d,"[",$CLJS.tz),d=$CLJS.eb(Bz,d,$CLJS.gf($CLJS.hz,b)),yz(d,"]",$CLJS.tz);if(b instanceof $CLJS.Sf)d=yz(a,"#queue ",$CLJS.kz),e=$CLJS.fg.g($CLJS.Df,b),a=d,b=e;else{if($CLJS.Dd(b))return d=a,d=yz(d,"(",$CLJS.kz),d=$CLJS.eb(Bz,d,$CLJS.gf($CLJS.hz,b)),yz(d,")",$CLJS.kz);if(null!=b?b.I&16384||$CLJS.Bc===b.aj||(b.I?0:$CLJS.$a(vz,b)):$CLJS.$a(vz,b))d=yz(a,"#atom ",$CLJS.kz),e=$CLJS.q(b),a=d,b=e;else if($CLJS.gi(b))d=yz(a,"#uuid ",$CLJS.kz),e=$CLJS.p.h(b),a=d,
b=e;else if($CLJS.Ya(b))d=yz(a,"#js ",$CLJS.kz),e=$CLJS.eb(function(f,k){return function(l,m){return $CLJS.R.j(l,$CLJS.Ah.h(m),$CLJS.Ca(k,m))}}(a,b),$CLJS.N,Object.keys(b)),a=d,b=e;else if($CLJS.Sa(b))d=yz(a,"#js ",$CLJS.kz),e=$CLJS.fg.g($CLJS.Df,b),a=d,b=e;else return yz(a,$CLJS.Nh.l($CLJS.H([b])),$CLJS.lz)}}};$CLJS.Sda=Az(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.At);var b=$CLJS.J.g(a,$CLJS.qy),c=$CLJS.J.g(a,$CLJS.Ui);$CLJS.J.g(a,$CLJS.Cy);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),c],null)});
$CLJS.Tda=Az(function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.At);a=$CLJS.J.g(b,$CLJS.qy);var d=$CLJS.J.g(b,$CLJS.Ui);$CLJS.J.g(b,$CLJS.Cy);b=Qda(c);d=Bz(xz(zz(zz(zz(new $CLJS.P(null,2,5,$CLJS.Q,["",$CLJS.Df],null),"[",b),a,b),"]",b)),d);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.fe(a,d)});
$CLJS.Uda=Az(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.At);var b=$CLJS.J.g(a,$CLJS.qy),c=$CLJS.J.g(a,$CLJS.Ui);$CLJS.J.g(a,$CLJS.Cy);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),$CLJS.Nh.l($CLJS.H([c]))],null)});