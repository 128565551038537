var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$moment.js");require("./clojure.string.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.i18n.js");
'use strict';var V$=function(a){return new $CLJS.oe(null,function(){return $CLJS.fe(a.o?a.o():a.call(null),V$(a))},null,null)},W$=function(a,b){return new $CLJS.oe(null,function(){var c=$CLJS.y(b);if(c){var d=$CLJS.z(c),e=a.h?a.h(d):a.call(null,d),f=$CLJS.fe(d,$CLJS.mu(function(k){return $CLJS.E.g(e,a.h?a.h(k):a.call(null,k))},$CLJS.B(c)));return $CLJS.fe(f,W$(a,new $CLJS.oe(null,function(){return $CLJS.cf($CLJS.D(f),c)},null,null)))}return null},null,null)},oxa=function(a,b){switch(a){case "minutes":return $CLJS.iV("Minute",
"Minutes",b);case "hours":return $CLJS.iV("Hour","Hours",b);case "days":return $CLJS.iV("Day","Days",b);case "weeks":return $CLJS.iV("Week","Weeks",b);case "months":return $CLJS.iV("Month","Months",b);case "quarters":return $CLJS.iV("Quarter","Quarters",b);case "years":return $CLJS.iV("Year","Years",b);default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}},pxa=function(a,b,c){b=parseInt(b);c=oxa(c,b);a=new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.E.g(b,1)],null);if($CLJS.E.g(new $CLJS.P(null,
2,5,$CLJS.Q,["past",!0],null),a))return $CLJS.bE("Previous {0}",$CLJS.H([c]));if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,["past",!1],null),a))return $CLJS.bE("Previous {0} {1}",$CLJS.H([b,c]));if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,["next",!0],null),a))return $CLJS.bE("Next {0}",$CLJS.H([c]));if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,["next",!1],null),a))return $CLJS.bE("Next {0} {1}",$CLJS.H([b,c]));throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));},rxa=function(a){return $CLJS.xA(a,
qxa,function(b){return["\\",$CLJS.p.h(b)].join("")})},X$=function(a,b,c,d,e){this.tag=a;this.source=b;this.S=c;this.G=d;this.J=e;this.C=2230716170;this.I=139264},txa=function(a){var b=$CLJS.It(a,sxa),c=$CLJS.jf.g($CLJS.z,$CLJS.DW(Y$,a)),d=function(){var e=$CLJS.D(b),f=$CLJS.D(c);return e>f?e:f}();a=$CLJS.hf.g(b,$CLJS.bf(d-$CLJS.D(b),V$($CLJS.Ve(""))));d=$CLJS.hf.g(c,$CLJS.bf(d-$CLJS.D(c),V$($CLJS.Ve(""))));a=$CLJS.ff.g(a,d);return $CLJS.jf.g(function(e){var f=$CLJS.oh(Y$,e);return $CLJS.n(f)?($CLJS.I(f,
0,null),f=$CLJS.I(f,1,null),new X$(f,e,null,null,null)):e},a)},uxa=function(a){return $CLJS.kf(function(b){return"string"===typeof $CLJS.z(b)?new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.gV(b)],null):b},$CLJS.H([W$(function(b){return"string"===typeof b||$CLJS.Ua($CLJS.Ij.h(b))},a)]))},vxa=function(a,b,c,d){return $CLJS.jf.g(function(e){if(e instanceof X$){var f=$CLJS.R.j;var k=$CLJS.Mj.h(e),l=$CLJS.J.g(a,k);k=$CLJS.Ij.h(l);l=$CLJS.rj.h(l);if($CLJS.n(k))try{var m=Z$.j(l,k,b);var t=$CLJS.n(c)?rxa(m):m}catch(u){if(u instanceof
Error)t=Z$.j($CLJS.ei,k,b);else throw u;}else t=null;e=f.call($CLJS.R,e,$CLJS.Ij,t)}return e},d)},yxa=function(a){a=$CLJS.gV($CLJS.jf.g(function(b){return b instanceof X$?b:$CLJS.xA(b,wxa,"")},a));return $CLJS.xA(a,xxa,$CLJS.hd)},zxa=function(a){return $CLJS.vA($CLJS.z($CLJS.nY(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.eN],null),new $CLJS.P(null,1,5,$CLJS.Q,[a],null))),$CLJS.Ah)};var Z$=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.fi($CLJS.Gh.g("metabase.shared.parameters.parameters","formatted-value"),function(f){return $CLJS.Ah.h(f)},e,a,b,c,d)}();Z$.m(null,$CLJS.HO,function(a,b,c){return(0,$CLJS.DI)(b).locale(c).format("MMMM D, YYYY")});
Z$.m(null,$CLJS.jN,function(a,b,c){a=(0,$CLJS.DI)(b,"YYYY-MM").locale(c);return $CLJS.n(a.isValid())?a.format("MMMM, YYYY"):""});Z$.m(null,$CLJS.dO,function(a,b,c){a=(0,$CLJS.DI)(b,"[Q]Q-YYYY").locale(c);return $CLJS.n(a.isValid())?a.format("[Q]Q, YYYY"):""});Z$.m(null,$CLJS.NM,function(a,b,c){b=$CLJS.It(b,/~/);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.n($CLJS.n(a)?b:a)?[$CLJS.p.h(Z$.j($CLJS.HO,a,c))," - ",$CLJS.p.h(Z$.j($CLJS.HO,b,c))].join(""):""});
Z$.m(null,$CLJS.eO,function(a,b){function c(d,e){return $CLJS.B($CLJS.ph(d,e))}if(c(/^today$/,b)||c(/^thisday$/,b))return $CLJS.DE("Today");if(c(/^thisweek$/,b))return $CLJS.DE("This Week");if(c(/^thismonth$/,b))return $CLJS.DE("This Month");if(c(/^thisquarter$/,b))return $CLJS.DE("This Quarter");if(c(/^thisyear$/,b))return $CLJS.DE("This Year");if(c(/^past1days$/,b))return $CLJS.DE("Yesterday");if(c(/^next1days$/,b))return $CLJS.DE("Tomorrow");if(a=c(/^(past|next)([0-9]+)([a-z]+)~?$/,b))return $CLJS.Te.g(pxa,
a);throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));});Z$.m(null,$CLJS.LR,function(a,b,c){function d(e,f){return $CLJS.hd($CLJS.ph(e,f))}a=d(/^(this[a-z]+)$/,b);if($CLJS.n(a))return Z$.j($CLJS.eO,a,c);a=d(/^~?([0-9-T:]+)~?$/,b);if($CLJS.n(a))return Z$.j($CLJS.HO,a,c);a=d(/^([0-9-T:]+~[0-9-T:]+)$/,b);return $CLJS.n(a)?Z$.j($CLJS.NM,a,c):Z$.j($CLJS.eO,b,c)});
Z$.m(null,$CLJS.ei,function(a,b){$CLJS.wd(b)?(a=$CLJS.D(b),b=$CLJS.n($CLJS.E.g?$CLJS.E.g(1,a):$CLJS.E.call(null,1,a))?$CLJS.p.h($CLJS.z(b)):$CLJS.n($CLJS.E.g?$CLJS.E.g(2,a):$CLJS.E.call(null,2,a))?$CLJS.bE("{0} and {1}",$CLJS.H([$CLJS.z(b),$CLJS.hd(b)])):$CLJS.bE("{0}, {1}, and {2}",$CLJS.H([$CLJS.tt(", ",$CLJS.nu(2,b)),$CLJS.F(b,$CLJS.D(b)-2),$CLJS.id(b)]))):b=$CLJS.p.h(b);return b});var qxa=/[\\\/*_`'\[\](){}<>#+-.!$@%^&=|\?~]/,Y$=/\{\{\s*([A-Za-z0-9_\.]+?)\s*\}\}/,sxa=/\{\{\s*[A-Za-z0-9_\.]+?\s*\}\}/;
$CLJS.g=X$.prototype;$CLJS.g.toString=function(){var a=$CLJS.Ij.h(this);return $CLJS.n(a)?a:this.source};$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "tag":return this.tag;case "source":return this.source;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.th(b,function(d){return $CLJS.th(b,$CLJS.zh,""," ","",c,d)},"#metabase.shared.parameters.parameters.TextParam{",", ","}",c,$CLJS.hf.g(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,this.tag],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sz,this.source],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.rt(this,2,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,$CLJS.Sz],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.Ie())};$CLJS.g.O=function(){return this.S};
$CLJS.g.ha=function(){return 2+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-884441273^$CLJS.Qc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.tag,b.tag)&&$CLJS.E.g(this.source,b.source)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.Sz,null,$CLJS.Mj,null],null),null),b)?$CLJS.Ok.g($CLJS.Ob($CLJS.fg.g($CLJS.N,this),this.S),b):new X$(this.tag,this.source,this.S,$CLJS.Ge($CLJS.Ok.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "tag":case "source":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.ie.g?$CLJS.ie.g($CLJS.Mj,b):$CLJS.ie.call(null,$CLJS.Mj,b))?new X$(c,this.source,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g($CLJS.Sz,b):$CLJS.ie.call(null,$CLJS.Sz,b))?new X$(this.tag,c,this.S,this.G,null):new X$(this.tag,this.source,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.hf.g(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.Zf($CLJS.Mj,this.tag),new $CLJS.Zf($CLJS.Sz,this.source)],null),this.G))};
$CLJS.g.P=function(a,b){return new X$(this.tag,this.source,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.eb($CLJS.pb,this,b)};var wxa=/\[\[.+\]\]/,xxa=/\[\[(.+?)\]\]/,$$=function $$(a){switch(arguments.length){case 2:return $$.g(arguments[0],arguments[1]);case 4:return $$.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.za("metabase.shared.parameters.parameters.substitute_tags",$$);$$.g=function(a,b){return $$.v(a,b,"en",!0)};$$.v=function(a,b,c,d){return $CLJS.n(a)?(b=$CLJS.Fz(b),b=$CLJS.st(b,zxa),yxa(uxa(vxa(b,c,d,txa(a))))):null};$$.A=4;module.exports={tag_names:function(a){a=$CLJS.gh($CLJS.jf.g($CLJS.hd,$CLJS.DW(Y$,$CLJS.n(a)?a:"")));return $CLJS.ql(a)},substitute_tags:$$};