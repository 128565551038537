var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");
'use strict';var cu,eu,fu,gu,hu,iu,ju,qu,ru,su,tu,uu,Rba,xu,Sba,Au,Bu,Du,Eu,Fu,Gu,Hu,Iu,Nu,Qu,Su,Vu,Tba,Uba,$u,Vba,bv,cv,dv,jv,kv,sv,wv,Cv,Ev,Jv,Nv,Sv,Uv,Vv,Pv,Xv,Yv,$v,Zv,aw,Zba,fw,ow,pw,iw,aca,tw,ww,yw,zw,cca,dca,eca,Dw,Hw,Iw,Jw,Kw,fca,Pw,Sw,gca,hca,ica,jca,kca,lca,mca,nca,oca,qca,Xw,pca,ax,rca,bx,sca,tca,cx,uca,dx,vca,wca,xca,zca,Aca,Cca,Dca,Fca,Hca,ix,lx,Jca,Ica,Kca,Ov,Rv,sx,tx,ux,vx,yx,zx,Nca,Oca,Pca,Cx,Qca,Dx,Bx,Ex,Lu,Fx,Zu,Rw,Rca,Gv,Sca,Tca,Ou,Xu,wx,Uca,uv,ew,ev,Vca,Wca,rv,Xca,jx,iv,dw,pv,
Tw,Yca,Yu,Zca,$w,gx,Gx,$ca,Aw,Ix,Cw,tv,cw,Ku,fx,Yba,Fv,nv,Ru,ada,Wv,bda,lw,Bw,nx,cda,px,Xba,dda,eda,fda,Kv,gda,Yw,Iv,hda,mw,kw,ida,Tu,jda,Mca,qx,fv,Kx,Fw,Lx,Mx,qw,kda,hv,lda,Lca,mda,Mv,Ww,bw,nda,Gw,xx,oda,Vw,pda,jw,Wu,qda,vv,Ox,Ew,rda,Pu,ex,Hv,Wba,hx,Uu,Lw,kx,Qw,Mw,Ow,Zw,rw,Px,Qx,sda,Cu,tda,zu,Ju,uda,gv,vda;cu=function(a){return 1==a.length&&" "<=a&&"~">=a||""<=a&&"�">=a};$CLJS.du=function(){};
eu=function(a){if(null!=a&&null!=a.rc)a=a.rc(a);else{var b=eu[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=eu._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("IWriter.-flush",a);}return a};fu=function(){};gu=function(a){if(null!=a&&null!=a.Lc)a=a.Lc(a);else{var b=gu[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=gu._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("IPending.-realized?",a);}return a};
hu=function(a){if("number"===typeof a)return String.fromCharCode(a);if("string"===typeof a&&1===a.length)return a;throw Error("Argument to char must be a character or number");};iu=function(a,b){return a-b*$CLJS.Wd(a,b)};ju=function(a,b,c){if(null!=a&&null!=a.m)a.m(a,b,c);else{var d=ju[$CLJS.wa(null==a?null:a)];if(null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else if(d=ju._,null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else throw $CLJS.bb("IMultiFn.-add-method",a);}};
$CLJS.ku=function(a,b){var c=$CLJS.ec($CLJS.N);a=$CLJS.y(a);for(b=$CLJS.y(b);;)if(a&&b)c=$CLJS.ig.j(c,$CLJS.z(a),$CLJS.z(b)),a=$CLJS.B(a),b=$CLJS.B(b);else return $CLJS.gc(c)};$CLJS.lu=function(a,b){return $CLJS.mh(a,b)};$CLJS.mu=function(a,b){return new $CLJS.oe(null,function(){var c=$CLJS.y(b);if(c){var d=$CLJS.z(c);d=a.h?a.h(d):a.call(null,d);c=$CLJS.n(d)?$CLJS.fe($CLJS.z(c),$CLJS.mu(a,$CLJS.Lc(c))):null}else c=null;return c},null,null)};
$CLJS.nu=function(a,b){return $CLJS.jf.j(function(c){return c},b,$CLJS.cf(a,b))};qu=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;$CLJS.Te.g(ou,0<b.length?new $CLJS.w(b.slice(0),0,null):null);$CLJS.ac($CLJS.pu,"\n")};ru=function(a){if("number"===typeof a)return a;if("string"===typeof a&&1===a.length)return a.charCodeAt(0);throw Error("Argument to char must be a character or number");};
su=function(a,b,c){var d=c;for(c=$CLJS.Df;;){if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null);var e=$CLJS.z(d);d=$CLJS.B(d);e=$CLJS.Te.g(a,new $CLJS.P(null,2,5,$CLJS.Q,[e,b],null));b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);c=$CLJS.ce.g(c,b);b=e}};tu=function(a,b){var c=b;for(b=$CLJS.Df;;){var d=$CLJS.Te.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[c],null));c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);if($CLJS.Ua(c))return new $CLJS.P(null,2,5,$CLJS.Q,[b,d],null);b=$CLJS.ce.g(b,c);c=d}};
uu=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fg.g($CLJS.N,function(){return function d(c){return new $CLJS.oe(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.re(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);var v=$CLJS.I(t,0,null);$CLJS.I(t,1,null);l.add(new $CLJS.P(null,2,5,$CLJS.Q,[u,v],null));m+=1}else{f=!0;break a}return f?$CLJS.ue($CLJS.we(l),d($CLJS.mc(e))):$CLJS.ue($CLJS.we(l),null)}f=
$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);k=$CLJS.I(f,0,null);$CLJS.I(f,1,null);return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[l,k],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}()),$CLJS.fg.g($CLJS.N,function(){return function d(c){return new $CLJS.oe(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.re(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);l.add(new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null));m+=1}else{f=!0;break a}return f?$CLJS.ue($CLJS.we(l),d($CLJS.mc(e))):$CLJS.ue($CLJS.we(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[l,f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())],null)};
Rba=function(a,b){return $CLJS.fg.g($CLJS.N,function(){return function e(d){return new $CLJS.oe(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.re(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);m.add(new $CLJS.P(null,2,5,$CLJS.Q,[v,new $CLJS.P(null,2,5,$CLJS.Q,[u,b],null)],null));t+=1}else{k=!0;break a}return k?$CLJS.ue($CLJS.we(m),e($CLJS.mc(f))):$CLJS.ue($CLJS.we(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);
k=$CLJS.I(k,1,null);return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[m,new $CLJS.P(null,2,5,$CLJS.Q,[k,b],null)],null),e($CLJS.Lc(f)))}return null}},null,null)}(a)}())};$CLJS.vu=function(){var a=$CLJS.pu;if(null!=a&&null!=a.uf)a.uf(a);else{var b=$CLJS.vu[$CLJS.wa(null==a?null:a)];if(null!=b)b.h?b.h(a):b.call(null,a);else if(b=$CLJS.vu._,null!=b)b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("IPrettyFlush.-ppflush",a);}};$CLJS.wu=function(a,b){a=$CLJS.q($CLJS.q(a));return b.h?b.h(a):b.call(null,a)};
xu=function(a,b,c){$CLJS.Ph.v($CLJS.q(a),$CLJS.R,b,c)};Sba=function(a,b){$CLJS.E.g(b,"\n")?(xu(a,$CLJS.yu,0),xu(a,$CLJS.sj,$CLJS.wu(a,$CLJS.sj)+1)):xu(a,$CLJS.yu,$CLJS.wu(a,$CLJS.yu)+1);return $CLJS.ac($CLJS.wu(a,zu),b)};Au=function(a,b,c,d){this.Ca=a;this.Nf=b;this.Yc=c;this.di=d;this.C=1074167808;this.I=0};
Bu=function(a,b,c,d,e,f,k,l,m,t,u,v,x){this.parent=a;this.Cb=b;this.tb=c;this.sb=d;this.vb=e;this.wb=f;this.prefix=k;this.Ab=l;this.Db=m;this.xb=t;this.S=u;this.G=v;this.J=x;this.C=2230716170;this.I=139264};Du=function(a,b){for(b=Cu.h(b);;){if(null==b)return!1;if(a===b)return!0;b=Cu.h(b)}};Eu=function(a,b,c,d,e,f,k,l){this.ca=a;this.data=b;this.ac=c;this.ba=d;this.aa=e;this.S=f;this.G=k;this.J=l;this.C=2230716170;this.I=139264};
Fu=function(a,b,c,d,e,f,k,l){this.ca=a;this.type=b;this.ea=c;this.ba=d;this.aa=e;this.S=f;this.G=k;this.J=l;this.C=2230716170;this.I=139264};Gu=function(a,b,c,d,e,f,k){this.ca=a;this.ea=b;this.ba=c;this.aa=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};Hu=function(a,b,c,d,e,f,k){this.ca=a;this.ea=b;this.ba=c;this.aa=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};
Iu=function(a,b,c,d,e,f,k,l,m){this.ca=a;this.ea=b;this.Wb=c;this.offset=d;this.ba=e;this.aa=f;this.S=k;this.G=l;this.J=m;this.C=2230716170;this.I=139264};
Nu=function(a,b,c){b=$CLJS.y(b);for(var d=null,e=0,f=0;;)if(f<e){var k=d.X(null,f);if(!$CLJS.E.g(Ju.h(k),Ku)){var l=Lu.h($CLJS.q($CLJS.q(a)));$CLJS.n(l)&&$CLJS.ac(zu.h($CLJS.q($CLJS.q(a))),l)}Mu.g(a,k);$CLJS.Ph.v($CLJS.q(a),$CLJS.R,Lu,Lu.h(k));k=Lu.h($CLJS.q($CLJS.q(a)));$CLJS.n($CLJS.n(c)?k:c)&&($CLJS.ac(zu.h($CLJS.q($CLJS.q(a))),k),$CLJS.Ph.v($CLJS.q(a),$CLJS.R,Lu,null));f+=1}else if(b=$CLJS.y(b))$CLJS.Ad(b)?(d=$CLJS.lc(b),b=$CLJS.mc(b),k=d,e=$CLJS.D(d),d=k):(k=$CLJS.z(b),$CLJS.E.g(Ju.h(k),Ku)||
(d=Lu.h($CLJS.q($CLJS.q(a))),$CLJS.n(d)&&$CLJS.ac(zu.h($CLJS.q($CLJS.q(a))),d)),Mu.g(a,k),$CLJS.Ph.v($CLJS.q(a),$CLJS.R,Lu,Lu.h(k)),k=Lu.h($CLJS.q($CLJS.q(a))),$CLJS.n($CLJS.n(c)?k:c)&&($CLJS.ac(zu.h($CLJS.q($CLJS.q(a))),k),$CLJS.Ph.v($CLJS.q(a),$CLJS.R,Lu,null)),b=$CLJS.B(b),d=null,e=0),f=0;else break};Qu=function(a,b){var c=$CLJS.wu(zu.h($CLJS.q($CLJS.q(a))),$CLJS.pk),d;(d=null==c)||(a=$CLJS.wu(zu.h($CLJS.q($CLJS.q(a))),$CLJS.yu),b=(b=$CLJS.y(b))?Ou.h($CLJS.id(b))-Pu.h($CLJS.z(b)):0,d=a+b<c);return d};
Su=function(a,b,c){b=$CLJS.q(Ru.h(b));return $CLJS.n(b)?b:!Qu(a,c)};Vu=function(a,b,c){var d=Tu.h($CLJS.q($CLJS.q(a))),e=$CLJS.wu(zu.h($CLJS.q($CLJS.q(a))),$CLJS.pk);return $CLJS.n(d)?$CLJS.n(e)?(d=$CLJS.q(Uu.h(b))>=e-d)?Su(a,b,c):d:e:d};Tba=function(a){var b=$CLJS.z(a),c=Wu.h(b);b=$CLJS.y($CLJS.mu(function(d){return!($CLJS.E.g(Ju.h(d),Ku)&&Du(Wu.h(d),c))},$CLJS.B(a)));return new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.y($CLJS.cf($CLJS.D(b)+1,a))],null)};
Uba=function(a){var b=$CLJS.z(a),c=Wu.h(b);return $CLJS.y($CLJS.mu(function(d){var e=Wu.h(d);return!($CLJS.E.g(Ju.h(d),Ku)&&($CLJS.E.g(e,c)||Du(e,c)))},$CLJS.B(a)))};
$u=function(a,b){$CLJS.ac(zu.h($CLJS.q($CLJS.q(a))),"\n");$CLJS.Ph.v($CLJS.q(a),$CLJS.R,Lu,null);b=Wu.h(b);var c=Xu.h(b);$CLJS.n(c)&&$CLJS.ac(zu.h($CLJS.q($CLJS.q(a))),c);c=$CLJS.Te.g($CLJS.p,$CLJS.ef($CLJS.q(Yu.h(b))-$CLJS.D(c)," "));$CLJS.ac(zu.h($CLJS.q($CLJS.q(a))),c);a:for($CLJS.Ze(Zu.h(b),!0),$CLJS.Ze(Ru.h(b),!0),a=Cu.h(b);;)if($CLJS.n(a))$CLJS.Ze(Ru.h(a),!0),$CLJS.Ze(Zu.h(a),!0),a=Cu.h(a);else break a;return null};
Vba=function(a){var b=$CLJS.y($CLJS.mu(function(c){return!$CLJS.E.g(Ju.h(c),Ku)},a));return new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.y($CLJS.cf($CLJS.D(b),a))],null)};bv=function(a){for(var b=$CLJS.wt.h($CLJS.q($CLJS.q(a)));;){$CLJS.Ph.v($CLJS.q(a),$CLJS.R,$CLJS.wt,$CLJS.fg.g($CLJS.Df,b));if(Qu(a,b))return null;var c=av(a,b);if(b!==c)b=c;else return null}};
cv=function(a,b){$CLJS.Ph.v($CLJS.q(a),$CLJS.R,$CLJS.wt,$CLJS.ce.g($CLJS.wt.h($CLJS.q($CLJS.q(a))),b));return Qu(a,$CLJS.wt.h($CLJS.q($CLJS.q(a))))?null:bv(a)};dv=function(a){var b=Lu.h($CLJS.q($CLJS.q(a)));return $CLJS.n(b)?($CLJS.ac(zu.h($CLJS.q($CLJS.q(a))),b),$CLJS.Ph.v($CLJS.q(a),$CLJS.R,Lu,null)):null};
jv=function(a,b){var c=$CLJS.ut(b,"\n",-1);if($CLJS.E.g($CLJS.D(c),1))return b;b=Xu.h($CLJS.z(ev.h($CLJS.q($CLJS.q(a)))));var d=$CLJS.z(c);if($CLJS.E.g(fv,$CLJS.Bi.h($CLJS.q($CLJS.q(a))))){var e=gv.h($CLJS.q($CLJS.q(a))),f=e+$CLJS.D(d);$CLJS.Ph.v($CLJS.q(a),$CLJS.R,gv,f);cv(a,new Eu(hv,d,null,e,f,null,null,null));bv(a);d=$CLJS.wt.h($CLJS.q($CLJS.q(a)));$CLJS.n(d)&&(Nu(a,d,!0),$CLJS.Ph.v($CLJS.q(a),$CLJS.R,$CLJS.wt,$CLJS.Df))}else dv(a),$CLJS.ac(zu.h($CLJS.q($CLJS.q(a))),d);$CLJS.ac(zu.h($CLJS.q($CLJS.q(a))),
"\n");d=$CLJS.y($CLJS.B($CLJS.qt(c)));e=null;for(var k=f=0;;)if(k<f){var l=e.X(null,k);$CLJS.ac(zu.h($CLJS.q($CLJS.q(a))),l);$CLJS.ac(zu.h($CLJS.q($CLJS.q(a))),"\n");$CLJS.n(b)&&$CLJS.ac(zu.h($CLJS.q($CLJS.q(a))),b);k+=1}else if(d=$CLJS.y(d))e=d,$CLJS.Ad(e)?(d=$CLJS.lc(e),k=$CLJS.mc(e),e=d,f=$CLJS.D(d),d=k):(d=$CLJS.z(e),$CLJS.ac(zu.h($CLJS.q($CLJS.q(a))),d),$CLJS.ac(zu.h($CLJS.q($CLJS.q(a))),"\n"),$CLJS.n(b)&&$CLJS.ac(zu.h($CLJS.q($CLJS.q(a))),b),d=$CLJS.B(e),e=null,f=0),k=0;else break;$CLJS.Ph.v($CLJS.q(a),
$CLJS.R,fv,iv);return $CLJS.id(c)};kv=function(a,b,c,d,e,f){this.Ca=a;this.Nf=b;this.ni=c;this.oh=d;this.Yc=e;this.ei=f;this.C=1074167808;this.I=0};
$CLJS.ov=function(a){var b=$CLJS.lv,c=mv,d=new Bu(null,null,$CLJS.Ye(0),$CLJS.Ye(0),$CLJS.Ye(!1),$CLJS.Ye(!1),null,null,null,null,null,null,null),e=$CLJS.Ye($CLJS.Jg([ev,Tu,Wba,nv,Xba,$CLJS.Bi,gv,Lu,zu,Yba,$CLJS.wt],[d,c,d,!0,null,iv,0,null,new Au(a,b,$CLJS.Ye(new $CLJS.h(null,4,[$CLJS.pk,b,$CLJS.yu,0,$CLJS.sj,0,zu,a],null)),$CLJS.N),1,$CLJS.Df]));return new kv(a,b,c,d,e,$CLJS.N)};
sv=function(a,b){var c=$CLJS.pu;b=new Bu(ev.h($CLJS.q($CLJS.q(c))),null,$CLJS.Ye(0),$CLJS.Ye(0),$CLJS.Ye(!1),$CLJS.Ye(!1),a,null,b,null,null,null,null);$CLJS.Ph.v($CLJS.q(c),$CLJS.R,ev,b);if($CLJS.E.g($CLJS.Bi.h($CLJS.q($CLJS.q(c))),iv)){dv(c);var d=pv.h($CLJS.q($CLJS.q(c)));$CLJS.n(d)&&(d.h?d.h($CLJS.qv):d.call(null,$CLJS.qv));$CLJS.n(a)&&$CLJS.ac(zu.h($CLJS.q($CLJS.q(c))),a);c=$CLJS.wu(zu.h($CLJS.q($CLJS.q(c))),$CLJS.yu);$CLJS.Ze(b.tb,c);$CLJS.Ze(b.sb,c)}else d=gv.h($CLJS.q($CLJS.q(c))),a=d+($CLJS.n(a)?
$CLJS.D(a):0),$CLJS.Ph.v($CLJS.q(c),$CLJS.R,gv,a),cv(c,new Gu(rv,b,d,a,null,null,null))};
wv=function(){var a=$CLJS.pu,b=ev.h($CLJS.q($CLJS.q(a))),c=tv.h(b);if($CLJS.E.g($CLJS.Bi.h($CLJS.q($CLJS.q(a))),iv)){dv(a);$CLJS.n(c)&&$CLJS.ac(zu.h($CLJS.q($CLJS.q(a))),c);var d=pv.h($CLJS.q($CLJS.q(a)));$CLJS.n(d)&&(d.h?d.h(uv):d.call(null,uv))}else d=gv.h($CLJS.q($CLJS.q(a))),c=d+($CLJS.n(c)?$CLJS.D(c):0),$CLJS.Ph.v($CLJS.q(a),$CLJS.R,gv,c),cv(a,new Hu(vv,b,d,c,null,null,null));$CLJS.Ph.v($CLJS.q(a),$CLJS.R,ev,Cu.h(b))};
$CLJS.xv=function(a){var b=null!=a?a.C&32768||$CLJS.Bc===a.ag?!0:a.C?!1:$CLJS.$a($CLJS.du,a):$CLJS.$a($CLJS.du,a);return b?nv.h($CLJS.q($CLJS.q(a))):b};$CLJS.Bv=function(a){var b=yv;$CLJS.n(b)&&(b=$CLJS.Ma,b=$CLJS.n(b)?yv>=$CLJS.Ma:b);$CLJS.Ua($CLJS.zv)?ou.call(null,a):$CLJS.n(b)?$CLJS.ac($CLJS.pu,"..."):($CLJS.n(yv)&&(yv+=1),Av.call(null,a));return b};
Cv=function(a,b){if($CLJS.Ua(b.h?b.h(a):b.call(null,a)))throw Error(["Bad argument: ",$CLJS.p.h(a),". It must be one of ",$CLJS.p.h(b)].join(""));};Ev=function(){var a=$CLJS.rh;return $CLJS.n(a)?Dv>=$CLJS.rh:a};Jv=function(a){Cv(a,new $CLJS.bh(null,new $CLJS.h(null,4,[Fv,null,Gv,null,Hv,null,Iv,null],null),null));var b=$CLJS.pu;$CLJS.Ph.v($CLJS.q(b),$CLJS.R,$CLJS.Bi,fv);var c=gv.h($CLJS.q($CLJS.q(b))),d=ev.h($CLJS.q($CLJS.q(b)));cv(b,new Fu(Ku,a,d,c,c,null,null,null))};
Nv=function(a,b){Cv(a,new $CLJS.bh(null,new $CLJS.h(null,2,[Kv,null,$CLJS.Lv,null],null),null));var c=$CLJS.pu,d=ev.h($CLJS.q($CLJS.q(c)));if($CLJS.E.g($CLJS.Bi.h($CLJS.q($CLJS.q(c))),iv)){dv(c);var e=Yu.h(d);if($CLJS.n($CLJS.E.g?$CLJS.E.g(Kv,a):$CLJS.E.call(null,Kv,a)))a=$CLJS.q(Uu.h(d));else if($CLJS.n($CLJS.E.g?$CLJS.E.g($CLJS.Lv,a):$CLJS.E.call(null,$CLJS.Lv,a)))a=$CLJS.wu(zu.h($CLJS.q($CLJS.q(c))),$CLJS.yu);else throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));$CLJS.Ze(e,b+a)}else e=
gv.h($CLJS.q($CLJS.q(c))),cv(c,new Iu(Mv,d,a,b,e,e,null,null,null))};Sv=function(a,b,c){b="string"===typeof b?Ov(b):b;c=Pv(c);a:{var d=new $CLJS.Ea,e=$CLJS.Ua(a)||!0===a?new $CLJS.sc(d):a,f=Qv(b)&&$CLJS.Ua($CLJS.xv(e))?$CLJS.n($CLJS.xv(e))?e:$CLJS.ov(e):e,k=$CLJS.pu;$CLJS.pu=f;try{try{Rv(b,c)}finally{e!==f&&eu(f)}var l=$CLJS.Ua(a)?$CLJS.p.h(d):!0===a?$CLJS.vh($CLJS.p.h(d)):null;break a}finally{$CLJS.pu=k}l=void 0}return l};
Uv=function(a,b){a=[$CLJS.p.h(a),"\n",$CLJS.p.h(Tv),"\n",$CLJS.p.h($CLJS.Te.g($CLJS.p,$CLJS.ef(b," "))),"^\n"].join("");throw Error(a);};Vv=function(a,b,c,d,e,f){this.uc=a;this.pb=b;this.$b=c;this.S=d;this.G=e;this.J=f;this.C=2230716170;this.I=139264};Pv=function(a){a=$CLJS.y(a);return new Vv(a,a,0,null,null,null)};
Xv=function(a){var b=$CLJS.Ti.h(a);if($CLJS.n(b))return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.z(b),new Vv(Wv.h(a),$CLJS.B(b),gv.h(a)+1,null,null,null)],null);throw Error("Not enough arguments for format definition");};Yv=function(a){var b=Xv(a);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);a="string"===typeof a?Ov(a):a;return new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null)};$v=function(a,b){return b>=gv.h(a)?(b=gv.h(a)-b,Zv.g?Zv.g(a,b):Zv.call(null,a,b)):new Vv(Wv.h(a),$CLJS.cf(b,Wv.h(a)),b,null,null,null)};
Zv=function(a,b){var c=gv.h(a)+b;return 0>b?$v(a,c):new Vv(Wv.h(a),$CLJS.cf(b,$CLJS.Ti.h(a)),c,null,null,null)};aw=function(a,b,c,d,e,f,k){this.dc=a;this.cc=b;this.kc=c;this.offset=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};
Zba=function(a,b){var c=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);d=$CLJS.Hd(new $CLJS.bh(null,new $CLJS.h(null,2,[bw,null,cw,null],null),null),c)?new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null):$CLJS.E.g(d,dw)?Xv(b):$CLJS.E.g(d,ew)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.D($CLJS.Ti.h(b)),b],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[c,new $CLJS.P(null,2,5,$CLJS.Q,
[b,a],null)],null),d],null)};fw=function(a,b){b=su(Zba,b,a);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fg.g($CLJS.N,a),b],null)};
ow=function(a,b,c){c=Xv(c);var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.Fd(d))if($CLJS.E.g(gw,10))var e=[$CLJS.p.h(d),$CLJS.n(hw)?".":null].join("");else{e=$CLJS.p;var f=e.h;if($CLJS.n(hw)){var k=$CLJS.J.g($ba,gw);k=$CLJS.n(k)?k:["#",$CLJS.p.h(gw),"r"].join("")}else k=null;e=[f.call(e,k),$CLJS.p.h(iw(gw,d))].join("")}else e=null;a=$CLJS.n(e)?e:a.h?a.h(d):a.call(null,d);d=a.length;e=d+jw.h(b);e=e>=kw.h(b)?e:e+($CLJS.Wd(kw.h(b)-e-1,lw.h(b))+1)*lw.h(b);d=$CLJS.Te.g($CLJS.p,$CLJS.ef(e-d,mw.h(b)));
$CLJS.n(cw.h(b))?nw.l($CLJS.H([[$CLJS.p.h(d),$CLJS.p.h(a)].join("")])):nw.l($CLJS.H([[$CLJS.p.h(a),$CLJS.p.h(d)].join("")]));return c};pw=function(a,b){return $CLJS.de($CLJS.z(tu(function(c){return 0<c?new $CLJS.P(null,2,5,$CLJS.Q,[iu(c,a),$CLJS.Wd(c,a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,null],null)},b)))};iw=function(a,b){return 0===b?"0":$CLJS.Te.g($CLJS.p,$CLJS.jf.g(function(c){return 10>c?hu(ru("0")+c):hu(ru("a")+(c-10))},pw(a,b)))};
aca=function(a,b){return $CLJS.de($CLJS.z(tu(function(c){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.y($CLJS.de($CLJS.bf(a,c))),$CLJS.y($CLJS.cf(a,c))],null)},$CLJS.de(b))))};
tw=function(a,b,c){c=Xv(c);var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.Fd(d)||"number"===typeof d&&!isNaN(d)&&Infinity!==d&&parseFloat(d)!==parseInt(d,10)&&$CLJS.E.g(d,Math.floor(d))){var e=0>d,f=iw(a,e?-d:d);a=$CLJS.n(bw.h(b))?function(){var k=$CLJS.jf.g(function(m){return $CLJS.Te.g($CLJS.p,m)},aca(qw.h(b),f)),l=$CLJS.ef($CLJS.D(k),rw.h(b));return $CLJS.Te.g($CLJS.p,$CLJS.B($CLJS.ff.g(l,k)))}():f;a=e?["-",$CLJS.p.h(a)].join(""):$CLJS.n(cw.h(b))?["+",$CLJS.p.h(a)].join(""):a;a=a.length<
kw.h(b)?[$CLJS.p.h($CLJS.Te.g($CLJS.p,$CLJS.ef(kw.h(b)-a.length,mw.h(b)))),$CLJS.p.h(a)].join(""):a;nw.l($CLJS.H([a]))}else ow($CLJS.sw,new $CLJS.h(null,5,[kw,kw.h(b),lw,1,jw,0,mw,mw.h(b),cw,!0],null),Pv(new $CLJS.P(null,1,5,$CLJS.Q,[d],null)));return c};
ww=function(a){var b=$CLJS.Wd(a,100);a=iu(a,100);var c=0<b?[$CLJS.p.h($CLJS.F(uw,b))," hundred"].join(""):null,d=$CLJS.p,e=d.h;if(0<a)if(20>a)var f=$CLJS.F(uw,a);else{f=$CLJS.Wd(a,10);var k=iu(a,10);f=[$CLJS.p.h(0<f?$CLJS.F(vw,f):null),0<f&&0<k?"-":null,$CLJS.p.h(0<k?$CLJS.F(uw,k):null)].join("")}else f=null;return[c,0<b&&0<a?" ":null,e.call(d,f)].join("")};
yw=function(a,b){var c=$CLJS.D(a),d=$CLJS.Df;--c;var e=$CLJS.z(a);for(a=$CLJS.B(a);;){if(null==a)return[$CLJS.p.h($CLJS.Te.g($CLJS.p,$CLJS.gf(", ",d))),$CLJS.td(e)||$CLJS.td(d)?null:", ",$CLJS.p.h(e),!$CLJS.td(e)&&0<c+b?[" ",$CLJS.p.h($CLJS.F(xw,c+b))].join(""):null].join("");d=$CLJS.td(e)?d:$CLJS.ce.g(d,[$CLJS.p.h(e)," ",$CLJS.p.h($CLJS.F(xw,c+b))].join(""));--c;e=$CLJS.z(a);a=$CLJS.B(a)}};
zw=function(a,b){b=Xv(b);var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);if("number"===typeof c&&0<c&&4E3>c){var d=pw(10,c);c=$CLJS.Df;for(var e=$CLJS.D(d)-1;;)if($CLJS.td(d)){nw.l($CLJS.H([$CLJS.Te.g($CLJS.p,c)]));break}else{var f=$CLJS.z(d);c=$CLJS.E.g(0,f)?c:$CLJS.ce.g(c,$CLJS.F($CLJS.F(a,e),f-1));--e;d=$CLJS.B(d)}}else tw(10,new $CLJS.h(null,5,[kw,0,mw," ",rw,",",qw,3,bw,!0],null),Pv(new $CLJS.P(null,1,5,$CLJS.Q,[c],null)));return b};
cca=function(a,b){a=Xv(b);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);var c=ru(b);b=c&127;c&=128;var d=$CLJS.J.g(bca,b);0<c&&nw.l($CLJS.H(["Meta-"]));nw.l($CLJS.H([$CLJS.n(d)?d:32>b?["Control-",$CLJS.p.h(hu(b+64))].join(""):$CLJS.E.g(b,127)?"Control-?":hu(b)]));return a};
dca=function(a,b){var c=Xv(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);a=Aw.h(a);if($CLJS.n($CLJS.E.g?$CLJS.E.g("o",a):$CLJS.E.call(null,"o",a)))Sv(!0,"\\o~3,'0o",$CLJS.H([ru(b)]));else if($CLJS.n($CLJS.E.g?$CLJS.E.g("u",a):$CLJS.E.call(null,"u",a)))Sv(!0,"\\u~4,'0x",$CLJS.H([ru(b)]));else if($CLJS.n($CLJS.E.g?$CLJS.E.g(null,a):$CLJS.E.call(null,null,a)))$CLJS.ac($CLJS.pu,$CLJS.n($CLJS.E.g?$CLJS.E.g("\b",b):$CLJS.E.call(null,"\b",b))?"\\backspace":$CLJS.n($CLJS.E.g?$CLJS.E.g(" ",b):$CLJS.E.call(null,
" ",b))?"\\space":$CLJS.n($CLJS.E.g?$CLJS.E.g("\t",b):$CLJS.E.call(null,"\t",b))?"\\tab":$CLJS.n($CLJS.E.g?$CLJS.E.g("\n",b):$CLJS.E.call(null,"\n",b))?"\\newline":$CLJS.n($CLJS.E.g?$CLJS.E.g("\f",b):$CLJS.E.call(null,"\f",b))?"\\formfeed":$CLJS.n($CLJS.E.g?$CLJS.E.g("\r",b):$CLJS.E.call(null,"\r",b))?"\\return":$CLJS.n($CLJS.E.g?$CLJS.E.g('"',b):$CLJS.E.call(null,'"',b))?'\\"':$CLJS.n($CLJS.E.g?$CLJS.E.g("\\",b):$CLJS.E.call(null,"\\",b))?"\\\\":["\\",$CLJS.p.h(b)].join(""));else throw Error(["No matching clause: ",
$CLJS.p.h(a)].join(""));return c};eca=function(a,b){b=Xv(b);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);nw.l($CLJS.H([a]));return b};Dw=function(a){a=$CLJS.z(a);return $CLJS.E.g(Bw,a)||$CLJS.E.g(Cw,a)};
Hw=function(a,b,c){return $CLJS.hd(su(function(d,e){if(Dw(e))return new $CLJS.P(null,2,5,$CLJS.Q,[null,e],null);e=fw(Ew.h(d),e);var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);var k=uu(f);f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);f=$CLJS.R.j(f,Fw,c);return new $CLJS.P(null,2,5,$CLJS.Q,[null,$CLJS.Te.g(Gw.h(d),new $CLJS.P(null,3,5,$CLJS.Q,[f,e,k],null))],null)},b,a))};
Iw=function(a){a=$CLJS.p.h(a).toLowerCase();var b=a.indexOf("e"),c=a.indexOf(".");a=0>b?0>c?new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.p.h(a.length-1)],null):new $CLJS.P(null,2,5,$CLJS.Q,[[a.substring(0,c),a.substring(c+1)].join(""),$CLJS.p.h(c-1)],null):0>c?new $CLJS.P(null,2,5,$CLJS.Q,[a.substring(0,b),a.substring(b+1)],null):new $CLJS.P(null,2,5,$CLJS.Q,[[a.substring(0,1),a.substring(2,b)].join(""),a.substring(b+1)],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);a:if(c=$CLJS.D(b),0<c&&$CLJS.E.g($CLJS.F(b,
$CLJS.D(b)-1),"0"))for(--c;;){if(0>c){b="";break a}if($CLJS.E.g($CLJS.F(b,c),"0"))--c;else{b=b.substring(0,c+1);break a}}a:{c=b;var d=$CLJS.D(c);if(0<d&&$CLJS.E.g($CLJS.F(c,0),"0"))for(var e=0;;){if($CLJS.E.g(e,d)||!$CLJS.E.g($CLJS.F(c,e),"0")){c=c.substring(e);break a}e+=1}}b=$CLJS.D(b)-$CLJS.D(c);a=0<$CLJS.D(a)&&$CLJS.E.g($CLJS.F(a,0),"+")?a.substring(1):a;return $CLJS.td(c)?new $CLJS.P(null,2,5,$CLJS.Q,["0",0],null):new $CLJS.P(null,2,5,$CLJS.Q,[c,parseInt(a,10)-b],null)};
Jw=function(a,b,c,d){if($CLJS.n($CLJS.n(c)?c:d)){var e=$CLJS.D(a);d=$CLJS.n(d)?2>d?2:d:0;$CLJS.n(c)?c=b+c+1:0<=b?(c=b+1,--d,c=c>d?c:d):c=d+b;var f=$CLJS.E.g(c,0)?new $CLJS.P(null,4,5,$CLJS.Q,[["0",$CLJS.p.h(a)].join(""),b+1,1,e+1],null):new $CLJS.P(null,4,5,$CLJS.Q,[a,b,c,e],null);c=$CLJS.I(f,0,null);e=$CLJS.I(f,1,null);d=$CLJS.I(f,2,null);f=$CLJS.I(f,3,null);if($CLJS.n(d)){if(0>d)return new $CLJS.P(null,3,5,$CLJS.Q,["0",0,!1],null);if(f>d){b=$CLJS.F(c,d);a=c.substring(0,d);if(ru(b)>=ru("5")){a:for(b=
$CLJS.D(a)-1,c=b|0;;){if(0>c){b=$CLJS.Te.j($CLJS.p,"1",$CLJS.ef(b+1,"0"));break a}if($CLJS.E.g("9",a.charAt(c)))--c;else{b=$CLJS.Te.v($CLJS.p,a.substring(0,c),hu(ru(a.charAt(c))+1),$CLJS.ef(b-c,"0"));break a}}a=$CLJS.D(b)>a.length;c=$CLJS.Q;a&&(d=$CLJS.D(b)-1,b=b.substring(0,d));return new $CLJS.P(null,3,5,c,[b,e,a],null)}return new $CLJS.P(null,3,5,$CLJS.Q,[a,e,!1],null)}}}return new $CLJS.P(null,3,5,$CLJS.Q,[a,b,!1],null)};
Kw=function(a,b,c){var d=0>b?new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h($CLJS.Te.g($CLJS.p,$CLJS.ef(-b-1,"0"))),$CLJS.p.h(a)].join(""),-1],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);a=$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null);d=$CLJS.D(a);c=$CLJS.n(c)?e+c+1:e+1;c=d<c?[$CLJS.p.h(a),$CLJS.p.h($CLJS.Te.g($CLJS.p,$CLJS.ef(c-d,"0")))].join(""):a;0>b?b=[".",$CLJS.p.h(c)].join(""):(b+=1,b=[c.substring(0,b),".",c.substring(b)].join(""));return b};
fca=function(a,b){return 0>b?[".",$CLJS.p.h(a)].join(""):[a.substring(0,b),".",a.substring(b)].join("")};
Pw=function(a,b){var c=Lw.h(a),d=Mw.h(a);b=Xv(b);var e=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var f=0>e?new $CLJS.P(null,2,5,$CLJS.Q,["-",-e],null):new $CLJS.P(null,2,5,$CLJS.Q,["+",e],null),k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);f=Iw(f);var l=$CLJS.I(f,0,null),m=$CLJS.I(f,1,null)+$CLJS.Nw.h(a);f=function(){var x=cw.h(a);return $CLJS.n(x)?x:0>e}();var t=$CLJS.Ua(d)&&$CLJS.D(l)-1<=m,u=Jw(l,m,d,$CLJS.n(c)?c-($CLJS.n(f)?1:0):null);l=$CLJS.I(u,0,null);m=$CLJS.I(u,1,null);u=$CLJS.I(u,2,null);l=Kw(l,$CLJS.n(u)?
m+1:m,d);d=$CLJS.n($CLJS.n(c)?$CLJS.n(d)?1<=d&&$CLJS.E.g(l.charAt(0),"0")&&$CLJS.E.g(l.charAt(1),".")&&l.length>c-($CLJS.n(f)?1:0):d:c)?l.substring(1):l;m=$CLJS.E.g($CLJS.z(d),".");if($CLJS.n(c)){l=d.length;l=$CLJS.n(f)?l+1:l;m=m&&!(l>=c);t=t&&!(l>=c);var v=m||t?l+1:l;$CLJS.n(function(){var x=v>c;return x?Ow.h(a):x}())?nw.l($CLJS.H([$CLJS.Te.g($CLJS.p,$CLJS.ef(c,Ow.h(a)))])):nw.l($CLJS.H([[$CLJS.p.h($CLJS.Te.g($CLJS.p,$CLJS.ef(c-v,mw.h(a)))),$CLJS.p.h($CLJS.n(f)?k:null),m?"0":null,d,t?"0":null].join("")]))}else nw.l($CLJS.H([[$CLJS.p.h($CLJS.n(f)?
k:null),m?"0":null,d,t?"0":null].join("")]));return b};
Sw=function(a,b){b=Xv(b);var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=Iw(0>c?-c:c);$CLJS.I(d,0,null);for($CLJS.I(d,1,null);;){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null),k=Lw.h(a),l=Mw.h(a),m=Qw.h(a),t=$CLJS.Nw.h(a),u=function(){var K=Rw.h(a);return $CLJS.n(K)?K:"E"}();d=function(){var K=cw.h(a);return $CLJS.n(K)?K:0>c}();var v=0>=t,x=f-(t-1),A=$CLJS.p.h(Math.abs(x));u=[$CLJS.p.h(u),0>x?"-":"+",$CLJS.p.h($CLJS.n(m)?$CLJS.Te.g($CLJS.p,$CLJS.ef(m-A.length,"0")):null),A].join("");var C=u.length;
x=$CLJS.D(e);e=[$CLJS.p.h($CLJS.Te.g($CLJS.p,$CLJS.ef(-t,"0"))),$CLJS.p.h(e),$CLJS.p.h($CLJS.n(l)?$CLJS.Te.g($CLJS.p,$CLJS.ef(l-(x-1)-(0>t?-t:0),"0")):null)].join("");x=$CLJS.n(k)?k-C:null;e=Jw(e,0,$CLJS.E.g(t,0)?l-1:0<t?l:0>t?l-1:null,$CLJS.n(x)?x-($CLJS.n(d)?1:0):null);x=$CLJS.I(e,0,null);$CLJS.I(e,1,null);A=$CLJS.I(e,2,null);e=fca(x,t);l=$CLJS.E.g(t,$CLJS.D(x))&&null==l;if($CLJS.Ua(A)){if($CLJS.n(k)){f=e.length+C;f=$CLJS.n(d)?f+1:f;var G=(v=v&&!$CLJS.E.g(f,k))?f+1:f;f=l&&G<k;$CLJS.n(function(){var K=
G>k;K||(K=m,K=$CLJS.n(K)?C-2>m:K);return $CLJS.n(K)?Ow.h(a):K}())?nw.l($CLJS.H([$CLJS.Te.g($CLJS.p,$CLJS.ef(k,Ow.h(a)))])):nw.l($CLJS.H([[$CLJS.p.h($CLJS.Te.g($CLJS.p,$CLJS.ef(k-G-(f?1:0),mw.h(a)))),$CLJS.n(d)?0>c?"-":"+":null,v?"0":null,e,f?"0":null,u].join("")]))}else nw.l($CLJS.H([[$CLJS.n(d)?0>c?"-":"+":null,v?"0":null,e,l?"0":null,u].join("")]));break}else d=new $CLJS.P(null,2,5,$CLJS.Q,[x,f+1],null)}return b};
gca=function(a,b,c){var d=Xv(b),e=$CLJS.I(d,0,null);$CLJS.I(d,1,null);d=Iw(0>e?-e:e);var f=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var k=Lw.h(a),l=Mw.h(a),m=Qw.h(a);d=$CLJS.E.g(e,0)?0:d+1;e=$CLJS.n(m)?m+2:4;k=$CLJS.n(k)?k-e:null;$CLJS.n(l)?f=l:(f=$CLJS.D(f),l=7>d?d:7,f=f>l?f:l);d=f-d;return 0<=d&&d<=f?(a=Pw(new $CLJS.h(null,6,[Lw,k,Mw,d,$CLJS.Nw,0,Ow,Ow.h(a),mw,mw.h(a),cw,cw.h(a)],null),b,c),nw.l($CLJS.H([$CLJS.Te.g($CLJS.p,$CLJS.ef(e," "))])),a):Sw(a,b,c)};
hca=function(a,b){b=Xv(b);var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=Iw(Math.abs(c)),e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null),k=Mw.h(a),l=$CLJS.xk.h(a);d=Lw.h(a);var m=function(){var u=cw.h(a);return $CLJS.n(u)?u:0>c}(),t=Jw(e,f,k,null);e=$CLJS.I(t,0,null);f=$CLJS.I(t,1,null);t=$CLJS.I(t,2,null);k=Kw(e,$CLJS.n(t)?f+1:f,k);l=[$CLJS.p.h($CLJS.Te.g($CLJS.p,$CLJS.ef(l-k.indexOf("."),"0"))),k].join("");k=l.length+($CLJS.n(m)?1:0);nw.l($CLJS.H([[$CLJS.n(function(){var u=bw.h(a);return $CLJS.n(u)?
m:u}())?0>c?"-":"+":null,$CLJS.p.h($CLJS.Te.g($CLJS.p,$CLJS.ef(d-k,mw.h(a)))),$CLJS.n(function(){var u=$CLJS.Ua(bw.h(a));return u?m:u}())?0>c?"-":"+":null,l].join("")]));return b};ica=function(a,b){var c=Tw.h(a);c=$CLJS.n(c)?new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null):Xv(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var d=$CLJS.Uw.h(a);b=0>b||b>=$CLJS.D(d)?$CLJS.z($CLJS.xt.h(a)):$CLJS.F(d,b);return $CLJS.n(b)?Hw(b,c,Fw.h(a)):c};
jca=function(a,b){var c=Xv(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var d=$CLJS.Uw.h(a);b=$CLJS.n(b)?$CLJS.hd(d):$CLJS.z(d);return $CLJS.n(b)?Hw(b,c,Fw.h(a)):c};kca=function(a,b){var c=Xv(b),d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var e=$CLJS.Uw.h(a);e=$CLJS.n(d)?$CLJS.z(e):null;return $CLJS.n(d)?$CLJS.n(e)?Hw(e,b,Fw.h(a)):b:c};
lca=function(a,b){var c=Vw.h(a),d=$CLJS.z($CLJS.Uw.h(a));d=$CLJS.td(d)?Yv(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=Xv(d);var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var f=0;e=Pv(e);for(var k=-1;;){if($CLJS.Ua(c)&&$CLJS.E.g(gv.h(e),k)&&1<f)throw Error("%{ construct not consuming any arguments: Infinite loop!");k=$CLJS.td($CLJS.Ti.h(e))&&($CLJS.Ua(bw.h(Ww.h(a)))||0<f);if($CLJS.n(k?k:$CLJS.n(c)?f>=c:c))return d;k=Hw(b,e,Fw.h(a));if($CLJS.E.g(Bw,$CLJS.z(k)))return d;
f+=1;var l=gv.h(e);e=k;k=l}};mca=function(a,b){var c=Vw.h(a),d=$CLJS.z($CLJS.Uw.h(a));d=$CLJS.td(d)?Yv(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=Xv(d);var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);for(var f=0;;){var k=$CLJS.td(e)&&($CLJS.Ua(bw.h(Ww.h(a)))||0<f);if($CLJS.n(k?k:$CLJS.n(c)?f>=c:c))return d;k=Hw(b,Pv($CLJS.z(e)),Pv($CLJS.B(e)));if($CLJS.E.g(Cw,$CLJS.z(k)))return d;f+=1;e=$CLJS.B(e)}};
nca=function(a,b){var c=Vw.h(a),d=$CLJS.z($CLJS.Uw.h(a)),e=$CLJS.td(d)?Yv(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(e,0,null);d=0;e=$CLJS.I(e,1,null);for(var f=-1;;){if($CLJS.Ua(c)&&$CLJS.E.g(gv.h(e),f)&&1<d)throw Error("%@{ construct not consuming any arguments: Infinite loop!");f=$CLJS.td($CLJS.Ti.h(e))&&($CLJS.Ua(bw.h(Ww.h(a)))||0<d);if($CLJS.n(f?f:$CLJS.n(c)?d>=c:c))return e;f=Hw(b,e,Fw.h(a));if($CLJS.E.g(Bw,$CLJS.z(f)))return $CLJS.hd(f);d+=1;var k=gv.h(e);e=f;f=k}};
oca=function(a,b){var c=Vw.h(a),d=$CLJS.z($CLJS.Uw.h(a)),e=$CLJS.td(d)?Yv(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(e,0,null);d=0;for(e=$CLJS.I(e,1,null);;){var f=$CLJS.td($CLJS.Ti.h(e))&&($CLJS.Ua(bw.h(Ww.h(a)))||0<d);if($CLJS.n(f?f:$CLJS.n(c)?d>=c:c))return e;f=$CLJS.Ti.h(e);f=$CLJS.n(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.z(f),new Vv(Wv.h(e),$CLJS.B(f),gv.h(e)+1,null,null,null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,e],null);e=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);e=Hw(b,Pv(e),f);
if($CLJS.E.g(Cw,$CLJS.z(e)))return f;e=f;d+=1}};qca=function(a,b){if($CLJS.n(bw.h(Ww.h(a)))){var c=$CLJS.Uw.h(a),d=$CLJS.D(c),e=1<d?$CLJS.ek.h(Ew.h($CLJS.z($CLJS.z(c)))):$CLJS.n(bw.h(a))?"(":null,f=$CLJS.F(c,1<d?1:0);c=2<d?$CLJS.ek.h(Ew.h($CLJS.z($CLJS.F(c,2)))):$CLJS.n(bw.h(a))?")":null;d=Xv(b);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);if($CLJS.n(Ev()))$CLJS.ac($CLJS.pu,"#");else{var k=Dv,l=yv;Dv+=1;yv=0;try{sv(e,c),Hw(f,Pv(b),Fw.h(a)),wv()}finally{yv=l,Dv=k}}a=d}else a=pca(a,b);return a};
Xw=function(a,b,c){for(var d=$CLJS.Df;;){if($CLJS.td(a))return new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);var e=$CLJS.z(a);a:{var f=new $CLJS.Ea,k=$CLJS.pu;$CLJS.pu=new $CLJS.sc(f);try{var l=new $CLJS.P(null,2,5,$CLJS.Q,[Hw(e,b,c),$CLJS.p.h(f)],null);break a}finally{$CLJS.pu=k}l=void 0}b=$CLJS.I(l,0,null);e=$CLJS.I(l,1,null);if($CLJS.E.g(Bw,$CLJS.z(b)))return new $CLJS.P(null,2,5,$CLJS.Q,[d,$CLJS.hd(b)],null);a=$CLJS.B(a);d=$CLJS.ce.g(d,e)}};
pca=function(a,b){var c=function(){var V=$CLJS.xt.h(a);return $CLJS.n(V)?Xw(V,b,Fw.h(a)):null}(),d=$CLJS.I(c,0,null);d=$CLJS.I(d,0,null);c=$CLJS.I(c,1,null);var e=$CLJS.n(c)?c:b;c=function(){var V=Yw.h(a);return $CLJS.n(V)?fw(V,e):null}();var f=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var k=$CLJS.n(c)?c:e;c=function(){var V=$CLJS.z(Zw.h(f));return $CLJS.n(V)?V:0}();var l=function(){var V=$CLJS.z($w.h(f));return $CLJS.n(V)?V:$CLJS.wu($CLJS.pu,$CLJS.pk)}(),m=$CLJS.Uw.h(a);k=Xw(m,k,Fw.h(a));var t=$CLJS.I(k,
0,null);k=$CLJS.I(k,1,null);var u=function(){var V=$CLJS.D(t)-1+($CLJS.n(bw.h(a))?1:0)+($CLJS.n(cw.h(a))?1:0);return 1>V?1:V}();m=$CLJS.Qd($CLJS.Qk,$CLJS.jf.g($CLJS.D,t));var v=kw.h(a),x=jw.h(a),A=lw.h(a),C=m+u*x;v=C<=v?v:v+A*(1+$CLJS.Wd(C-v-1,A));var G=v-m;m=function(){var V=$CLJS.Wd(G,u);return x>V?x:V}();A=G-m*u;m=$CLJS.Te.g($CLJS.p,$CLJS.ef(m,mw.h(a)));$CLJS.n($CLJS.n(d)?$CLJS.wu(zu.h($CLJS.q($CLJS.q($CLJS.pu))),$CLJS.yu)+c+v>l:d)&&nw.l($CLJS.H([d]));c=A;for(var K=t,S=function(){var V=bw.h(a);
return $CLJS.n(V)?V:$CLJS.E.g($CLJS.D(K),1)&&$CLJS.Ua(cw.h(a))}();;)if($CLJS.y(K))nw.l($CLJS.H([[$CLJS.p.h($CLJS.Ua(S)?$CLJS.z(K):null),$CLJS.p.h($CLJS.n(function(){var V=S;return $CLJS.n(V)?V:(V=$CLJS.B(K))?V:cw.h(a)}())?m:null),$CLJS.p.h(0<c?mw.h(a):null)].join("")])),--c,K=d=$CLJS.n(S)?K:$CLJS.B(K),S=!1;else break;return k};ax=function(a,b){this.Ca=a;this.fi=b;this.C=1074135040;this.I=0};rca=function(a){return new ax(a,$CLJS.N)};bx=function(a,b){this.Ca=a;this.gi=b;this.C=1074135040;this.I=0};
sca=function(a){return new bx(a,$CLJS.N)};
tca=function(a,b){var c=$CLJS.z(a);return $CLJS.Te.g($CLJS.p,$CLJS.z(tu(function(d){if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,null],null);var e=RegExp("\\W\\w","g").exec(d);e=$CLJS.n(e)?e.index+1:e;return $CLJS.n(e)?new $CLJS.P(null,2,5,$CLJS.Q,[[d.substring(0,e),$CLJS.F(d,e).toUpperCase()].join(""),d.substring(e+1)],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,null],null)},$CLJS.n($CLJS.n(b)?$CLJS.n(c)?cu(c):c:b)?[c.toUpperCase(),a.substring(1)].join(""):a)))};
cx=function(a,b,c){this.Ca=a;this.Jd=b;this.hi=c;this.C=1074135040;this.I=0};uca=function(a){return new cx(a,$CLJS.Ye(!0),$CLJS.N)};dx=function(a,b,c){this.Ca=a;this.vd=b;this.ii=c;this.C=1074135040;this.I=0};vca=function(a){return new dx(a,$CLJS.Ye(!1),$CLJS.N)};wca=function(a,b){var c=$CLJS.n(bw.h(a))?$CLJS.Lv:Kv;Nv(c,$CLJS.xk.h(a));return b};xca=function(a,b){a=$CLJS.n(bw.h(a))?$CLJS.n(cw.h(a))?Fv:Hv:$CLJS.n(cw.h(a))?Gv:Iv;Jv(a);return b};
zca=function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null),d=$CLJS.I(a,2,null);a=new RegExp(yca.source,"g");var e=a.exec(b);return $CLJS.n(e)?(d=$CLJS.z(e),b=b.substring(a.lastIndex),a=c+a.lastIndex,$CLJS.E.g(",",$CLJS.F(b,0))?new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[b.substring(1),a+1,!0],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[b,a,!1],null)],null)):$CLJS.n(d)?Uv("Badly formed parameters in format directive",
c):new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,2,5,$CLJS.Q,[b,c],null)],null)};Aca=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.E.g(b.length,0)?null:$CLJS.E.g(b.length,1)&&$CLJS.Hd(new $CLJS.bh(null,new $CLJS.h(null,2,["V",null,"v",null],null),null),$CLJS.F(b,0))?dw:$CLJS.E.g(b.length,1)&&$CLJS.E.g("#",$CLJS.F(b,0))?ew:$CLJS.E.g(b.length,2)&&$CLJS.E.g("'",$CLJS.F(b,0))?$CLJS.F(b,1):parseInt(b,10),a],null)};
Cca=function(a,b){return tu(function(c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,3,5,$CLJS.Q,[d,e,c],null)],null);var f=$CLJS.J.g(Bca,$CLJS.z(d));return $CLJS.n(f)?$CLJS.Hd(c,f)?Uv(['Flag "',$CLJS.p.h($CLJS.z(d)),'" appears more than once in a directive'].join(""),e):new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,3,5,$CLJS.Q,[d.substring(1),e+1,$CLJS.R.j(c,f,new $CLJS.P(null,2,5,$CLJS.Q,[!0,e],null))],
null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,3,5,$CLJS.Q,[d,e,c],null)],null)},new $CLJS.P(null,3,5,$CLJS.Q,[a,b,$CLJS.N],null))};
Dca=function(a,b){var c=ex.h(a);$CLJS.n(function(){var d=$CLJS.Ua(cw.h(c));return d?cw.h(b):d}())&&Uv(['"@" is an illegal flag for format directive "',$CLJS.p.h(fx.h(a)),'"'].join(""),$CLJS.F(cw.h(b),1));$CLJS.n(function(){var d=$CLJS.Ua(bw.h(c));return d?bw.h(b):d}())&&Uv(['":" is an illegal flag for format directive "',$CLJS.p.h(fx.h(a)),'"'].join(""),$CLJS.F(bw.h(b),1));$CLJS.n(function(){var d=$CLJS.Ua(gx.h(c));return d?(d=cw.h(b),$CLJS.n(d)?bw.h(b):d):d}())&&Uv(['Cannot combine "@" and ":" flags for format directive "',
$CLJS.p.h(fx.h(a)),'"'].join(""),function(){var d=$CLJS.F(bw.h(b),1),e=$CLJS.F(cw.h(b),1);return d<e?d:e}())};
Fca=function(a,b,c,d){Dca(a,c);$CLJS.D(b)>$CLJS.D(Ew.h(a))&&Uv(Sv(null,'Too many parameters for directive "~C": ~D~:* ~[were~;was~:;were~] specified but only ~D~:* ~[are~;is~:;are~] allowed',$CLJS.H([fx.h(a),$CLJS.D(b),$CLJS.D(Ew.h(a))])),$CLJS.hd($CLJS.z(b)));$CLJS.nh($CLJS.jf.j(function(e,f){var k=$CLJS.z(e);return null==k||$CLJS.Hd(Eca,k)||$CLJS.E.g($CLJS.hd($CLJS.hd(f)),$CLJS.ab(k))?null:Uv(["Parameter ",$CLJS.hh($CLJS.z(f)),' has bad type in directive "',$CLJS.p.h(fx.h(a)),'": ',$CLJS.p.h($CLJS.ab(k))].join(""),
$CLJS.hd(e))},b,Ew.h(a)));return $CLJS.nl.l($CLJS.H([$CLJS.fg.g($CLJS.N,$CLJS.de(function(){return function k(f){return new $CLJS.oe(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.re(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),A=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.I(x,0,null);u.add(new $CLJS.P(null,2,5,$CLJS.Q,[A,new $CLJS.P(null,2,5,$CLJS.Q,[x,d],null)],null));v+=1}else{m=!0;break a}return m?$CLJS.ue($CLJS.we(u),k($CLJS.mc(l))):
$CLJS.ue($CLJS.we(u),null)}m=$CLJS.z(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);m=$CLJS.I(m,0,null);return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[u,new $CLJS.P(null,2,5,$CLJS.Q,[m,d],null)],null),k($CLJS.Lc(l)))}return null}},null,null)}(Ew.h(a))}())),$CLJS.eb(function(e,f){return $CLJS.Te.j($CLJS.R,e,f)},$CLJS.N,$CLJS.mf(function(e){return $CLJS.z($CLJS.F(e,1))},$CLJS.ku($CLJS.Yg(Ew.h(a)),b))),c]))};
Hca=function(a,b){b=tu(zca,new $CLJS.P(null,3,5,$CLJS.Q,[a,b,!1],null));a=$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);b=Cca(b,c);$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);c=$CLJS.z(d);var f=$CLJS.J.g(Gca,c.toUpperCase()),k=$CLJS.n(f)?Fca(f,$CLJS.jf.g(Aca,a),b,e):null;$CLJS.Ua(c)&&Uv("Format string ended in the middle of a directive",e);$CLJS.Ua(f)&&Uv(['Directive "',$CLJS.p.h(c),'" is undefined'].join(""),
e);return new $CLJS.P(null,2,5,$CLJS.Q,[new aw(function(){var l=hx.h(f);return l.g?l.g(k,e):l.call(null,k,e)}(),f,k,e,null,null,null),function(){var l=d.substring(1),m=e+1;if($CLJS.E.g("\n",fx.h(f))&&$CLJS.Ua(bw.h(k)))a:{var t=new $CLJS.P(null,2,5,$CLJS.Q,[" ","\t"],null);t=$CLJS.ud(t)?$CLJS.gh(t):$CLJS.dh([t]);for(var u=0;;){var v;(v=$CLJS.E.g(u,$CLJS.D(l)))||(v=$CLJS.F(l,u),v=t.h?t.h(v):t.call(null,v),v=$CLJS.Ua(v));if(v){t=u;break a}u+=1}}else t=0;return new $CLJS.P(null,2,5,$CLJS.Q,[l.substring(t),
m+t],null)}()],null)};ix=function(a,b){return new aw(function(c,d){nw.l($CLJS.H([a]));return d},null,new $CLJS.h(null,1,[$CLJS.ek,a],null),b,null,null,null)};lx=function(a,b){var c=Ica(jx.h(kx.h(a)),$CLJS.yt.h(a),b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[new aw(Gw.h(a),kx.h(a),$CLJS.nl.l($CLJS.H([Ew.h(a),Rba(b,$CLJS.yt.h(a))])),$CLJS.yt.h(a),null,null,null),c],null)};
Jca=function(a,b,c){return tu(function(d){if($CLJS.td(d))return Uv("No closing bracket found.",b);var e=$CLJS.z(d);d=$CLJS.B(d);if($CLJS.n($CLJS.mx.h(jx.h(kx.h(e)))))e=lx(e,d);else if($CLJS.E.g($CLJS.mx.h(a),fx.h(kx.h(e))))e=new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,4,5,$CLJS.Q,[nx,Ew.h(e),null,d],null)],null);else{var f=$CLJS.zt.h(jx.h(kx.h(e)));f=$CLJS.n(f)?bw.h(Ew.h(e)):f;e=$CLJS.n(f)?new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xt,null,Ew.h(e),d],null)],null):
$CLJS.n($CLJS.zt.h(jx.h(kx.h(e))))?new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zt,null,null,d],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[e,d],null)}return e},c)};
Ica=function(a,b,c){return $CLJS.hd(tu(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);d=$CLJS.I(d,2,null);var k=Jca(a,b,d);d=$CLJS.I(k,0,null);var l=$CLJS.I(k,1,null);k=$CLJS.I(l,0,null);var m=$CLJS.I(l,1,null),t=$CLJS.I(l,2,null);l=$CLJS.I(l,3,null);return $CLJS.E.g(k,nx)?new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ox.l($CLJS.hf,$CLJS.H([e,$CLJS.Fe([$CLJS.n(f)?$CLJS.xt:$CLJS.Uw,new $CLJS.P(null,1,5,$CLJS.Q,[d],null),Ww,m])])),l],null)],null):$CLJS.E.g(k,$CLJS.xt)?
$CLJS.n($CLJS.xt.h(e))?Uv('Two else clauses ("~:;") inside bracket construction.',b):$CLJS.Ua($CLJS.xt.h(a))?Uv('An else clause ("~:;") is in a bracket type that doesn\'t support it.',b):$CLJS.E.g(px,$CLJS.xt.h(a))&&$CLJS.y($CLJS.Uw.h(e))?Uv('The else clause ("~:;") is only allowed in the first position for this directive.',b):$CLJS.E.g(px,$CLJS.xt.h(a))?new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ox.l($CLJS.hf,$CLJS.H([e,new $CLJS.h(null,2,[$CLJS.xt,new $CLJS.P(null,1,5,
$CLJS.Q,[d],null),Yw,t],null)])),!1,l],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ox.l($CLJS.hf,$CLJS.H([e,new $CLJS.h(null,1,[$CLJS.Uw,new $CLJS.P(null,1,5,$CLJS.Q,[d],null)],null)])),!0,l],null)],null):$CLJS.E.g(k,$CLJS.zt)?$CLJS.n(f)?Uv('A plain clause (with "~;") follows an else clause ("~:;") inside bracket construction.',b):$CLJS.Ua(qx.h(a))?Uv('A separator ("~;") is in a bracket type that doesn\'t support it.',b):new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.ox.l($CLJS.hf,$CLJS.H([e,new $CLJS.h(null,1,[$CLJS.Uw,new $CLJS.P(null,1,5,$CLJS.Q,[d],null)],null)])),!1,l],null)],null):null},new $CLJS.P(null,3,5,$CLJS.Q,[new $CLJS.h(null,1,[$CLJS.Uw,$CLJS.Df],null),!1,c],null)))};Kca=function(a){return $CLJS.z(tu(function(b){var c=$CLJS.z(b);b=$CLJS.B(b);var d=jx.h(kx.h(c));return $CLJS.n($CLJS.mx.h(d))?lx(c,b):new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)},a))};
Ov=function(a){var b=Tv;Tv=a;try{return Kca($CLJS.z(tu(function(c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);var e=d.indexOf("~");return 0>e?new $CLJS.P(null,2,5,$CLJS.Q,[ix(d,c),new $CLJS.P(null,2,5,$CLJS.Q,["",c+d.length],null)],null):0===e?Hca(d.substring(1),c+1):new $CLJS.P(null,2,5,$CLJS.Q,[ix(d.substring(0,e),c),new $CLJS.P(null,2,5,$CLJS.Q,[d.substring(e),e+c],null)],null)},new $CLJS.P(null,2,5,$CLJS.Q,[a,0],null))))}finally{Tv=
b}};Rv=function(a,b){su(function(c,d){if(Dw(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);d=fw(Ew.h(c),d);var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var f=uu(e);e=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);e=$CLJS.R.j(e,Fw,d);return new $CLJS.P(null,2,5,$CLJS.Q,[null,$CLJS.Te.g(Gw.h(c),new $CLJS.P(null,3,5,$CLJS.Q,[e,d,f],null))],null)},b,a);return null};
sx=function(a){var b=$CLJS.z(a);b=rx.h?rx.h(b):rx.call(null,b);return $CLJS.n($CLJS.n(b)?$CLJS.E.g(2,$CLJS.D(a)):b)?($CLJS.ac($CLJS.pu,b),$CLJS.Bv($CLJS.hd(a)),!0):null};
tx=function(a){if($CLJS.n(Ev()))$CLJS.ac($CLJS.pu,"#");else{var b=Dv,c=yv;Dv+=1;yv=0;try{sv("[","]");for(var d=0,e=$CLJS.y(a);;){if($CLJS.Ua($CLJS.Ma)||d<$CLJS.Ma){if(e&&($CLJS.Bv($CLJS.z(e)),$CLJS.B(e))){$CLJS.ac($CLJS.pu," ");Jv(Iv);a=d+1;var f=$CLJS.B(e);d=a;e=f;continue}}else $CLJS.ac($CLJS.pu,"...");break}wv()}finally{yv=c,Dv=b}}return null};
ux=function(a){var b=$CLJS.yd(a)?null:function(){var m=new $CLJS.Gc(function(){return $CLJS.Ih},Lca,$CLJS.Jg([$CLJS.Ck,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,$CLJS.vi,$CLJS.qk,$CLJS.uk],[!0,$CLJS.ni,Mca,"cljs/core.cljs",15,1,10543,10543,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.jj],null)),"Returns [lifted-ns lifted-map] or nil if m can't be lifted.",$CLJS.n($CLJS.Ih)?$CLJS.Ih.H:null]));return m.h?m.h(a):m.call(null,a)}(),c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$CLJS.n(b)?
b:a,e=$CLJS.n(c)?["#:",$CLJS.p.h(c),"{"].join(""):"{";if($CLJS.n(Ev()))$CLJS.ac($CLJS.pu,"#");else{c=Dv;b=yv;Dv+=1;yv=0;try{sv(e,"}");e=0;for(var f=$CLJS.y(d);;){if($CLJS.Ua($CLJS.Ma)||e<$CLJS.Ma){if(f){if($CLJS.n(Ev()))$CLJS.ac($CLJS.pu,"#");else{d=Dv;var k=yv;Dv+=1;yv=0;try{sv(null,null),$CLJS.Bv($CLJS.z($CLJS.z(f))),$CLJS.ac($CLJS.pu," "),Jv(Iv),yv=0,$CLJS.Bv($CLJS.z($CLJS.B($CLJS.z(f)))),wv()}finally{yv=k,Dv=d}}if($CLJS.B(f)){$CLJS.ac($CLJS.pu,", ");Jv(Iv);d=e+1;var l=$CLJS.B(f);e=d;f=l;continue}}}else $CLJS.ac($CLJS.pu,
"...");break}wv()}finally{yv=b,Dv=c}}return null};vx=function(a){return $CLJS.ac($CLJS.pu,$CLJS.Nh.l($CLJS.H([a])))};yx=function(a){return a instanceof $CLJS.Sf?wx:(null!=a?a.C&32768||$CLJS.Bc===a.ag||(a.C?0:$CLJS.$a($CLJS.du,a)):$CLJS.$a($CLJS.du,a))?xx:a instanceof $CLJS.r?$CLJS.Ki:$CLJS.Dd(a)?$CLJS.Hj:$CLJS.xd(a)?$CLJS.ok:$CLJS.zd(a)?$CLJS.Pj:$CLJS.vd(a)?$CLJS.ej:null==a?null:$CLJS.ei};
zx=function(a){return $CLJS.zd(a)?new $CLJS.P(null,2,5,$CLJS.Q,["[","]"],null):new $CLJS.P(null,2,5,$CLJS.Q,["(",")"],null)};
Nca=function(a){if($CLJS.wd(a)){var b=zx(a),c=$CLJS.I(b,0,null),d=$CLJS.I(b,1,null),e=$CLJS.y(a),f=$CLJS.z(e),k=$CLJS.B(e);if($CLJS.n(Ev()))$CLJS.ac($CLJS.pu,"#");else{var l=Dv,m=yv,t=Dv+1;Dv=t;yv=0;try{sv(c,d);(function(){var Ta=Ax("~w~:i");return function(){function Cb(Wa){var fb=null;if(0<arguments.length){fb=0;for(var Va=Array(arguments.length-0);fb<Va.length;)Va[fb]=arguments[fb+0],++fb;fb=new $CLJS.w(Va,0,null)}return Oa.call(this,fb)}function Oa(Wa){Wa=Pv(Wa);return Rv(Ta,Wa)}Cb.A=0;Cb.B=function(Wa){Wa=
$CLJS.y(Wa);return Oa(Wa)};Cb.l=Oa;return Cb}()})()(f);for(var u=k;;)if($CLJS.y(u)){(function(){var Ta=Ax(" ");return function(Cb,Oa,Wa){return function(){function fb(nb){var Ja=null;if(0<arguments.length){Ja=0;for(var bc=Array(arguments.length-0);Ja<bc.length;)bc[Ja]=arguments[Ja+0],++Ja;Ja=new $CLJS.w(bc,0,null)}return Va.call(this,Ja)}function Va(nb){nb=Pv(nb);return Rv(Wa,nb)}fb.A=0;fb.B=function(nb){nb=$CLJS.y(nb);return Va(nb)};fb.l=Va;return fb}()}(u," ",Ta,l,m,t,0,b,c,d,a,e,f,k,f,k)})()();
var v=$CLJS.z(u);if($CLJS.wd(v)){var x=zx(v),A=$CLJS.I(x,0,null),C=$CLJS.I(x,1,null);if($CLJS.n(Ev()))$CLJS.ac($CLJS.pu,"#");else{var G=Dv,K=yv,S=Dv+1;Dv=S;yv=0;try{sv(A,C);if($CLJS.E.g($CLJS.D(v),3)&&$CLJS.hd(v)instanceof $CLJS.M){var V=v,Y=$CLJS.I(V,0,null),ha=$CLJS.I(V,1,null),ta=$CLJS.I(V,2,null);(function(){var Ta=Ax("~w ~w ");return function(Cb,Oa,Wa){return function(){function fb(nb){var Ja=null;if(0<arguments.length){Ja=0;for(var bc=Array(arguments.length-0);Ja<bc.length;)bc[Ja]=arguments[Ja+
0],++Ja;Ja=new $CLJS.w(bc,0,null)}return Va.call(this,Ja)}function Va(nb){nb=Pv(nb);return Rv(Wa,nb)}fb.A=0;fb.B=function(nb){nb=$CLJS.y(nb);return Va(nb)};fb.l=Va;return fb}()}(u,"~w ~w ",Ta,V,Y,ha,ta,G,K,S,0,x,A,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)})()(Y,ha);$CLJS.wd(ta)?function(){var Ta=$CLJS.zd(ta)?"~\x3c[~;~@{~w~^ ~:_~}~;]~:\x3e":"~\x3c(~;~@{~w~^ ~:_~}~;)~:\x3e",Cb="string"===typeof Ta?Ax(Ta):Ta;return function(Oa,Wa,fb){return function(){function Va(Ja){var bc=null;if(0<arguments.length){bc=0;for(var $d=
Array(arguments.length-0);bc<$d.length;)$d[bc]=arguments[bc+0],++bc;bc=new $CLJS.w($d,0,null)}return nb.call(this,bc)}function nb(Ja){Ja=Pv(Ja);return Rv(fb,Ja)}Va.A=0;Va.B=function(Ja){Ja=$CLJS.y(Ja);return nb(Ja)};Va.l=nb;return Va}()}(u,Ta,Cb,V,Y,ha,ta,G,K,S,0,x,A,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()(ta):$CLJS.Bv(ta)}else $CLJS.Te.g(function(){var Ta=Ax("~w ~:i~@{~w~^ ~:_~}");return function(Cb,Oa,Wa){return function(){function fb(nb){var Ja=null;if(0<arguments.length){Ja=0;for(var bc=Array(arguments.length-
0);Ja<bc.length;)bc[Ja]=arguments[Ja+0],++Ja;Ja=new $CLJS.w(bc,0,null)}return Va.call(this,Ja)}function Va(nb){nb=Pv(nb);return Rv(Wa,nb)}fb.A=0;fb.B=function(nb){nb=$CLJS.y(nb);return Va(nb)};fb.l=Va;return fb}()}(u,"~w ~:i~@{~w~^ ~:_~}",Ta,G,K,S,0,x,A,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}(),v);wv()}finally{yv=K,Dv=G}}$CLJS.B(u)&&function(){var Ta=Ax("~_");return function(Cb,Oa,Wa){return function(){function fb(nb){var Ja=null;if(0<arguments.length){Ja=0;for(var bc=Array(arguments.length-0);Ja<bc.length;)bc[Ja]=
arguments[Ja+0],++Ja;Ja=new $CLJS.w(bc,0,null)}return Va.call(this,Ja)}function Va(nb){nb=Pv(nb);return Rv(Wa,nb)}fb.A=0;fb.B=function(nb){nb=$CLJS.y(nb);return Va(nb)};fb.l=Va;return fb}()}(u,"~_",Ta,x,A,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()()}else $CLJS.Bv(v),$CLJS.B(u)&&function(){var Ta=Ax("~:_");return function(Cb,Oa,Wa){return function(){function fb(nb){var Ja=null;if(0<arguments.length){Ja=0;for(var bc=Array(arguments.length-0);Ja<bc.length;)bc[Ja]=arguments[Ja+0],++Ja;Ja=new $CLJS.w(bc,0,null)}return Va.call(this,
Ja)}function Va(nb){nb=Pv(nb);return Rv(Wa,nb)}fb.A=0;fb.B=function(nb){nb=$CLJS.y(nb);return Va(nb)};fb.l=Va;return fb}()}(u,"~:_",Ta,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()();u=$CLJS.B(u)}else break;wv()}finally{yv=m,Dv=l}}}else $CLJS.Bv(a)};
Oca=function(a,b){$CLJS.y(a)&&($CLJS.n(b)?function(){var c=Ax(" ~_");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.w(l,0,null)}return e.call(this,k)}function e(f){f=Pv(f);return Rv(c,f)}d.A=0;d.B=function(f){f=$CLJS.y(f);return e(f)};d.l=e;return d}()}()():function(){var c=Ax(" ~@_");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<
l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.w(l,0,null)}return e.call(this,k)}function e(f){f=Pv(f);return Rv(c,f)}d.A=0;d.B=function(f){f=$CLJS.y(f);return e(f)};d.l=e;return d}()}()(),function(){var c=Ax("~{~w~^ ~_~}");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.w(l,0,null)}return e.call(this,k)}function e(f){f=Pv(f);return Rv(c,f)}d.A=0;d.B=function(f){f=$CLJS.y(f);return e(f)};d.l=e;
return d}()}()(a))};Pca=function(a){$CLJS.y(a)&&function(){var b=Ax(" ~_~{~w~^ ~_~}");return function(){function c(e){var f=null;if(0<arguments.length){f=0;for(var k=Array(arguments.length-0);f<k.length;)k[f]=arguments[f+0],++f;f=new $CLJS.w(k,0,null)}return d.call(this,f)}function d(e){e=Pv(e);return Rv(b,e)}c.A=0;c.B=function(e){e=$CLJS.y(e);return d(e)};c.l=d;return c}()}()(a)};
Cx=function(a){if($CLJS.B(a)){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);var d="string"===typeof $CLJS.z(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.z(c),$CLJS.B(c)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,c],null);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var e=$CLJS.xd($CLJS.z(d))?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.z(d),$CLJS.B(d)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);d=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.n(Ev()))$CLJS.ac($CLJS.pu,"#");else{var f=
Dv,k=yv;Dv+=1;yv=0;try{sv("(",")"),function(){var l=Ax("~w ~1I~@_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=Pv(u);return Rv(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};m.l=t;return m}()}()(a,b),$CLJS.n(c)&&function(){var l=Ax(" ~_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-
0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=Pv(u);return Rv(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};m.l=t;return m}()}()(c),$CLJS.n(d)&&function(){var l=Ax(" ~_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=Pv(u);return Rv(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};
m.l=t;return m}()}()(d),$CLJS.zd($CLJS.z(e))?Oca(e,$CLJS.n(c)?c:d):Pca(e),wv()}finally{yv=k,Dv=f}}return null}return Bx(a)};
Qca=function(a){if($CLJS.n(Ev()))$CLJS.ac($CLJS.pu,"#");else{var b=Dv,c=yv;Dv+=1;yv=0;try{sv("[","]");for(var d=0;;){if($CLJS.Ua($CLJS.Ma)||d<$CLJS.Ma){if($CLJS.y(a)){if($CLJS.n(Ev()))$CLJS.ac($CLJS.pu,"#");else{var e=Dv,f=yv;Dv+=1;yv=0;try{sv(null,null),$CLJS.Bv($CLJS.z(a)),$CLJS.B(a)&&($CLJS.ac($CLJS.pu," "),Jv(Gv),$CLJS.Bv($CLJS.hd(a))),wv()}finally{yv=f,Dv=e}}if($CLJS.B($CLJS.Lc(a))){$CLJS.ac($CLJS.pu," ");Jv(Iv);e=d+1;var k=$CLJS.B($CLJS.Lc(a));d=e;a=k;continue}}}else $CLJS.ac($CLJS.pu,"...");
break}wv()}finally{yv=c,Dv=b}}};
Dx=function(a){var b=$CLJS.z(a);if($CLJS.n(Ev()))$CLJS.ac($CLJS.pu,"#");else{var c=Dv,d=yv;Dv+=1;yv=0;try{sv("(",")"),$CLJS.B(a)&&$CLJS.zd($CLJS.hd(a))?(function(){var e=Ax("~w ~1I~@_");return function(){function f(l){var m=null;if(0<arguments.length){m=0;for(var t=Array(arguments.length-0);m<t.length;)t[m]=arguments[m+0],++m;m=new $CLJS.w(t,0,null)}return k.call(this,m)}function k(l){l=Pv(l);return Rv(e,l)}f.A=0;f.B=function(l){l=$CLJS.y(l);return k(l)};f.l=k;return f}()}()(b),Qca($CLJS.hd(a)),function(){var e=
Ax(" ~_~{~w~^ ~_~}");return function(){function f(l){var m=null;if(0<arguments.length){m=0;for(var t=Array(arguments.length-0);m<t.length;)t[m]=arguments[m+0],++m;m=new $CLJS.w(t,0,null)}return k.call(this,m)}function k(l){l=Pv(l);return Rv(e,l)}f.A=0;f.B=function(l){l=$CLJS.y(l);return k(l)};f.l=k;return f}()}()($CLJS.B($CLJS.Lc(a)))):Bx(a),wv()}finally{yv=d,Dv=c}}return null};
Bx=function(a){if($CLJS.n(Ev()))$CLJS.ac($CLJS.pu,"#");else{var b=Dv,c=yv;Dv+=1;yv=0;try{sv("(",")");Nv(Kv,1);for(var d=0,e=$CLJS.y(a);;){if($CLJS.Ua($CLJS.Ma)||d<$CLJS.Ma){if(e&&($CLJS.Bv($CLJS.z(e)),$CLJS.B(e))){$CLJS.ac($CLJS.pu," ");Jv(Iv);a=d+1;var f=$CLJS.B(e);d=a;e=f;continue}}else $CLJS.ac($CLJS.pu,"...");break}wv()}finally{yv=c,Dv=b}}return null};$CLJS.oe.prototype.Lc=$CLJS.va(2,function(){return $CLJS.Ua(this.Rb)});$CLJS.df.prototype.Lc=$CLJS.va(1,function(){return!1});
$CLJS.Kh.prototype.Lc=$CLJS.va(0,function(){return $CLJS.Ua(this.f)});$CLJS.pu=null;Ex=function Ex(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Ex.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)};Ex.l=function(a){a=$CLJS.y(a);for(var b=$CLJS.ec($CLJS.hg);;)if(a){var c=$CLJS.B(a);if(null==c)throw Error(["No value supplied for key: ",$CLJS.p.h($CLJS.z(a))].join(""));var d=$CLJS.B(c);b=$CLJS.ig.j(b,$CLJS.z(a),$CLJS.z(c));a=d}else return $CLJS.gc(b)};
Ex.A=0;Ex.B=function(a){return this.l($CLJS.y(a))};$CLJS.ox=function ox(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return ox.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
$CLJS.ox.l=function(a,b){if($CLJS.n($CLJS.Se($CLJS.Td,b))){var c=function(d,e){var f=$CLJS.Eb(e),k=$CLJS.Fb(e);if($CLJS.Hd(d,f)){e=$CLJS.R.j;var l=$CLJS.J.g(d,f);k=a.g?a.g(l,k):a.call(null,l,k);d=e.call($CLJS.R,d,f,k)}else d=$CLJS.R.j(d,f,k);return d};return $CLJS.Qd(function(d,e){return $CLJS.eb(c,$CLJS.n(d)?d:$CLJS.N,$CLJS.y(e))},b)}return null};$CLJS.ox.A=1;$CLJS.ox.B=function(a){var b=$CLJS.z(a);a=$CLJS.B(a);return this.l(b,a)};
$CLJS.sw=function sw(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return sw.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)};$CLJS.sw.l=function(a){return $CLJS.Fh(a,$CLJS.R.j($CLJS.Na(),$CLJS.Ga,!1))};$CLJS.sw.A=0;$CLJS.sw.B=function(a){return this.l($CLJS.y(a))};Lu=new $CLJS.M(null,"trailing-white-space","trailing-white-space",1496006996);Fx=new $CLJS.M(null,"relative-to","relative-to",-470100051);Zu=new $CLJS.M(null,"intra-block-nl","intra-block-nl",1808826875);
Rw=new $CLJS.M(null,"exponentchar","exponentchar",1986664222);Rca=new $CLJS.r(null,"when-let","when-let",-1383043480,null);Gv=new $CLJS.M(null,"miser","miser",-556060186);$CLJS.Lv=new $CLJS.M(null,"current","current",-1088038603);Sca=new $CLJS.r(null,"-\x3e","-\x3e",-2139605430,null);Tca=new $CLJS.r(null,"..","..",-300507420,null);Ou=new $CLJS.M(null,"end-pos","end-pos",-1643883926);Xu=new $CLJS.M(null,"per-line-prefix","per-line-prefix",846941813);wx=new $CLJS.M(null,"queue","queue",1455835879);
Uca=new $CLJS.r(null,"if-let","if-let",1803593690,null);uv=new $CLJS.M(null,"end","end",-268185958);ew=new $CLJS.M(null,"remaining-arg-count","remaining-arg-count",-1216589335);ev=new $CLJS.M(null,"logical-blocks","logical-blocks",-1466339776);Vca=new $CLJS.r(null,"defmacro","defmacro",2054157304,null);Wca=new $CLJS.r(null,"when-first","when-first",821699168,null);rv=new $CLJS.M(null,"start-block-t","start-block-t",-373430594);Xca=new $CLJS.r(null,"binding","binding",-2114503176,null);
jx=new $CLJS.M(null,"bracket-info","bracket-info",-1600092774);iv=new $CLJS.M(null,"writing","writing",-1486865108);dw=new $CLJS.M(null,"parameter-from-args","parameter-from-args",-758446196);pv=new $CLJS.M(null,"logical-block-callback","logical-block-callback",1612691194);Tw=new $CLJS.M(null,"selector","selector",762528866);Yca=new $CLJS.r(null,"struct","struct",325972931,null);Yu=new $CLJS.M(null,"indent","indent",-148200125);Zca=new $CLJS.r(null,"loop","loop",1244978678,null);
$w=new $CLJS.M(null,"max-columns","max-columns",1742323262);gx=new $CLJS.M(null,"both","both",-393648840);Gx=new $CLJS.M(null,"colnum","colnum",2023796854);$ca=new $CLJS.r(null,"doseq","doseq",221164135,null);$CLJS.Hx=new $CLJS.M(null,"length","length",588987862);Aw=new $CLJS.M(null,"char-format","char-format",-1016499218);Ix=new $CLJS.M(null,"prefix","prefix",-265908465);Cw=new $CLJS.M(null,"colon-up-arrow","colon-up-arrow",244853007);tv=new $CLJS.M(null,"suffix","suffix",367373057);
cw=new $CLJS.M(null,"at","at",1476951349);Ku=new $CLJS.M(null,"nl-t","nl-t",-1608382114);fx=new $CLJS.M(null,"directive","directive",793559132);Yba=new $CLJS.M(null,"buffer-level","buffer-level",928864731);Fv=new $CLJS.M(null,"mandatory","mandatory",542802336);nv=new $CLJS.M(null,"pretty-writer","pretty-writer",-1222834267);Ru=new $CLJS.M(null,"done-nl","done-nl",-381024340);ada=new $CLJS.r(null,"condp","condp",1054325175,null);Wv=new $CLJS.M(null,"seq","seq",-1817803783);
bda=new $CLJS.r(null,"defn","defn",-126010802,null);lw=new $CLJS.M(null,"colinc","colinc",-584873385);Bw=new $CLJS.M(null,"up-arrow","up-arrow",1705310333);nx=new $CLJS.M(null,"right-bracket","right-bracket",951856080);cda=new $CLJS.M(null,"radix","radix",857016463);px=new $CLJS.M(null,"first","first",-644103046);Xba=new $CLJS.M(null,"sections","sections",-886710106);$CLJS.qv=new $CLJS.M(null,"start","start",-355208981);dda=new $CLJS.r("cljs.core","unquote","cljs.core/unquote",1013085760,null);
eda=new $CLJS.r(null,"defn-","defn-",1097765044,null);fda=new $CLJS.r("cljs.core","deref","cljs.core/deref",1901963335,null);Kv=new $CLJS.M(null,"block","block",664686210);gda=new $CLJS.r(null,"when","when",1064114221,null);Yw=new $CLJS.M(null,"else-params","else-params",-832171646);$CLJS.Jx=new $CLJS.M(null,"count","count",2139924085);$CLJS.mx=new $CLJS.M(null,"right","right",-452581833);Iv=new $CLJS.M(null,"linear","linear",872268697);hda=new $CLJS.r(null,"when-not","when-not",-1223136340,null);
mw=new $CLJS.M(null,"padchar","padchar",2018584530);kw=new $CLJS.M(null,"mincol","mincol",1230695445);ida=new $CLJS.M(null,"not-delivered","not-delivered",1599158697);Tu=new $CLJS.M(null,"miser-width","miser-width",-1310049437);jda=new $CLJS.r(null,"with-local-vars","with-local-vars",837642072,null);Mca=new $CLJS.r(null,"lift-ns","lift-ns",602311926,null);qx=new $CLJS.M(null,"allows-separator","allows-separator",-818967742);fv=new $CLJS.M(null,"buffering","buffering",-876713613);
Kx=new $CLJS.M(null,"arg1","arg1",951899358);Fw=new $CLJS.M(null,"base-args","base-args",-1268706822);Lx=new $CLJS.M(null,"arg3","arg3",-1486822496);Mx=new $CLJS.M(null,"arg2","arg2",1729550917);qw=new $CLJS.M(null,"commainterval","commainterval",-1980061083);kda=new $CLJS.M(null,"right-margin","right-margin",-810413306);hv=new $CLJS.M(null,"buffer-blob","buffer-blob",-1830112173);lda=new $CLJS.r(null,"with-open","with-open",172119667,null);
Lca=new $CLJS.r("cljs.core","lift-ns","cljs.core/lift-ns",463499081,null);mda=new $CLJS.M(null,"lines","lines",-700165781);Mv=new $CLJS.M(null,"indent-t","indent-t",528318969);Ww=new $CLJS.M(null,"right-params","right-params",-1790676237);bw=new $CLJS.M(null,"colon","colon",-965200945);nda=new $CLJS.r(null,"if-not","if-not",-265415609,null);Gw=new $CLJS.M(null,"func","func",-238706040);$CLJS.Nx=new $CLJS.M(null,"last","last",1105735132);xx=new $CLJS.M(null,"deref","deref",-145586795);
oda=new $CLJS.r(null,"dotimes","dotimes",-818708397,null);Vw=new $CLJS.M(null,"max-iterations","max-iterations",2021275563);pda=new $CLJS.r(null,"cond","cond",1606708055,null);jw=new $CLJS.M(null,"minpad","minpad",323570901);Wu=new $CLJS.M(null,"logical-block","logical-block",-581022564);qda=new $CLJS.r(null,"struct-map","struct-map",-1387540878,null);vv=new $CLJS.M(null,"end-block-t","end-block-t",1544648735);Ox=new $CLJS.M(null,"stream","stream",1534941648);
Ew=new $CLJS.M(null,"params","params",710516235);rda=new $CLJS.M(null,"circle","circle",1903212362);Pu=new $CLJS.M(null,"start-pos","start-pos",668789086);ex=new $CLJS.M(null,"flags","flags",1775418075);Hv=new $CLJS.M(null,"fill","fill",883462889);Wba=new $CLJS.M(null,"buffer-block","buffer-block",-10937307);hx=new $CLJS.M(null,"generator-fn","generator-fn",811851656);Uu=new $CLJS.M(null,"start-col","start-col",668080143);Lw=new $CLJS.M(null,"w","w",354169001);kx=new $CLJS.M(null,"def","def",-1043430536);
$CLJS.Nw=new $CLJS.M(null,"k","k",-2146297393);Qw=new $CLJS.M(null,"e","e",1381269198);Mw=new $CLJS.M(null,"d","d",1972142424);Ow=new $CLJS.M(null,"overflowchar","overflowchar",-1620088106);Zw=new $CLJS.M(null,"min-remaining","min-remaining",962687677);rw=new $CLJS.M(null,"commachar","commachar",652859327);Px=new $CLJS.M(null,"section","section",-300141526);Qx=new $CLJS.M(null,"pretty","pretty",-1916372486);sda=new $CLJS.r(null,"let","let",358118826,null);Cu=new $CLJS.M(null,"parent","parent",-878878779);
$CLJS.Uw=new $CLJS.M(null,"clauses","clauses",1454841241);tda=new $CLJS.r(null,"defonce","defonce",-1681484013,null);zu=new $CLJS.M(null,"base","base",185279322);Ju=new $CLJS.M(null,"type-tag","type-tag",-1873863267);uda=new $CLJS.r(null,"locking","locking",1542862874,null);gv=new $CLJS.M(null,"pos","pos",-864607220);vda=new $CLJS.M(null,"suppress-namespaces","suppress-namespaces",2130686956);$CLJS.yu=new $CLJS.M(null,"cur","cur",1153190599);var nw=function nw(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return nw.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)},Av,mv,Sx,Tx,Ux,hw,gw,Dv,yv,Vx;nw.l=function(a){return $CLJS.ac($CLJS.pu,$CLJS.Te.g($CLJS.sw,a))};nw.A=0;nw.B=function(a){return this.l($CLJS.y(a))};var ou=function ou(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return ou.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)};
ou.l=function(a){return $CLJS.ac($CLJS.pu,$CLJS.Te.g($CLJS.Nh,a))};ou.A=0;ou.B=function(a){return this.l($CLJS.y(a))};$CLJS.g=Au.prototype;$CLJS.g.P=function(a,b){return new Au(this.Ca,this.Nf,this.Yc,b)};$CLJS.g.O=function(){return this.di};$CLJS.g.Qb=function(){return this.Yc};$CLJS.g.rc=function(){return eu(this.Ca)};
$CLJS.g.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return a=b.lastIndexOf("\n"),0>a?xu(this,$CLJS.yu,$CLJS.wu(this,$CLJS.yu)+$CLJS.D(b)):(xu(this,$CLJS.yu,$CLJS.D(b)-a-1),xu(this,$CLJS.sj,$CLJS.wu(this,$CLJS.sj)+$CLJS.D($CLJS.mf(function(c){return $CLJS.E.g(c,"\n")},b)))),$CLJS.ac($CLJS.wu(this,zu),b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return Sba(this,b);throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));
};$CLJS.g=Bu.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "parent":return this.parent;case "section":return this.Cb;case "start-col":return this.tb;case "indent":return this.sb;case "done-nl":return this.vb;case "intra-block-nl":return this.wb;case "prefix":return this.prefix;case "per-line-prefix":return this.Ab;case "suffix":return this.Db;case "logical-block-callback":return this.xb;default:return $CLJS.J.j(this.G,b,c)}};
$CLJS.g.Fb=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.th(b,function(d){return $CLJS.th(b,$CLJS.zh,""," ","",c,d)},"#cljs.pprint.logical-block{",", ","}",c,$CLJS.hf.g(new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Cu,this.parent],null),new $CLJS.P(null,2,5,$CLJS.Q,[Px,this.Cb],null),new $CLJS.P(null,2,5,$CLJS.Q,[Uu,this.tb],null),new $CLJS.P(null,2,5,$CLJS.Q,[Yu,this.sb],null),new $CLJS.P(null,2,5,$CLJS.Q,[Ru,this.vb],null),new $CLJS.P(null,2,5,$CLJS.Q,[Zu,this.wb],null),new $CLJS.P(null,2,5,$CLJS.Q,
[Ix,this.prefix],null),new $CLJS.P(null,2,5,$CLJS.Q,[Xu,this.Ab],null),new $CLJS.P(null,2,5,$CLJS.Q,[tv,this.Db],null),new $CLJS.P(null,2,5,$CLJS.Q,[pv,this.xb],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.rt(this,10,new $CLJS.P(null,10,5,$CLJS.Q,[Cu,Px,Uu,Yu,Ru,Zu,Ix,Xu,tv,pv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.Ie())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 10+$CLJS.D(this.G)};
$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1977012399^$CLJS.Qc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.parent,b.parent)&&$CLJS.E.g(this.Cb,b.Cb)&&$CLJS.E.g(this.tb,b.tb)&&$CLJS.E.g(this.sb,b.sb)&&$CLJS.E.g(this.vb,b.vb)&&$CLJS.E.g(this.wb,b.wb)&&$CLJS.E.g(this.prefix,b.prefix)&&$CLJS.E.g(this.Ab,b.Ab)&&$CLJS.E.g(this.Db,b.Db)&&$CLJS.E.g(this.xb,b.xb)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.bh(null,new $CLJS.h(null,10,[tv,null,Yu,null,Cu,null,Px,null,Ru,null,Uu,null,Ix,null,Xu,null,pv,null,Zu,null],null),null),b)?$CLJS.Ok.g($CLJS.Ob($CLJS.fg.g($CLJS.N,this),this.S),b):new Bu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,$CLJS.Ge($CLJS.Ok.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "parent":case "section":case "start-col":case "indent":case "done-nl":case "intra-block-nl":case "prefix":case "per-line-prefix":case "suffix":case "logical-block-callback":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.ie.g?$CLJS.ie.g(Cu,b):$CLJS.ie.call(null,Cu,b))?new Bu(c,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(Px,b):$CLJS.ie.call(null,Px,b))?new Bu(this.parent,c,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(Uu,b):$CLJS.ie.call(null,Uu,b))?new Bu(this.parent,this.Cb,c,this.sb,this.vb,this.wb,this.prefix,this.Ab,
this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(Yu,b):$CLJS.ie.call(null,Yu,b))?new Bu(this.parent,this.Cb,this.tb,c,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(Ru,b):$CLJS.ie.call(null,Ru,b))?new Bu(this.parent,this.Cb,this.tb,this.sb,c,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(Zu,b):$CLJS.ie.call(null,Zu,b))?new Bu(this.parent,this.Cb,this.tb,this.sb,this.vb,c,this.prefix,
this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(Ix,b):$CLJS.ie.call(null,Ix,b))?new Bu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,c,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(Xu,b):$CLJS.ie.call(null,Xu,b))?new Bu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,c,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(tv,b):$CLJS.ie.call(null,tv,b))?new Bu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,
this.prefix,this.Ab,c,this.xb,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(pv,b):$CLJS.ie.call(null,pv,b))?new Bu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,c,this.S,this.G,null):new Bu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,$CLJS.R.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.y($CLJS.hf.g(new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.Zf(Cu,this.parent),new $CLJS.Zf(Px,this.Cb),new $CLJS.Zf(Uu,this.tb),new $CLJS.Zf(Yu,this.sb),new $CLJS.Zf(Ru,this.vb),new $CLJS.Zf(Zu,this.wb),new $CLJS.Zf(Ix,this.prefix),new $CLJS.Zf(Xu,this.Ab),new $CLJS.Zf(tv,this.Db),new $CLJS.Zf(pv,this.xb)],null),this.G))};$CLJS.g.P=function(a,b){return new Bu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,b,this.G,this.J)};
$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.eb($CLJS.pb,this,b)};$CLJS.g=Eu.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "data":return this.data;case "trailing-white-space":return this.ac;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};
$CLJS.g.Fb=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.th(b,function(d){return $CLJS.th(b,$CLJS.zh,""," ","",c,d)},"#cljs.pprint.buffer-blob{",", ","}",c,$CLJS.hf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Ju,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,this.data],null),new $CLJS.P(null,2,5,$CLJS.Q,[Lu,this.ac],null),new $CLJS.P(null,2,5,$CLJS.Q,[Pu,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[Ou,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.rt(this,5,new $CLJS.P(null,5,5,$CLJS.Q,[Ju,$CLJS.wj,Lu,Pu,Ou],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.Ie())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 5+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1809113693^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.data,b.data)&&$CLJS.E.g(this.ac,b.ac)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.bh(null,new $CLJS.h(null,5,[Ou,null,Lu,null,Ju,null,Pu,null,$CLJS.wj,null],null),null),b)?$CLJS.Ok.g($CLJS.Ob($CLJS.fg.g($CLJS.N,this),this.S),b):new Eu(this.ca,this.data,this.ac,this.ba,this.aa,this.S,$CLJS.Ge($CLJS.Ok.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "data":case "trailing-white-space":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.ie.g?$CLJS.ie.g(Ju,b):$CLJS.ie.call(null,Ju,b))?new Eu(c,this.data,this.ac,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g($CLJS.wj,b):$CLJS.ie.call(null,$CLJS.wj,b))?new Eu(this.ca,c,this.ac,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(Lu,b):$CLJS.ie.call(null,Lu,b))?new Eu(this.ca,this.data,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(Pu,b):$CLJS.ie.call(null,Pu,b))?new Eu(this.ca,this.data,
this.ac,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(Ou,b):$CLJS.ie.call(null,Ou,b))?new Eu(this.ca,this.data,this.ac,this.ba,c,this.S,this.G,null):new Eu(this.ca,this.data,this.ac,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.hf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.Zf(Ju,this.ca),new $CLJS.Zf($CLJS.wj,this.data),new $CLJS.Zf(Lu,this.ac),new $CLJS.Zf(Pu,this.ba),new $CLJS.Zf(Ou,this.aa)],null),this.G))};
$CLJS.g.P=function(a,b){return new Eu(this.ca,this.data,this.ac,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.eb($CLJS.pb,this,b)};$CLJS.g=Fu.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "type":return this.type;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.th(b,function(d){return $CLJS.th(b,$CLJS.zh,""," ","",c,d)},"#cljs.pprint.nl-t{",", ","}",c,$CLJS.hf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Ju,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,this.type],null),new $CLJS.P(null,2,5,$CLJS.Q,[Wu,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[Pu,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[Ou,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.rt(this,5,new $CLJS.P(null,5,5,$CLJS.Q,[Ju,$CLJS.rj,Wu,Pu,Ou],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.Ie())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 5+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-1640656800^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.type,b.type)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.bh(null,new $CLJS.h(null,5,[Ou,null,$CLJS.rj,null,Wu,null,Ju,null,Pu,null],null),null),b)?$CLJS.Ok.g($CLJS.Ob($CLJS.fg.g($CLJS.N,this),this.S),b):new Fu(this.ca,this.type,this.ea,this.ba,this.aa,this.S,$CLJS.Ge($CLJS.Ok.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "type":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.ie.g?$CLJS.ie.g(Ju,b):$CLJS.ie.call(null,Ju,b))?new Fu(c,this.type,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g($CLJS.rj,b):$CLJS.ie.call(null,$CLJS.rj,b))?new Fu(this.ca,c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(Wu,b):$CLJS.ie.call(null,Wu,b))?new Fu(this.ca,this.type,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(Pu,b):$CLJS.ie.call(null,Pu,b))?new Fu(this.ca,this.type,
this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(Ou,b):$CLJS.ie.call(null,Ou,b))?new Fu(this.ca,this.type,this.ea,this.ba,c,this.S,this.G,null):new Fu(this.ca,this.type,this.ea,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.hf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.Zf(Ju,this.ca),new $CLJS.Zf($CLJS.rj,this.type),new $CLJS.Zf(Wu,this.ea),new $CLJS.Zf(Pu,this.ba),new $CLJS.Zf(Ou,this.aa)],null),this.G))};
$CLJS.g.P=function(a,b){return new Fu(this.ca,this.type,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.eb($CLJS.pb,this,b)};$CLJS.g=Gu.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.th(b,function(d){return $CLJS.th(b,$CLJS.zh,""," ","",c,d)},"#cljs.pprint.start-block-t{",", ","}",c,$CLJS.hf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Ju,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[Wu,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[Pu,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[Ou,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.rt(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[Ju,Wu,Pu,Ou],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.Ie())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-414877272^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.bh(null,new $CLJS.h(null,4,[Ou,null,Wu,null,Ju,null,Pu,null],null),null),b)?$CLJS.Ok.g($CLJS.Ob($CLJS.fg.g($CLJS.N,this),this.S),b):new Gu(this.ca,this.ea,this.ba,this.aa,this.S,$CLJS.Ge($CLJS.Ok.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.ie.g?$CLJS.ie.g(Ju,b):$CLJS.ie.call(null,Ju,b))?new Gu(c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(Wu,b):$CLJS.ie.call(null,Wu,b))?new Gu(this.ca,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(Pu,b):$CLJS.ie.call(null,Pu,b))?new Gu(this.ca,this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(Ou,b):$CLJS.ie.call(null,Ou,b))?new Gu(this.ca,this.ea,this.ba,c,this.S,this.G,null):new Gu(this.ca,
this.ea,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.hf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.Zf(Ju,this.ca),new $CLJS.Zf(Wu,this.ea),new $CLJS.Zf(Pu,this.ba),new $CLJS.Zf(Ou,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new Gu(this.ca,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.eb($CLJS.pb,this,b)};$CLJS.g=Hu.prototype;
$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.th(b,function(d){return $CLJS.th(b,$CLJS.zh,""," ","",c,d)},"#cljs.pprint.end-block-t{",", ","}",c,$CLJS.hf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Ju,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[Wu,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[Pu,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[Ou,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.rt(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[Ju,Wu,Pu,Ou],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.Ie())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1365867980^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.bh(null,new $CLJS.h(null,4,[Ou,null,Wu,null,Ju,null,Pu,null],null),null),b)?$CLJS.Ok.g($CLJS.Ob($CLJS.fg.g($CLJS.N,this),this.S),b):new Hu(this.ca,this.ea,this.ba,this.aa,this.S,$CLJS.Ge($CLJS.Ok.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.ie.g?$CLJS.ie.g(Ju,b):$CLJS.ie.call(null,Ju,b))?new Hu(c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(Wu,b):$CLJS.ie.call(null,Wu,b))?new Hu(this.ca,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(Pu,b):$CLJS.ie.call(null,Pu,b))?new Hu(this.ca,this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(Ou,b):$CLJS.ie.call(null,Ou,b))?new Hu(this.ca,this.ea,this.ba,c,this.S,this.G,null):new Hu(this.ca,
this.ea,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.hf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.Zf(Ju,this.ca),new $CLJS.Zf(Wu,this.ea),new $CLJS.Zf(Pu,this.ba),new $CLJS.Zf(Ou,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new Hu(this.ca,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.eb($CLJS.pb,this,b)};$CLJS.g=Iu.prototype;
$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "relative-to":return this.Wb;case "offset":return this.offset;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.th(b,function(d){return $CLJS.th(b,$CLJS.zh,""," ","",c,d)},"#cljs.pprint.indent-t{",", ","}",c,$CLJS.hf.g(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Ju,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[Wu,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[Fx,this.Wb],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yt,this.offset],null),new $CLJS.P(null,2,5,$CLJS.Q,[Pu,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[Ou,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.rt(this,6,new $CLJS.P(null,6,5,$CLJS.Q,[Ju,Wu,Fx,$CLJS.yt,Pu,Ou],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.Ie())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 6+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-1602780238^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.Wb,b.Wb)&&$CLJS.E.g(this.offset,b.offset)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.bh(null,new $CLJS.h(null,6,[$CLJS.yt,null,Ou,null,Fx,null,Wu,null,Ju,null,Pu,null],null),null),b)?$CLJS.Ok.g($CLJS.Ob($CLJS.fg.g($CLJS.N,this),this.S),b):new Iu(this.ca,this.ea,this.Wb,this.offset,this.ba,this.aa,this.S,$CLJS.Ge($CLJS.Ok.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "relative-to":case "offset":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.ie.g?$CLJS.ie.g(Ju,b):$CLJS.ie.call(null,Ju,b))?new Iu(c,this.ea,this.Wb,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(Wu,b):$CLJS.ie.call(null,Wu,b))?new Iu(this.ca,c,this.Wb,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(Fx,b):$CLJS.ie.call(null,Fx,b))?new Iu(this.ca,this.ea,c,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g($CLJS.yt,b):$CLJS.ie.call(null,$CLJS.yt,
b))?new Iu(this.ca,this.ea,this.Wb,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(Pu,b):$CLJS.ie.call(null,Pu,b))?new Iu(this.ca,this.ea,this.Wb,this.offset,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(Ou,b):$CLJS.ie.call(null,Ou,b))?new Iu(this.ca,this.ea,this.Wb,this.offset,this.ba,c,this.S,this.G,null):new Iu(this.ca,this.ea,this.Wb,this.offset,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.y($CLJS.hf.g(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.Zf(Ju,this.ca),new $CLJS.Zf(Wu,this.ea),new $CLJS.Zf(Fx,this.Wb),new $CLJS.Zf($CLJS.yt,this.offset),new $CLJS.Zf(Pu,this.ba),new $CLJS.Zf(Ou,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new Iu(this.ca,this.ea,this.Wb,this.offset,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.eb($CLJS.pb,this,b)};
var Mu=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.fi($CLJS.Gh.g("cljs.pprint","write-token"),function(f,k){return Ju.h(k)},e,a,b,c,d)}();
Mu.m(null,rv,function(a,b){var c=pv.h($CLJS.q($CLJS.q(a)));$CLJS.n(c)&&(c.h?c.h($CLJS.qv):c.call(null,$CLJS.qv));b=Wu.h(b);c=Ix.h(b);$CLJS.n(c)&&$CLJS.ac(zu.h($CLJS.q($CLJS.q(a))),c);a=$CLJS.wu(zu.h($CLJS.q($CLJS.q(a))),$CLJS.yu);$CLJS.Ze(Uu.h(b),a);return $CLJS.Ze(Yu.h(b),a)});Mu.m(null,vv,function(a,b){var c=pv.h($CLJS.q($CLJS.q(a)));$CLJS.n(c)&&(c.h?c.h(uv):c.call(null,uv));b=tv.h(Wu.h(b));return $CLJS.n(b)?$CLJS.ac(zu.h($CLJS.q($CLJS.q(a))),b):null});
Mu.m(null,Mv,function(a,b){var c=Wu.h(b),d=Yu.h(c),e=$CLJS.yt.h(b);b=Fx.h(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(Kv,b):$CLJS.E.call(null,Kv,b)))a=$CLJS.q(Uu.h(c));else if($CLJS.n($CLJS.E.g?$CLJS.E.g($CLJS.Lv,b):$CLJS.E.call(null,$CLJS.Lv,b)))a=$CLJS.wu(zu.h($CLJS.q($CLJS.q(a))),$CLJS.yu);else throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));return $CLJS.Ze(d,e+a)});Mu.m(null,hv,function(a,b){return $CLJS.ac(zu.h($CLJS.q($CLJS.q(a))),$CLJS.wj.h(b))});
Mu.m(null,Ku,function(a,b){var c=$CLJS.E.g($CLJS.rj.h(b),Fv);c||(c=(c=!$CLJS.E.g($CLJS.rj.h(b),Hv))?$CLJS.q(Ru.h(Wu.h(b))):c);$CLJS.n(c)?$u.g?$u.g(a,b):$u.call(null,a,b):(b=Lu.h($CLJS.q($CLJS.q(a))),$CLJS.n(b)&&$CLJS.ac(zu.h($CLJS.q($CLJS.q(a))),b));return $CLJS.Ph.v($CLJS.q(a),$CLJS.R,Lu,null)});
var Rx=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.fi($CLJS.Gh.g("cljs.pprint","emit-nl?"),function(f){return $CLJS.rj.h(f)},e,a,b,c,d)}();Rx.m(null,Iv,function(a,b,c){a=Wu.h(a);return Su(b,a,c)});Rx.m(null,Gv,function(a,b,c){a=Wu.h(a);return Vu(b,a,c)});
Rx.m(null,Hv,function(a,b,c,d){a=Wu.h(a);var e=$CLJS.q(Zu.h(a));return $CLJS.n(e)?e:(d=!Qu(b,d))?d:Vu(b,a,c)});Rx.m(null,Fv,function(){return!0});
var av=function av(a,b){var d=Vba(b);b=$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null);$CLJS.n(b)&&Nu(a,b,!1);if($CLJS.n(e)){d=Tba(e);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var f=$CLJS.z(e),k=Rx.v(f,a,b,Uba(e));$CLJS.n(k)&&($u(a,f),e=$CLJS.B(e));Qu(a,e)?a=e:(e=av.g?av.g(a,b):av.call(null,a,b),$CLJS.E.g(e,b)?(Nu(a,b,!1),a=d):a=$CLJS.fg.g($CLJS.Df,$CLJS.hf.g(e,d)));return a}return null};$CLJS.g=kv.prototype;$CLJS.g.P=function(a,b){return new kv(this.Ca,this.Nf,this.ni,this.oh,this.Yc,b)};$CLJS.g.O=function(){return this.ei};
$CLJS.g.Qb=function(){return this.Yc};
$CLJS.g.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a))){var c=jv(this,b);b=c.replace(/\s+$/,"");a=c.substring(b.length);var d=$CLJS.Bi.h($CLJS.q($CLJS.q(this)));if($CLJS.E.g(d,iv))return dv(this),$CLJS.ac(zu.h($CLJS.q($CLJS.q(this))),b),$CLJS.Ph.v($CLJS.q(this),$CLJS.R,Lu,a);d=gv.h($CLJS.q($CLJS.q(this)));c=d+$CLJS.D(c);$CLJS.Ph.v($CLJS.q(this),$CLJS.R,gv,c);return cv(this,new Eu(hv,b,a,d,c,null,null,null))}if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,
a):$CLJS.E.call(null,Number,a)))return $CLJS.E.g($CLJS.Bi.h($CLJS.q($CLJS.q(this))),iv)?(dv(this),b=$CLJS.ac(zu.h($CLJS.q($CLJS.q(this))),b)):$CLJS.E.g(b,"\n")?b=jv(this,"\n"):(a=gv.h($CLJS.q($CLJS.q(this))),c=a+1,$CLJS.Ph.v($CLJS.q(this),$CLJS.R,gv,c),b=hu(b),b=cv(this,new Eu(hv,b,null,a,c,null,null,null))),b;throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};$CLJS.g.rc=function(){this.uf(null);return eu(zu.h($CLJS.q($CLJS.q(this))))};
$CLJS.g.uf=function(){return $CLJS.E.g($CLJS.Bi.h($CLJS.q($CLJS.q(this))),fv)?(Nu(this,$CLJS.wt.h($CLJS.q($CLJS.q(this))),!0),$CLJS.Ph.v($CLJS.q(this),$CLJS.R,$CLJS.wt,$CLJS.Df)):dv(this)};$CLJS.zv=!0;Av=null;$CLJS.lv=72;mv=40;Sx=null;Tx=null;Ux=null;hw=null;gw=10;Dv=0;yv=null;Vx=function Vx(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Vx.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
Vx.l=function(a,b){var c=$CLJS.nl.l($CLJS.H([new $CLJS.h(null,1,[Ox,!0],null),$CLJS.Te.g(Ex,b)]));b=gw;var d=Tx,e=$CLJS.Ma,f=$CLJS.rh,k=Sx,l=mv,m=Av,t=$CLJS.zv,u=hw,v=$CLJS.Ha,x=$CLJS.lv,A=Ux,C=zu.g(c,gw),G=rda.g(c,Tx),K=$CLJS.Hx.g(c,$CLJS.Ma),S=$CLJS.At.g(c,$CLJS.rh),V=mda.g(c,Sx),Y=Tu.g(c,mv),ha=$CLJS.Si.g(c,Av),ta=Qx.g(c,$CLJS.zv),Ta=cda.g(c,hw),Cb=$CLJS.Ga.g(c,$CLJS.Ha),Oa=kda.g(c,$CLJS.lv),Wa=vda.g(c,Ux);gw=C;Tx=G;$CLJS.Ma=K;$CLJS.rh=S;Sx=V;mv=Y;Av=ha;$CLJS.zv=ta;hw=Ta;$CLJS.Ha=Cb;$CLJS.lv=Oa;
Ux=Wa;try{var fb=new $CLJS.Ea,Va=$CLJS.Hd(c,Ox)?Ox.h(c):!0,nb=!0===Va||null==Va?new $CLJS.sc(fb):Va;if($CLJS.n($CLJS.zv)){var Ja=$CLJS.Ua($CLJS.xv(nb));c=$CLJS.pu;$CLJS.pu=Ja?$CLJS.ov(nb):nb;try{$CLJS.Bv(a),$CLJS.vu()}finally{$CLJS.pu=c}}else{Ja=$CLJS.pu;$CLJS.pu=nb;try{ou.call(null,a)}finally{$CLJS.pu=Ja}}!0===Va&&$CLJS.vh($CLJS.p.h(fb));return null==Va?$CLJS.p.h(fb):null}finally{Ux=A,$CLJS.lv=x,$CLJS.Ha=v,hw=u,$CLJS.zv=t,Av=m,mv=l,Sx=k,$CLJS.rh=f,$CLJS.Ma=e,Tx=d,gw=b}};Vx.A=1;
Vx.B=function(a){var b=$CLJS.z(a);a=$CLJS.B(a);return this.l(b,a)};var Tv=null;$CLJS.g=Vv.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "seq":return this.uc;case "rest":return this.pb;case "pos":return this.$b;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.th(b,function(d){return $CLJS.th(b,$CLJS.zh,""," ","",c,d)},"#cljs.pprint.arg-navigator{",", ","}",c,$CLJS.hf.g(new $CLJS.P(null,3,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Wv,this.uc],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ti,this.pb],null),new $CLJS.P(null,2,5,$CLJS.Q,[gv,this.$b],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.rt(this,3,new $CLJS.P(null,3,5,$CLJS.Q,[Wv,$CLJS.Ti,gv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.Ie())};
$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 3+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-402038447^$CLJS.Qc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.uc,b.uc)&&$CLJS.E.g(this.pb,b.pb)&&$CLJS.E.g(this.$b,b.$b)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.bh(null,new $CLJS.h(null,3,[gv,null,Wv,null,$CLJS.Ti,null],null),null),b)?$CLJS.Ok.g($CLJS.Ob($CLJS.fg.g($CLJS.N,this),this.S),b):new Vv(this.uc,this.pb,this.$b,this.S,$CLJS.Ge($CLJS.Ok.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "seq":case "rest":case "pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.ie.g?$CLJS.ie.g(Wv,b):$CLJS.ie.call(null,Wv,b))?new Vv(c,this.pb,this.$b,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g($CLJS.Ti,b):$CLJS.ie.call(null,$CLJS.Ti,b))?new Vv(this.uc,c,this.$b,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(gv,b):$CLJS.ie.call(null,gv,b))?new Vv(this.uc,this.pb,c,this.S,this.G,null):new Vv(this.uc,this.pb,this.$b,this.S,$CLJS.R.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.y($CLJS.hf.g(new $CLJS.P(null,3,5,$CLJS.Q,[new $CLJS.Zf(Wv,this.uc),new $CLJS.Zf($CLJS.Ti,this.pb),new $CLJS.Zf(gv,this.$b)],null),this.G))};$CLJS.g.P=function(a,b){return new Vv(this.uc,this.pb,this.$b,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.eb($CLJS.pb,this,b)};$CLJS.g=aw.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "func":return this.dc;case "def":return this.cc;case "params":return this.kc;case "offset":return this.offset;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.th(b,function(d){return $CLJS.th(b,$CLJS.zh,""," ","",c,d)},"#cljs.pprint.compiled-directive{",", ","}",c,$CLJS.hf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Gw,this.dc],null),new $CLJS.P(null,2,5,$CLJS.Q,[kx,this.cc],null),new $CLJS.P(null,2,5,$CLJS.Q,[Ew,this.kc],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yt,this.offset],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.rt(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[Gw,kx,Ew,$CLJS.yt],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.Ie())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-829256337^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.dc,b.dc)&&$CLJS.E.g(this.cc,b.cc)&&$CLJS.E.g(this.kc,b.kc)&&$CLJS.E.g(this.offset,b.offset)&&$CLJS.E.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.bh(null,new $CLJS.h(null,4,[$CLJS.yt,null,Gw,null,Ew,null,kx,null],null),null),b)?$CLJS.Ok.g($CLJS.Ob($CLJS.fg.g($CLJS.N,this),this.S),b):new aw(this.dc,this.cc,this.kc,this.offset,this.S,$CLJS.Ge($CLJS.Ok.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "func":case "def":case "params":case "offset":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.ie.g?$CLJS.ie.g(Gw,b):$CLJS.ie.call(null,Gw,b))?new aw(c,this.cc,this.kc,this.offset,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(kx,b):$CLJS.ie.call(null,kx,b))?new aw(this.dc,c,this.kc,this.offset,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g(Ew,b):$CLJS.ie.call(null,Ew,b))?new aw(this.dc,this.cc,c,this.offset,this.S,this.G,null):$CLJS.n($CLJS.ie.g?$CLJS.ie.g($CLJS.yt,b):$CLJS.ie.call(null,$CLJS.yt,b))?new aw(this.dc,this.cc,this.kc,c,this.S,
this.G,null):new aw(this.dc,this.cc,this.kc,this.offset,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.hf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.Zf(Gw,this.dc),new $CLJS.Zf(kx,this.cc),new $CLJS.Zf(Ew,this.kc),new $CLJS.Zf($CLJS.yt,this.offset)],null),this.G))};$CLJS.g.P=function(a,b){return new aw(this.dc,this.cc,this.kc,this.offset,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.eb($CLJS.pb,this,b)};
var $ba=new $CLJS.h(null,3,[2,"#b",8,"#o",16,"#x"],null),uw=new $CLJS.P(null,20,5,$CLJS.Q,"zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(" "),null),Wx=new $CLJS.P(null,20,5,$CLJS.Q,"zeroth first second third fourth fifth sixth seventh eighth ninth tenth eleventh twelfth thirteenth fourteenth fifteenth sixteenth seventeenth eighteenth nineteenth".split(" "),null),vw=new $CLJS.P(null,10,5,$CLJS.Q,"  twenty thirty forty fifty sixty seventy eighty ninety".split(" "),
null),wda=new $CLJS.P(null,10,5,$CLJS.Q,"  twentieth thirtieth fortieth fiftieth sixtieth seventieth eightieth ninetieth".split(" "),null),xw=new $CLJS.P(null,22,5,$CLJS.Q," thousand million billion trillion quadrillion quintillion sextillion septillion octillion nonillion decillion undecillion duodecillion tredecillion quattuordecillion quindecillion sexdecillion septendecillion octodecillion novemdecillion vigintillion".split(" "),null),xda=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,9,5,$CLJS.Q,
"I II III IIII V VI VII VIII VIIII".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"X XX XXX XXXX L LX LXX LXXX LXXXX".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"C CC CCC CCCC D DC DCC DCCC DCCCC".split(" "),null),new $CLJS.P(null,3,5,$CLJS.Q,["M","MM","MMM"],null)],null),yda=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,9,5,$CLJS.Q,"I II III IV V VI VII VIII IX".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"X XX XXX XL L LX LXX LXXX XC".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"C CC CCC CD D DC DCC DCCC CM".split(" "),
null),new $CLJS.P(null,3,5,$CLJS.Q,["M","MM","MMM"],null)],null),bca=new $CLJS.h(null,5,[8,"Backspace",9,"Tab",10,"Newline",13,"Return",32,"Space"],null);ax.prototype.P=function(a,b){return new ax(this.Ca,b)};ax.prototype.O=function(){return this.fi};ax.prototype.rc=function(){return eu(this.Ca)};
ax.prototype.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return $CLJS.ac(this.Ca,b.toLowerCase());if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return $CLJS.ac(this.Ca,hu(b).toLowerCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};bx.prototype.P=function(a,b){return new bx(this.Ca,b)};bx.prototype.O=function(){return this.gi};bx.prototype.rc=function(){return eu(this.Ca)};
bx.prototype.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return $CLJS.ac(this.Ca,b.toUpperCase());if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return $CLJS.ac(this.Ca,hu(b).toUpperCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};cx.prototype.P=function(a,b){return new cx(this.Ca,this.Jd,b)};cx.prototype.O=function(){return this.hi};cx.prototype.rc=function(){return eu(this.Ca)};
cx.prototype.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return $CLJS.ac(this.Ca,tca(b.toLowerCase(),$CLJS.q(this.Jd))),0<b.length?$CLJS.Ze(this.Jd,$CLJS.Aa($CLJS.F(b,$CLJS.D(b)-1))):null;if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return b=hu(b),a=$CLJS.n($CLJS.q(this.Jd))?b.toUpperCase():b,$CLJS.ac(this.Ca,a),$CLJS.Ze(this.Jd,$CLJS.Aa(b));throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};
dx.prototype.P=function(a,b){return new dx(this.Ca,this.vd,b)};dx.prototype.O=function(){return this.ii};dx.prototype.rc=function(){return eu(this.Ca)};
dx.prototype.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return b=b.toLowerCase(),$CLJS.Ua($CLJS.q(this.vd))?(a=RegExp("\\S","g").exec(b),a=$CLJS.n(a)?a.index:a,$CLJS.n(a)?($CLJS.ac(this.Ca,[b.substring(0,a),$CLJS.F(b,a).toUpperCase(),b.substring(a+1).toLowerCase()].join("")),$CLJS.Ze(this.vd,!0)):$CLJS.ac(this.Ca,b)):$CLJS.ac(this.Ca,b.toLowerCase());if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return b=hu(b),a=$CLJS.Ua($CLJS.q(this.vd)),
$CLJS.n(a?cu(b):a)?($CLJS.Ze(this.vd,!0),$CLJS.ac(this.Ca,b.toUpperCase())):$CLJS.ac(this.Ca,b.toLowerCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};
var Gca=$CLJS.Jg("ASDBOXRPCFEG$%\x26|~\nT*?()[;]{}\x3c\x3e^W_I".split(""),[new $CLJS.h(null,5,[fx,"A",Ew,new $CLJS.h(null,4,[kw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),lw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),jw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),mw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),ex,new $CLJS.bh(null,new $CLJS.h(null,3,[bw,null,cw,null,gx,null],null),null),jx,$CLJS.N,hx,function(){return function(a,b){return ow($CLJS.sw,a,b)}}],null),new $CLJS.h(null,
5,[fx,"S",Ew,new $CLJS.h(null,4,[kw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),lw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),jw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),mw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),ex,new $CLJS.bh(null,new $CLJS.h(null,3,[bw,null,cw,null,gx,null],null),null),jx,$CLJS.N,hx,function(){return function(a,b){return ow($CLJS.Nh,a,b)}}],null),new $CLJS.h(null,5,[fx,"D",Ew,new $CLJS.h(null,4,[kw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),mw,new $CLJS.P(null,
2,5,$CLJS.Q,[" ",String],null),rw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),qw,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),ex,new $CLJS.bh(null,new $CLJS.h(null,3,[bw,null,cw,null,gx,null],null),null),jx,$CLJS.N,hx,function(){return function(a,b){return tw(10,a,b)}}],null),new $CLJS.h(null,5,[fx,"B",Ew,new $CLJS.h(null,4,[kw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),mw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),rw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),qw,new $CLJS.P(null,
2,5,$CLJS.Q,[3,Number],null)],null),ex,new $CLJS.bh(null,new $CLJS.h(null,3,[bw,null,cw,null,gx,null],null),null),jx,$CLJS.N,hx,function(){return function(a,b){return tw(2,a,b)}}],null),new $CLJS.h(null,5,[fx,"O",Ew,new $CLJS.h(null,4,[kw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),mw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),rw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),qw,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),ex,new $CLJS.bh(null,new $CLJS.h(null,3,[bw,null,cw,null,gx,
null],null),null),jx,$CLJS.N,hx,function(){return function(a,b){return tw(8,a,b)}}],null),new $CLJS.h(null,5,[fx,"X",Ew,new $CLJS.h(null,4,[kw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),mw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),rw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),qw,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),ex,new $CLJS.bh(null,new $CLJS.h(null,3,[bw,null,cw,null,gx,null],null),null),jx,$CLJS.N,hx,function(){return function(a,b){return tw(16,a,b)}}],null),new $CLJS.h(null,
5,[fx,"R",Ew,new $CLJS.h(null,5,[zu,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),kw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),mw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),rw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),qw,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),ex,new $CLJS.bh(null,new $CLJS.h(null,3,[bw,null,cw,null,gx,null],null),null),jx,$CLJS.N,hx,function(a){return $CLJS.n($CLJS.z(zu.h(a)))?function(b,c){return tw(zu.h(b),b,c)}:$CLJS.n(function(){var b=cw.h(a);return $CLJS.n(b)?
bw.h(a):b}())?function(b,c){return zw(xda,c)}:$CLJS.n(cw.h(a))?function(b,c){return zw(yda,c)}:$CLJS.n(bw.h(a))?function(b,c){b=Xv(c);c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);if($CLJS.E.g(0,c))nw.l($CLJS.H(["zeroth"]));else{var d=pw(1E3,0>c?-c:c);if($CLJS.D(d)<=$CLJS.D(xw)){var e=$CLJS.jf.g(ww,$CLJS.nu(1,d));e=yw(e,1);var f=$CLJS.id(d);d=$CLJS.Wd(f,100);f=iu(f,100);var k=0<d?[$CLJS.p.h($CLJS.F(uw,d))," hundred"].join(""):null,l=$CLJS.p,m=l.h;if(0<f)if(20>f)var t=$CLJS.F(Wx,f);else{t=$CLJS.Wd(f,10);
var u=iu(f,10);t=0<t&&!(0<u)?$CLJS.F(wda,t):[$CLJS.p.h(0<t?$CLJS.F(vw,t):null),0<t&&0<u?"-":null,$CLJS.p.h(0<u?$CLJS.F(Wx,u):null)].join("")}else t=0<d?"th":null;d=[k,0<d&&0<f?" ":null,m.call(l,t)].join("");nw.l($CLJS.H([[0>c?"minus ":null,$CLJS.td(e)||$CLJS.td(d)?$CLJS.td(e)?d:[e,"th"].join(""):[e,", ",d].join("")].join("")]))}else tw(10,new $CLJS.h(null,5,[kw,0,mw," ",rw,",",qw,3,bw,!0],null),Pv(new $CLJS.P(null,1,5,$CLJS.Q,[c],null))),e=iu(c,100),c=11<e||19>e,e=iu(e,10),nw.l($CLJS.H([1===e&&c?
"st":2===e&&c?"nd":3===e&&c?"rd":"th"]))}return b}:function(b,c){c=Xv(c);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.E.g(0,b))nw.l($CLJS.H(["zero"]));else{var d=pw(1E3,0>b?-b:b);$CLJS.D(d)<=$CLJS.D(xw)?(d=$CLJS.jf.g(ww,d),d=yw(d,0),nw.l($CLJS.H([[0>b?"minus ":null,d].join("")]))):tw(10,new $CLJS.h(null,5,[kw,0,mw," ",rw,",",qw,3,bw,!0],null),Pv(new $CLJS.P(null,1,5,$CLJS.Q,[b],null)))}return c}}],null),new $CLJS.h(null,5,[fx,"P",Ew,$CLJS.N,ex,new $CLJS.bh(null,new $CLJS.h(null,3,[bw,null,cw,
null,gx,null],null),null),jx,$CLJS.N,hx,function(){return function(a,b){b=$CLJS.n(bw.h(a))?Zv(b,-1):b;a=$CLJS.n(cw.h(a))?new $CLJS.P(null,2,5,$CLJS.Q,["y","ies"],null):new $CLJS.P(null,2,5,$CLJS.Q,["","s"],null);var c=Xv(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);nw.l($CLJS.H([$CLJS.E.g(b,1)?$CLJS.z(a):$CLJS.hd(a)]));return c}}],null),new $CLJS.h(null,5,[fx,"C",Ew,new $CLJS.h(null,1,[Aw,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null)],null),ex,new $CLJS.bh(null,new $CLJS.h(null,3,[bw,null,cw,null,
gx,null],null),null),jx,$CLJS.N,hx,function(a){return $CLJS.n(bw.h(a))?cca:$CLJS.n(cw.h(a))?dca:eca}],null),new $CLJS.h(null,5,[fx,"F",Ew,new $CLJS.h(null,5,[Lw,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),Mw,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$CLJS.Nw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Ow,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null),mw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),ex,new $CLJS.bh(null,new $CLJS.h(null,1,[cw,null],null),null),jx,$CLJS.N,hx,
function(){return Pw}],null),new $CLJS.h(null,5,[fx,"E",Ew,new $CLJS.h(null,7,[Lw,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),Mw,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),Qw,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$CLJS.Nw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),Ow,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null),mw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Rw,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null)],null),ex,new $CLJS.bh(null,new $CLJS.h(null,1,[cw,null],
null),null),jx,$CLJS.N,hx,function(){return Sw}],null),new $CLJS.h(null,5,[fx,"G",Ew,new $CLJS.h(null,7,[Lw,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),Mw,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),Qw,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$CLJS.Nw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),Ow,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null),mw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Rw,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null)],null),ex,new $CLJS.bh(null,new $CLJS.h(null,
1,[cw,null],null),null),jx,$CLJS.N,hx,function(){return gca}],null),new $CLJS.h(null,5,[fx,"$",Ew,new $CLJS.h(null,4,[Mw,new $CLJS.P(null,2,5,$CLJS.Q,[2,Number],null),$CLJS.xk,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),Lw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),mw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),ex,new $CLJS.bh(null,new $CLJS.h(null,3,[bw,null,cw,null,gx,null],null),null),jx,$CLJS.N,hx,function(){return hca}],null),new $CLJS.h(null,5,[fx,"%",Ew,new $CLJS.h(null,1,[$CLJS.Jx,
new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),ex,$CLJS.ch,jx,$CLJS.N,hx,function(){return function(a,b){a=$CLJS.Jx.h(a);for(var c=0;;)if(c<a)qu(),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[fx,"\x26",Ew,new $CLJS.h(null,1,[$CLJS.Jx,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),ex,new $CLJS.bh(null,new $CLJS.h(null,1,[Qx,null],null),null),jx,$CLJS.N,hx,function(){return function(a,b){a=$CLJS.Jx.h(a);0<a&&((null!=$CLJS.pu?$CLJS.pu.C&32768||$CLJS.Bc===$CLJS.pu.ag||($CLJS.pu.C?
0:$CLJS.$a($CLJS.du,$CLJS.pu)):$CLJS.$a($CLJS.du,$CLJS.pu))?$CLJS.E.g(0,$CLJS.wu(zu.h($CLJS.q($CLJS.q($CLJS.pu))),$CLJS.yu))||qu():qu());--a;for(var c=0;;)if(c<a)qu(),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[fx,"|",Ew,new $CLJS.h(null,1,[$CLJS.Jx,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),ex,$CLJS.ch,jx,$CLJS.N,hx,function(){return function(a,b){a=$CLJS.Jx.h(a);for(var c=0;;)if(c<a)nw.l($CLJS.H(["\f"])),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[fx,"~",Ew,new $CLJS.h(null,
1,[$CLJS.xk,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),ex,$CLJS.ch,jx,$CLJS.N,hx,function(){return function(a,b){a=$CLJS.xk.h(a);nw.l($CLJS.H([$CLJS.Te.g($CLJS.p,$CLJS.ef(a,"~"))]));return b}}],null),new $CLJS.h(null,5,[fx,"\n",Ew,$CLJS.N,ex,new $CLJS.bh(null,new $CLJS.h(null,2,[bw,null,cw,null],null),null),jx,$CLJS.N,hx,function(){return function(a,b){$CLJS.n(cw.h(a))&&qu();return b}}],null),new $CLJS.h(null,5,[fx,"T",Ew,new $CLJS.h(null,2,[Gx,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),
lw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),ex,new $CLJS.bh(null,new $CLJS.h(null,2,[cw,null,Qx,null],null),null),jx,$CLJS.N,hx,function(a){return $CLJS.n(cw.h(a))?function(b,c){var d=Gx.h(b);b=lw.h(b);var e=d+$CLJS.wu(zu.h($CLJS.q($CLJS.q($CLJS.pu))),$CLJS.yu);e=0<b?iu(e,b):0;d+=$CLJS.E.g(0,e)?0:b-e;nw.l($CLJS.H([$CLJS.Te.g($CLJS.p,$CLJS.ef(d," "))]));return c}:function(b,c){var d=Gx.h(b);b=lw.h(b);var e=$CLJS.wu(zu.h($CLJS.q($CLJS.q($CLJS.pu))),$CLJS.yu);d=e<d?d-e:$CLJS.E.g(b,0)?0:b-
iu(e-d,b);nw.l($CLJS.H([$CLJS.Te.g($CLJS.p,$CLJS.ef(d," "))]));return c}}],null),new $CLJS.h(null,5,[fx,"*",Ew,new $CLJS.h(null,1,[$CLJS.xk,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),ex,new $CLJS.bh(null,new $CLJS.h(null,2,[bw,null,cw,null],null),null),jx,$CLJS.N,hx,function(){return function(a,b){var c=$CLJS.xk.h(a);return $CLJS.n(cw.h(a))?$v(b,c):Zv(b,$CLJS.n(bw.h(a))?-c:c)}}],null),new $CLJS.h(null,5,[fx,"?",Ew,$CLJS.N,ex,new $CLJS.bh(null,new $CLJS.h(null,1,[cw,null],null),null),jx,
$CLJS.N,hx,function(a){return $CLJS.n(cw.h(a))?function(b,c){var d=Yv(c);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return Hw(c,d,Fw.h(b))}:function(b,c){var d=Yv(c);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var e=Xv(d);d=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);d=Pv(d);Hw(c,d,Fw.h(b));return e}}],null),new $CLJS.h(null,5,[fx,"(",Ew,$CLJS.N,ex,new $CLJS.bh(null,new $CLJS.h(null,3,[bw,null,cw,null,gx,null],null),null),jx,new $CLJS.h(null,3,[$CLJS.mx,")",qx,null,$CLJS.xt,null],null),hx,function(a){var b=$CLJS.n(function(){var c=
cw.h(a);return $CLJS.n(c)?bw.h(a):c}())?sca:$CLJS.n(bw.h(a))?uca:$CLJS.n(cw.h(a))?vca:rca;return function(c,d){a:{var e=$CLJS.z($CLJS.Uw.h(c)),f=$CLJS.pu;$CLJS.pu=b.h?b.h($CLJS.pu):b.call(null,$CLJS.pu);try{var k=Hw(e,d,Fw.h(c));break a}finally{$CLJS.pu=f}k=void 0}return k}}],null),new $CLJS.h(null,5,[fx,")",Ew,$CLJS.N,ex,$CLJS.ch,jx,$CLJS.N,hx,function(){return null}],null),new $CLJS.h(null,5,[fx,"[",Ew,new $CLJS.h(null,1,[Tw,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),ex,new $CLJS.bh(null,
new $CLJS.h(null,2,[bw,null,cw,null],null),null),jx,new $CLJS.h(null,3,[$CLJS.mx,"]",qx,!0,$CLJS.xt,$CLJS.Nx],null),hx,function(a){return $CLJS.n(bw.h(a))?jca:$CLJS.n(cw.h(a))?kca:ica}],null),new $CLJS.h(null,5,[fx,";",Ew,new $CLJS.h(null,2,[Zw,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$w,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),ex,new $CLJS.bh(null,new $CLJS.h(null,1,[bw,null],null),null),jx,new $CLJS.h(null,1,[$CLJS.zt,!0],null),hx,function(){return null}],null),new $CLJS.h(null,
5,[fx,"]",Ew,$CLJS.N,ex,$CLJS.ch,jx,$CLJS.N,hx,function(){return null}],null),new $CLJS.h(null,5,[fx,"{",Ew,new $CLJS.h(null,1,[Vw,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),ex,new $CLJS.bh(null,new $CLJS.h(null,3,[bw,null,cw,null,gx,null],null),null),jx,new $CLJS.h(null,2,[$CLJS.mx,"}",qx,!1],null),hx,function(a){var b=cw.h(a);b=$CLJS.n(b)?bw.h(a):b;return $CLJS.n(b)?oca:$CLJS.n(bw.h(a))?mca:$CLJS.n(cw.h(a))?nca:lca}],null),new $CLJS.h(null,5,[fx,"}",Ew,$CLJS.N,ex,new $CLJS.bh(null,
new $CLJS.h(null,1,[bw,null],null),null),jx,$CLJS.N,hx,function(){return null}],null),new $CLJS.h(null,5,[fx,"\x3c",Ew,new $CLJS.h(null,4,[kw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),lw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),jw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),mw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),ex,new $CLJS.bh(null,new $CLJS.h(null,4,[bw,null,cw,null,gx,null,Qx,null],null),null),jx,new $CLJS.h(null,3,[$CLJS.mx,"\x3e",qx,!0,$CLJS.xt,px],null),hx,function(){return qca}],
null),new $CLJS.h(null,5,[fx,"\x3e",Ew,$CLJS.N,ex,new $CLJS.bh(null,new $CLJS.h(null,1,[bw,null],null),null),jx,$CLJS.N,hx,function(){return null}],null),new $CLJS.h(null,5,[fx,"^",Ew,new $CLJS.h(null,3,[Kx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),Mx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),Lx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),ex,new $CLJS.bh(null,new $CLJS.h(null,1,[bw,null],null),null),jx,$CLJS.N,hx,function(){return function(a,b){var c=Kx.h(a),d=Mx.h(a),e=
Lx.h(a),f=$CLJS.n(bw.h(a))?Cw:Bw;return $CLJS.n($CLJS.n(c)?$CLJS.n(d)?e:d:c)?c<=d&&d<=e?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b:$CLJS.n($CLJS.n(c)?d:c)?$CLJS.E.g(c,d)?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b:$CLJS.n(c)?$CLJS.E.g(c,0)?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b:($CLJS.n(bw.h(a))?$CLJS.td($CLJS.Ti.h(Fw.h(a))):$CLJS.td($CLJS.Ti.h(b)))?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b}}],null),new $CLJS.h(null,5,[fx,"W",Ew,$CLJS.N,ex,new $CLJS.bh(null,new $CLJS.h(null,4,[bw,null,cw,null,
gx,null,Qx,null],null),null),jx,$CLJS.N,hx,function(a){if($CLJS.n(function(){var c=cw.h(a);return $CLJS.n(c)?c:bw.h(a)}())){var b=$CLJS.hf.g($CLJS.n(cw.h(a))?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.At,null,$CLJS.Hx,null],null):$CLJS.Df,$CLJS.n(bw.h(a))?new $CLJS.P(null,2,5,$CLJS.Q,[Qx,!0],null):$CLJS.Df);return function(c,d){d=Xv(d);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.n($CLJS.Te.j(Vx,c,b))?new $CLJS.P(null,2,5,$CLJS.Q,[Bw,d],null):d}}return function(c,d){d=Xv(d);c=$CLJS.I(d,0,null);d=
$CLJS.I(d,1,null);return $CLJS.n($CLJS.Bv(c))?new $CLJS.P(null,2,5,$CLJS.Q,[Bw,d],null):d}}],null),new $CLJS.h(null,5,[fx,"_",Ew,$CLJS.N,ex,new $CLJS.bh(null,new $CLJS.h(null,3,[bw,null,cw,null,gx,null],null),null),jx,$CLJS.N,hx,function(){return xca}],null),new $CLJS.h(null,5,[fx,"I",Ew,new $CLJS.h(null,1,[$CLJS.xk,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null)],null),ex,new $CLJS.bh(null,new $CLJS.h(null,1,[bw,null],null),null),jx,$CLJS.N,hx,function(){return wca}],null)]),yca=/^([vV]|#|('.)|([+-]?\d+)|(?=,))/,
Eca=new $CLJS.bh(null,new $CLJS.h(null,2,[ew,null,dw,null],null),null),Bca=new $CLJS.h(null,2,[":",bw,"@",cw],null),Qv=function Qv(a){for(;;){if($CLJS.td(a))return!1;var c=Qx.h(ex.h(kx.h($CLJS.z(a))));$CLJS.n(c)||(c=$CLJS.Se(Qv,$CLJS.z($CLJS.Uw.h(Ew.h($CLJS.z(a))))),c=$CLJS.n(c)?c:$CLJS.Se(Qv,$CLJS.z($CLJS.xt.h(Ew.h($CLJS.z(a))))));if($CLJS.n(c))return!0;a=$CLJS.B(a)}},Ax=$CLJS.Qh(Ov),rx=new $CLJS.h(null,6,[$CLJS.Vj,"'",$CLJS.Zj,"#'",$CLJS.Eba,"@",$CLJS.Fba,"~",fda,"@",dda,"~"],null);
(function(){var a=Ax("~\x3c[~;~@{~w~^, ~:_~}~;]~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=Pv(d);return Rv(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()})();
var Xx=function(){var a=Ax("~\x3c#{~;~@{~w~^ ~:_~}~;}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=Pv(d);return Rv(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),Yx=new $CLJS.h(null,2,["core$future_call","Future","core$promise","Promise"],null),zda=function(){var a=Ax("~\x3c\x3c-(~;~@{~w~^ ~_~}~;)-\x3c~:\x3e");
return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=Pv(d);return Rv(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),Zx,Ada=$CLJS.Ye($CLJS.N),Bda=$CLJS.Ye($CLJS.N),Cda=$CLJS.Ye($CLJS.N),Dda=$CLJS.Ye($CLJS.N),Eda=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));
Zx=new $CLJS.fi($CLJS.Gh.g("cljs.pprint","simple-dispatch"),yx,Eda,Ada,Bda,Cda,Dda);ju(Zx,$CLJS.Hj,function(a){if($CLJS.Ua(sx(a)))if($CLJS.n(Ev()))$CLJS.ac($CLJS.pu,"#");else{var b=Dv,c=yv;Dv+=1;yv=0;try{sv("(",")");for(var d=0,e=$CLJS.y(a);;){if($CLJS.Ua($CLJS.Ma)||d<$CLJS.Ma){if(e&&($CLJS.Bv($CLJS.z(e)),$CLJS.B(e))){$CLJS.ac($CLJS.pu," ");Jv(Iv);a=d+1;var f=$CLJS.B(e);d=a;e=f;continue}}else $CLJS.ac($CLJS.pu,"...");break}wv()}finally{yv=c,Dv=b}}return null});ju(Zx,$CLJS.Pj,tx);ju(Zx,$CLJS.ok,ux);
ju(Zx,$CLJS.ej,Xx);ju(Zx,null,function(){return $CLJS.ac($CLJS.pu,$CLJS.Nh.l($CLJS.H([null])))});ju(Zx,$CLJS.ei,vx);Av=Zx;
var $x=function(){var a=Ax("~:\x3c~w~^ ~@_~w~^ ~_~@{~w~^ ~_~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=Pv(d);return Rv(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),ay=function(){var a=Ax("~:\x3c~1I~w~^ ~@_~w~@{ ~_~w~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=
Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=Pv(d);return Rv(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),by=$CLJS.N,Fda=function(a){return $CLJS.fg.g($CLJS.N,$CLJS.kf($CLJS.Td,$CLJS.H([function(){return function d(c){return new $CLJS.oe(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.re(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=new $CLJS.P(null,
2,5,$CLJS.Q,[t,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gh.h($CLJS.hh($CLJS.z(t))),$CLJS.hd(t)],null)],null);l.add(t);m+=1}else{f=!0;break a}return f?$CLJS.ue($CLJS.we(l),d($CLJS.mc(e))):$CLJS.ue($CLJS.we(l),null)}l=$CLJS.z(e);return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gh.h($CLJS.hh($CLJS.z(l))),$CLJS.hd(l)],null)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}()])))}(function(a){return $CLJS.fg.g($CLJS.N,$CLJS.jf.g(function(b){var c=$CLJS.I(b,0,null),d=$CLJS.I(b,
1,null);var e=$CLJS.je(c);e=$CLJS.n(e)?e:$CLJS.Hd(new $CLJS.bh(null,new $CLJS.h(null,24,[$CLJS.fj,"null",$CLJS.vj,"null",$CLJS.yk,"null",$CLJS.Ak,"null",$CLJS.qj,"null",$CLJS.Rj,"null",$CLJS.Nj,"null",$CLJS.Sj,"null",$CLJS.si,"null",$CLJS.ck,"null",$CLJS.Gj,"null",$CLJS.yj,"null",$CLJS.kk,"null",$CLJS.zk,"null",$CLJS.Wi,"null",$CLJS.Ri,"null",$CLJS.oj,"null",$CLJS.Aj,"null",$CLJS.gj,"null",$CLJS.Zj,"null",$CLJS.Vj,"null",$CLJS.rk,"null",$CLJS.Ni,"null",$CLJS.nk,"null"],null),null),c);return $CLJS.Ua(e)?
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gh.g("clojure.core",$CLJS.hh(c)),d],null):b},a))}($CLJS.Jg([$CLJS.gj,$CLJS.Ri,Wca,$CLJS.ck,ada,Tca,Vca,bda,Zca,Yca,$ca,nda,hda,$CLJS.nk,gda,lda,jda,tda,Rca,$CLJS.yj,oda,pda,sda,$CLJS.Wj,eda,uda,Sca,Uca,Xca,qda],[$x,function(a){var b=$CLJS.hd(a),c=$CLJS.z($CLJS.Lc($CLJS.Lc(a)));if($CLJS.zd(b)){a=by;by=$CLJS.E.g(1,$CLJS.D(b))?$CLJS.Fe([$CLJS.z(b),"%"]):$CLJS.fg.g($CLJS.N,$CLJS.jf.j(function(d,e){return new $CLJS.P(null,2,5,$CLJS.Q,[d,["%",$CLJS.p.h(e)].join("")],
null)},b,$CLJS.lu(1,$CLJS.D(b)+1)));try{return function(){var d=Ax("~\x3c#(~;~@{~w~^ ~_~}~;)~:\x3e");return function(){function e(k){var l=null;if(0<arguments.length){l=0;for(var m=Array(arguments.length-0);l<m.length;)m[l]=arguments[l+0],++l;l=new $CLJS.w(m,0,null)}return f.call(this,l)}function f(k){k=Pv(k);return Rv(d,k)}e.A=0;e.B=function(k){k=$CLJS.y(k);return f(k)};e.l=f;return e}()}()(c)}finally{by=a}}else return Bx(a)},Dx,ay,function(a){if(3<$CLJS.D(a)){if($CLJS.n(Ev()))$CLJS.ac($CLJS.pu,
"#");else{var b=Dv,c=yv;Dv+=1;yv=0;try{sv("(",")");Nv(Kv,1);$CLJS.Te.g(function(){var l=Ax("~w ~@_~w ~@_~w ~_");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=Pv(u);return Rv(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};m.l=t;return m}()}(),a);for(var d=0,e=$CLJS.y($CLJS.cf(3,a));;){if($CLJS.Ua($CLJS.Ma)||d<$CLJS.Ma){if(e){if($CLJS.n(Ev()))$CLJS.ac($CLJS.pu,
"#");else{a=Dv;var f=yv;Dv+=1;yv=0;try{sv(null,null),$CLJS.Bv($CLJS.z(e)),$CLJS.B(e)&&($CLJS.ac($CLJS.pu," "),Jv(Gv),$CLJS.Bv($CLJS.hd(e))),wv()}finally{yv=f,Dv=a}}if($CLJS.B($CLJS.Lc(e))){$CLJS.ac($CLJS.pu," ");Jv(Iv);a=d+1;var k=$CLJS.B($CLJS.Lc(e));d=a;e=k;continue}}}else $CLJS.ac($CLJS.pu,"...");break}wv()}finally{yv=c,Dv=b}}return null}return Bx(a)},$x,Cx,Cx,Dx,$x,Dx,ay,ay,$x,ay,Dx,Dx,$x,Dx,function(a){if($CLJS.B(a)){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);var d=
"string"===typeof $CLJS.z(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.z(c),$CLJS.B(c)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,c],null);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=$CLJS.xd($CLJS.z(d))?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.z(d),$CLJS.B(d)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);if($CLJS.n(Ev()))$CLJS.ac($CLJS.pu,"#");else{d=Dv;var k=yv;Dv+=1;yv=0;try{sv("(",")");(function(){var m=Ax("~w ~1I~@_~w");return function(){function t(v){var x=
null;if(0<arguments.length){x=0;for(var A=Array(arguments.length-0);x<A.length;)A[x]=arguments[x+0],++x;x=new $CLJS.w(A,0,null)}return u.call(this,x)}function u(v){v=Pv(v);return Rv(m,v)}t.A=0;t.B=function(v){v=$CLJS.y(v);return u(v)};t.l=u;return t}()})()(a,b);$CLJS.n($CLJS.n(c)?c:$CLJS.n(e)?e:$CLJS.y(f))&&function(){var m=Ax("~@:_");return function(){function t(v){var x=null;if(0<arguments.length){x=0;for(var A=Array(arguments.length-0);x<A.length;)A[x]=arguments[x+0],++x;x=new $CLJS.w(A,0,null)}return u.call(this,
x)}function u(v){v=Pv(v);return Rv(m,v)}t.A=0;t.B=function(v){v=$CLJS.y(v);return u(v)};t.l=u;return t}()}()();$CLJS.n(c)&&Sv(!0,'"~a"~:[~;~:@_~]',$CLJS.H([c,$CLJS.n(e)?e:$CLJS.y(f)]));$CLJS.n(e)&&function(){var m=Ax("~w~:[~;~:@_~]");return function(){function t(v){var x=null;if(0<arguments.length){x=0;for(var A=Array(arguments.length-0);x<A.length;)A[x]=arguments[x+0],++x;x=new $CLJS.w(A,0,null)}return u.call(this,x)}function u(v){v=Pv(v);return Rv(m,v)}t.A=0;t.B=function(v){v=$CLJS.y(v);return u(v)};
t.l=u;return t}()}()(e,$CLJS.y(f));for(a=f;;){Nca($CLJS.z(a));var l=$CLJS.B(a);if(l)b=l,Jv(Iv),a=b;else break}wv()}finally{yv=k,Dv=d}}return null}return $CLJS.Bv(a)},Dx,function(a){if($CLJS.n(Ev()))$CLJS.ac($CLJS.pu,"#");else{var b=Dv,c=yv;Dv+=1;yv=0;try{sv("(",")");Nv(Kv,1);$CLJS.Bv($CLJS.z(a));if($CLJS.B(a)){$CLJS.ac($CLJS.pu," ");Jv(Iv);for(var d=0,e=$CLJS.B(a);;){if($CLJS.Ua($CLJS.Ma)||d<$CLJS.Ma){if(e){if($CLJS.n(Ev()))$CLJS.ac($CLJS.pu,"#");else{a=Dv;var f=yv;Dv+=1;yv=0;try{sv(null,null),$CLJS.Bv($CLJS.z(e)),
$CLJS.B(e)&&($CLJS.ac($CLJS.pu," "),Jv(Gv),$CLJS.Bv($CLJS.hd(e))),wv()}finally{yv=f,Dv=a}}if($CLJS.B($CLJS.Lc(e))){$CLJS.ac($CLJS.pu," ");Jv(Iv);a=d+1;var k=$CLJS.B($CLJS.Lc(e));d=a;e=k;continue}}}else $CLJS.ac($CLJS.pu,"...");break}}wv()}finally{yv=c,Dv=b}}return null},Dx,Cx,Cx,$x,$x,Dx,Dx,$x]))),cy,Gda=$CLJS.Ye($CLJS.N),Hda=$CLJS.Ye($CLJS.N),Ida=$CLJS.Ye($CLJS.N),Jda=$CLJS.Ye($CLJS.N),Kda=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));
cy=new $CLJS.fi($CLJS.Gh.g("cljs.pprint","code-dispatch"),yx,Kda,Gda,Hda,Ida,Jda);ju(cy,$CLJS.Hj,function(a){if($CLJS.Ua(sx(a))){var b=Fda.call(null,$CLJS.z(a));return $CLJS.n(b)?b.h?b.h(a):b.call(null,a):Bx(a)}return null});ju(cy,$CLJS.Ki,function(a){var b=a.h?a.h(by):a.call(null,by);return $CLJS.n(b)?nw.l($CLJS.H([b])):$CLJS.n(Ux)?nw.l($CLJS.H([$CLJS.hh(a)])):ou.call(null,a)});ju(cy,$CLJS.Pj,tx);ju(cy,$CLJS.ok,ux);ju(cy,$CLJS.ej,Xx);ju(cy,wx,zda);
ju(cy,xx,function(a){var b=$CLJS.p,c=b.h,d=$CLJS.ab(a).name;var e=$CLJS.ph(/^[^$]+\$[^$]+/,d);e=$CLJS.n(e)?Yx.h?Yx.h(e):Yx.call(null,e):null;b=["#\x3c",c.call(b,$CLJS.n(e)?e:d),"@",$CLJS.p.h($CLJS.ya(a)),": "].join("");if($CLJS.n(Ev()))$CLJS.ac($CLJS.pu,"#");else{c=Dv;d=yv;Dv+=1;yv=0;try{sv(b,"\x3e");Nv(Kv,-(b.length-2));Jv(Iv);var f=null!=a?a.I&1||$CLJS.Bc===a.kj?!0:a.I?!1:$CLJS.$a(fu,a):$CLJS.$a(fu,a);var k=f?!gu(a):f;$CLJS.Bv(k?ida:$CLJS.q(a));wv()}finally{yv=d,Dv=c}}return null});ju(cy,null,ou);
ju(cy,$CLJS.ei,vx);Av=Zx;