var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.mbql.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var cW,mla,nla,kW,ola,pla,rla,gW,sla,qla;cW=function(a){return a+1};$CLJS.dW=function(a){if("string"===typeof a){var b=$CLJS.oh(/[+-]?\d+/,a);return $CLJS.n(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.qm(a));};
mla=function(a){var b=new $CLJS.Ea,c=$CLJS.pu;$CLJS.pu=new $CLJS.sc(b);try{var d=$CLJS.pu,e=$CLJS.Ua($CLJS.xv(d)),f=$CLJS.pu;$CLJS.pu=e?$CLJS.ov(d):d;try{d=$CLJS.zv;$CLJS.zv=!0;try{$CLJS.Bv(a)}finally{$CLJS.zv=d}$CLJS.E.g(0,$CLJS.wu($CLJS.pu,$CLJS.yu))||$CLJS.ac($CLJS.pu,"\n");$CLJS.vu()}finally{$CLJS.pu=f}$CLJS.vh($CLJS.p.h(b))}finally{$CLJS.pu=c}};$CLJS.eW=function(a,b){return $CLJS.Te.j($CLJS.fa,a,b)};
$CLJS.fW=function(a){var b=$CLJS.lv;$CLJS.lv=120;try{var c=new $CLJS.Ea,d=$CLJS.Pa,e=$CLJS.Qa;$CLJS.Pa=!0;$CLJS.Qa=function(f){return c.append(f)};try{mla(a)}finally{$CLJS.Qa=e,$CLJS.Pa=d}return $CLJS.p.h(c)}finally{$CLJS.lv=b}};
nla=function(a){a=$CLJS.O(a);var b=$CLJS.J.j(a,$CLJS.oV,$CLJS.Td),c=$CLJS.J.j(a,$CLJS.lV,function(f,k){return[$CLJS.p.h(f),"_",$CLJS.p.h(k)].join("")}),d=$CLJS.Ye($CLJS.N),e=$CLJS.Ye($CLJS.N);return function(){function f(m,t){for(;;){var u=b.h?b.h(t):b.call(null,t),v=function(){var A=new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),C=$CLJS.q(d);return C.h?C.h(A):C.call(null,A)}();if($CLJS.n(v))return v;var x=$CLJS.J.g($CLJS.Ph.v(e,$CLJS.Zk,u,$CLJS.ND(cW,0)),u);if($CLJS.E.g(x,1))return $CLJS.Ph.v(d,$CLJS.R,
new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),t),t;t=function(){var A=t,C=$CLJS.p.h(x);return c.g?c.g(A,C):c.call(null,A,C)}()}}function k(m){var t=l,u=t.g;null==gW&&(gW=$CLJS.Ye(0));var v=$CLJS.Gh.h([$CLJS.p.h("G__"),$CLJS.p.h($CLJS.Ph.g(gW,cW))].join(""));return u.call(t,v,m)}var l=null;l=function(m,t){switch(arguments.length){case 1:return k.call(this,m);case 2:return f.call(this,m,t)}throw Error("Invalid arity: "+arguments.length);};l.h=k;l.g=f;return l}()};
$CLJS.hW=function(a){var b=$CLJS.zd(a);return b?(b=$CLJS.z(a)instanceof $CLJS.M)?(a=$CLJS.J.g(a,1),$CLJS.xd(a)&&$CLJS.Hd(a,$CLJS.sE)):b:b};$CLJS.iW=function(a){return $CLJS.hW(a)?$CLJS.kE.h($CLJS.zE(a)):null};$CLJS.jW=function(a,b){return $CLJS.AE($CLJS.hW(a)?a:new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ij,new $CLJS.h(null,2,[$CLJS.sE,$CLJS.p.h($CLJS.CE()),$CLJS.Ii,$CLJS.GE(a)],null),a],null),$CLJS.R,$CLJS.H([$CLJS.kE,b]))};
kW=function(a,b,c){var d=$CLJS.Hd(a,b)?$CLJS.Zk.j(a,b,function(e){return $CLJS.zd(e)&&$CLJS.E.g($CLJS.z(e),$CLJS.rs)?$CLJS.Ef($CLJS.cf(1,e)):new $CLJS.P(null,1,5,$CLJS.Q,[e],null)}):a;return $CLJS.Hd(a,b)?$CLJS.jV(d,$CLJS.Fe([b,c])):d};ola=function(a){var b=$CLJS.lm(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PO,$CLJS.rQ],null));b=lW.h?lW.h(b):lW.call(null,b);return $CLJS.$V($CLJS.R.l(kW($CLJS.Ok.l(a,$CLJS.PO,$CLJS.H([$CLJS.rQ])),$CLJS.AR,$CLJS.hL),$CLJS.Qt,$CLJS.$K,$CLJS.H([$CLJS.eL,b])))};
pla=function(a){return $CLJS.Yk.g(ola,a)};rla=function(a){return $CLJS.n(a)?$CLJS.R.j($CLJS.Zk.j($CLJS.Tl(a)?new $CLJS.h(null,1,[$CLJS.mW,a],null):a,$CLJS.mW,function(b){return $CLJS.Yk.g(function(c){return $CLJS.R.j($CLJS.vA(c,$CLJS.WA),$CLJS.Qt,$CLJS.DK)},b)}),$CLJS.Qt,qla):null};
$CLJS.nW=function(a){if($CLJS.E.g($CLJS.Qt.h(a),$CLJS.SV))return a;var b=$CLJS.rj.h(a);b=b instanceof $CLJS.M?b.T:null;switch(b){case "native":return $CLJS.nl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.Qt,$CLJS.SV,$CLJS.eL,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.nl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.Qt,$CLJS.MV],null),$CLJS.jV($CLJS.TP.h(a),new $CLJS.h(null,1,[$CLJS.yF,$CLJS.TP],null))]))],null)],null),$CLJS.Ok.l(a,$CLJS.rj,$CLJS.H([$CLJS.TP]))]));case "query":return $CLJS.nl.l($CLJS.H([new $CLJS.h(null,
2,[$CLJS.Qt,$CLJS.SV,$CLJS.eL,lW($CLJS.yF.h(a))],null),$CLJS.Ok.l(a,$CLJS.rj,$CLJS.H([$CLJS.yF]))]));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};$CLJS.oW=function(a,b){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.eL);var c=0>b?$CLJS.D(a)+b:b;if(c>=$CLJS.D(a)||0>c)throw $CLJS.ji($CLJS.bE("Stage {0} does not exist",$CLJS.H([b])),new $CLJS.h(null,1,[sla,$CLJS.D(a)],null));return c};
$CLJS.pW=function(a,b){a=$CLJS.nW(a);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.eL);return $CLJS.J.g($CLJS.Ef(c),$CLJS.oW(a,b))};$CLJS.qW=function(a){return"string"===typeof a&&(a=$CLJS.ph(/^card__(\d+)$/,a),$CLJS.n(a))?($CLJS.I(a,0,null),a=$CLJS.I(a,1,null),$CLJS.dW(a)):null};$CLJS.sW=function(){return $CLJS.Wk.g(rW,nla($CLJS.H([$CLJS.oV,$CLJS.SA,$CLJS.lV,function(a,b){return rW.h([$CLJS.p.h(a),"_",$CLJS.p.h(b)].join(""))}])))};gW=null;
$CLJS.tW=new $CLJS.M("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);$CLJS.uW=new $CLJS.M("metabase.lib.util","cannot-remove-final-join-condition","metabase.lib.util/cannot-remove-final-join-condition",-470911224);$CLJS.vW=new $CLJS.M(null,"join","join",-758861890);$CLJS.wW=new $CLJS.M(null,"stage","stage",1843544772);sla=new $CLJS.M(null,"num-stages","num-stages",1426797535);$CLJS.mW=new $CLJS.M(null,"columns","columns",1998437288);
$CLJS.xW=new $CLJS.M("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);$CLJS.yW=new $CLJS.M(null,"table","table",-564943036);qla=new $CLJS.M("metadata","results","metadata/results",330329298);$CLJS.zW=new $CLJS.M("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);var lW,rW;
lW=function lW(a){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.rQ),d=$CLJS.J.g(a,$CLJS.BO);c=$CLJS.n(c)?lW.h?lW.h(c):lW.call(null,c):$CLJS.Df;d=rla(d);var e=$CLJS.D(c)-1;c=$CLJS.n($CLJS.n(d)?!(0>e):d)?$CLJS.GL(c,new $CLJS.P(null,2,5,$CLJS.Q,[e,$CLJS.YU],null),d):c;d=$CLJS.n($CLJS.TP.h(a))?$CLJS.MV:$CLJS.FV;a=$CLJS.nl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.Qt,d],null),$CLJS.Ok.l(a,$CLJS.rQ,$CLJS.H([$CLJS.BO]))]));a=$CLJS.y($CLJS.nN.h(a))?$CLJS.Zk.j(a,$CLJS.nN,pla):a;a=kW(a,$CLJS.GF,$CLJS.TV);return $CLJS.ce.g(c,a)};
$CLJS.AW=function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e,f){c=$CLJS.nW(c);c=$CLJS.O(c);var k=$CLJS.J.g(c,$CLJS.eL);d=$CLJS.oW(c,d);e=$CLJS.Te.N($CLJS.Zk,$CLJS.Ef(k),d,e,f);return $CLJS.R.j(c,$CLJS.eL,e)}a.A=3;a.B=function(c){var d=$CLJS.z(c);c=$CLJS.B(c);var e=$CLJS.z(c);c=$CLJS.B(c);var f=$CLJS.z(c);c=$CLJS.Lc(c);return b(d,e,f,c)};a.l=
b;return a}();
rW=function(){function a(d,e){if((new TextEncoder).encode(d).length<=e)return d;var f;a:for(f=($CLJS.YV.str(d,0)>>>0).toString(16);;)if(8>$CLJS.D(f))f=["0",$CLJS.p.h(f)].join("");else break a;e=new Uint8Array(e-9);e=(new TextEncoder).encodeInto(d,e);return[d.substring(0,e.read),"_",$CLJS.p.h(f)].join("")}function b(d){return c.g?c.g(d,60):c.call(null,d,60)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);
};c.h=b;c.g=a;return c}();$CLJS.BW=RegExp(" id$","i");