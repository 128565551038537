import React from "react";
import { t, jt } from "ttag";
import {
  MetabaseLink,
  MetabaseName,
  Message,
  Variant,
} from "./LogoBadge.styled";
import logo from "./monopay-logo.png";

function LogoBadge({
  dark,
  variant = "default",
}: {
  dark: boolean;
  variant?: Variant;
}) {
  const logoSize = variant === "large" ? 42 : 28;
  const Metabase = (
    <MetabaseName key="metabase" isDark={dark} variant={variant}>
      {t`Monotech Group`}
    </MetabaseName>
  );
  return (
    <MetabaseLink
      href="https://monotech.group/"
      target="_blank"
      variant={variant}
    >
      <img
        src={logo}
        height={logoSize}
        data-testid="main-logo"
        alt="Monotech Group"
      />
      <Message
        variant={variant}
      >{jt`Powered by Integration Team@ ${Metabase}`}</Message>
    </MetabaseLink>
  );
}

export default LogoBadge;
