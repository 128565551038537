var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.expression.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var i1,j1;$CLJS.f1=function(a,b){return $CLJS.D(a)<=$CLJS.D(b)&&$CLJS.Re(function(c){return $CLJS.Hd(b,c)},a)};$CLJS.g1=function(a,b){return $CLJS.AA($CLJS.Ue(a),b)};$CLJS.h1=function(a){var b;a:for(b=a;;)if($CLJS.xd(b))b=$CLJS.dj.h(b);else{b=$CLJS.Fd(b)?b:null;break a}if($CLJS.n(b))return b;throw Error($CLJS.bE("Not something with an ID: {0}",$CLJS.H([$CLJS.Nh.l($CLJS.H([a]))])));};
i1=function(a){if(null!=a&&null!=a.sg)a=a.sg(a);else{var b=i1[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=i1._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("MetadataProvider.database",a);}return a};j1=function(a,b){if(null!=a&&null!=a.tg)a=a.tg(a,b);else{var c=j1[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=j1._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.field",a);}return a};$CLJS.k1=function(a){return i1($CLJS.G_(a))};
$CLJS.l1=function(a,b){return j1($CLJS.G_(a),b)};$CLJS.e1.m(null,$CLJS.SV,function(a){return $CLJS.d1(a,new $CLJS.h(null,2,[$CLJS.rj,$CLJS.Ah,$CLJS.eL,$CLJS.We($CLJS.Yk,$CLJS.e1)],null))});$CLJS.u0.m(null,$CLJS.SV,function(){throw $CLJS.ji("You can't calculate a metadata map for a query! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});$CLJS.w0.m(null,$CLJS.SV,function(a,b,c,d){return $CLJS.x0.v(a,b,$CLJS.pW(c,b),d)});$CLJS.o0.m(null,$CLJS.SV,function(a,b,c,d){return $CLJS.P_.v(a,b,$CLJS.pW(c,b),d)});
$CLJS.m1=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.fi($CLJS.Gh.g("metabase.lib.query","can-run-method"),function(f){return $CLJS.Qt.h($CLJS.pW(f,0))},e,a,b,c,d)}();$CLJS.m1.m(null,$CLJS.ei,function(){return!0});
$CLJS.n1=function(){function a(d,e,f){return new $CLJS.h(null,4,[$CLJS.Qt,$CLJS.SV,$CLJS.cE,$CLJS.G_(e),$CLJS.EP,d,$CLJS.eL,f],null)}function b(d,e){var f=$CLJS.dj.h($CLJS.k1(d));return c.j?c.j(f,d,e):c.call(null,f,d,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.o1=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.$B],null),$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.fi($CLJS.Gh.g("metabase.lib.query","query-method"),function(f,k){return $CLJS.YB(k)},e,a,b,c,d)}();$CLJS.o1.m(null,$CLJS.gB,function(a,b){b=$CLJS.IY.h(b);return $CLJS.n1.g(a,$CLJS.eL.h(b))});
$CLJS.o1.m(null,$CLJS.SV,function(a,b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.EY);var d=$CLJS.G_(a),e=$CLJS.Ok.g($CLJS.R.j(c,$CLJS.cE,d),$CLJS.EY);a=$CLJS.eL.h(e);return $CLJS.n(b)?$CLJS.R.j(e,$CLJS.eL,$CLJS.fg.j($CLJS.Df,$CLJS.jf.h(function(f){var k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return function u(m,t){try{if($CLJS.zd(t)&&3===$CLJS.D(t))try{var v=$CLJS.F(t,0);if($CLJS.ie(v,$CLJS.cG))try{var x=$CLJS.F(t,1);if($CLJS.n($CLJS.Ue($CLJS.tA($CLJS.kB,$CLJS.Ii))(x)))try{var A=$CLJS.F(t,2);if($CLJS.n($CLJS.$D($CLJS.Il,
$CLJS.am)(A))){var C=$CLJS.F(t,2),G=$CLJS.F(t,1),K=$CLJS.lm($CLJS.l1(d,C),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kB,$CLJS.Ii],null));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,$CLJS.nl.l($CLJS.H([K,G])),C],null)}throw $CLJS.Z;}catch(ha){if(ha instanceof Error){var S=ha;if(S===$CLJS.Z)throw $CLJS.Z;throw S;}throw ha;}else throw $CLJS.Z;}catch(ha){if(ha instanceof Error){S=ha;if(S===$CLJS.Z)throw $CLJS.Z;throw S;}throw ha;}else throw $CLJS.Z;}catch(ha){if(ha instanceof Error)if(S=ha,S===$CLJS.Z)try{if(v=
$CLJS.F(t,0),$CLJS.ie(v,$CLJS.hB))try{x=$CLJS.F(t,1);if($CLJS.n($CLJS.Ue($CLJS.tA($CLJS.kB,$CLJS.Ii))(x))){G=$CLJS.F(t,1);var V=$CLJS.F(t,2);K=function(){try{return $CLJS.g1(function(ta){return $CLJS.E.g($CLJS.Fj,ta)},$CLJS.lm($CLJS.hd($CLJS.koa.j(e,k,V)),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kB,$CLJS.Ii],null)))}catch(ta){return null}}();return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hB,$CLJS.nl.l($CLJS.H([K,G])),V],null)}throw $CLJS.Z;}catch(ta){if(ta instanceof Error){var Y=ta;if(Y===$CLJS.Z)throw $CLJS.Z;
throw Y;}throw ta;}else throw $CLJS.Z;}catch(ta){if(ta instanceof Error){Y=ta;if(Y===$CLJS.Z)throw $CLJS.Z;throw Y;}throw ta;}else throw S;else throw ha;}else throw $CLJS.Z;}catch(ha){if(ha instanceof Error){S=ha;if(S===$CLJS.Z)return $CLJS.JW(u,m,t);throw S;}throw ha;}}($CLJS.Df,f)}),$CLJS.km($CLJS.Bs,a))):e});$CLJS.o1.m(null,$CLJS.KK,function(a,b){return $CLJS.n1.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Qt,$CLJS.FV,$CLJS.PO,$CLJS.h1(b)],null)],null))});
$CLJS.o1.m(null,$CLJS.XK,function(a,b){return $CLJS.n1.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Qt,$CLJS.FV,$CLJS.yV,$CLJS.h1(b)],null)],null))});