var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var e7,g7,h7,Bra,Cra,Dra,k7,l7,n7,o7,Era,p7,Fra,Gra;e7=function(a){var b=new $CLJS.h(null,3,[$CLJS.sE,$CLJS.p.h($CLJS.CE()),$CLJS.kB,$CLJS.kB.h(a),$CLJS.Ii,$CLJS.tA($CLJS.Ii,$CLJS.kB)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hB,b,$CLJS.tA($CLJS.kE,$CLJS.T)(a)],null)};$CLJS.f7=function(a){return $CLJS.j5.h(a)};g7=function(a){return $CLJS.R.j(a,$CLJS.Qt,$CLJS.A4)};
h7=function(a,b){return $CLJS.E.g($CLJS.fE.h(a),$CLJS.lm(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wE,$CLJS.eE,$CLJS.vE],null)))};Bra=function(a){return $CLJS.d1(a,new $CLJS.h(null,1,[$CLJS.wE,$CLJS.Ah],null))};
Cra=function(a,b){var c=$CLJS.Se(function(e){return $CLJS.nH(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uZ,$CLJS.T],null));if($CLJS.n(c))return c;c=$CLJS.DA($CLJS.wy);if($CLJS.n($CLJS.CA("metabase.lib.field",c))){var d=$CLJS.bE("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.Nh.l($CLJS.H([a])),$CLJS.Nh.l($CLJS.H([$CLJS.Yk.g($CLJS.uZ,b)]))]));d instanceof Error?$CLJS.BA("metabase.lib.field",c,$CLJS.sw(),d):$CLJS.BA("metabase.lib.field",
c,$CLJS.sw.l($CLJS.H([d])),null)}return null};
Dra=function(a,b,c){if($CLJS.n(i7))return null;var d=i7;i7=!0;try{var e=$CLJS.S1(a,b),f=$CLJS.n(e)?$CLJS.pW(a,e):$CLJS.pW(a,b),k=function(){var m=$CLJS.j7.h(f);if($CLJS.n(m))return m;m=$CLJS.uA(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YU,$CLJS.mW],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.yV.h(f);if($CLJS.n(u))return u;u=$CLJS.PO.h(f);if($CLJS.n(u))return u;u=$CLJS.JP.h(f);return $CLJS.n(u)?u:$CLJS.LE.h(f)}())?$CLJS.z0.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.DA($CLJS.wy);if($CLJS.n($CLJS.CA("metabase.lib.field",
m))){var t=$CLJS.bE("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.Nh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.BA("metabase.lib.field",m,$CLJS.sw(),t):$CLJS.BA("metabase.lib.field",m,$CLJS.sw.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?Cra(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.s3($CLJS.Ok.l(l,$CLJS.dj,$CLJS.H([$CLJS.QK,$CLJS.zW,$CLJS.tW])),null),$CLJS.T,function(){var m=$CLJS.uZ.h(l);return $CLJS.n(m)?m:$CLJS.T.h(l)}()),
$CLJS.FK,$CLJS.hZ):l:null}finally{i7=d}};
k7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.dP);var f=$CLJS.I(c,2,null);c=$CLJS.nl.l($CLJS.H([function(){var k=$CLJS.kB.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.kB,k],null):null}(),function(){var k=$CLJS.tA($CLJS.Ii,$CLJS.kB)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.Ii,k],null):null}(),function(){var k=$CLJS.eR.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.zW,k],null):null}(),function(){var k=$CLJS.iG.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.tW,k],null):null}(),$CLJS.Fd(f)?function(){var k=$CLJS.Y1(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Qt,$CLJS.DK,$CLJS.T,$CLJS.p.h(f)],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.Qt,$CLJS.DK,$CLJS.T,$CLJS.p.h(f)],null):function(){var k=Dra(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Qt,$CLJS.DK,$CLJS.T,$CLJS.p.h(f)],null)}()]));return $CLJS.n(d)?$CLJS.s3(c,d):c};
l7=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.tW);return $CLJS.n($CLJS.n(b)?$CLJS.Hd($CLJS.kH,b):b)?$CLJS.Bj:$CLJS.tA($CLJS.Ii,$CLJS.kB)(a)};
$CLJS.m7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.sE),l=$CLJS.J.g(f,$CLJS.kB),m=$CLJS.J.g(f,$CLJS.eR),t=$CLJS.J.g(f,$CLJS.Ii);e=$CLJS.J.g(f,$CLJS.dP);var u=$CLJS.J.g(f,$CLJS.oN),v=$CLJS.J.g(f,$CLJS.iG),x=$CLJS.nl.l;k=new $CLJS.h(null,2,[$CLJS.Qt,$CLJS.DK,$CLJS.S0,k],null);f=$CLJS.gE.h(f);a=$CLJS.n(f)?f:$CLJS.P_.j(a,b,d);c=x.call($CLJS.nl,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.gE,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.Ii,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.kB,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.tW,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.zW,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.PK,u):m;return $CLJS.n(e)?$CLJS.s3(u,e):u};n7=function(a,b,c){return $CLJS.n($CLJS.Se(function(d){return $CLJS.E.g($CLJS.ti.h(d),c)},a))?$CLJS.Yk.g(function(d){var e=$CLJS.Hd(d,b)?$CLJS.Ok.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.ti.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
o7=function(a){var b=$CLJS.n($CLJS.B1.h(a))?null:function(){var d=$CLJS.FK.h(a),e=new $CLJS.bh(null,new $CLJS.h(null,3,[$CLJS.yZ,null,$CLJS.EZ,null,$CLJS.hZ,null],null),null);return e.h?e.h(d):e.call(null,d)}(),c=$CLJS.nl.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.sE,$CLJS.p.h($CLJS.CE()),$CLJS.kB,$CLJS.kB.h(a),$CLJS.Ii,l7(a)],null),function(){var d=$CLJS.u1(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.dP,d],null):null}(),function(){var d=$CLJS.tW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.iG,d],
null):null}(),function(){var d=$CLJS.xW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.xW,d],null):null}(),function(){var d=$CLJS.zW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.eR,d],null):null}(),function(){var d=$CLJS.PK.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.oN,d],null):null}()]));b=($CLJS.n(b)?$CLJS.tA($CLJS.uZ,$CLJS.T):$CLJS.tA($CLJS.dj,$CLJS.T))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,c,b],null)};
Era=function(a,b){return $CLJS.mf(function(c){return $CLJS.E.g($CLJS.FK.h(c),$CLJS.oZ)},$CLJS.z0.v(a,b,$CLJS.pW(a,b),new $CLJS.h(null,3,[$CLJS.h0,!1,$CLJS.n0,!0,$CLJS.d0,!1],null)))};p7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.j7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);Fra=new $CLJS.M(null,"earliest","earliest",-1928154382);Gra=new $CLJS.M(null,"latest","latest",24323665);var Jra;$CLJS.e1.m(null,$CLJS.cG,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ah.h(b),$CLJS.d1(c,new $CLJS.h(null,2,[$CLJS.iG,$CLJS.Ah,$CLJS.eR,Bra],null)),a],null)});var i7=!1,q7=function q7(a,b){var d=$CLJS.l1(a,p7.h(b));a=$CLJS.n(p7.h(d))?q7.g?q7.g(a,d):q7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.T);return $CLJS.Zk.j(b,$CLJS.T,function(f){return[$CLJS.p.h(e),".",$CLJS.p.h(f)].join("")})};
$CLJS.s0.m(null,$CLJS.DK,function(a,b,c){return l7(c)});$CLJS.s0.m(null,$CLJS.cG,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.iG);$CLJS.I(c,2,null);c=k7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.tW,d):c;return $CLJS.t0.j(a,b,c)});$CLJS.u0.m(null,$CLJS.DK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.T);return $CLJS.R.j(a,$CLJS.T,b)});$CLJS.u0.m(null,$CLJS.cG,function(a,b,c){var d=k7(a,b,c);b=$CLJS.m7(a,b,d,c);return $CLJS.n(p7.h(b))?q7(a,b):b});
$CLJS.o0.m(null,$CLJS.DK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.gE),f=$CLJS.J.g(c,$CLJS.T),k=$CLJS.J.g(c,$CLJS.ti),l=$CLJS.J.g(c,$CLJS.zW),m=$CLJS.J.g(c,$CLJS.HZ),t=$CLJS.J.g(c,$CLJS.PK),u=$CLJS.J.g(c,$CLJS.QK);e=$CLJS.n(e)?e:"string"===typeof f?$CLJS.s1.g($CLJS.r1,f):$CLJS.p.h(f);$CLJS.E.g(d,$CLJS.Q_)&&-1==e.indexOf(" → ")?($CLJS.n(t)?(t=$CLJS.l1(a,t),$CLJS.n(t)?a=(0,$CLJS.ma)($CLJS.xA($CLJS.gE.h($CLJS.S_.j(a,b,t)),$CLJS.BW,"")):(u=$CLJS.t1(a,u),a=$CLJS.P_.v(a,b,u,d))):a=null,m=$CLJS.n(a)?
a:$CLJS.n(m)?m:$CLJS.u1(c)):m=null;m=$CLJS.n(m)?[$CLJS.p.h(m)," → ",$CLJS.p.h(e)].join(""):e;return $CLJS.n(k)?(c=$CLJS.UA($CLJS.xA($CLJS.hh(k),"-"," ")),$CLJS.fa.j?$CLJS.fa.j("%s: %s",m,c):$CLJS.fa.call(null,"%s: %s",m,c)):$CLJS.n(l)?(c=$CLJS.g5(l,c),$CLJS.fa.j?$CLJS.fa.j("%s: %s",m,c):$CLJS.fa.call(null,"%s: %s",m,c)):m});
$CLJS.o0.m(null,$CLJS.cG,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.eR);var k=$CLJS.J.g(f,$CLJS.dP),l=$CLJS.J.g(f,$CLJS.iG);f=$CLJS.J.g(f,$CLJS.oN);$CLJS.I(c,2,null);c=k7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.HZ,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.ti,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.zW,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.PK,f):e;return $CLJS.n(e)?$CLJS.P_.v(a,b,e,d):$CLJS.DE("[Unknown Field]")});
$CLJS.p0.m(null,$CLJS.DK,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.T)});$CLJS.p0.m(null,$CLJS.cG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=k7(a,b,c);return $CLJS.n(c)?$CLJS.q0.j(a,b,c):"unknown_field"});
$CLJS.v0.m(null,$CLJS.DK,function(a,b,c){return $CLJS.nl.l($CLJS.H([function(){var d=$CLJS.ci($CLJS.v0,$CLJS.ei);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.E.g($CLJS.FK.h(c),$CLJS.yZ)?function(){var d=$CLJS.GZ.h(c);return $CLJS.n(d)?(d=$CLJS.J_(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.yW,new $CLJS.h(null,2,[$CLJS.T,$CLJS.T.h(d),$CLJS.gE,$CLJS.T.h(d)],null)],null):null):null}():null]))});$CLJS.J0.m(null,$CLJS.cG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.iG.h(b)});
$CLJS.J0.m(null,$CLJS.DK,function(a){return $CLJS.tW.h(a)});
$CLJS.I0.m(null,$CLJS.cG,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Hd($CLJS.kH,b),e=$CLJS.jC($CLJS.xW,$CLJS.Ii,$CLJS.kB)(c);c=$CLJS.R.l(c,$CLJS.iG,b,$CLJS.H([$CLJS.Ii,d?$CLJS.Bj:e,$CLJS.xW,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,c,a],null)}b=$CLJS.xW.h(c);c=$CLJS.n(b)?$CLJS.Ok.g($CLJS.R.j(c,$CLJS.Ii,b),$CLJS.xW):c;c=$CLJS.Ok.g(c,$CLJS.iG);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,c,a],null)});
$CLJS.I0.m(null,$CLJS.DK,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.tW,b,$CLJS.H([$CLJS.xW,$CLJS.jC($CLJS.xW,$CLJS.Ii,$CLJS.kB)(a)])):$CLJS.Ok.l(a,$CLJS.tW,$CLJS.H([$CLJS.xW]))});$CLJS.L0.m(null,$CLJS.cG,function(a,b,c){return $CLJS.M0.j(a,b,k7(a,b,c))});
$CLJS.L0.m(null,$CLJS.DK,function(a,b,c){if($CLJS.Uk.g($CLJS.FK.h(c),$CLJS.oZ)){a=$CLJS.tA($CLJS.Ii,$CLJS.kB)(c);b=null==c?null:$CLJS.CN.h(c);if(null==b)var d=null;else try{var e=$CLJS.lD.h($CLJS.rj.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,Fra),l=$CLJS.J.g(f,Gra),m=$CLJS.Rna.l($CLJS.H([$CLJS.G0.h(k),$CLJS.G0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Lr.g?$CLJS.Lr.g(1,m):$CLJS.Lr.call(null,1,m))?$CLJS.Yi:$CLJS.n($CLJS.Lr.g?$CLJS.Lr.g(31,m):$CLJS.Lr.call(null,31,m))?$CLJS.Rt:$CLJS.n($CLJS.Lr.g?
$CLJS.Lr.g(365,m):$CLJS.Lr.call(null,365,m))?$CLJS.tj:$CLJS.Cj}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.lC(a,$CLJS.lD)?$CLJS.Zna:$CLJS.lC(a,$CLJS.MC)?$CLJS.Yna:$CLJS.lC(a,$CLJS.LD)?$CLJS.Xna:$CLJS.Df;d=$CLJS.n(d)?n7(e,$CLJS.ei,d):e;return $CLJS.n($CLJS.tW.h(c))?n7(d,$CLJS.X_,$CLJS.tW.h(c)):d}return $CLJS.Df});
$CLJS.j5.m(null,$CLJS.cG,function(a){var b=null==a?null:$CLJS.zE(a);b=null==b?null:$CLJS.eR.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.Qt,$CLJS.i5,$CLJS.H([$CLJS.h5,function(c,d){return k7(c,d,a)}]))});$CLJS.j5.m(null,$CLJS.DK,function(a){var b=null==a?null:$CLJS.zW.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.Qt,$CLJS.i5,$CLJS.H([$CLJS.h5,$CLJS.Ve(a)]))});$CLJS.d5.m(null,$CLJS.cG,function(a,b){return $CLJS.AE(a,$CLJS.yE,$CLJS.H([$CLJS.eR,b]))});
$CLJS.d5.m(null,$CLJS.DK,function(a,b){return $CLJS.yE(a,$CLJS.zW,b)});$CLJS.k5.m(null,$CLJS.cG,function(a,b,c){return $CLJS.l5.j(a,b,k7(a,b,c))});
$CLJS.k5.m(null,$CLJS.DK,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.Ii);var d=$CLJS.J.g(b,$CLJS.CN),e=$CLJS.J.g(b,$CLJS.ak);if($CLJS.Uk.g($CLJS.FK.h(b),$CLJS.oZ)){var f=function(){var m=null==a?null:$CLJS.k1(a);m=null==m?null:$CLJS.Yz.h(m);return null==m?null:$CLJS.Hd(m,$CLJS.eR)}(),k=$CLJS.uA(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,$CLJS.Uj],null)),l=$CLJS.f7(b);return function u(t){return new $CLJS.oe(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.Ad(v)){var x=$CLJS.lc(v),A=$CLJS.D(x),
C=$CLJS.re(A);return function(){for(var K=0;;)if(K<A){var S=$CLJS.kd(x,K),V=C,Y=S;S=h7(S,l)?$CLJS.R.j(Y,$CLJS.X_,!0):Y;V.add(S);K+=1}else return!0}()?$CLJS.ue($CLJS.we(C),u($CLJS.mc(v))):$CLJS.ue($CLJS.we(C),null)}var G=$CLJS.z(v);return $CLJS.fe(function(){var K=G;return h7(G,l)?$CLJS.R.j(K,$CLJS.X_,!0):K}(),u($CLJS.Lc(v)))}return null}},null,null)}($CLJS.Ua(function(){if($CLJS.n(f)){var t=$CLJS.go.h(k);return $CLJS.n(t)?$CLJS.pk.h(k):t}return f}())?null:$CLJS.lC(e,$CLJS.Dj)?$CLJS.Yk.g(g7,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.f5(),new $CLJS.h(null,2,[$CLJS.gE,$CLJS.DE("Bin every 0.1 degrees"),$CLJS.fE,new $CLJS.h(null,2,[$CLJS.wE,$CLJS.vE,$CLJS.vE,.1],null)],null),new $CLJS.h(null,2,[$CLJS.gE,$CLJS.DE("Bin every 1 degree"),$CLJS.fE,new $CLJS.h(null,2,[$CLJS.wE,$CLJS.vE,$CLJS.vE,1],null)],null),new $CLJS.h(null,2,[$CLJS.gE,$CLJS.DE("Bin every 10 degrees"),$CLJS.fE,new $CLJS.h(null,2,[$CLJS.wE,$CLJS.vE,$CLJS.vE,10],null)],null),new $CLJS.h(null,2,[$CLJS.gE,$CLJS.DE("Bin every 20 degrees"),$CLJS.fE,new $CLJS.h(null,
2,[$CLJS.wE,$CLJS.vE,$CLJS.vE,20],null)],null)],null)):$CLJS.lC(c,$CLJS.Uj)&&!$CLJS.lC(e,$CLJS.oi)?$CLJS.Yk.g(g7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.f5(),new $CLJS.h(null,2,[$CLJS.gE,$CLJS.DE("10 bins"),$CLJS.fE,new $CLJS.h(null,2,[$CLJS.wE,$CLJS.eE,$CLJS.eE,10],null)],null),new $CLJS.h(null,2,[$CLJS.gE,$CLJS.DE("50 bins"),$CLJS.fE,new $CLJS.h(null,2,[$CLJS.wE,$CLJS.eE,$CLJS.eE,50],null)],null),new $CLJS.h(null,2,[$CLJS.gE,$CLJS.DE("100 bins"),$CLJS.fE,new $CLJS.h(null,2,[$CLJS.wE,$CLJS.eE,$CLJS.eE,
100],null)],null)],null)):null)}return $CLJS.Df});$CLJS.ZV.m(null,$CLJS.cG,function(a){return a});
$CLJS.ZV.m(null,$CLJS.DK,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.FK);switch(b instanceof $CLJS.M?b.T:null){case "source/aggregations":return b=new $CLJS.h(null,2,[$CLJS.sE,$CLJS.p.h($CLJS.CE()),$CLJS.Ii,$CLJS.tA($CLJS.Ii,$CLJS.kB)(a)],null),a=$CLJS.S0.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SF,b,a],null);case "source/expressions":return e7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.kE.h(a))?e7(a):o7(a);default:return o7(a)}});
$CLJS.Hra=function(){function a(e,f,k){k=$CLJS.Ge($CLJS.Yk.g($CLJS.aW,k));var l=Era(e,f),m=$CLJS.fg.j($CLJS.ch,$CLJS.dm(function(t){return $CLJS.d2.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.Df);m=$CLJS.Xk.g(m,l);k=$CLJS.n(k)?$CLJS.fg.j(k,$CLJS.jf.h($CLJS.aW),m):null;return $CLJS.AW.l(e,f,$CLJS.yE,$CLJS.H([$CLJS.LE,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.r7=function(){function a(d,e){return $CLJS.LE.h($CLJS.pW(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Ira=function(){function a(d,e){var f=$CLJS.z0.v(d,e,$CLJS.pW(d,e),new $CLJS.h(null,3,[$CLJS.h0,!1,$CLJS.n0,!1,$CLJS.d0,!1],null)),k=$CLJS.r7.g(d,e);return $CLJS.td(k)?$CLJS.Yk.g(function(l){return $CLJS.R.j(l,$CLJS.wZ,!0)},f):$CLJS.q3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
Jra=function(){function a(d,e,f){var k=$CLJS.pW(d,e),l=$CLJS.E.g($CLJS.YB(f),$CLJS.SF)?$CLJS.x0:$CLJS.z0;k=l.j?l.j(d,e,k):l.call(null,d,e,k);return $CLJS.d2.v(d,e,f,k)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Kra=function(){function a(d,e,f){f=$CLJS.dZ.j(d,e,f);return Jra.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();