var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var P0,$na,aoa,Q0,R0;$CLJS.N0=function(a,b){return $CLJS.gc($CLJS.eb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.fc(c,d):c},$CLJS.ec($CLJS.Df),b))};P0=function(a){return $CLJS.n(O0)?["(",$CLJS.p.h(a),")"].join(""):a};$na=function(a,b,c){var d=$CLJS.pW(a,b);a=$CLJS.z0.j(a,b,d);var e=$CLJS.SA(c);return $CLJS.Se(function(f){return $CLJS.E.g($CLJS.SA($CLJS.T.h(f)),e)},a)};
aoa=function(a,b){var c=$CLJS.Zk.v(a,$CLJS.JP,$CLJS.ND($CLJS.ce,$CLJS.Df),b);return $CLJS.zd($CLJS.LE.h(a))?$CLJS.Zk.v(c,$CLJS.LE,$CLJS.ce,$CLJS.$V(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hB,$CLJS.N,$CLJS.iW(b)],null))):c};Q0=function(a,b,c){var d=$CLJS.iW(c);return $CLJS.R.l($CLJS.M_.j(a,b,c),$CLJS.FK,$CLJS.oZ,$CLJS.H([$CLJS.T,d,$CLJS.gE,d]))};R0=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);
$CLJS.S0=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var T0=function(){function a(d,e,f){var k=$CLJS.pW(d,e);k=$CLJS.nH($CLJS.Wk.g($CLJS.dh([f]),$CLJS.iW),$CLJS.JP.h(k));if($CLJS.n(k))return k;throw $CLJS.ji($CLJS.bE("No expression named {0}",$CLJS.H([$CLJS.Nh.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.KR,f,$CLJS.yF,d,$CLJS.qL,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.s0.m(null,$CLJS.hB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=T0.j(a,b,c);return $CLJS.t0.j(a,b,c)});$CLJS.u0.m(null,$CLJS.hB,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.Qt,$CLJS.DK,$CLJS.S0,$CLJS.sE.h(d),$CLJS.T,e,$CLJS.kE,e,$CLJS.gE,$CLJS.P_.j(a,b,c),$CLJS.kB,$CLJS.t0.j(a,b,c),$CLJS.FK,$CLJS.oZ],null)});$CLJS.o0.m(null,$CLJS.cB,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.o0.m(null,$CLJS.aB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.o0.m(null,$CLJS.bB,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.o0.m(null,$CLJS.qB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.o0.m(null,$CLJS.hB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.p0.m(null,$CLJS.hB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var O0=!1,boa=new $CLJS.h(null,4,[$CLJS.Tr,"+",$CLJS.at,"-",$CLJS.Ur,"×",$CLJS.yG,"÷"],null),U0=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Tr,$CLJS.at,$CLJS.yG,$CLJS.Ur],null)),V0=null,W0=0,X0=0;;)if(X0<W0){var coa=V0.X(null,X0);$CLJS.jF(coa,R0);X0+=1}else{var Y0=$CLJS.y(U0);if(Y0){var Z0=Y0;if($CLJS.Ad(Z0)){var $0=$CLJS.lc(Z0),doa=$CLJS.mc(Z0),eoa=$0,foa=$CLJS.D($0);U0=doa;V0=eoa;W0=foa}else{var goa=$CLJS.z(Z0);$CLJS.jF(goa,R0);U0=$CLJS.B(Z0);V0=null;W0=0}X0=0}else break}
$CLJS.o0.m(null,R0,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.z(d);d=$CLJS.B(d);$CLJS.z(d);d=$CLJS.B(d);c=$CLJS.J.g(boa,c);a:{var e=O0;O0=!0;try{var f=$CLJS.tt([" ",$CLJS.hh(c)," "].join(""),$CLJS.jf.g($CLJS.Tt($CLJS.P_,a,b),d));break a}finally{O0=e}f=void 0}return P0(f)});$CLJS.p0.m(null,R0,function(){return"expression"});
$CLJS.s0.m(null,$CLJS.tH,function(a,b,c){c=$CLJS.y(c);$CLJS.z(c);c=$CLJS.B(c);$CLJS.z(c);var d=$CLJS.B(c);return $CLJS.Qd($CLJS.lF,function(){return function k(f){return new $CLJS.oe(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.re(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);x=$CLJS.t0.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.ue($CLJS.we(u),k($CLJS.mc(l))):$CLJS.ue($CLJS.we(u),null)}u=$CLJS.z(l);return $CLJS.fe($CLJS.t0.j(a,
b,u),k($CLJS.Lc(l)))}return null}},null,null)}(d)}())});$CLJS.o0.m(null,$CLJS.$I,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.P_.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.H0.g(f,c).toLowerCase();0<f?f=$CLJS.fa.j?$CLJS.fa.j("+ %d %s",f,c):$CLJS.fa.call(null,"+ %d %s",f,c):(f=$CLJS.sA(f),f=$CLJS.fa.j?$CLJS.fa.j("- %d %s",f,c):$CLJS.fa.call(null,"- %d %s",f,c));f=P0(f);return[a," ",d.call(b,f)].join("")});
$CLJS.p0.m(null,$CLJS.$I,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.q0.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.H0.g(e,c).toLowerCase();0<e?e=$CLJS.fa.j?$CLJS.fa.j("plus_%s_%s",e,c):$CLJS.fa.call(null,"plus_%s_%s",e,c):(e=$CLJS.sA(e),e=$CLJS.fa.j?$CLJS.fa.j("minus_%d_%s",e,c):$CLJS.fa.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.o0.m(null,$CLJS.fG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.P_.v(a,b,e,d)});$CLJS.p0.m(null,$CLJS.fG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.q0.j(a,b,d)});
$CLJS.hoa=function(){function a(d,e,f,k){e=$CLJS.n(e)?e:-1;if($CLJS.n($na(d,e,f)))throw $CLJS.ji("Expression name conflicts with a column in the same query stage",new $CLJS.h(null,1,[$CLJS.KR,f],null));return $CLJS.AW.l(d,e,aoa,$CLJS.H([$CLJS.jW($CLJS.bW.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);
};c.j=b;c.v=a;return c}();$CLJS.ioa=function(){function a(d,e){var f=$CLJS.Ge($CLJS.JP.h($CLJS.pW(d,e)));return null==f?null:$CLJS.Yk.g($CLJS.Tt(Q0,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.a1=function(){function a(d,e){return $CLJS.Ge($CLJS.JP.h($CLJS.pW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.ZV.m(null,$CLJS.hB,function(a){return a});
$CLJS.joa=function(){function a(d,e,f){var k=$CLJS.fg.g($CLJS.N,$CLJS.km(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iW(t),m],null)},$CLJS.a1.g(d,e))),l=$CLJS.pW(d,e);d=$CLJS.z0.j(d,e,l);return $CLJS.Ge($CLJS.N0(function(m){return $CLJS.Ua(f)||$CLJS.Uk.g($CLJS.FK.h(m),$CLJS.oZ)||$CLJS.J.g(k,$CLJS.T.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.koa=function(){function a(d,e,f){return $CLJS.aW(Q0(d,e,T0.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();