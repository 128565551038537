var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var BL,CL,DL,Tha,Uha,HL,Vha,KL,LL,ML,NL,Wha,OL,PL,RL,SL,EL,TL,Xha,UL,Yha,VL,WL,XL,Zha,YL,ZL,$ha,$L,aia,aM,bM,bia,dM,cia,dia,eia,fM,gM,hM,iM,jM,kM,lM,mM,fia,nM,oM,pM,rM,sM,gia,tM,uM,vM,wM,hia,xM,yM,zM,AM,iia,BM,CM,DM,EM,jia,FM,kia,GM,HM,IM,lia,JM,KM,LM,MM,OM,PM,mia,QM,RM,nia,SM,TM,UM,VM,WM,oia,XM,pia,YM,ZM,aN,qia,bN,cN,dN,ria,gN,hN,sia,tia,iN,kN,lN,mN,uia,pN,qN,via,wia,xia,rN,sN,tN,uN,vN,wN,yia,xN,zia,yN,zN,BN,DN,EN,FN,GN,Aia,IN,Bia,Cia,JN,KN,LN,MN,NN,Dia,ON,Eia,Fia,PN,QN,RN,SN,TN,Gia,
Hia,Iia,UN,Jia,VN,Kia,XN,YN,Lia,$N,aO,bO,cO,Mia,fO,Nia,Oia,gO,hO,iO,Pia,jO,kO,lO,mO,Qia,oO,pO,qO,rO,sO,Ria,tO,uO,Sia,vO,wO,xO,yO,zO,AO,CO,Tia,DO,Uia,EO,FO,GO,Via,Wia,Xia,IO,JO,KO,Yia,LO,MO,NO,OO,Zia,$ia,QO,aja,bja,RO,SO,TO,UO,VO,cja,WO,XO,YO,ZO,dja,$O,aP,bP,cP,eP,eja,fP,gP,fja,hP,iP,jP,gja,kP,hja,mP,ija,nP,oP,pP,qP,rP,sP,tP,jja,kja,lja,uP,mja,vP,wP,nja,oja,xP,yP,zP,pja,AP,qja,BP,rja,sja,CP,tja,uja,vja,FP,GP,wja,HP,IP,KP,xja,LP,yja,MP,NP,OP,zja,Aja,PP,QP,RP,SP,Bja,UP,VP,Cja,WP,XP,YP,ZP,$P,aQ,bQ,cQ,
Dja,dQ,eQ,Eja,fQ,Fja,gQ,hQ,iQ,Gja,jQ,kQ,Hja,Ija,lQ,mQ,nQ,Jja,Kja,oQ,Lja,Mja,Nja,qQ,Oja,sQ,Pja,Qja,uQ,vQ,Rja,Sja,Tja,wQ,xQ,yQ,zQ,AQ,BQ,CQ,Uja,DQ,EQ,Vja,FQ,Wja,Xja,Yja,GQ,HQ,IQ,Zja,JQ,KQ,$ja,LQ,aka,MQ,bka,cka,OQ,PQ,QQ,RQ,SQ,dka,TQ,UQ,eka,VQ,WQ,XQ,YQ,ZQ,$Q,aR,bR,fka,cR,dR,fR,gR,hR,iR,jR,kR,lR,mR,nR,oR,gka,pR,qR,rR,sR,tR,hka,uR,vR,wR,xR,ika,zR,jka,kka,BR,lka,CR,mka,DR,nka,oka,ER,pka,FR,HR,IR,qka,MR,NR,rka,ska,OR,PR,QR,RR,SR,TR,UR,WR,YR,tka;
$CLJS.AL=function(a){var b=$CLJS.Fo.g(a,null),c=$CLJS.fF(b,$CLJS.Ds,function(d){return $CLJS.En(d,$CLJS.Df)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.xl,b,$CLJS.Ij,k,$CLJS.Js,l],null):null}function e(k){return f.j(k,$CLJS.Df,$CLJS.Df)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
BL=function(a,b){return $CLJS.xd(a)?$CLJS.J.g(a,b):a};CL=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.Hn($CLJS.Fo.g($CLJS.xl.h(a),null));if($CLJS.n(b)){var e=BL($CLJS.kt.h(b),c);$CLJS.n(e)?(e=$CLJS.Jq(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:BL($CLJS.ht.h(b),c)}return null};DL=function(a,b){return $CLJS.vd(a)||$CLJS.Ql(a)?$CLJS.J.g(a,b):$CLJS.wd(a)?$CLJS.J.g($CLJS.Ef(a),b):null};
Tha=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.xl),d=$CLJS.J.g(a,$CLJS.rj);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.Js,$CLJS.Fea),f=$CLJS.J.j(b,$CLJS.Aea,!0),k=$CLJS.J.g(b,$CLJS.XB),l=$CLJS.J.j(b,$CLJS.VB,$CLJS.TB),m=CL(a,$CLJS.Lq.h(c),k,b);if($CLJS.n(m))return m;m=CL(a,$CLJS.Vs.h(c),k,b);if($CLJS.n(m))return m;m=CL(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=CL(a,function(){var t=$CLJS.mo.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=CL(a,$CLJS.Lq.h(c),
l,b);if($CLJS.n(m))return m;m=CL(a,$CLJS.Vs.h(c),l,b);if($CLJS.n(m))return m;d=CL(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=CL(a,function(){var t=$CLJS.mo.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?CL(a,e.h?e.h($CLJS.RB):e.call(null,$CLJS.RB),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?CL(a,e.h?e.h($CLJS.RB):e.call(null,$CLJS.RB),l,b):f};
Uha=function(a,b,c){a=$CLJS.Q;var d=$CLJS.fg.g;var e=$CLJS.tl.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.xl),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.XB);l=$CLJS.J.j(l,$CLJS.VB,$CLJS.TB);k=$CLJS.Lq.h(k);f=BL($CLJS.UB.h(k),f);f=$CLJS.n(f)?f:BL($CLJS.UB.h(k),l);d=d.call($CLJS.fg,e,f);return new $CLJS.P(null,2,5,a,[d,Tha(b,c)],null)};
$CLJS.FL=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Ij);a=$CLJS.J.g(b,$CLJS.Js);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.Bea,$CLJS.Ui),f=$CLJS.J.j(d,$CLJS.Dea,Uha);return $CLJS.n(a)?$CLJS.eb(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.Ui,m);l=e.h?e.h(l):e.call(null,l);return EL(k,c,t,l)},null,a):null};
HL=function(a,b,c){var d=$CLJS.uA($CLJS.q($CLJS.WD),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.Ph.v($CLJS.WD,$CLJS.GL,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.IL=function(a){return HL($CLJS.Dk,a,function(){return $CLJS.gF.h(a)})};Vha=function(){var a=JL;return HL($CLJS.Ds,a,function(){var b=$CLJS.gF.h(a),c=$CLJS.AL(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
KL=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,$CLJS.ne(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a],null):a],null)};LL=function(a){if($CLJS.zd(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.T:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,KL(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,KL(c)],null);default:return KL(a)}}else return KL(a)};
ML=function(a,b){var c=$CLJS.zd(b);return c?(c=$CLJS.z(b)instanceof $CLJS.M)?$CLJS.ud(a)?(b=$CLJS.z(b),a=$CLJS.gh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c:c};
NL=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.ht,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.We(ML,a)],null),$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mi,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,a],null)],null)],null),function(){return function e(d){return new $CLJS.oe(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.re(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,LL(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.ue($CLJS.we(m),e($CLJS.mc(f))):$CLJS.ue($CLJS.we(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[m,LL(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}($CLJS.Ys(2,2,b))}())],null)};Wha=function(a){return $CLJS.zd(a)&&$CLJS.z(a)instanceof $CLJS.M?$CLJS.z(a):null};
OL=function(a){return $CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,2,[$CLJS.Si,Wha,$CLJS.ht,["valid instance of one of these MBQL clauses: ",$CLJS.tt(", ",$CLJS.jf.g($CLJS.z,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.oe(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.re(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.ne(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.ue($CLJS.we(l),d($CLJS.mc(e))):$CLJS.ue($CLJS.we(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.ne(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f],null):f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};
PL=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.zL],null)],null)};$CLJS.QL={};RL={};SL={};$CLJS.GL=function GL(a,b,c){var e=$CLJS.y(b);b=$CLJS.z(e);var f=$CLJS.B(e);if(f){e=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=GL.j?GL.j(k,f,c):GL.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
EL=function EL(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.z(f);var k=$CLJS.B(f),l=DL(b,c);f=$CLJS.n(a)?a:$CLJS.wd(b)?$CLJS.Df:$CLJS.yd(b)?$CLJS.N:$CLJS.jd(b);return $CLJS.n($CLJS.n(c)?$CLJS.SB.h($CLJS.pd(f)):c)?a:$CLJS.n(c)?(b=DL(f,c),d=EL.v?EL.v(b,l,k,d):EL.call(null,b,l,k,d),$CLJS.Vl(c)&&$CLJS.wd(f)&&c>$CLJS.D(f)&&(b=$CLJS.ef(c-$CLJS.D(f),null),b=$CLJS.hf.g(f,b),f=null==f||$CLJS.Dd(f)?b:$CLJS.fg.g($CLJS.jd(f),b)),null==f||$CLJS.Ql(f)?$CLJS.R.j(f,c,d):$CLJS.vd(f)?$CLJS.ce.g(f,d):$CLJS.Te.g($CLJS.U,$CLJS.R.j($CLJS.Ef(f),
c,d))):$CLJS.xd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Cea],null),EL.v?EL.v(f,b,c,d):EL.call(null,f,b,c,d)):$CLJS.n($CLJS.SB.h($CLJS.pd(f)))?$CLJS.ce.g(f,d):$CLJS.zd($CLJS.Ge(f))?f:$CLJS.od(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.SB,!0],null))};TL=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);Xha=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
UL=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);Yha=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);VL=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);WL=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);XL=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
Zha=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);YL=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);ZL=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);$ha=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);$L=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
aia=new $CLJS.M(null,"from","from",1815293044);aM=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);bM=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);bia=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.cM=new $CLJS.M(null,"snippet-name","snippet-name",819240328);dM=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
cia=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);dia=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.eM=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);eia=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);fM=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
gM=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);hM=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);iM=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);jM=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);kM=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
lM=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);mM=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);fia=new $CLJS.M(null,"lon-max","lon-max",1590224717);nM=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);oM=new $CLJS.r(null,"stddev","stddev",775056588,null);pM=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.qM=new $CLJS.M(null,"snippet","snippet",953581994);rM=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);sM=new $CLJS.M(null,"string-expression","string-expression",-1395337766);gia=new $CLJS.M(null,"lat-field","lat-field",-830652957);tM=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);uM=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
vM=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);wM=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);hia=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);xM=new $CLJS.M("location","country","location/country",1666636202);yM=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
zM=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);AM=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);iia=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);BM=new $CLJS.M(null,"unary","unary",-989314568);CM=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
DM=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);EM=new $CLJS.r(null,"ceil","ceil",-184398425,null);jia=new $CLJS.M(null,"lon-min","lon-min",-787291357);FM=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);kia=new $CLJS.M(null,"match","match",1220059550);GM=new $CLJS.r(null,"count-where","count-where",2025939247,null);HM=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
IM=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);lia=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);JM=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);KM=new $CLJS.r(null,"sum","sum",1777518341,null);LM=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);MM=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.NM=new $CLJS.M("date","range","date/range",1647265776);OM=new $CLJS.r(null,"between","between",-1523336493,null);PM=new $CLJS.M(null,"clause-form","clause-form",1820463737);mia=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);QM=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);RM=new $CLJS.r(null,"field","field",338095027,null);nia=new $CLJS.M(null,"segment-id","segment-id",1810133590);
SM=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);TM=new $CLJS.r(null,"not-null","not-null",313812992,null);UM=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);VM=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);WM=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);oia=new $CLJS.M(null,"template-tag","template-tag",310841038);
XM=new $CLJS.M(null,"invalid","invalid",412869516);pia=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);YM=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);ZM=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.$M=new $CLJS.M(null,"context","context",-830191113);aN=new $CLJS.r(null,"get-year","get-year",704520253,null);
qia=new $CLJS.M(null,"format-rows?","format-rows?",992129486);bN=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);cN=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);dN=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.eN=new $CLJS.M(null,"parameters","parameters",-1229919748);ria=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.fN=new $CLJS.M(null,"card","card",-1430355152);gN=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);hN=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);sia=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);tia=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);iN=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.jN=new $CLJS.M("date","month-year","date/month-year",1948031290);
kN=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);lN=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);mN=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.nN=new $CLJS.M(null,"joins","joins",1033962699);uia=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.oN=new $CLJS.M(null,"source-field","source-field",933829534);pN=new $CLJS.r(null,"Field","Field",430385967,null);
qN=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);via=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);wia=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);xia=new $CLJS.M(null,"items","items",1031954938);rN=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);sN=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
tN=new $CLJS.M(null,"more","more",-2058821800);uN=new $CLJS.M(null,"first-clause","first-clause",-20953491);vN=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);wN=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);yia=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);xN=new $CLJS.r(null,"contains","contains",-1977535957,null);
zia=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);yN=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);zN=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.AN=new $CLJS.M(null,"widget-type","widget-type",1836256899);BN=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.CN=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
DN=new $CLJS.r(null,"is-null","is-null",-356519403,null);EN=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);FN=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);GN=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);Aia=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.HN=new $CLJS.M(null,"required","required",1807647006);IN=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);
Bia=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);Cia=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);JN=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);KN=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);LN=new $CLJS.M("string","contains","string/contains",1602423827);MN=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);NN=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);
Dia=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);ON=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);Eia=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);Fia=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);PN=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);
QN=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);RN=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);SN=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);TN=new $CLJS.r(null,"share","share",1051097594,null);Gia=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);
Hia=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);Iia=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);UN=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);Jia=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);VN=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);
Kia=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.WN=new $CLJS.M(null,"collection","collection",-683361892);XN=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);YN=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);$CLJS.ZN=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);
Lia=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);$N=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);aO=new $CLJS.r(null,"metric","metric",2049329604,null);bO=new $CLJS.r(null,"concat","concat",-467652465,null);cO=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.dO=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);Mia=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);
$CLJS.eO=new $CLJS.M("date","relative","date/relative",25987732);fO=new $CLJS.M("location","city","location/city",-1746973325);Nia=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);Oia=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);gO=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);hO=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);iO=new $CLJS.M("number","between","number/between",97700581);
Pia=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);jO=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);kO=new $CLJS.r(null,"sqrt","sqrt",370479598,null);lO=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);mO=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);$CLJS.nO=new $CLJS.M(null,"semantic_type","semantic_type",272485089);
Qia=new $CLJS.M(null,"metric-id","metric-id",-686486942);oO=new $CLJS.r(null,"*","*",345799209,null);pO=new $CLJS.r(null,"+","+",-740910886,null);qO=new $CLJS.r(null,"-","-",-471816912,null);rO=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);sO=new $CLJS.M(null,"allowed-for","allowed-for",122724334);Ria=new $CLJS.M(null,"question","question",-1411720117);tO=new $CLJS.r(null,"asc","asc",1997386096,null);
uO=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);Sia=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);vO=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);wO=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);xO=new $CLJS.r(null,"\x3c","\x3c",993667236,null);yO=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);zO=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
AO=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.BO=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);CO=new $CLJS.M("string","ends-with","string/ends-with",302681156);Tia=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);DO=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);Uia=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
EO=new $CLJS.r(null,"and","and",668631710,null);FO=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);GO=new $CLJS.r(null,"round","round",-645002441,null);Via=new $CLJS.M(null,"to","to",192099007);$CLJS.HO=new $CLJS.M("date","single","date/single",1554682003);Wia=new $CLJS.M(null,"action-id","action-id",-1727958578);Xia=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);IO=new $CLJS.r(null,"exp","exp",1378825265,null);
JO=new $CLJS.r(null,"Filter","Filter",-424893332,null);KO=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);Yia=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);LO=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);MO=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);NO=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
OO=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.PO=new $CLJS.M(null,"source-table","source-table",-225307692);Zia=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);$ia=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);QO=new $CLJS.r(null,"floor","floor",-772394748,null);aja=new $CLJS.M(null,"middleware","middleware",1462115504);
bja=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);RO=new $CLJS.M(null,"requires-features","requires-features",-101116256);SO=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);TO=new $CLJS.M(null,"clause-name","clause-name",-996419059);UO=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);VO=new $CLJS.r(null,"now","now",-9994004,null);
cja=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);WO=new $CLJS.r(null,"not","not",1044554643,null);XO=new $CLJS.r(null,"avg","avg",1837937727,null);YO=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);ZO=new $CLJS.M(null,"max-results","max-results",-32858165);dja=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);$O=new $CLJS.r(null,"case","case",-1510733573,null);
aP=new $CLJS.r(null,"distinct","distinct",-148347594,null);bP=new $CLJS.r(null,"get-second","get-second",-425414791,null);cP=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.dP=new $CLJS.M(null,"join-alias","join-alias",1454206794);eP=new $CLJS.r(null,"is-empty","is-empty",600228619,null);eja=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
fP=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);gP=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);fja=new $CLJS.M(null,"original","original",-445386197);hP=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);iP=new $CLJS.r(null,"abs","abs",1394505050,null);jP=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);gja=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
kP=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);hja=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.lP=new $CLJS.M(null,"date","date",-1463434462);mP=new $CLJS.M(null,"second-clause","second-clause",-461435645);ija=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);nP=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
oP=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);pP=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);qP=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);rP=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);sP=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);tP=new $CLJS.r(null,"or","or",1876275696,null);
jja=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);kja=new $CLJS.M(null,"constraints","constraints",422775616);lja=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);uP=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);mja=new $CLJS.M(null,"csv-download","csv-download",2141432084);vP=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
wP=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);nja=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);oja=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);xP=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);yP=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
zP=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);pja=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);AP=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);qja=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);BP=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);rja=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
sja=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);CP=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);tja=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);uja=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);vja=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.DP=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.EP=new $CLJS.M(null,"database","database",1849087575);FP=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);GP=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);wja=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);HP=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
IP=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.JP=new $CLJS.M(null,"expressions","expressions",255689909);KP=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);xja=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);LP=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
yja=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);MP=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);NP=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);OP=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);zja=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);Aja=new $CLJS.M(null,"action","action",-811238024);
PP=new $CLJS.r(null,"get-day","get-day",1768100384,null);QP=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);RP=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);SP=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.TP=new $CLJS.M(null,"native","native",-613060878);Bja=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);UP=new $CLJS.M(null,"page","page",849072397);
VP=new $CLJS.r(null,"length","length",-2065447907,null);Cja=new $CLJS.M(null,"dashboard","dashboard",-631747508);WP=new $CLJS.r(null,"get-week","get-week",752472178,null);XP=new $CLJS.r(null,"get-month","get-month",1271156796,null);YP=new $CLJS.r(null,"dimension","dimension",-2111181571,null);ZP=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);$P=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
aQ=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);bQ=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);cQ=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);Dja=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);dQ=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);eQ=new $CLJS.r(null,"substring","substring",-1513569493,null);
Eja=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);fQ=new $CLJS.M(null,"internal","internal",-854870097);Fja=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);gQ=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);hQ=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
iQ=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);Gja=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);jQ=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);kQ=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);Hja=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
Ija=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);lQ=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);mQ=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);nQ=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
Jja=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);Kja=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);oQ=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.pQ=new $CLJS.M(null,"template-tags","template-tags",1853115685);Lja=new $CLJS.M(null,"public-question","public-question",629369976);Mja=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
Nja=new $CLJS.M(null,"binary","binary",-1802232288);qQ=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);Oja=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.rQ=new $CLJS.M(null,"source-query","source-query",198004422);sQ=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);Pja=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
Qja=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);uQ=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);vQ=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);Rja=new $CLJS.M(null,"executed-by","executed-by",-739811161);Sja=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
Tja=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);wQ=new $CLJS.M(null,"amount","amount",364489504);xQ=new $CLJS.r(null,"percentile","percentile",1039342775,null);yQ=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);zQ=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);AQ=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
BQ=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);CQ=new $CLJS.r(null,"trim","trim",-1880116002,null);Uja=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);DQ=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);EQ=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);Vja=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
FQ=new $CLJS.M("string","\x3d","string/\x3d",983744235);Wja=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);Xja=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Yja=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);GQ=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);HQ=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
IQ=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);Zja=new $CLJS.M(null,"lat-min","lat-min",1630784161);JQ=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);KQ=new $CLJS.r(null,"inside","inside",-681932758,null);$ja=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);LQ=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
aka=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);MQ=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);bka=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);cka=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.NQ=new $CLJS.M(null,"card-id","card-id",-1770060179);OQ=new $CLJS.M(null,"variadic","variadic",882626057);
PQ=new $CLJS.r(null,"upper","upper",1886775433,null);QQ=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);RQ=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);SQ=new $CLJS.r(null,"optional","optional",-600484260,null);dka=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);TQ=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
UQ=new $CLJS.M(null,"sugar","sugar",-73788488);eka=new $CLJS.M(null,"lat-max","lat-max",841568226);VQ=new $CLJS.r(null,"power","power",702679448,null);WQ=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);XQ=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);YQ=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
ZQ=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);$Q=new $CLJS.r(null,"median","median",-2084869638,null);aR=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);bR=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);fka=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);cR=new $CLJS.M(null,"y","y",-1757859776);dR=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.eR=new $CLJS.M(null,"binning","binning",1709835866);fR=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);gR=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);hR=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);iR=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);jR=new $CLJS.r(null,"Reference","Reference",2024574086,null);kR=new $CLJS.M(null,"b","b",1482224470);
lR=new $CLJS.M(null,"a","a",-2123407586);mR=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);nR=new $CLJS.r(null,"replace","replace",853943757,null);oR=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);gka=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);pR=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
qR=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);rR=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);sR=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);tR=new $CLJS.r(null,"segment","segment",675610331,null);hka=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);uR=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
vR=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);wR=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);xR=new $CLJS.M("string","starts-with","string/starts-with",1266861170);ika=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.yR=new $CLJS.M(null,"order-by","order-by",1527318070);zR=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
jka=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);kka=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.AR=new $CLJS.M(null,"condition","condition",1668437652);BR=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);lka=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);CR=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
mka=new $CLJS.M(null,"card-name","card-name",-2035606807);DR=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);nka=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);oka=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);ER=new $CLJS.r(null,"log","log",45015523,null);pka=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
FR=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.GR=new $CLJS.M(null,"database_type","database_type",-54700895);HR=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);IR=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.JR=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.KR=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.LR=new $CLJS.M("date","all-options","date/all-options",549325958);
qka=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);MR=new $CLJS.M("location","state","location/state",-114378652);NR=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);rka=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);ska=new $CLJS.M(null,"lon-field","lon-field",517872067);OR=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);
PR=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);QR=new $CLJS.M(null,"numeric","numeric",-1495594714);RR=new $CLJS.r(null,"variable","variable",1359185035,null);SR=new $CLJS.r(null,"lower","lower",-1534114948,null);TR=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);UR=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.VR=new $CLJS.M(null,"limit","limit",-1355822363);
WR=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);YR=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);tka=new $CLJS.M(null,"pulse","pulse",-244494476);var ZR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lE],null),$R=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uE],null),aS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hE],null),bS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null),cS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pE],null),dS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WK],null),uka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.NK],null),vka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.IK],null),eS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.yI],null),fS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.CI],null),gS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tI],null),hS=new $CLJS.bh(null,new $CLJS.h(null,12,[$CLJS.qi,null,$CLJS.Rt,null,$CLJS.tj,null,$CLJS.ei,null,$CLJS.Ji,null,$CLJS.Cj,null,$CLJS.sk,null,$CLJS.Lj,null,$CLJS.li,null,$CLJS.Jj,null,$CLJS.ri,null,$CLJS.Di,null],null),null),iS=new $CLJS.bh(null,new $CLJS.h(null,7,[$CLJS.wk,null,$CLJS.NG,null,$CLJS.ei,null,$CLJS.xi,null,$CLJS.QG,null,$CLJS.nj,null,$CLJS.Yi,null],null),null),
jS=$CLJS.St.g(hS,iS),wka=$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.ht,"date bucketing unit"],null)],null),hS),xka=$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.ht,"time bucketing unit"],null)],null),iS),kS=$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.ht,"datetime bucketing unit"],null)],null),jS),lS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cJ],null),yka=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,
1,[$CLJS.ht,"temporal extract unit"],null),$CLJS.RG,$CLJS.Di,$CLJS.sk,$CLJS.OI,$CLJS.iJ,$CLJS.jJ,$CLJS.Lj,$CLJS.Ji,$CLJS.xi,$CLJS.nj,$CLJS.OG],null),zka=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.ht,"datetime-diff unit"],null),$CLJS.NG,$CLJS.Yi,$CLJS.wk,$CLJS.Rt,$CLJS.tj,$CLJS.Cj,$CLJS.qi,$CLJS.li],null),mS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.ht,"temporal-extract week extraction mode"],null),$CLJS.II,$CLJS.RI,$CLJS.WI],null),nS=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.ht,"relative-datetime unit"],null),$CLJS.ei,$CLJS.Yi,$CLJS.wk,$CLJS.Rt,$CLJS.tj,$CLJS.Cj,$CLJS.qi,$CLJS.li],null),uS,Bka,CS,DS,ES,FS,GS,HS,IS,tU,Dka,uU,Eka,Fka,vU,Gka,Hka,Ika;$CLJS.X(DM,NL($CLJS.SI,$CLJS.H(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.Lv],null),$CLJS.zi],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,nS],null)])));var oS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DM],null);
$CLJS.X(hP,NL($CLJS.QI,$CLJS.H(["n",$CLJS.zi,"unit",nS])));var Aka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hP],null);
$CLJS.X(YM,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,2,[$CLJS.ht,"valid :absolute-datetime clause",$CLJS.Si,function(a){if($CLJS.Ua(ML($CLJS.MI,a)))a=XM;else{a=$CLJS.hd(a);var b=$CLJS.IL(eS);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.lP:$CLJS.TI}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[XM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.ht,"not an :absolute-datetime clause"],null),$CLJS.Ve(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lP,NL($CLJS.MI,
$CLJS.H(["date",eS,"unit",wka]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TI,NL($CLJS.MI,$CLJS.H(["datetime",fS,"unit",kS]))],null)],null));var pS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YM],null);$CLJS.X(mN,NL($CLJS.xy,$CLJS.H(["time",gS,"unit",xka])));var qS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mN],null),rS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ms,new $CLJS.h(null,1,[$CLJS.ht,"date or datetime literal"],null),pS,fS,eS],null);
$CLJS.X(YL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ms,new $CLJS.h(null,1,[$CLJS.ht,"time literal"],null),qS,gS],null));$CLJS.X(OR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ms,new $CLJS.h(null,1,[$CLJS.ht,"temporal literal"],null),rS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YL],null)],null));var sS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OR],null);
$CLJS.X(Vja,OL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof pS?new $CLJS.Gc(function(){return pS},$CLJS.od(via,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,fQ,$CLJS.sj,$CLJS.Fk,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),OP,"metabase/mbql/schema.cljc",69,$CLJS.MI,1,!0,168,168,$CLJS.Kc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(pS)?pS.H:null])):null));return $CLJS.n(a)?a:OP}(),pS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof oS?new $CLJS.Gc(function(){return oS},$CLJS.od(qka,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,
$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fP,"metabase/mbql/schema.cljc",29,$CLJS.SI,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.SI,$CLJS.xk,$CLJS.U($CLJS.Vj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.Lv],null),$CLJS.zi],null)),$CLJS.ti,$CLJS.U($CLJS.Vj,$CLJS.U(SQ,DO))],null),$CLJS.Kc,"Schema for a valid relative-datetime clause.",
$CLJS.n(oS)?oS.H:null])):null));return $CLJS.n(a)?a:fP}(),oS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof qS?new $CLJS.Gc(function(){return qS},$CLJS.od(sja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,fQ,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),FR,"metabase/mbql/schema.cljc",27,$CLJS.xy,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.xy,$CLJS.xy,$CLJS.U($CLJS.Vj,Uja),$CLJS.ti,$CLJS.U($CLJS.Vj,Mia)],null),$CLJS.Kc,"Schema for a valid time clause.",$CLJS.n(qS)?qS.H:null])):null));return $CLJS.n(a)?a:FR}(),qS],null)])));
$CLJS.X(DR,NL($CLJS.Ij,$CLJS.H(["value",$CLJS.ys,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GR,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,ZR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZC,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$R],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nO,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ls,aS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ti,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,kS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,ZR],null)],null)],null)],null)])));var tS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DR],null);$CLJS.X(mO,NL($CLJS.hB,$CLJS.H(["expression-name",ZR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,$CLJS.ok],null)])));
uS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mO],null);
Bka=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.ht,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.wE);c=$CLJS.J.g(c,$CLJS.eE);return $CLJS.E.g(b,$CLJS.eE)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.ht,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.wE);c=$CLJS.J.g(c,$CLJS.vE);return $CLJS.E.g(b,$CLJS.vE)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ok,new $CLJS.h(null,1,[$CLJS.ht,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wE,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.ht,"binning strategy"],null),$CLJS.eE,$CLJS.vE,$CLJS.ei],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eE,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),bS],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.vE,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,$CLJS.Il,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.ht,"bin width must be \x3e\x3d 0."],null),$CLJS.Ue($CLJS.cm)],null)],null)],null)],null)));$CLJS.vS=function vS(a){switch(arguments.length){case 1:return vS.h(arguments[0]);case 2:return vS.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.vS.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.kB);b=$CLJS.J.g(b,$CLJS.iG);return $CLJS.vS.g(a,b)};$CLJS.vS.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.lC(a,$CLJS.MC)?hS:$CLJS.lC(a,$CLJS.LD)?iS:$CLJS.lC(a,$CLJS.lD)?jS:null:null;return $CLJS.n(a)?$CLJS.Hd(a,b):!0};$CLJS.vS.A=2;
$CLJS.X(TQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ok,new $CLJS.h(null,1,[$CLJS.ht,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kB,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$R],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oN,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,dS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iG,new $CLJS.h(null,
1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,kS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dP,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,ZR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eR,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,Bka],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.ht,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.vS],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.ht,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Ue($CLJS.wE)],null)],null));
$CLJS.X(uR,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.ht,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.kB);return"string"===typeof c?b:!0}],null)],null)}(NL($CLJS.cG,$CLJS.H(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,dS,ZR],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TQ],null)],null)]))));var wS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uR],null);
$CLJS.X(uP,OL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof uS?new $CLJS.Gc(function(){return uS},$CLJS.od(yN,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.JP,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),iN,"metabase/mbql/schema.cljc",60,$CLJS.hB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hB,$CLJS.KR,$CLJS.U($CLJS.Vj,FM),$CLJS.kj,$CLJS.U($CLJS.Vj,$CLJS.U(SQ,$CLJS.ok))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(uS)?uS.H:null])):null));return $CLJS.n(a)?a:iN}(),uS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof wS?new $CLJS.Gc(function(){return wS},
$CLJS.od(QN,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.mr,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,$CLJS.vi,$CLJS.qk,$CLJS.uk],["0.39.0",$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RM,"metabase/mbql/schema.cljc",51,$CLJS.cG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(wS)?wS.H:null])):null));return $CLJS.n(a)?a:RM}(),wS],null)])));
$CLJS.xS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uP],null);$CLJS.X(TL,NL($CLJS.SF,$CLJS.H(["aggregation-clause-index",$CLJS.zi,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,$CLJS.ok],null)])));var yS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TL],null);
$CLJS.X(zM,OL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof yS?new $CLJS.Gc(function(){return yS},$CLJS.od(Sja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),dR,"metabase/mbql/schema.cljc",23,$CLJS.SF,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.SF,rka,$CLJS.U($CLJS.Vj,$CLJS.zi),$CLJS.kj,$CLJS.U($CLJS.Vj,$CLJS.U(SQ,$CLJS.ok))],null),$CLJS.Kc,"Schema for a valid aggregation clause.",$CLJS.n(yS)?yS.H:null])):null));return $CLJS.n(a)?a:dR}(),yS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof uS?new $CLJS.Gc(function(){return uS},
$CLJS.od(yN,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.JP,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iN,"metabase/mbql/schema.cljc",60,$CLJS.hB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hB,$CLJS.KR,$CLJS.U($CLJS.Vj,FM),
$CLJS.kj,$CLJS.U($CLJS.Vj,$CLJS.U(SQ,$CLJS.ok))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(uS)?uS.H:null])):null));return $CLJS.n(a)?a:iN}(),uS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof wS?new $CLJS.Gc(function(){return wS},$CLJS.od(QN,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.mr,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,
$CLJS.vi,$CLJS.qk,$CLJS.uk],["0.39.0",$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RM,"metabase/mbql/schema.cljc",51,$CLJS.cG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(wS)?wS.H:null])):null));return $CLJS.n(a)?a:RM}(),wS],null)])));
var zS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zM],null),AS=new $CLJS.bh(null,new $CLJS.h(null,11,[$CLJS.vt,null,$CLJS.vG,null,$CLJS.uF,null,$CLJS.BG,null,$CLJS.BF,null,$CLJS.fG,null,$CLJS.LF,null,$CLJS.Bt,null,$CLJS.tG,null,$CLJS.eG,null,$CLJS.CG,null],null),null),BS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wM],null);
$CLJS.X($L,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,1,[$CLJS.Si,function(a){return"string"===typeof a?$CLJS.ek:$CLJS.n(ML(AS,a))?sM:$CLJS.n(ML($CLJS.Ij,a))?$CLJS.Ij:$CLJS.xt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,$CLJS.ek],null),new $CLJS.P(null,2,5,$CLJS.Q,[sM,BS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ij,tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xt,$CLJS.xS],null)],null));CS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$L],null);
DS=new $CLJS.bh(null,new $CLJS.h(null,26,[$CLJS.xF,null,$CLJS.hJ,null,$CLJS.Ur,null,$CLJS.dJ,null,$CLJS.aJ,null,$CLJS.QF,null,$CLJS.at,null,$CLJS.tF,null,$CLJS.eJ,null,$CLJS.YI,null,$CLJS.fG,null,$CLJS.yG,null,$CLJS.bJ,null,$CLJS.hG,null,$CLJS.zF,null,$CLJS.KI,null,$CLJS.Tr,null,$CLJS.CF,null,$CLJS.LF,null,$CLJS.EI,null,$CLJS.Hx,null,$CLJS.LI,null,$CLJS.XI,null,$CLJS.PI,null,$CLJS.AG,null,$CLJS.aG,null],null),null);
ES=new $CLJS.bh(null,new $CLJS.h(null,9,[$CLJS.Qr,null,$CLJS.Kr,null,$CLJS.ms,null,$CLJS.os,null,$CLJS.Mr,null,$CLJS.uG,null,$CLJS.rs,null,$CLJS.Rr,null,$CLJS.Or,null],null),null);FS=new $CLJS.bh(null,new $CLJS.h(null,17,[$CLJS.go,null,$CLJS.sG,null,$CLJS.lG,null,$CLJS.dG,null,$CLJS.xG,null,$CLJS.zG,null,$CLJS.HF,null,$CLJS.OF,null,$CLJS.eM,null,$CLJS.IF,null,$CLJS.qG,null,$CLJS.jG,null,$CLJS.pk,null,$CLJS.Jx,null,$CLJS.XF,null,$CLJS.YF,null,$CLJS.pG,null],null),null);
GS=new $CLJS.bh(null,new $CLJS.h(null,5,[$CLJS.UI,null,$CLJS.GI,null,$CLJS.fJ,null,$CLJS.Tr,null,$CLJS.$I,null],null),null);HS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LQ],null);IS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kN],null);$CLJS.JS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lQ],null);$CLJS.KS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RQ],null);
$CLJS.X(YQ,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,2,[$CLJS.ht,"numeric expression argument",$CLJS.Si,function(a){return"number"===typeof a?$CLJS.Kt:$CLJS.n(ML(DS,a))?OO:$CLJS.n(ML(FS,a))?$CLJS.SF:$CLJS.n(ML($CLJS.Ij,a))?$CLJS.Ij:$CLJS.cG}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kt,$CLJS.Il],null),new $CLJS.P(null,2,5,$CLJS.Q,[OO,HS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SF,$CLJS.KS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ij,tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cG,
$CLJS.xS],null)],null));var LS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YQ],null);
$CLJS.X(gQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,2,[$CLJS.ht,"datetime expression argument",$CLJS.Si,function(a){return $CLJS.n(ML(FS,a))?$CLJS.SF:$CLJS.n(ML($CLJS.Ij,a))?$CLJS.Ij:$CLJS.n(ML(GS,a))?rN:$CLJS.xt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SF,$CLJS.KS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ij,tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[rN,$CLJS.JS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,rS,$CLJS.xS],null)],null)],null));
var MS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gQ],null);
$CLJS.X(KP,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,2,[$CLJS.ht,"expression argument",$CLJS.Si,function(a){return"number"===typeof a?$CLJS.Kt:$CLJS.Cd(a)?$CLJS.xs:$CLJS.n(ML(ES,a))?ZP:$CLJS.n(ML(DS,a))?OO:$CLJS.n(ML(GS,a))?rN:"string"===typeof a?$CLJS.ek:$CLJS.n(ML(AS,a))?sM:$CLJS.n(ML($CLJS.Ij,a))?$CLJS.Ij:$CLJS.xt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kt,$CLJS.Il],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.xs],null),new $CLJS.P(null,2,5,$CLJS.Q,[ZP,IS],null),new $CLJS.P(null,
2,5,$CLJS.Q,[OO,HS],null),new $CLJS.P(null,2,5,$CLJS.Q,[rN,$CLJS.JS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,$CLJS.ek],null),new $CLJS.P(null,2,5,$CLJS.Q,[sM,BS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ij,tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xt,$CLJS.xS],null)],null));var NS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KP],null);$CLJS.X(IP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ms,new $CLJS.h(null,1,[$CLJS.ht,"numeric expression arg or interval"],null),Aka,LS],null));
var OS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IP],null);$CLJS.X(kM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,2,[$CLJS.ht,"int greater than zero or numeric expression",$CLJS.Si,function(a){return"number"===typeof a?$CLJS.Kt:$CLJS.xt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kt,bS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xt,HS],null)],null));var Cka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kM],null);
$CLJS.X(sN,NL($CLJS.fG,$CLJS.H(["a",NS,"b",NS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ti,NS],null)])));var PS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sN],null);$CLJS.X(XL,NL($CLJS.uF,$CLJS.H(["s",CS,"start",Cka,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,LS],null)])));var QS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XL],null);$CLJS.X($P,NL($CLJS.Hx,$CLJS.H(["s",CS])));var RS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$P],null);$CLJS.X(yM,NL($CLJS.tG,$CLJS.H(["s",CS])));
var SS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yM],null);$CLJS.X(CM,NL($CLJS.CG,$CLJS.H(["s",CS])));var TS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CM],null);$CLJS.X(VM,NL($CLJS.vG,$CLJS.H(["s",CS])));var US=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VM],null);$CLJS.X(GN,NL($CLJS.vt,$CLJS.H(["s",CS])));var VS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GN],null);$CLJS.X(pM,NL($CLJS.Bt,$CLJS.H(["s",CS])));var WS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pM],null);
$CLJS.X(XQ,NL($CLJS.BG,$CLJS.H(["s",CS,"match",$CLJS.ek,"replacement",$CLJS.ek])));var XS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XQ],null);$CLJS.X(TR,NL($CLJS.BF,$CLJS.H(["a",CS,"b",CS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ti,CS],null)])));var YS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TR],null);$CLJS.X(vO,NL($CLJS.eG,$CLJS.H(["s",CS,"pattern",$CLJS.ek])));var ZS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vO],null);
$CLJS.X(sR,NL($CLJS.Tr,$CLJS.H(["x",OS,"y",OS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ti,OS],null)])));var $S=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sR],null);$CLJS.X(qR,NL($CLJS.at,$CLJS.H(["x",LS,"y",OS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ti,OS],null)])));var aT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qR],null);$CLJS.X(pR,NL($CLJS.yG,$CLJS.H(["x",LS,"y",LS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ti,LS],null)])));var bT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pR],null);
$CLJS.X(rR,NL($CLJS.Ur,$CLJS.H(["x",LS,"y",LS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ti,LS],null)])));var cT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rR],null);$CLJS.X(HP,NL($CLJS.aG,$CLJS.H(["x",LS])));var dT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HP],null);$CLJS.X(AQ,NL($CLJS.xF,$CLJS.H(["x",LS])));var eT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AQ],null);$CLJS.X(WR,NL($CLJS.hG,$CLJS.H(["x",LS])));var fT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WR],null);$CLJS.X(MO,NL($CLJS.CF,$CLJS.H(["x",LS])));
var gT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MO],null);$CLJS.X(SO,NL($CLJS.zF,$CLJS.H(["x",LS,"y",LS])));var hT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SO],null);$CLJS.X(oP,NL($CLJS.QF,$CLJS.H(["x",LS])));var iT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oP],null);$CLJS.X(lM,NL($CLJS.tF,$CLJS.H(["x",LS])));var jT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lM],null);$CLJS.X(NP,NL($CLJS.AG,$CLJS.H(["x",LS])));var kT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NP],null);
$CLJS.X(LM,NL($CLJS.PI,$CLJS.H(["datetime-x",MS,"datetime-y",MS,"unit",zka])));var lT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LM],null);$CLJS.X(cN,NL($CLJS.dJ,$CLJS.H(["datetime",MS,"unit",yka,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,mS],null)])));var mT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cN],null);$CLJS.X(UO,NL($CLJS.LI,$CLJS.H(["date",MS])));var nT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UO],null);$CLJS.X(vP,NL($CLJS.hJ,$CLJS.H(["date",MS])));
var oT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vP],null);$CLJS.X(ZL,NL($CLJS.EI,$CLJS.H(["date",MS])));var pT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZL],null);$CLJS.X(UR,NL($CLJS.YI,$CLJS.H(["date",MS,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,mS],null)])));var qT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UR],null);$CLJS.X(gO,NL($CLJS.XI,$CLJS.H(["date",MS])));var rT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gO],null);$CLJS.X(pP,NL($CLJS.bJ,$CLJS.H(["date",MS])));
var sT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pP],null);$CLJS.X(HM,NL($CLJS.eJ,$CLJS.H(["datetime",MS])));var tT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HM],null);$CLJS.X(lO,NL($CLJS.aJ,$CLJS.H(["datetime",MS])));var uT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lO],null);$CLJS.X(AM,NL($CLJS.KI,$CLJS.H(["datetime",MS])));var vT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AM],null);$CLJS.X(RN,NL($CLJS.GI,$CLJS.H(["datetime",MS,"to",lS,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,lS],null)])));
var wT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RN],null),xT=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.ht,"datetime arithmetic unit"],null),$CLJS.QG,$CLJS.NG,$CLJS.Yi,$CLJS.wk,$CLJS.Rt,$CLJS.tj,$CLJS.Cj,$CLJS.qi,$CLJS.li],null);$CLJS.X(WL,NL($CLJS.$I,$CLJS.H(["datetime",MS,"amount",LS,"unit",xT])));var yT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WL],null);
$CLJS.X(tM,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return NL(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.UI));var zT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tM],null);$CLJS.X(VL,NL($CLJS.fJ,$CLJS.H(["datetime",MS,"amount",LS,"unit",xT])));var AT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VL],null);
$CLJS.X(lQ,OL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof $S?new $CLJS.Gc(function(){return $S},$CLJS.od(BP,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.JP,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),pO,"metabase/mbql/schema.cljc",51,$CLJS.Tr,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Tr,$CLJS.tE,$CLJS.U($CLJS.Vj,FP),cR,$CLJS.U($CLJS.Vj,FP),tN,$CLJS.U($CLJS.Vj,$CLJS.U($CLJS.Qi,FP))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n($S)?$S.H:null])):null));return $CLJS.n(a)?a:pO}(),$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof yT?new $CLJS.Gc(function(){return yT},
$CLJS.od(cja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[KN,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wP,"metabase/mbql/schema.cljc",67,$CLJS.$I,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.$I,$CLJS.TI,$CLJS.U($CLJS.Vj,PR),wQ,$CLJS.U($CLJS.Vj,
BQ),$CLJS.ti,$CLJS.U($CLJS.Vj,JQ)],null),$CLJS.Kc,"Schema for a valid datetime-add clause.",$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?a:wP}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof AT?new $CLJS.Gc(function(){return AT},$CLJS.od(Xha,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,
$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[KN,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NR,"metabase/mbql/schema.cljc",72,$CLJS.fJ,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.fJ,$CLJS.TI,$CLJS.U($CLJS.Vj,PR),wQ,$CLJS.U($CLJS.Vj,BQ),$CLJS.ti,$CLJS.U($CLJS.Vj,JQ)],null),$CLJS.Kc,"Schema for a valid datetime-subtract clause.",$CLJS.n(AT)?AT.H:null])):null));
return $CLJS.n(a)?a:NR}(),AT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof wT?new $CLJS.Gc(function(){return wT},$CLJS.od(aka,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.GI,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qN,"metabase/mbql/schema.cljc",71,$CLJS.GI,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.GI,$CLJS.TI,$CLJS.U($CLJS.Vj,PR),Via,$CLJS.U($CLJS.Vj,fR),aia,$CLJS.U($CLJS.Vj,$CLJS.U(SQ,fR))],null),$CLJS.Kc,"Schema for a valid convert-timezone clause.",$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?a:qN}(),wT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&
"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof zT?new $CLJS.Gc(function(){return zT},$CLJS.od(Dja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.UI,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VO,"metabase/mbql/schema.cljc",
45,$CLJS.UI,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.UI],null),$CLJS.Kc,"Schema for a valid now clause.",$CLJS.n(zT)?zT.H:null])):null));return $CLJS.n(a)?a:VO}(),zT],null)])));$CLJS.BT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rP],null);$CLJS.X(aM,NL($CLJS.rs,$CLJS.H(["first-clause",$CLJS.BT,"second-clause",$CLJS.BT,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ti,$CLJS.BT],null)])));var CT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aM],null);
$CLJS.X(NN,NL($CLJS.ms,$CLJS.H(["first-clause",$CLJS.BT,"second-clause",$CLJS.BT,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ti,$CLJS.BT],null)])));var DT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NN],null);$CLJS.X(vM,NL($CLJS.os,$CLJS.H(["clause",$CLJS.BT])));
var ET=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vM],null),FT=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,3,[$CLJS.ht,":field or :expression reference or :relative-datetime",$CLJS.kt,$CLJS.Ve(":field or :expression reference or :relative-datetime"),$CLJS.Si,function(a){return $CLJS.n(ML($CLJS.SI,a))?$CLJS.SI:$CLJS.xt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SI,oS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xt,$CLJS.xS],null)],null);
$CLJS.X(iQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ls,new $CLJS.h(null,1,[$CLJS.ht,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ms,$CLJS.xs,$CLJS.Il,$CLJS.ek,sS,FT,NS,tS],null)],null));var GT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iQ],null);
$CLJS.X(AO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,2,[$CLJS.ht,"order comparable",$CLJS.Si,function(a){return $CLJS.n(ML($CLJS.Ij,a))?$CLJS.Ij:$CLJS.xt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ij,tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xt,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ms,$CLJS.Il,$CLJS.ek,sS,NS,FT],null)],null)],null));var HT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AO],null);
$CLJS.X(iR,NL($CLJS.Rr,$CLJS.H(["field",GT,"value-or-field",GT,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ti,GT],null)])));var IT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iR],null);$CLJS.X(mR,NL($CLJS.uG,$CLJS.H(["field",GT,"value-or-field",GT,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ti,GT],null)])));var JT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mR],null);$CLJS.X(hR,NL($CLJS.Or,$CLJS.H(["field",HT,"value-or-field",HT])));
var KT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hR],null);$CLJS.X(gR,NL($CLJS.Kr,$CLJS.H(["field",HT,"value-or-field",HT])));var LT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gR],null);$CLJS.X(WM,NL($CLJS.Qr,$CLJS.H(["field",HT,"value-or-field",HT])));var MT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WM],null);$CLJS.X(bN,NL($CLJS.Mr,$CLJS.H(["field",HT,"value-or-field",HT])));var NT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bN],null);$CLJS.X(RP,NL($CLJS.EF,$CLJS.H(["field",HT,"min",HT,"max",HT])));
var OT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RP],null);$CLJS.X(sQ,NL($CLJS.KF,$CLJS.H(["lat-field",HT,"lon-field",HT,"lat-max",HT,"lon-min",HT,"lat-min",HT,"lon-max",HT])));var PT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sQ],null);$CLJS.X(FN,NL($CLJS.DF,$CLJS.H(["field",$CLJS.xS])));var QT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FN],null);$CLJS.X(uO,NL($CLJS.PF,$CLJS.H(["field",$CLJS.xS])));var RT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uO],null);$CLJS.X(XN,NL($CLJS.FF,$CLJS.H(["field",$CLJS.xS])));
var ST=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XN],null);$CLJS.X(dM,NL($CLJS.MF,$CLJS.H(["field",$CLJS.xS])));var TT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dM],null),UT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kG,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.xs],null)],null);$CLJS.X(jO,NL($CLJS.UF,$CLJS.H(["field",CS,"string-or-field",CS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,UT],null)])));var VT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jO],null);
$CLJS.X(cP,NL($CLJS.nG,$CLJS.H(["field",CS,"string-or-field",CS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,UT],null)])));var WT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cP],null);$CLJS.X(VN,NL($CLJS.bG,$CLJS.H(["field",CS,"string-or-field",CS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,UT],null)])));var XT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VN],null);$CLJS.X(LP,NL($CLJS.VF,$CLJS.H(["field",CS,"string-or-field",CS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,UT],null)])));
var YT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LP],null);$CLJS.X(mM,NL($CLJS.oG,$CLJS.H(["field",$CLJS.xS,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,$CLJS.zi,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.js,$CLJS.Lv,$CLJS.Nx,$CLJS.JF],null)],null),"unit",nS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.xs],null)],null)],null)])));var ZT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mM],null);
$CLJS.X(bQ,NL($CLJS.AF,$CLJS.H(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.GK],null),ZR],null)])));var $T=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bQ],null);
$CLJS.X(kN,OL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof CT?new $CLJS.Gc(function(){return CT},$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),EO,"metabase/mbql/schema.cljc",15,$CLJS.rs,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rs,uN,$CLJS.U($CLJS.Vj,JO),mP,$CLJS.U($CLJS.Vj,JO),EN,$CLJS.U($CLJS.Vj,$CLJS.U($CLJS.Qi,JO))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(CT)?CT.H:null])):null));return $CLJS.n(a)?a:EO}(),CT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof DT?new $CLJS.Gc(function(){return DT},
$CLJS.od(qP,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tP,"metabase/mbql/schema.cljc",14,$CLJS.ms,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ms,uN,$CLJS.U($CLJS.Vj,JO),mP,$CLJS.U($CLJS.Vj,JO),EN,$CLJS.U($CLJS.Vj,$CLJS.U($CLJS.Qi,JO))],null),$CLJS.Kc,
"Schema for a valid or clause.",$CLJS.n(DT)?DT.H:null])):null));return $CLJS.n(a)?a:tP}(),DT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof ET?new $CLJS.Gc(function(){return ET},$CLJS.od(cQ,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WO,"metabase/mbql/schema.cljc",15,$CLJS.os,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,$CLJS.JR,$CLJS.U($CLJS.Vj,JO)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(ET)?ET.H:null])):null));return $CLJS.n(a)?a:WO}(),ET],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==
typeof KT?new $CLJS.Gc(function(){return KT},$CLJS.od(zP,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xO,"metabase/mbql/schema.cljc",13,$CLJS.Or,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Or,$CLJS.cG,$CLJS.U($CLJS.Vj,wN),HQ,$CLJS.U($CLJS.Vj,wN)],
null),$CLJS.Kc,"Schema for a valid \x3c clause.",$CLJS.n(KT)?KT.H:null])):null));return $CLJS.n(a)?a:xO}(),KT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof MT?new $CLJS.Gc(function(){return MT},$CLJS.od(CR,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,
1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wO,"metabase/mbql/schema.cljc",14,$CLJS.Qr,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Qr,$CLJS.cG,$CLJS.U($CLJS.Vj,wN),HQ,$CLJS.U($CLJS.Vj,wN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(MT)?MT.H:null])):null));return $CLJS.n(a)?a:wO}(),MT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&
"undefined"!==typeof SL&&"undefined"!==typeof LT?new $CLJS.Gc(function(){return LT},$CLJS.od(xP,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yO,"metabase/mbql/schema.cljc",13,$CLJS.Kr,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Kr,$CLJS.cG,$CLJS.U($CLJS.Vj,
wN),HQ,$CLJS.U($CLJS.Vj,wN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(LT)?LT.H:null])):null));return $CLJS.n(a)?a:yO}(),LT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof NT?new $CLJS.Gc(function(){return NT},$CLJS.od(vR,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],
[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FO,"metabase/mbql/schema.cljc",14,$CLJS.Mr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Mr,$CLJS.cG,$CLJS.U($CLJS.Vj,wN),HQ,$CLJS.U($CLJS.Vj,wN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(NT)?NT.H:null])):null));return $CLJS.n(a)?a:FO}(),NT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&
"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof IT?new $CLJS.Gc(function(){return IT},$CLJS.od(yP,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zO,"metabase/mbql/schema.cljc",13,$CLJS.Rr,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,
[$CLJS.Rr,$CLJS.cG,$CLJS.U($CLJS.Vj,HR),HQ,$CLJS.U($CLJS.Vj,HR),jQ,$CLJS.U($CLJS.Vj,$CLJS.U($CLJS.Qi,HR))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(IT)?IT.H:null])):null));return $CLJS.n(a)?a:zO}(),IT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof JT?new $CLJS.Gc(function(){return JT},$CLJS.od(MM,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,
$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hN,"metabase/mbql/schema.cljc",14,$CLJS.uG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.uG,$CLJS.cG,$CLJS.U($CLJS.Vj,HR),HQ,$CLJS.U($CLJS.Vj,HR),jQ,$CLJS.U($CLJS.Vj,$CLJS.U($CLJS.Qi,HR))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(JT)?JT.H:null])):null));
return $CLJS.n(a)?a:hN}(),JT],null)])));
$CLJS.X(rP,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,2,[$CLJS.ht,"valid filter expression",$CLJS.Si,function(a){return $CLJS.n(ML(GS,a))?$CLJS.TI:$CLJS.n(ML(DS,a))?QR:$CLJS.n(ML(AS,a))?$CLJS.ek:$CLJS.n(ML(ES,a))?$CLJS.xs:$CLJS.xt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TI,$CLJS.JS],null),new $CLJS.P(null,2,5,$CLJS.Q,[QR,HS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,BS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,IS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xt,OL($CLJS.H([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof CT?new $CLJS.Gc(function(){return CT},$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EO,"metabase/mbql/schema.cljc",
15,$CLJS.rs,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rs,uN,$CLJS.U($CLJS.Vj,JO),mP,$CLJS.U($CLJS.Vj,JO),EN,$CLJS.U($CLJS.Vj,$CLJS.U($CLJS.Qi,JO))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(CT)?CT.H:null])):null));return $CLJS.n(a)?a:EO}(),CT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof DT?new $CLJS.Gc(function(){return DT},$CLJS.od(qP,new $CLJS.h(null,1,[$CLJS.gk,
!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tP,"metabase/mbql/schema.cljc",14,$CLJS.ms,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ms,uN,$CLJS.U($CLJS.Vj,JO),mP,$CLJS.U($CLJS.Vj,JO),EN,$CLJS.U($CLJS.Vj,$CLJS.U($CLJS.Qi,JO))],null),$CLJS.Kc,"Schema for a valid or clause.",$CLJS.n(DT)?
DT.H:null])):null));return $CLJS.n(a)?a:tP}(),DT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof ET?new $CLJS.Gc(function(){return ET},$CLJS.od(cQ,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),WO,"metabase/mbql/schema.cljc",15,$CLJS.os,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,$CLJS.JR,$CLJS.U($CLJS.Vj,JO)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(ET)?ET.H:null])):null));return $CLJS.n(a)?a:WO}(),ET],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof IT?new $CLJS.Gc(function(){return IT},$CLJS.od(yP,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,
$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zO,"metabase/mbql/schema.cljc",13,$CLJS.Rr,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Rr,$CLJS.cG,$CLJS.U($CLJS.Vj,HR),HQ,$CLJS.U($CLJS.Vj,HR),jQ,$CLJS.U($CLJS.Vj,$CLJS.U($CLJS.Qi,HR))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(IT)?IT.H:null])):
null));return $CLJS.n(a)?a:zO}(),IT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof JT?new $CLJS.Gc(function(){return JT},$CLJS.od(MM,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),hN,"metabase/mbql/schema.cljc",14,$CLJS.uG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.uG,$CLJS.cG,$CLJS.U($CLJS.Vj,HR),HQ,$CLJS.U($CLJS.Vj,HR),jQ,$CLJS.U($CLJS.Vj,$CLJS.U($CLJS.Qi,HR))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(JT)?JT.H:null])):null));return $CLJS.n(a)?a:hN}(),JT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof KT?new $CLJS.Gc(function(){return KT},
$CLJS.od(zP,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xO,"metabase/mbql/schema.cljc",13,$CLJS.Or,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Or,$CLJS.cG,$CLJS.U($CLJS.Vj,wN),HQ,$CLJS.U($CLJS.Vj,wN)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",
$CLJS.n(KT)?KT.H:null])):null));return $CLJS.n(a)?a:xO}(),KT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof LT?new $CLJS.Gc(function(){return LT},$CLJS.od(xP,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),yO,"metabase/mbql/schema.cljc",13,$CLJS.Kr,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Kr,$CLJS.cG,$CLJS.U($CLJS.Vj,wN),HQ,$CLJS.U($CLJS.Vj,wN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(LT)?LT.H:null])):null));return $CLJS.n(a)?a:yO}(),LT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof MT?new $CLJS.Gc(function(){return MT},$CLJS.od(CR,new $CLJS.h(null,
1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wO,"metabase/mbql/schema.cljc",14,$CLJS.Qr,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Qr,$CLJS.cG,$CLJS.U($CLJS.Vj,wN),HQ,$CLJS.U($CLJS.Vj,wN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(MT)?MT.H:null])):
null));return $CLJS.n(a)?a:wO}(),MT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof NT?new $CLJS.Gc(function(){return NT},$CLJS.od(vR,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),FO,"metabase/mbql/schema.cljc",14,$CLJS.Mr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Mr,$CLJS.cG,$CLJS.U($CLJS.Vj,wN),HQ,$CLJS.U($CLJS.Vj,wN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(NT)?NT.H:null])):null));return $CLJS.n(a)?a:FO}(),NT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof OT?new $CLJS.Gc(function(){return OT},$CLJS.od(uja,new $CLJS.h(null,
1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OM,"metabase/mbql/schema.cljc",19,$CLJS.EF,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.EF,$CLJS.cG,$CLJS.U($CLJS.Vj,wN),$CLJS.go,$CLJS.U($CLJS.Vj,wN),$CLJS.pk,$CLJS.U($CLJS.Vj,wN)],null),$CLJS.Kc,"Schema for a valid between clause.",
$CLJS.n(OT)?OT.H:null])):null));return $CLJS.n(a)?a:OM}(),OT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof VT?new $CLJS.Gc(function(){return VT},$CLJS.od(wja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),MQ,"metabase/mbql/schema.cljc",23,$CLJS.UF,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.UF,$CLJS.cG,$CLJS.U($CLJS.Vj,GQ),gN,$CLJS.U($CLJS.Vj,GQ),$CLJS.kj,$CLJS.U($CLJS.Vj,$CLJS.U(SQ,aQ))],null),$CLJS.Kc,"Schema for a valid starts-with clause.",$CLJS.n(VT)?VT.H:null])):null));return $CLJS.n(a)?a:MQ}(),VT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof WT?new $CLJS.Gc(function(){return WT},
$CLJS.od(yia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lN,"metabase/mbql/schema.cljc",21,$CLJS.nG,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.nG,$CLJS.cG,$CLJS.U($CLJS.Vj,GQ),gN,$CLJS.U($CLJS.Vj,GQ),$CLJS.kj,$CLJS.U($CLJS.Vj,$CLJS.U(SQ,aQ))],null),
$CLJS.Kc,"Schema for a valid ends-with clause.",$CLJS.n(WT)?WT.H:null])):null));return $CLJS.n(a)?a:lN}(),WT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof XT?new $CLJS.Gc(function(){return XT},$CLJS.od(Kia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,
1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xN,"metabase/mbql/schema.cljc",20,$CLJS.bG,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bG,$CLJS.cG,$CLJS.U($CLJS.Vj,GQ),gN,$CLJS.U($CLJS.Vj,GQ),$CLJS.kj,$CLJS.U($CLJS.Vj,$CLJS.U(SQ,aQ))],null),$CLJS.Kc,"Schema for a valid contains clause.",$CLJS.n(XT)?XT.H:null])):null));return $CLJS.n(a)?a:xN}(),XT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==
typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof YT?new $CLJS.Gc(function(){return YT},$CLJS.od(oka,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,UQ,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bM,"metabase/mbql/schema.cljc",36,$CLJS.VF,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.VF,$CLJS.cG,$CLJS.U($CLJS.Vj,GQ),gN,$CLJS.U($CLJS.Vj,GQ),$CLJS.kj,$CLJS.U($CLJS.Vj,$CLJS.U(SQ,aQ))],null),$CLJS.Kc,"Schema for a valid does-not-contain clause.",$CLJS.n(YT)?YT.H:null])):null));return $CLJS.n(a)?a:bM}(),YT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof PT?new $CLJS.Gc(function(){return PT},$CLJS.od(Yja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),
$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,UQ,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KQ,"metabase/mbql/schema.cljc",26,$CLJS.KF,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.KF,gia,$CLJS.U($CLJS.Vj,wN),ska,$CLJS.U($CLJS.Vj,wN),eka,$CLJS.U($CLJS.Vj,wN),jia,$CLJS.U($CLJS.Vj,wN),Zja,$CLJS.U($CLJS.Vj,wN),fia,$CLJS.U($CLJS.Vj,
wN)],null),$CLJS.Kc,"Schema for a valid inside clause.",$CLJS.n(PT)?PT.H:null])):null));return $CLJS.n(a)?a:KQ}(),PT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof ST?new $CLJS.Gc(function(){return ST},$CLJS.od(Lia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,UQ,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,
1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eP,"metabase/mbql/schema.cljc",28,$CLJS.FF,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,$CLJS.cG,$CLJS.U($CLJS.Vj,pN)],null),$CLJS.Kc,"Schema for a valid is-empty clause.",$CLJS.n(ST)?ST.H:null])):null));return $CLJS.n(a)?a:eP}(),ST],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&
"undefined"!==typeof TT?new $CLJS.Gc(function(){return TT},$CLJS.od(Jja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,UQ,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SP,"metabase/mbql/schema.cljc",29,$CLJS.MF,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MF,$CLJS.cG,$CLJS.U($CLJS.Vj,pN)],null),
$CLJS.Kc,"Schema for a valid not-empty clause.",$CLJS.n(TT)?TT.H:null])):null));return $CLJS.n(a)?a:SP}(),TT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof QT?new $CLJS.Gc(function(){return QT},$CLJS.od(jka,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,UQ,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,
1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DN,"metabase/mbql/schema.cljc",27,$CLJS.DF,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DF,$CLJS.cG,$CLJS.U($CLJS.Vj,pN)],null),$CLJS.Kc,"Schema for a valid is-null clause.",$CLJS.n(QT)?QT.H:null])):null));return $CLJS.n(a)?a:DN}(),QT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&
"undefined"!==typeof RT?new $CLJS.Gc(function(){return RT},$CLJS.od(Xia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,UQ,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TM,"metabase/mbql/schema.cljc",28,$CLJS.PF,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PF,$CLJS.cG,$CLJS.U($CLJS.Vj,pN)],null),
$CLJS.Kc,"Schema for a valid not-null clause.",$CLJS.n(RT)?RT.H:null])):null));return $CLJS.n(a)?a:TM}(),RT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof ZT?new $CLJS.Gc(function(){return ZT},$CLJS.od(Yha,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,UQ,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,
1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qQ,"metabase/mbql/schema.cljc",33,$CLJS.oG,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.oG,$CLJS.cG,$CLJS.U($CLJS.Vj,pN),$CLJS.xk,$CLJS.U($CLJS.Vj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,$CLJS.zi,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.js,$CLJS.Lv,$CLJS.Nx,$CLJS.JF],null)],null)),$CLJS.ti,$CLJS.U($CLJS.Vj,DO),$CLJS.kj,$CLJS.U($CLJS.Vj,$CLJS.U(SQ,zja))],null),$CLJS.Kc,"Schema for a valid time-interval clause.",
$CLJS.n(ZT)?ZT.H:null])):null));return $CLJS.n(a)?a:qQ}(),ZT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof $T?new $CLJS.Gc(function(){return $T},$CLJS.od(eja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,UQ,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),tR,"metabase/mbql/schema.cljc",27,$CLJS.AF,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AF,nia,$CLJS.U($CLJS.Vj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,Pja,FM],null))],null),$CLJS.Kc,"Schema for a valid segment clause.",$CLJS.n($T)?$T.H:null])):null));return $CLJS.n(a)?a:tR}(),$T],null)]))],null)],null));
$CLJS.X(dQ,NL($CLJS.LF,$CLJS.H(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.ht,":case subclause"],null),$CLJS.BT,NS],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.h(null,1,[$CLJS.ht,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ei,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),NS],null)],null)],null)])));
var aU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dQ],null);
$CLJS.X(LQ,OL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof $S?new $CLJS.Gc(function(){return $S},$CLJS.od(BP,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.JP,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),pO,"metabase/mbql/schema.cljc",51,$CLJS.Tr,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Tr,$CLJS.tE,$CLJS.U($CLJS.Vj,FP),cR,$CLJS.U($CLJS.Vj,FP),tN,$CLJS.U($CLJS.Vj,$CLJS.U($CLJS.Qi,FP))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n($S)?$S.H:null])):null));return $CLJS.n(a)?a:pO}(),$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof aT?new $CLJS.Gc(function(){return aT},
$CLJS.od(qja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.JP,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qO,"metabase/mbql/schema.cljc",51,$CLJS.at,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.at,$CLJS.tE,$CLJS.U($CLJS.Vj,BQ),
cR,$CLJS.U($CLJS.Vj,FP),tN,$CLJS.U($CLJS.Vj,$CLJS.U($CLJS.Qi,FP))],null),$CLJS.Kc,"Schema for a valid - clause.",$CLJS.n(aT)?aT.H:null])):null));return $CLJS.n(a)?a:qO}(),aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL?new $CLJS.Gc(function(){return bT},$CLJS.od(pja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,TO,$CLJS.Xi,$CLJS.sj,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],
[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.JP,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Wz,"metabase/mbql/schema.cljc",$CLJS.yG,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.yG,$CLJS.tE,$CLJS.U($CLJS.Vj,BQ),cR,$CLJS.U($CLJS.Vj,BQ),tN,$CLJS.U($CLJS.Vj,$CLJS.U($CLJS.Qi,BQ))],null),$CLJS.Kc,"Schema for a valid / clause.",$CLJS.n(bT)?bT.H:null])):null));return $CLJS.n(a)?
a:$CLJS.Wz}(),bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof cT?new $CLJS.Gc(function(){return cT},$CLJS.od(rja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.JP,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),oO,"metabase/mbql/schema.cljc",51,$CLJS.Ur,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ur,$CLJS.tE,$CLJS.U($CLJS.Vj,BQ),cR,$CLJS.U($CLJS.Vj,BQ),tN,$CLJS.U($CLJS.Vj,$CLJS.U($CLJS.Qi,BQ))],null),$CLJS.Kc,"Schema for a valid * clause.",$CLJS.n(cT)?cT.H:null])):null));return $CLJS.n(a)?a:oO}(),cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof PS?new $CLJS.Gc(function(){return PS},
$CLJS.od(nQ,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.JP,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DQ,"metabase/mbql/schema.cljc",58,$CLJS.fG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.fG,lR,$CLJS.U($CLJS.Vj,gM),kR,$CLJS.U($CLJS.Vj,
gM),tN,$CLJS.U($CLJS.Vj,$CLJS.U($CLJS.Qi,gM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(PS)?PS.H:null])):null));return $CLJS.n(a)?a:DQ}(),PS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof RS?new $CLJS.Gc(function(){return RS},$CLJS.od(Ija,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,
$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.JP,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VP,"metabase/mbql/schema.cljc",56,$CLJS.Hx,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hx,$CLJS.dy,$CLJS.U($CLJS.Vj,GQ)],null),$CLJS.Kc,"Schema for a valid length clause.",$CLJS.n(RS)?RS.H:null])):null));return $CLJS.n(a)?a:VP}(),RS],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof dT?new $CLJS.Gc(function(){return dT},$CLJS.od(zia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.JP,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),QO,"metabase/mbql/schema.cljc",55,$CLJS.aG,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aG,$CLJS.tE,$CLJS.U($CLJS.Vj,BQ)],null),$CLJS.Kc,"Schema for a valid floor clause.",$CLJS.n(dT)?dT.H:null])):null));return $CLJS.n(a)?a:QO}(),dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof eT?new $CLJS.Gc(function(){return eT},$CLJS.od(tia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),
$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.JP,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EM,"metabase/mbql/schema.cljc",54,$CLJS.xF,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xF,$CLJS.tE,$CLJS.U($CLJS.Vj,BQ)],null),$CLJS.Kc,"Schema for a valid ceil clause.",$CLJS.n(eT)?
eT.H:null])):null));return $CLJS.n(a)?a:EM}(),eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof fT?new $CLJS.Gc(function(){return fT},$CLJS.od(Hia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.JP,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,
1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GO,"metabase/mbql/schema.cljc",55,$CLJS.hG,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,$CLJS.tE,$CLJS.U($CLJS.Vj,BQ)],null),$CLJS.Kc,"Schema for a valid round clause.",$CLJS.n(fT)?fT.H:null])):null));return $CLJS.n(a)?a:GO}(),fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&
"undefined"!==typeof gT?new $CLJS.Gc(function(){return gT},$CLJS.od(pka,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.JP,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iP,"metabase/mbql/schema.cljc",53,$CLJS.CF,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.CF,$CLJS.tE,$CLJS.U($CLJS.Vj,BQ)],null),$CLJS.Kc,"Schema for a valid abs clause.",$CLJS.n(gT)?gT.H:null])):null));return $CLJS.n(a)?a:iP}(),gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof hT?new $CLJS.Gc(function(){return hT},$CLJS.od(cka,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,
PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[zR,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VQ,"metabase/mbql/schema.cljc",69,$CLJS.zF,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.zF,$CLJS.tE,$CLJS.U($CLJS.Vj,BQ),cR,$CLJS.U($CLJS.Vj,BQ)],null),$CLJS.Kc,"Schema for a valid power clause.",$CLJS.n(hT)?hT.H:null])):null));return $CLJS.n(a)?a:VQ}(),hT],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof iT?new $CLJS.Gc(function(){return iT},$CLJS.od($ia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[zR,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),kO,"metabase/mbql/schema.cljc",68,$CLJS.QF,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QF,$CLJS.tE,$CLJS.U($CLJS.Vj,BQ)],null),$CLJS.Kc,"Schema for a valid sqrt clause.",$CLJS.n(iT)?iT.H:null])):null));return $CLJS.n(a)?a:kO}(),iT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof jT?new $CLJS.Gc(function(){return jT},$CLJS.od(dka,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),
$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[zR,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IO,"metabase/mbql/schema.cljc",67,$CLJS.tF,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tF,$CLJS.tE,$CLJS.U($CLJS.Vj,BQ)],null),$CLJS.Kc,"Schema for a valid exp clause.",$CLJS.n(jT)?
jT.H:null])):null));return $CLJS.n(a)?a:IO}(),jT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof kT?new $CLJS.Gc(function(){return kT},$CLJS.od(mia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[zR,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,
1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ER,"metabase/mbql/schema.cljc",67,$CLJS.AG,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,$CLJS.tE,$CLJS.U($CLJS.Vj,BQ)],null),$CLJS.Kc,"Schema for a valid log clause.",$CLJS.n(kT)?kT.H:null])):null));return $CLJS.n(a)?a:ER}(),kT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&
"undefined"!==typeof aU?new $CLJS.Gc(function(){return aU},$CLJS.od(rM,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.wF,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$O,"metabase/mbql/schema.cljc",61,$CLJS.LF,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.LF,$CLJS.Uw,$CLJS.U($CLJS.Vj,JN),$CLJS.kj,$CLJS.U($CLJS.Vj,$CLJS.U(SQ,sP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(aU)?aU.H:null])):null));return $CLJS.n(a)?a:$O}(),aU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof lT?new $CLJS.Gc(function(){return lT},$CLJS.od(pia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,
$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.PI,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jP,"metabase/mbql/schema.cljc",65,$CLJS.PI,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.PI,Bia,$CLJS.U($CLJS.Vj,PR),Cia,$CLJS.U($CLJS.Vj,PR),$CLJS.ti,$CLJS.U($CLJS.Vj,hja)],null),$CLJS.Kc,"Schema for a valid datetime-diff clause.",
$CLJS.n(lT)?lT.H:null])):null));return $CLJS.n(a)?a:jP}(),lT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof mT?new $CLJS.Gc(function(){return mT},$CLJS.od(Mja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.dJ,"null"],null),null),$CLJS.od(zQ,
new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CP,"metabase/mbql/schema.cljc",71,$CLJS.dJ,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.dJ,$CLJS.TI,$CLJS.U($CLJS.Vj,PR),$CLJS.ti,$CLJS.U($CLJS.Vj,ika),$CLJS.Bi,$CLJS.U($CLJS.Vj,$CLJS.U(SQ,YR))],null),$CLJS.Kc,"Schema for a valid temporal-extract clause.",$CLJS.n(mT)?mT.H:null])):null));return $CLJS.n(a)?a:CP}(),mT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof nT?new $CLJS.Gc(function(){return nT},$CLJS.od(Tia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,UQ,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.dJ,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aN,"metabase/mbql/schema.cljc",71,$CLJS.LI,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LI,$CLJS.lP,$CLJS.U($CLJS.Vj,PR)],null),$CLJS.Kc,"Schema for a valid get-year clause.",$CLJS.n(nT)?nT.H:null])):null));return $CLJS.n(a)?a:aN}(),nT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof oT?new $CLJS.Gc(function(){return oT},$CLJS.od(Iia,new $CLJS.h(null,1,[$CLJS.gk,!0],
null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,UQ,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.dJ,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AP,"metabase/mbql/schema.cljc",74,$CLJS.hJ,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hJ,$CLJS.lP,$CLJS.U($CLJS.Vj,PR)],null),$CLJS.Kc,"Schema for a valid get-quarter clause.",
$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:AP}(),oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof pT?new $CLJS.Gc(function(){return pT},$CLJS.od(oja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,UQ,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.dJ,"null"],null),null),
$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XP,"metabase/mbql/schema.cljc",72,$CLJS.EI,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EI,$CLJS.lP,$CLJS.U($CLJS.Vj,PR)],null),$CLJS.Kc,"Schema for a valid get-month clause.",$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:XP}(),pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==
typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof qT?new $CLJS.Gc(function(){return qT},$CLJS.od(hka,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,UQ,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.dJ,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WP,"metabase/mbql/schema.cljc",
71,$CLJS.YI,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.YI,$CLJS.lP,$CLJS.U($CLJS.Vj,PR),$CLJS.Bi,$CLJS.U($CLJS.Vj,$CLJS.U(SQ,YR))],null),$CLJS.Kc,"Schema for a valid get-week clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:WP}(),qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof rT?new $CLJS.Gc(function(){return rT},$CLJS.od(dia,new $CLJS.h(null,1,[$CLJS.gk,
!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,UQ,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.dJ,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PP,"metabase/mbql/schema.cljc",70,$CLJS.XI,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XI,$CLJS.lP,$CLJS.U($CLJS.Vj,PR)],null),$CLJS.Kc,"Schema for a valid get-day clause.",
$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:PP}(),rT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof sT?new $CLJS.Gc(function(){return sT},$CLJS.od(ija,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,UQ,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.dJ,"null"],null),null),
$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GP,"metabase/mbql/schema.cljc",78,$CLJS.bJ,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bJ,$CLJS.lP,$CLJS.U($CLJS.Vj,PR)],null),$CLJS.Kc,"Schema for a valid get-day-of-week clause.",$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:GP}(),sT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==
typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof tT?new $CLJS.Gc(function(){return tT},$CLJS.od(gja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,UQ,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.dJ,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hM,"metabase/mbql/schema.cljc",
71,$CLJS.eJ,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eJ,$CLJS.TI,$CLJS.U($CLJS.Vj,PR)],null),$CLJS.Kc,"Schema for a valid get-hour clause.",$CLJS.n(tT)?tT.H:null])):null));return $CLJS.n(a)?a:hM}(),tT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof uT?new $CLJS.Gc(function(){return uT},$CLJS.od(Qja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,
$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,UQ,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.dJ,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IR,"metabase/mbql/schema.cljc",73,$CLJS.aJ,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aJ,$CLJS.TI,$CLJS.U($CLJS.Vj,PR)],null),$CLJS.Kc,"Schema for a valid get-minute clause.",$CLJS.n(uT)?uT.H:null])):null));
return $CLJS.n(a)?a:IR}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof vT?new $CLJS.Gc(function(){return vT},$CLJS.od(dja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,UQ,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.dJ,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,
[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bP,"metabase/mbql/schema.cljc",73,$CLJS.KI,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KI,$CLJS.TI,$CLJS.U($CLJS.Vj,PR)],null),$CLJS.Kc,"Schema for a valid get-second clause.",$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:bP}(),vT],null)])));
$CLJS.X(wM,OL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof QS?new $CLJS.Gc(function(){return QS},$CLJS.od(Gja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.JP,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),eQ,"metabase/mbql/schema.cljc",59,$CLJS.uF,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.uF,$CLJS.dy,$CLJS.U($CLJS.Vj,GQ),$CLJS.qv,$CLJS.U($CLJS.Vj,Eia),$CLJS.Hx,$CLJS.U($CLJS.Vj,$CLJS.U(SQ,BQ))],null),$CLJS.Kc,"Schema for a valid substring clause.",$CLJS.n(QS)?QS.H:null])):null));return $CLJS.n(a)?a:eQ}(),QS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof SS?new $CLJS.Gc(function(){return SS},
$CLJS.od(fka,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.JP,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CQ,"metabase/mbql/schema.cljc",54,$CLJS.tG,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,$CLJS.dy,$CLJS.U($CLJS.Vj,GQ)],
null),$CLJS.Kc,"Schema for a valid trim clause.",$CLJS.n(SS)?SS.H:null])):null));return $CLJS.n(a)?a:CQ}(),SS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof US?new $CLJS.Gc(function(){return US},$CLJS.od(jja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,
1,[$CLJS.JP,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kQ,"metabase/mbql/schema.cljc",55,$CLJS.vG,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,$CLJS.dy,$CLJS.U($CLJS.Vj,GQ)],null),$CLJS.Kc,"Schema for a valid ltrim clause.",$CLJS.n(US)?US.H:null])):null));return $CLJS.n(a)?a:kQ}(),US],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&
"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof TS?new $CLJS.Gc(function(){return TS},$CLJS.od(Hja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.JP,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nP,"metabase/mbql/schema.cljc",
55,$CLJS.CG,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CG,$CLJS.dy,$CLJS.U($CLJS.Vj,GQ)],null),$CLJS.Kc,"Schema for a valid rtrim clause.",$CLJS.n(TS)?TS.H:null])):null));return $CLJS.n(a)?a:nP}(),TS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof XS?new $CLJS.Gc(function(){return XS},$CLJS.od(Zha,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,
$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.JP,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nR,"metabase/mbql/schema.cljc",57,$CLJS.BG,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.BG,$CLJS.dy,$CLJS.U($CLJS.Vj,GQ),kia,$CLJS.U($CLJS.Vj,$CLJS.ek),$CLJS.cA,$CLJS.U($CLJS.Vj,$CLJS.ek)],null),$CLJS.Kc,"Schema for a valid replace clause.",
$CLJS.n(XS)?XS.H:null])):null));return $CLJS.n(a)?a:nR}(),XS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof WS?new $CLJS.Gc(function(){return WS},$CLJS.od(Kja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.JP,"null"],null),null),$CLJS.od(zQ,
new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SR,"metabase/mbql/schema.cljc",55,$CLJS.Bt,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bt,$CLJS.dy,$CLJS.U($CLJS.Vj,GQ)],null),$CLJS.Kc,"Schema for a valid lower clause.",$CLJS.n(WS)?WS.H:null])):null));return $CLJS.n(a)?a:SR}(),WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==
typeof SL&&"undefined"!==typeof VS?new $CLJS.Gc(function(){return VS},$CLJS.od(gka,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.JP,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PQ,"metabase/mbql/schema.cljc",55,$CLJS.vt,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,$CLJS.dy,$CLJS.U($CLJS.Vj,GQ)],null),$CLJS.Kc,"Schema for a valid upper clause.",$CLJS.n(VS)?VS.H:null])):null));return $CLJS.n(a)?a:PQ}(),VS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof YS?new $CLJS.Gc(function(){return YS},$CLJS.od(yja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,
$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.JP,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bO,"metabase/mbql/schema.cljc",56,$CLJS.BF,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.BF,lR,$CLJS.U($CLJS.Vj,GQ),kR,$CLJS.U($CLJS.Vj,GQ),tN,$CLJS.U($CLJS.Vj,$CLJS.U($CLJS.Qi,GQ))],null),$CLJS.Kc,"Schema for a valid concat clause.",
$CLJS.n(YS)?YS.H:null])):null));return $CLJS.n(a)?a:bO}(),YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof ZS?new $CLJS.Gc(function(){return ZS},$CLJS.od(Fja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.JP,"null",$CLJS.eea,"null"],
null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZM,"metabase/mbql/schema.cljc",74,$CLJS.eG,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.eG,$CLJS.dy,$CLJS.U($CLJS.Vj,GQ),$CLJS.bA,$CLJS.U($CLJS.Vj,$CLJS.ek)],null),$CLJS.Kc,"Schema for a valid regex-match-first clause.",$CLJS.n(ZS)?ZS.H:null])):null));return $CLJS.n(a)?a:ZM}(),ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==
typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof PS?new $CLJS.Gc(function(){return PS},$CLJS.od(nQ,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.JP,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
DQ,"metabase/mbql/schema.cljc",58,$CLJS.fG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.fG,lR,$CLJS.U($CLJS.Vj,gM),kR,$CLJS.U($CLJS.Vj,gM),tN,$CLJS.U($CLJS.Vj,$CLJS.U($CLJS.Qi,gM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(PS)?PS.H:null])):null));return $CLJS.n(a)?a:DQ}(),PS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof aU?new $CLJS.Gc(function(){return aU},
$CLJS.od(rM,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.wF,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$O,"metabase/mbql/schema.cljc",61,$CLJS.LF,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.LF,$CLJS.Uw,$CLJS.U($CLJS.Vj,JN),
$CLJS.kj,$CLJS.U($CLJS.Vj,$CLJS.U(SQ,sP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(aU)?aU.H:null])):null));return $CLJS.n(a)?a:$O}(),aU],null)])));
$CLJS.bU=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,2,[$CLJS.ht,":field or :expression reference or expression",$CLJS.Si,function(a){return $CLJS.n(ML(DS,a))?QR:$CLJS.n(ML(AS,a))?$CLJS.ek:$CLJS.n(ML(ES,a))?$CLJS.xs:$CLJS.n(ML(GS,a))?$CLJS.TI:$CLJS.n(ML($CLJS.LF,a))?$CLJS.LF:$CLJS.xt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[QR,HS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,BS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,IS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TI,$CLJS.JS],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LF,aU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xt,$CLJS.xS],null)],null);$CLJS.X(wR,NL($CLJS.Jx,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,$CLJS.xS],null)])));var cU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wR],null);$CLJS.X(kP,NL($CLJS.dG,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,$CLJS.xS],null)])));var dU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kP],null);$CLJS.X(uM,NL($CLJS.XF,$CLJS.H(["field-or-expression",$CLJS.bU])));
var eU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uM],null);$CLJS.X(oR,NL($CLJS.zG,$CLJS.H(["field-or-expression",$CLJS.bU])));var fU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oR],null);$CLJS.X(UN,NL($CLJS.HF,$CLJS.H(["field-or-expression",$CLJS.bU])));var gU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UN],null);$CLJS.X(EQ,NL($CLJS.pG,$CLJS.H(["field-or-expression",$CLJS.bU])));var hU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EQ],null);$CLJS.X(PN,NL($CLJS.go,$CLJS.H(["field-or-expression",$CLJS.bU])));
var iU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PN],null);$CLJS.X(BN,NL($CLJS.pk,$CLJS.H(["field-or-expression",$CLJS.bU])));var jU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BN],null);$CLJS.X(vN,NL($CLJS.xG,$CLJS.H(["field-or-expression",$CLJS.bU,"pred",$CLJS.BT])));var kU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vN],null);$CLJS.X(MN,NL($CLJS.lG,$CLJS.H(["pred",$CLJS.BT])));var lU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MN],null);$CLJS.X(vQ,NL($CLJS.jG,$CLJS.H(["pred",$CLJS.BT])));
var mU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vQ],null);$CLJS.X(SM,NL($CLJS.sG,$CLJS.H(["field-or-expression",$CLJS.bU])));var nU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SM],null);$CLJS.X(UL,NL($CLJS.IF,$CLJS.H(["field-or-expression",$CLJS.bU])));var oU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UL],null);$CLJS.X(YN,NL($CLJS.qG,$CLJS.H(["field-or-expression",$CLJS.bU])));var pU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YN],null);$CLJS.X(LO,NL($CLJS.OF,$CLJS.H(["field-or-expression",$CLJS.bU,"percentile",LS])));
var qU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LO],null);$CLJS.X(yQ,NL($CLJS.YF,$CLJS.H(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EK],null),ZR],null)])));var rU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yQ],null);
$CLJS.X(uQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,2,[$CLJS.ht,"unnamed aggregation clause or numeric expression",$CLJS.Si,function(a){return $CLJS.n(ML(DS,a))?OO:$CLJS.xt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[OO,HS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xt,OL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof eU?new $CLJS.Gc(function(){return eU},$CLJS.od(Eja,
new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.wF,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XO,"metabase/mbql/schema.cljc",60,$CLJS.XF,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XF,QQ,$CLJS.U($CLJS.Vj,IQ)],null),$CLJS.Kc,"Schema for a valid avg clause.",
$CLJS.n(eU)?eU.H:null])):null));return $CLJS.n(a)?a:XO}(),eU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof fU?new $CLJS.Gc(function(){return fU},$CLJS.od(Pia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.wF,"null"],null),null),$CLJS.od(zQ,
new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bR,"metabase/mbql/schema.cljc",64,$CLJS.zG,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zG,QQ,$CLJS.U($CLJS.Vj,IQ)],null),$CLJS.Kc,"Schema for a valid cum-sum clause.",$CLJS.n(fU)?fU.H:null])):null));return $CLJS.n(a)?a:bR}(),fU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==
typeof SL&&"undefined"!==typeof gU?new $CLJS.Gc(function(){return gU},$CLJS.od(Gia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.wF,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aP,"metabase/mbql/schema.cljc",65,$CLJS.HF,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HF,QQ,$CLJS.U($CLJS.Vj,IQ)],null),$CLJS.Kc,"Schema for a valid distinct clause.",$CLJS.n(gU)?gU.H:null])):null));return $CLJS.n(a)?a:aP}(),gU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof nU?new $CLJS.Gc(function(){return nU},$CLJS.od($ja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,
$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.mG,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oM,"metabase/mbql/schema.cljc",76,$CLJS.sG,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sG,QQ,$CLJS.U($CLJS.Vj,IQ)],null),$CLJS.Kc,"Schema for a valid stddev clause.",$CLJS.n(nU)?nU.H:null])):null));return $CLJS.n(a)?a:oM}(),nU],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof hU?new $CLJS.Gc(function(){return hU},$CLJS.od(tja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.wF,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),KM,"metabase/mbql/schema.cljc",60,$CLJS.pG,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,QQ,$CLJS.U($CLJS.Vj,IQ)],null),$CLJS.Kc,"Schema for a valid sum clause.",$CLJS.n(hU)?hU.H:null])):null));return $CLJS.n(a)?a:KM}(),hU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof iU?new $CLJS.Gc(function(){return iU},$CLJS.od(hia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,
$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.wF,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Os,"metabase/mbql/schema.cljc",60,$CLJS.go,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,QQ,$CLJS.U($CLJS.Vj,IQ)],null),$CLJS.Kc,"Schema for a valid min clause.",$CLJS.n(iU)?iU.H:
null])):null));return $CLJS.n(a)?a:$CLJS.Os}(),iU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof jU?new $CLJS.Gc(function(){return jU},$CLJS.od(cia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.wF,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,
1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Ss,"metabase/mbql/schema.cljc",60,$CLJS.pk,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pk,QQ,$CLJS.U($CLJS.Vj,IQ)],null),$CLJS.Kc,"Schema for a valid max clause.",$CLJS.n(jU)?jU.H:null])):null));return $CLJS.n(a)?a:$CLJS.Ss}(),jU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&
"undefined"!==typeof rU?new $CLJS.Gc(function(){return rU},$CLJS.od(Dia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aO,"metabase/mbql/schema.cljc",18,$CLJS.YF,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YF,Qia,$CLJS.U($CLJS.Vj,new $CLJS.P(null,3,
5,$CLJS.Q,[$CLJS.ms,Uia,FM],null))],null),$CLJS.Kc,"Schema for a valid metric clause.",$CLJS.n(rU)?rU.H:null])):null));return $CLJS.n(a)?a:aO}(),rU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof mU?new $CLJS.Gc(function(){return mU},$CLJS.od(bja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,
$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.wF,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TN,"metabase/mbql/schema.cljc",62,$CLJS.jG,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jG,$CLJS.yo,$CLJS.U($CLJS.Vj,JO)],null),$CLJS.Kc,"Schema for a valid share clause.",$CLJS.n(mU)?mU.H:null])):null));return $CLJS.n(a)?a:TN}(),mU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof lU?new $CLJS.Gc(function(){return lU},$CLJS.od(iia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.wF,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),GM,"metabase/mbql/schema.cljc",68,$CLJS.lG,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lG,$CLJS.yo,$CLJS.U($CLJS.Vj,JO)],null),$CLJS.Kc,"Schema for a valid count-where clause.",$CLJS.n(lU)?lU.H:null])):null));return $CLJS.n(a)?a:GM}(),lU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof kU?new $CLJS.Gc(function(){return kU},$CLJS.od(nka,new $CLJS.h(null,1,[$CLJS.gk,!0],
null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.wF,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YO,"metabase/mbql/schema.cljc",66,$CLJS.xG,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.xG,QQ,$CLJS.U($CLJS.Vj,IQ),$CLJS.yo,$CLJS.U($CLJS.Vj,JO)],null),$CLJS.Kc,"Schema for a valid sum-where clause.",
$CLJS.n(kU)?kU.H:null])):null));return $CLJS.n(a)?a:YO}(),kU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof aU?new $CLJS.Gc(function(){return aU},$CLJS.od(rM,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.wF,"null"],null),null),$CLJS.od(zQ,
new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$O,"metabase/mbql/schema.cljc",61,$CLJS.LF,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.LF,$CLJS.Uw,$CLJS.U($CLJS.Vj,JN),$CLJS.kj,$CLJS.U($CLJS.Vj,$CLJS.U(SQ,sP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(aU)?aU.H:null])):null));return $CLJS.n(a)?a:$O}(),aU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&
"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof pU?new $CLJS.Gc(function(){return pU},$CLJS.od(Tja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.ZF,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$Q,"metabase/mbql/schema.cljc",
68,$CLJS.qG,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qG,QQ,$CLJS.U($CLJS.Vj,IQ)],null),$CLJS.Kc,"Schema for a valid median clause.",$CLJS.n(pU)?pU.H:null])):null));return $CLJS.n(a)?a:$Q}(),pU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof qU?new $CLJS.Gc(function(){return qU},$CLJS.od(nja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,
TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.ZF,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xQ,"metabase/mbql/schema.cljc",72,$CLJS.OF,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.OF,QQ,$CLJS.U($CLJS.Vj,IQ),$CLJS.OF,$CLJS.U($CLJS.Vj,BQ)],null),$CLJS.Kc,"Schema for a valid percentile clause.",$CLJS.n(qU)?qU.H:null])):
null));return $CLJS.n(a)?a:xQ}(),qU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof oU?new $CLJS.Gc(function(){return oU},$CLJS.od(sia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),jM,"metabase/mbql/schema.cljc",77,$CLJS.IF,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IF,QQ,$CLJS.U($CLJS.Vj,IQ)],null),$CLJS.Kc,"Schema for a valid var clause.",$CLJS.n(oU)?oU.H:null])):null));return $CLJS.n(a)?a:jM}(),oU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof dU?new $CLJS.Gc(function(){return dU},$CLJS.od(eia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,
$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,UQ,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.wF,"null"],null),null),$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KO,"metabase/mbql/schema.cljc",74,$CLJS.dG,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dG,$CLJS.cG,$CLJS.U($CLJS.Vj,$CLJS.U(SQ,pN))],null),$CLJS.Kc,"Schema for a valid cum-count clause.",
$CLJS.n(dU)?dU.H:null])):null));return $CLJS.n(a)?a:KO}(),dU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof cU?new $CLJS.Gc(function(){return cU},$CLJS.od(Fia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([RO,$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,UQ,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.wF,"null"],null),null),
$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.yi,"metabase/mbql/schema.cljc",70,$CLJS.Jx,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Jx,$CLJS.cG,$CLJS.U($CLJS.Vj,$CLJS.U(SQ,pN))],null),$CLJS.Kc,"Schema for a valid count clause.",$CLJS.n(cU)?cU.H:null])):null));return $CLJS.n(a)?a:$CLJS.yi}(),cU],null)]))],null)],null));
$CLJS.X(MP,NL($CLJS.eM,$CLJS.H(["aggregation",uQ,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ok,new $CLJS.h(null,1,[$CLJS.ht,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),ZR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gE,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),ZR],null)],null)])));
$CLJS.X(RQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,2,[$CLJS.ht,"aggregation clause or numeric expression",$CLJS.Si,function(a){return $CLJS.n(ML($CLJS.eM,a))?$CLJS.eM:IN}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[IN,uQ],null)],null));$CLJS.X(nM,NL($CLJS.$F,$CLJS.H(["field",zS])));var sU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nM],null);$CLJS.X(NO,NL($CLJS.TF,$CLJS.H(["field",zS])));
tU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NO],null);
Dka=OL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof sU?new $CLJS.Gc(function(){return sU},$CLJS.od(Xja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
tO,"metabase/mbql/schema.cljc",15,$CLJS.$F,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$F,$CLJS.cG,$CLJS.U($CLJS.Vj,jR)],null),$CLJS.Kc,"Schema for a valid asc clause.",$CLJS.n(sU)?sU.H:null])):null));return $CLJS.n(a)?a:tO}(),sU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof tU?new $CLJS.Gc(function(){return tU},$CLJS.od(Oia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,
$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oQ,"metabase/mbql/schema.cljc",16,$CLJS.TF,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TF,$CLJS.cG,$CLJS.U($CLJS.Vj,jR)],null),$CLJS.Kc,"Schema for a valid desc clause.",$CLJS.n(tU)?tU.H:null])):null));return $CLJS.n(a)?a:oQ}(),tU],null)]));
uU=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.js,$CLJS.qM,$CLJS.fN,$CLJS.vF,$CLJS.Kt,$CLJS.oB,$CLJS.lP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,ZR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gE,ZR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dj,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),ZR],null)],null);
Eka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gt,uU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.qM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cM,ZR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DP,bS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EP,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),bS],null)],null)],null);
Fka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gt,uU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.fN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NQ,bS],null)],null)],null);
vU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gt,uU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ei,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.ys],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HN,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.xs],null)],null)],null);Gka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aR],null);
Hka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gt,vU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.vF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vF,wS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JM],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kj,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,$CLJS.bj,$CLJS.ys],null)],null)],null)],null);
$CLJS.wU=new $CLJS.bh(null,new $CLJS.h(null,4,[$CLJS.lP,null,$CLJS.Kt,null,$CLJS.xs,null,$CLJS.oB,null],null),null);Ika=$CLJS.fg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.js],null),$CLJS.wU);
$CLJS.X(hQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,1,[$CLJS.Si,$CLJS.rj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vF,Hka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qM,Eka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fN,Fka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gp,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gt,vU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,Ika],null)],null)],null)],null)],null));
var xU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pQ,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,ZR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.ht,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Re(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.T.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WN,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,ZR],null)],null)],null),Jka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gt,xU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yF,$CLJS.ys],null)],null)],null),yU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QP],null),zU=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,1,[$CLJS.Si,function(a){return $CLJS.n($CLJS.$D($CLJS.xd,
$CLJS.TP)(a))?$CLJS.TP:$CLJS.fE}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gt,xU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TP,$CLJS.ys],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fE,yU],null)],null),AU=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,ZR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZC,$R],null),new $CLJS.P(null,2,5,$CLJS.Q,[vja,ZR],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.nO,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,aS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CN,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.ok],null)],null)],null),BU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,vka,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.ht,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),Kka=$CLJS.fg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.js],null),
new $CLJS.bh(null,new $CLJS.h(null,4,[$CLJS.aF,null,$CLJS.KE,null,$CLJS.UE,null,$CLJS.YE,null],null),null)),CU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZQ],null);
$CLJS.X(SN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PO,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),BU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rQ,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),zU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AR,$CLJS.BT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wE,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),Kka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LE,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.ms,new $CLJS.h(null,1,[$CLJS.ht,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,$CLJS.oy,$CLJS.Xz],null),CU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PE,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),ZR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PK,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,dS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BO,new $CLJS.h(null,
1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,AU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.ht,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.$D($CLJS.tA($CLJS.PO,$CLJS.rQ),$CLJS.Ue($CLJS.$D($CLJS.PO,$CLJS.rQ)))],null)],null));var Lka=$CLJS.Q,DU;var EU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SN],null)],null);
if($CLJS.wd(EU)&&$CLJS.E.g($CLJS.z(EU),$CLJS.Ej)){var FU=$CLJS.y(EU);$CLJS.z(FU);var GU=$CLJS.B(FU),Mka=$CLJS.xd($CLJS.z(GU))?GU:$CLJS.fe(null,GU),HU=$CLJS.y(Mka),Nka=$CLJS.z(HU),Oka=$CLJS.B(HU);DU=$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,$CLJS.R.j(Nka,$CLJS.go,1)],null),Oka)}else DU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,EU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.ht,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.X(UM,new $CLJS.P(null,3,5,Lka,[$CLJS.rs,DU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.ht,"All join aliases must be unique."],null),function(a){return $CLJS.yL($CLJS.mf($CLJS.Xa,$CLJS.jf.g($CLJS.PE,a)))}],null)],null));var Pka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UM],null);
$CLJS.X(ZQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xl,new $CLJS.h(null,1,[$CLJS.ht,"Distinct, non-empty sequence of Field clauses"],null),PL(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.go,1],null),$CLJS.xS],null))],null));
$CLJS.X(QP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rQ,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),zU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PO,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),BU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SF,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.go,1],null),$CLJS.KS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RF,new $CLJS.h(null,1,
[$CLJS.Cs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.go,1],null),$CLJS.xS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JP,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,ZR,$CLJS.bU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LE,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),CU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GF,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.BT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VR,new $CLJS.h(null,
1,[$CLJS.Cs,!0],null),cS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yR,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),PL(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.go,1],null),Dka],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[UP,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[UP,bS],null),new $CLJS.P(null,2,5,$CLJS.Q,[xia,bS],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nN,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),Pka],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BO,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,AU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.ht,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.lm(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rQ,$CLJS.PO],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,
new $CLJS.h(null,1,[$CLJS.ht,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.RF);b=$CLJS.J.g(b,$CLJS.LE);return $CLJS.td($CLJS.Zt.g($CLJS.gh(a),$CLJS.gh(b)))}],null)],null));
$CLJS.IU=$CLJS.Jg([$CLJS.wG,$CLJS.lP,ON,zN,CO,MR,iO,$CLJS.LR,$CLJS.Kt,hO,xM,FQ,BR,$CLJS.NM,xR,LN,$CLJS.HO,fO,$CLJS.dj,$CLJS.eO,fM,$CLJS.jN,$CLJS.dO,gP,$CLJS.xs,$CLJS.oB,$N],[new $CLJS.h(null,1,[sO,new $CLJS.bh(null,new $CLJS.h(null,5,[$CLJS.wG,null,$CLJS.lP,null,$CLJS.Kt,null,$CLJS.xs,null,$CLJS.oB,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.lP,sO,new $CLJS.bh(null,new $CLJS.h(null,5,[$CLJS.wG,null,$CLJS.lP,null,$CLJS.LR,null,$CLJS.HO,null,$CLJS.dj,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.rj,$CLJS.ek,$CLJS.oE,OQ,sO,new $CLJS.bh(null,new $CLJS.h(null,1,[ON,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,QR,$CLJS.oE,OQ,sO,new $CLJS.bh(null,new $CLJS.h(null,5,[$CLJS.wG,null,zN,null,$CLJS.Kt,null,$CLJS.dj,null,fM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,$CLJS.ek,$CLJS.oE,BM,sO,new $CLJS.bh(null,new $CLJS.h(null,1,[CO,null],null),null)],null),new $CLJS.h(null,1,[sO,new $CLJS.bh(null,new $CLJS.h(null,1,[MR,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,
QR,$CLJS.oE,Nja,sO,new $CLJS.bh(null,new $CLJS.h(null,1,[iO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.lP,sO,new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.LR,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,QR,sO,new $CLJS.bh(null,new $CLJS.h(null,5,[$CLJS.wG,null,zN,null,$CLJS.Kt,null,$CLJS.dj,null,fM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,QR,$CLJS.oE,BM,sO,new $CLJS.bh(null,new $CLJS.h(null,1,[hO,null],null),null)],null),new $CLJS.h(null,1,[sO,new $CLJS.bh(null,
new $CLJS.h(null,1,[xM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,$CLJS.ek,$CLJS.oE,OQ,sO,new $CLJS.bh(null,new $CLJS.h(null,8,[$CLJS.wG,null,MR,null,xM,null,FQ,null,fO,null,$CLJS.dj,null,fM,null,$CLJS.oB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,$CLJS.ek,$CLJS.oE,BM,sO,new $CLJS.bh(null,new $CLJS.h(null,1,[BR,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.lP,sO,new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.LR,null,$CLJS.NM,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.rj,$CLJS.ek,$CLJS.oE,BM,sO,new $CLJS.bh(null,new $CLJS.h(null,1,[xR,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,$CLJS.ek,$CLJS.oE,BM,sO,new $CLJS.bh(null,new $CLJS.h(null,1,[LN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.lP,sO,new $CLJS.bh(null,new $CLJS.h(null,5,[$CLJS.wG,null,$CLJS.lP,null,$CLJS.LR,null,$CLJS.HO,null,$CLJS.dj,null],null),null)],null),new $CLJS.h(null,1,[sO,new $CLJS.bh(null,new $CLJS.h(null,1,[fO,null],null),null)],null),new $CLJS.h(null,1,[sO,
new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.dj,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.lP,sO,new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.LR,null,$CLJS.eO,null],null),null)],null),new $CLJS.h(null,1,[sO,new $CLJS.bh(null,new $CLJS.h(null,1,[fM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.lP,sO,new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.LR,null,$CLJS.jN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.lP,sO,new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.LR,
null,$CLJS.dO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,QR,$CLJS.oE,OQ,sO,new $CLJS.bh(null,new $CLJS.h(null,1,[gP,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.xs,sO,new $CLJS.bh(null,new $CLJS.h(null,3,[$CLJS.wG,null,$CLJS.dj,null,$CLJS.xs,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.ek,sO,new $CLJS.bh(null,new $CLJS.h(null,8,[$CLJS.wG,null,MR,null,xM,null,FQ,null,fO,null,$CLJS.dj,null,fM,null,$CLJS.oB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,
QR,$CLJS.oE,BM,sO,new $CLJS.bh(null,new $CLJS.h(null,1,[$N,null],null),null)],null)]);$CLJS.X(aR,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.ht,"valid parameter type"],null)],null),$CLJS.Yg($CLJS.IU)));$CLJS.X(JM,$CLJS.fg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.ht,"valid template tag widget type"],null),$CLJS.Xz],null),$CLJS.Yg($CLJS.IU)));
$CLJS.X(mQ,NL(oia,$CLJS.H(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,ZR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,ZR],null)],null)],null)])));var JU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mQ],null);$CLJS.X(iM,NL($CLJS.vF,$CLJS.H(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,$CLJS.xS,JU],null)])));var KU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iM],null);$CLJS.X(dN,NL(cO,$CLJS.H(["target",JU])));
var LU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dN],null),Qka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,$CLJS.xS,OL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof KU?new $CLJS.Gc(function(){return KU},$CLJS.od(lja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,
1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YP,"metabase/mbql/schema.cljc",21,$CLJS.vF,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vF,$CLJS.kJ,$CLJS.U($CLJS.Vj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,pN,rO],null))],null),$CLJS.Kc,"Schema for a valid dimension clause.",$CLJS.n(KU)?KU.H:null])):null));return $CLJS.n(a)?a:YP}(),KU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=TO.h($CLJS.pd("undefined"!==typeof $CLJS.QL&&
"undefined"!==typeof RL&&"undefined"!==typeof SL&&"undefined"!==typeof LU?new $CLJS.Gc(function(){return LU},$CLJS.od(Bja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Jg([$CLJS.aj,$CLJS.T,$CLJS.vk,$CLJS.wi,TO,$CLJS.Xi,$CLJS.sj,$CLJS.Fk,PM,$CLJS.vi,$CLJS.qk,$CLJS.uk],[$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.qk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RR,"metabase/mbql/schema.cljc",20,cO,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[cO,
$CLJS.kJ,$CLJS.U($CLJS.Vj,rO)],null),$CLJS.Kc,"Schema for a valid variable clause.",$CLJS.n(LU)?LU.H:null])):null));return $CLJS.n(a)?a:RR}(),LU],null)]))],null),JL=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QM],null);
$CLJS.X(QM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.ht,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.$D($CLJS.tA($CLJS.TP,$CLJS.yF),$CLJS.Ue($CLJS.$D($CLJS.TP,$CLJS.yF)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.ht,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.TP);var d=$CLJS.J.g(c,$CLJS.yF);c=$CLJS.J.g(c,$CLJS.rj);c=c instanceof $CLJS.M?c.T:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ms,new $CLJS.h(null,1,[$CLJS.ht,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TK],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.OK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,$CLJS.yF,$CLJS.TP],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TP,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),Jka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yF,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),yU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eN,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,
8,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,Gka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dj,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),ZR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kJ,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),Qka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ij,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.ys],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),ZR],null),new $CLJS.P(null,3,5,$CLJS.Q,[uia,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),
ZR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ei,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.ys],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RK,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[lka,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),lS],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[kja,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[ZO,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),cS],null),new $CLJS.P(null,3,5,$CLJS.Q,[IM,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),cS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.ht,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,ZO);b=$CLJS.J.g(b,IM);return $CLJS.Ua($CLJS.n(a)?b:a)?!0:a>=b}],null)],
null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[aja,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[Wja,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.xs],null),new $CLJS.P(null,3,5,$CLJS.Q,[qia,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.xs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$ha,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.xs],null),new $CLJS.P(null,3,5,$CLJS.Q,[wia,new $CLJS.h(null,1,[$CLJS.Cs,
!0],null),$CLJS.xs],null),new $CLJS.P(null,3,5,$CLJS.Q,[Oja,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.xs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[bia,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.xs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[bka,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.xs],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yy,new $CLJS.h(null,
1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$M,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.js,Aja,xja,$CLJS.WN,mja,Cja,Zia,lia,ria,Aia,Nia,Lja,tka,Ria,Jia],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Rja,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,bS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Wia,
new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,bS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NQ,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,uka],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[mka,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,ZR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Yia,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,bS],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[kka,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,$CLJS.ys,$CLJS.ys],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Sia,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,bS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZN,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,$CLJS.ys,
$CLJS.ys],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CK,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.ys],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.ht,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Ue($CLJS.BO)],null)],null));var MU=$CLJS.IL(JL);
(function(){var a=Vha();return function(b){if($CLJS.n(MU.h?MU.h(b):MU.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.FL(b);throw $CLJS.ji($CLJS.bE("Invalid query: {0}",$CLJS.H([$CLJS.Nh.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.uy,c,fja,b],null));}})();