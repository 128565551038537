var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.core.protocols.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.util.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var g9,i9,h9,fwa,o9,gwa,p9,hwa,iwa,jwa,kwa,j9,lwa,u9,mwa,nwa,owa;g9=function(a,b){return $CLJS.n($CLJS.n(a)?b in a:a)?$CLJS.Ca(a,b):null};i9=function(a,b){return h9(a,b,$CLJS.N)};
h9=function(a,b,c){c=$CLJS.O(c);c=$CLJS.J.j(c,j9,!0);if($CLJS.xd(b))return $CLJS.fg.j($CLJS.N,a,b);c=$CLJS.n(c)?function(){var d=g9(b,"_plainObject");d=null==d?null:$CLJS.Fz(d);return null==d?null:$CLJS.Ge(d)}():null;return $CLJS.n(c)?$CLJS.fg.j($CLJS.N,a,c):$CLJS.fg.j($CLJS.N,$CLJS.Wk.j($CLJS.jf.h(function(d){return new $CLJS.P(null,2,5,$CLJS.Q,[d,g9(b,d)],null)}),$CLJS.Xk.h(function(d){$CLJS.I(d,0,null);return"function"===typeof $CLJS.I(d,1,null)}),a),$CLJS.Ba(b))};
fwa=function(a){var b=k9.h(a),c=l9.h(a),d=m9.h(a);return $CLJS.Wk.j($CLJS.jf.h(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);var k=$CLJS.Q;f=$CLJS.Ah.h($CLJS.WA.h?$CLJS.WA.h(f):$CLJS.WA.call(null,f));if($CLJS.n(d)){var l=d.h?d.h(f):d.call(null,f);f=$CLJS.n(l)?l:f}return new $CLJS.P(null,2,5,k,[f,e],null)}),$CLJS.td(b)?$CLJS.Td:$CLJS.Xk.h(function(e){var f=$CLJS.I(e,0,null);$CLJS.I(e,1,null);return $CLJS.Hd(b,f)}),$CLJS.Ua(c)?$CLJS.Td:$CLJS.jf.h(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,
1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[f,c.g?c.g(f,e):c.call(null,f,e)],null)}))};
o9=function(a,b){var c=fwa(a),d=n9.h(a);return function(e){try{var f=$CLJS.R.j(h9(c,e,b),$CLJS.Qt,d),k=$CLJS.DA($CLJS.ty);$CLJS.n($CLJS.CA("metabase.lib.js.metadata",k))&&$CLJS.BA("metabase.lib.js.metadata",k,$CLJS.eW("Parsed metadata %s %s\n%s",$CLJS.H([a,$CLJS.dj.h(f),$CLJS.fW(f)])),null);return f}catch(l){if(l instanceof Error)return f=l,k=$CLJS.DA($CLJS.uy),$CLJS.n($CLJS.CA("metabase.lib.js.metadata",k))&&(f instanceof Error?$CLJS.BA("metabase.lib.js.metadata",k,$CLJS.eW("Error parsing %s %s: %s",
$CLJS.H([a,$CLJS.Nh.l($CLJS.H([e])),$CLJS.cV(f)])),f):$CLJS.BA("metabase.lib.js.metadata",k,$CLJS.eW(f,$CLJS.H(["Error parsing %s %s: %s",a,$CLJS.Nh.l($CLJS.H([e])),$CLJS.cV(f)])),null)),null;throw l;}}};gwa=function(a){return i9($CLJS.jf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=$CLJS.Ah.h($CLJS.WA.h?$CLJS.WA.h(c):$CLJS.WA.call(null,c));c=$CLJS.E.g(c,$CLJS.mV)?$CLJS.wE:c;b=$CLJS.E.g(c,$CLJS.wE)?$CLJS.Ah.h(b):b;return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)}),a)};
p9=function(a){return $CLJS.Yk.g(o9($CLJS.cG,$CLJS.N),a)};hwa=new $CLJS.M(null,"plain-object","plain-object",-943264281);$CLJS.q9=new $CLJS.M(null,"cards","cards",169174038);iwa=new $CLJS.M(null,"binning-info","binning-info",-2117036653);jwa=new $CLJS.M(null,"db","db",993250759);$CLJS.r9=new $CLJS.M(null,"metrics","metrics",394093469);kwa=new $CLJS.M(null,"fks","fks",2041987076);$CLJS.s9=new $CLJS.M(null,"segments","segments",1937535949);
j9=new $CLJS.M(null,"use-plain-object?","use-plain-object?",-1174595475);lwa=new $CLJS.M(null,"metadata","metadata",1799301597);$CLJS.t9=new $CLJS.M(null,"tables","tables",1334623052);u9=new $CLJS.M(null,"dimension-options","dimension-options",2122379680);mwa=new $CLJS.M(null,"schema-name","schema-name",1666725119);nwa=new $CLJS.M(null,"_comesFromEndpoint","_comesFromEndpoint",-1390455588);owa=new $CLJS.M(null,"default-dimension-option","default-dimension-option",149055958);
$CLJS.v9=new $CLJS.M(null,"databases","databases",2040134125);var k9,pwa=$CLJS.Ye($CLJS.N),qwa=$CLJS.Ye($CLJS.N),rwa=$CLJS.Ye($CLJS.N),swa=$CLJS.Ye($CLJS.N),twa=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null)),x9,Jwa,Kwa,Lwa,Mwa,Nwa;k9=new $CLJS.fi($CLJS.Gh.g("metabase.lib.js.metadata","excluded-keys"),$CLJS.Ah,twa,pwa,qwa,rwa,swa);k9.m(null,$CLJS.ei,function(){return null});var l9,uwa=$CLJS.Ye($CLJS.N),vwa=$CLJS.Ye($CLJS.N),wwa=$CLJS.Ye($CLJS.N),xwa=$CLJS.Ye($CLJS.N),ywa=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));
l9=new $CLJS.fi($CLJS.Gh.g("metabase.lib.js.metadata","parse-field-fn"),$CLJS.Ah,ywa,uwa,vwa,wwa,xwa);l9.m(null,$CLJS.ei,function(){return null});var n9,zwa=$CLJS.Ye($CLJS.N),Awa=$CLJS.Ye($CLJS.N),Bwa=$CLJS.Ye($CLJS.N),Cwa=$CLJS.Ye($CLJS.N),Dwa=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));n9=new $CLJS.fi($CLJS.Gh.g("metabase.lib.js.metadata","lib-type"),$CLJS.Ah,Dwa,zwa,Awa,Bwa,Cwa);
var m9,Ewa=$CLJS.Ye($CLJS.N),Fwa=$CLJS.Ye($CLJS.N),Gwa=$CLJS.Ye($CLJS.N),Hwa=$CLJS.Ye($CLJS.N),Iwa=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));m9=new $CLJS.fi($CLJS.Gh.g("metabase.lib.js.metadata","rename-key-fn"),$CLJS.Td,Iwa,Ewa,Fwa,Gwa,Hwa);m9.m(null,$CLJS.ei,function(){return null});
$CLJS.w9=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.fi($CLJS.Gh.g("metabase.lib.js.metadata","parse-objects"),function(f){return $CLJS.Ah.h(f)},e,a,b,c,d)}();Jwa=$CLJS.Ye($CLJS.N);Kwa=$CLJS.Ye($CLJS.N);Lwa=$CLJS.Ye($CLJS.N);Mwa=$CLJS.Ye($CLJS.N);Nwa=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));
x9=new $CLJS.fi($CLJS.Gh.g("metabase.lib.js.metadata","parse-objects-default-key"),$CLJS.Ah,Nwa,Jwa,Kwa,Lwa,Mwa);$CLJS.w9.m(null,$CLJS.ei,function(a,b){var c=o9(a,$CLJS.N);return i9($CLJS.jf.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dW(e),new $CLJS.Kh(function(){return c.h?c.h(f):c.call(null,f)})],null)}),g9(b,x9.h(a)))});n9.m(null,$CLJS.EP,function(){return $CLJS.UK});
k9.m(null,$CLJS.EP,function(){return new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.LE,null,$CLJS.t9,null],null),null)});l9.m(null,$CLJS.EP,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "dbms-version":return $CLJS.Ez(b,$CLJS.H([$CLJS.Ci,!0]));case "features":return $CLJS.fg.j($CLJS.ch,$CLJS.jf.h($CLJS.Ah),b);case "native-permissions":return $CLJS.Ah.h(b);default:return b}}});x9.m(null,$CLJS.EP,function(){return"databases"});n9.m(null,$CLJS.yW,function(){return $CLJS.KK});
k9.m(null,$CLJS.yW,function(){return new $CLJS.bh(null,new $CLJS.h(null,5,[u9,null,$CLJS.LE,null,$CLJS.s9,null,$CLJS.EP,null,$CLJS.r9,null],null),null)});l9.m(null,$CLJS.yW,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "entity-type":return $CLJS.Ah.h(b);case "field-order":return $CLJS.Ah.h(b);case "initial-sync-status":return $CLJS.Ah.h(b);case "visibility-type":return $CLJS.Ah.h(b);default:return b}}});
$CLJS.w9.m(null,$CLJS.yW,function(a,b){var c=o9(a,$CLJS.N);return i9($CLJS.Wk.g($CLJS.Xk.h(function(d){var e=$CLJS.I(d,0,null);$CLJS.I(d,1,null);return 0==e.lastIndexOf("card__",0)}),$CLJS.jf.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dW(e),new $CLJS.Kh(function(){return c.h?c.h(f):c.call(null,f)})],null)})),g9(b,"tables"))});n9.m(null,$CLJS.cG,function(){return $CLJS.DK});
k9.m(null,$CLJS.cG,function(){return new $CLJS.bh(null,new $CLJS.h(null,7,[u9,null,$CLJS.yW,null,$CLJS.tL,null,owa,null,$CLJS.EP,null,nwa,null,$CLJS.r9,null],null),null)});m9.m(null,$CLJS.cG,function(){return new $CLJS.h(null,4,[$CLJS.Sz,$CLJS.FK,$CLJS.ti,$CLJS.tW,$CLJS.KR,$CLJS.kE,iwa,$CLJS.zW],null)});
l9.m(null,$CLJS.cG,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "base-type":return $CLJS.Ah.h(b);case "coercion-strategy":return $CLJS.Ah.h(b);case "effective-type":return $CLJS.Ah.h(b);case "fingerprint":return $CLJS.xd(b)?$CLJS.fV(b):$CLJS.Ez(b,$CLJS.H([$CLJS.Ci,!0]));case "has-field-values":return $CLJS.Ah.h(b);case "lib/source":switch(b){case "aggregation":return $CLJS.YK;case "breakout":return $CLJS.SK;default:return $CLJS.Ah.g("source",b)}case "metabase.lib.field/temporal-unit":return $CLJS.Ah.h(b);
case "semantic-type":return $CLJS.Ah.h(b);case "visibility-type":return $CLJS.Ah.h(b);case "id":return $CLJS.zd(b)?$CLJS.hd(b):b;case "metabase.lib.field/binning":return gwa(b);default:return b}}});
$CLJS.w9.m(null,$CLJS.cG,function(a,b){var c=o9(a,$CLJS.N),d=g9(b,"fields");return i9($CLJS.dm(function(e){var f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null);e=function(){var l=$CLJS.dW(f);if($CLJS.n(l))return l;l=$CLJS.oh(/card__\d+:(\d+)/,f);if($CLJS.n(l)){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);var m=null==g9(d,l);return m?$CLJS.dW(l):m}return null}();return $CLJS.n(e)?new $CLJS.P(null,2,5,$CLJS.Q,[e,new $CLJS.Kh(function(){return c.h?c.h(k):c.call(null,k)})],null):null}),d)});n9.m(null,$CLJS.fN,function(){return $CLJS.XK});
k9.m(null,$CLJS.fN,function(){return new $CLJS.bh(null,new $CLJS.h(null,11,[u9,null,$CLJS.xl,null,kwa,null,$CLJS.yW,null,jwa,null,hwa,null,$CLJS.s9,null,$CLJS.EP,null,lwa,null,$CLJS.r9,null,mwa,null],null),null)});
l9.m(null,$CLJS.fN,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "result-metadata":return $CLJS.n($CLJS.tA($CLJS.wd,$CLJS.Sa)(b))?p9(b):$CLJS.Ez(b,$CLJS.H([$CLJS.Ci,!0]));case "fields":return p9(b);case "visibility-type":return $CLJS.Ah.h(b);case "dataset-query":return $CLJS.Ez(b,$CLJS.H([$CLJS.Ci,!0]));case "dataset":return b;default:return b}}});
$CLJS.w9.m(null,$CLJS.fN,function(a,b){return $CLJS.fg.j($CLJS.N,$CLJS.jf.h(function(c){return new $CLJS.P(null,2,5,$CLJS.Q,[c,new $CLJS.Kh(function(){var d=o9($CLJS.fN,new $CLJS.h(null,1,[j9,!1],null)),e=o9($CLJS.fN,$CLJS.N),f=$CLJS.nl.l;d=$CLJS.R.j(d(g9(g9(b,"tables"),["card__",$CLJS.p.h(c)].join(""))),$CLJS.dj,c);var k=g9(g9(b,"questions"),$CLJS.p.h(c));var l=g9(k,"_card");k=$CLJS.n(l)?l:k;e=e.h?e.h(k):e.call(null,k);return f.call($CLJS.nl,$CLJS.H([d,e]))})],null)}),$CLJS.fg.j($CLJS.fg.j($CLJS.ch,
$CLJS.dm($CLJS.qW),$CLJS.Ba(g9(b,"tables"))),$CLJS.jf.h($CLJS.dW),$CLJS.Ba(g9(b,"questions"))))});n9.m(null,$CLJS.YF,function(){return $CLJS.JK});k9.m(null,$CLJS.YF,function(){return new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.yW,null,$CLJS.EP,null],null),null)});l9.m(null,$CLJS.YF,function(){return function(a,b){return b}});x9.m(null,$CLJS.YF,function(){return"metrics"});n9.m(null,$CLJS.AF,function(){return $CLJS.HK});
k9.m(null,$CLJS.AF,function(){return new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.yW,null,$CLJS.EP,null],null),null)});l9.m(null,$CLJS.AF,function(){return function(a,b){return b}});x9.m(null,$CLJS.AF,function(){return"segments"});$CLJS.y9=o9($CLJS.cG,$CLJS.N);