var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var oH,pH,Zfa,rH,$fa,aga,bga,sH,qH;$CLJS.mH=function(a,b){return $CLJS.gc($CLJS.eb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.ig.j(c,e,$CLJS.ce.g($CLJS.J.j(c,e,$CLJS.Df),d))},$CLJS.ec($CLJS.N),b))};$CLJS.nH=function(a,b){return $CLJS.eb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
oH=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.lC(b,$CLJS.MC)?$CLJS.YG:$CLJS.lC(b,$CLJS.LD)?$CLJS.TG:$CLJS.lC(b,$CLJS.lD)?$CLJS.VG:null;return $CLJS.n(b)?$CLJS.iF(b,a):!0};
pH=function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);$CLJS.z(a);a=$CLJS.B(a);a=$CLJS.mH(function(d){return $CLJS.lC($CLJS.GE(d),$CLJS.XC)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Uk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.GE($CLJS.z(a));return $CLJS.Se(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(oH(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
Zfa=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.ht,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qH],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.kt,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.Ij);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(pH(b))].join("")}],null),$CLJS.Ue(pH)],null)],null)};
rH=function(a){var b=$CLJS.Q,c=Zfa(a);a=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Kj,new $CLJS.h(null,1,[$CLJS.ht,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.as,new $CLJS.h(null,1,[$CLJS.go,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],null)],null);
return new $CLJS.P(null,3,5,b,[$CLJS.ms,c,a],null)};$fa=function(a){return $CLJS.Qd($CLJS.lF,$CLJS.jf.g(function(b){var c=$CLJS.GE(b),d=$CLJS.lC(c,$CLJS.SE);b=d?$CLJS.iF($CLJS.gG,b):d;return $CLJS.n(b)?$CLJS.Uj:c},a))};aga=function(a){a=$CLJS.nH(function(b){return!$CLJS.lC(b,$CLJS.XC)},$CLJS.jf.g($CLJS.GE,a));return $CLJS.lC(a,$CLJS.SE)?$CLJS.bk:a};bga=function(a){return $CLJS.n($CLJS.Se(function(b){return $CLJS.lC($CLJS.GE(b),$CLJS.XC)},a))?aga(a):$fa(a)};
sH=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.tH=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);qH=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ME,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,qH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.ht,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=$CLJS.GE(a);return $CLJS.Re(function(d){return oH(d,c)},b)}],null)],null));
$CLJS.X(sH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.as,new $CLJS.h(null,1,[$CLJS.go,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],null));$CLJS.DG.g($CLJS.Tr,rH($CLJS.Tr));$CLJS.DG.g($CLJS.at,rH($CLJS.at));$CLJS.sF($CLJS.Ur,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ns,sH],null)]));$CLJS.sF($CLJS.yG,$CLJS.H([$CLJS.at,$CLJS.ID,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ns,sH],null)]));
for(var uH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,$CLJS.at,$CLJS.Ur],null)),vH=null,wH=0,xH=0;;)if(xH<wH){var cga=vH.X(null,xH);$CLJS.jF(cga,$CLJS.tH);xH+=1}else{var yH=$CLJS.y(uH);if(yH){var zH=yH;if($CLJS.Ad(zH)){var AH=$CLJS.lc(zH),dga=$CLJS.mc(zH),ega=AH,fga=$CLJS.D(AH);uH=dga;vH=ega;wH=fga}else{var gga=$CLJS.z(zH);$CLJS.jF(gga,$CLJS.tH);uH=$CLJS.B(zH);vH=null;wH=0}xH=0}else break}$CLJS.FE.m(null,$CLJS.tH,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);$CLJS.z(a);a=$CLJS.B(a);return bga(a)});
$CLJS.qF($CLJS.CF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));$CLJS.jF($CLJS.CF,$CLJS.WE);
for(var BH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,$CLJS.tF,$CLJS.QF],null)),CH=null,DH=0,EH=0;;)if(EH<DH){var hga=CH.X(null,EH);$CLJS.qF(hga,$CLJS.H([$CLJS.at,$CLJS.ID,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));EH+=1}else{var FH=$CLJS.y(BH);if(FH){var GH=FH;if($CLJS.Ad(GH)){var HH=$CLJS.lc(GH),iga=$CLJS.mc(GH),jga=HH,kga=$CLJS.D(HH);BH=iga;CH=jga;DH=kga}else{var lga=$CLJS.z(GH);$CLJS.qF(lga,$CLJS.H([$CLJS.at,$CLJS.ID,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));BH=$CLJS.B(GH);CH=null;DH=0}EH=0}else break}
for(var IH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xF,$CLJS.aG,$CLJS.hG],null)),JH=null,KH=0,LH=0;;)if(LH<KH){var mga=JH.X(null,LH);$CLJS.qF(mga,$CLJS.H([$CLJS.at,$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));LH+=1}else{var MH=$CLJS.y(IH);if(MH){var NH=MH;if($CLJS.Ad(NH)){var OH=$CLJS.lc(NH),nga=$CLJS.mc(NH),oga=OH,pga=$CLJS.D(OH);IH=nga;JH=oga;KH=pga}else{var qga=$CLJS.z(NH);$CLJS.qF(qga,$CLJS.H([$CLJS.at,$CLJS.Bj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));IH=$CLJS.B(NH);JH=null;KH=0}LH=0}else break}$CLJS.qF($CLJS.zF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));
$CLJS.FE.m(null,$CLJS.zF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.lC($CLJS.GE(b),$CLJS.Bj)&&$CLJS.lC($CLJS.GE(a),$CLJS.Bj)?$CLJS.Bj:$CLJS.ID});