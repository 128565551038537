var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Sna,Tna,Una,Vna,Wna,K0;
$CLJS.H0=function(){function a(e,f){if($CLJS.Ua(f))return"";e=$CLJS.sA(e);var k=$CLJS.Ah.h(f);switch(k instanceof $CLJS.M?k.T:null){case "default":return $CLJS.iV("Default period","Default periods",e);case "millisecond":return $CLJS.iV("Millisecond","Milliseconds",e);case "second":return $CLJS.iV("Second","Seconds",e);case "minute":return $CLJS.iV("Minute","Minutes",e);case "hour":return $CLJS.iV("Hour","Hours",e);case "day":return $CLJS.iV("Day","Days",e);case "week":return $CLJS.iV("Week","Weeks",
e);case "month":return $CLJS.iV("Month","Months",e);case "quarter":return $CLJS.iV("Quarter","Quarters",e);case "year":return $CLJS.iV("Year","Years",e);case "minute-of-hour":return $CLJS.iV("Minute of hour","Minutes of hour",e);case "hour-of-day":return $CLJS.iV("Hour of day","Hours of day",e);case "day-of-week":return $CLJS.iV("Day of week","Days of week",e);case "day-of-month":return $CLJS.iV("Day of month","Days of month",e);case "day-of-year":return $CLJS.iV("Day of year","Days of year",e);case "week-of-year":return $CLJS.iV("Week of year",
"Weeks of year",e);case "month-of-year":return $CLJS.iV("Month of year","Months of year",e);case "quarter-of-year":return $CLJS.iV("Quarter of year","Quarters of year",e);default:return f=$CLJS.It($CLJS.hh(f),/-/),e=$CLJS.y(f),f=$CLJS.z(e),e=$CLJS.B(e),$CLJS.tt(" ",$CLJS.fe($CLJS.pA(f),e))}}function b(e){return d.g?d.g(1,e):d.call(null,1,e)}function c(){return d.g?d.g(1,null):d.call(null,1,null)}var d=null;d=function(e,f){switch(arguments.length){case 0:return c.call(this);case 1:return b.call(this,
e);case 2:return a.call(this,e,f)}throw Error("Invalid arity: "+arguments.length);};d.o=c;d.h=b;d.g=a;return d}();$CLJS.I0=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.$B],null),$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.fi($CLJS.Gh.g("metabase.lib.temporal-bucket","with-temporal-bucket-method"),function(f){return $CLJS.YB(f)},e,a,b,c,d)}();Sna=$CLJS.Ye($CLJS.N);Tna=$CLJS.Ye($CLJS.N);
Una=$CLJS.Ye($CLJS.N);Vna=$CLJS.Ye($CLJS.N);Wna=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.$B],null),$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));$CLJS.J0=new $CLJS.fi($CLJS.Gh.g("metabase.lib.temporal-bucket","temporal-bucket-method"),$CLJS.YB,Wna,Sna,Tna,Una,Vna);$CLJS.J0.m(null,$CLJS.ei,function(){return null});$CLJS.J0.m(null,$CLJS.WG,function(a){return $CLJS.ti.h(a)});K0=new $CLJS.bh(null,new $CLJS.h(null,4,[$CLJS.NG,null,$CLJS.QG,null,$CLJS.RG,null,$CLJS.OG,null],null),null);
$CLJS.Xna=$CLJS.fg.j($CLJS.Df,$CLJS.Wk.g($CLJS.Xk.h(K0),$CLJS.jf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.Qt,$CLJS.WG,$CLJS.ti,a],null);return $CLJS.E.g(a,$CLJS.wk)?$CLJS.R.j(b,$CLJS.ei,!0):b})),$CLJS.hH);$CLJS.Yna=$CLJS.Yk.g(function(a){var b=new $CLJS.h(null,2,[$CLJS.Qt,$CLJS.WG,$CLJS.ti,a],null);return $CLJS.E.g(a,$CLJS.Rt)?$CLJS.R.j(b,$CLJS.ei,!0):b},$CLJS.cH);
$CLJS.Zna=$CLJS.fg.j($CLJS.Df,$CLJS.Wk.g($CLJS.Xk.h(K0),$CLJS.jf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.Qt,$CLJS.WG,$CLJS.ti,a],null);return $CLJS.E.g(a,$CLJS.Rt)?$CLJS.R.j(b,$CLJS.ei,!0):b})),$CLJS.iH);$CLJS.o0.m(null,$CLJS.WG,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.ti);return $CLJS.H0.h(a)});
$CLJS.v0.m(null,$CLJS.WG,function(a,b,c){return $CLJS.nl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.gE,$CLJS.P_.j(a,b,c),$CLJS.m0,$CLJS.RA($CLJS.J0.h(c))],null),$CLJS.lm(c,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ei,$CLJS.X_],null))]))});
$CLJS.L0=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.$B],null),$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.fi($CLJS.Gh.g("metabase.lib.temporal-bucket","available-temporal-buckets-method"),function(f,k,l){return $CLJS.YB(l)},e,a,b,c,d)}();$CLJS.L0.m(null,$CLJS.ei,function(){return $CLJS.ch});
$CLJS.M0=function(){function a(d,e,f){return $CLJS.L0.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();