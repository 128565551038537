var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.convert.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.filter.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.underlying.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var t7,v7,x7,Lra,z7,A7,Mra;t7=function(a){if("string"===typeof a)return $CLJS.oh(/[\x00-\x20]*[+-]?NaN[\x00-\x20]*/,a)?NaN:$CLJS.oh(/[\x00-\x20]*[+-]?(Infinity|((\d+\.?\d*|\.\d+)([eE][+-]?\d+)?)[dDfF]?)[\x00-\x20]*/,a)?parseFloat(a):null;throw Error($CLJS.qm(a));};$CLJS.u7=function(a){return $CLJS.Ya(a)?$CLJS.Ez(a,$CLJS.H([$CLJS.Ci,!0])):a};
v7=function(a,b){if(null!=a&&null!=a.zg)a=a.zg(a,b);else{var c=v7[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=v7._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.setting",a);}return a};$CLJS.w7=function(a,b){return v7($CLJS.G_(a),b)};x7=function(a,b,c){a=(b-a)/c;if(null!=a)a=Math.ceil(a);else throw $CLJS.ji("Unexpected Null passed to ceil",new $CLJS.h(null,1,[$CLJS.Vi,"ceil"],null));a=$CLJS.Vd(a);return 1<a?a:1};
Lra=function(a,b,c){var d=t7(((b-a)/c).toFixed(5)),e=Math.pow(10,0===d?0:Math.floor(Math.log($CLJS.sA(d))/Math.log(10)));return $CLJS.Se(function(f){f*=e;return f>=d?f:null},$CLJS.Cqa)};
$CLJS.y7=function(a,b,c){var d=$CLJS.f7(b);if($CLJS.n(d)){var e=$CLJS.wE.h(d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "num-bins":e=$CLJS.uA(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CN,$CLJS.rj,$CLJS.Uj],null));if($CLJS.n(e)){a=$CLJS.O(e);e=$CLJS.J.g(a,$CLJS.go);var f=$CLJS.J.g(a,$CLJS.pk);a=$CLJS.O(d);a=$CLJS.J.g(a,$CLJS.eE);a=Lra(e,f,a);return new $CLJS.h(null,3,[$CLJS.vE,a,$CLJS.sL,c,$CLJS.vL,c+a],null)}return null;case "bin-width":return a=$CLJS.O(d),a=$CLJS.J.g(a,$CLJS.vE),new $CLJS.h(null,
3,[$CLJS.vE,a,$CLJS.sL,c,$CLJS.vL,c+a],null);case "default":e=$CLJS.uA(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CN,$CLJS.rj,$CLJS.Uj],null));if($CLJS.n(e)){d=$CLJS.O(e);e=$CLJS.J.g(d,$CLJS.go);f=$CLJS.J.g(d,$CLJS.pk);a:switch(d=e,e=f,f=$CLJS.ei instanceof $CLJS.M?$CLJS.ei.T:null,f){case "num-bins":a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eE,new $CLJS.h(null,2,[$CLJS.eE,null,$CLJS.vE,t7(((e-d)/0).toFixed(5))],null)],null);break a;case "bin-width":a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vE,new $CLJS.h(null,
2,[$CLJS.vE,null,$CLJS.eE,x7(d,e,null)],null)],null);break a;case "default":$CLJS.v_(b)?(a=$CLJS.w7(a,$CLJS.Bqa),a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vE,new $CLJS.h(null,2,[$CLJS.vE,a,$CLJS.eE,x7(d,e,a)],null)],null)):(a=$CLJS.w7(a,$CLJS.Aqa),a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eE,new $CLJS.h(null,2,[$CLJS.eE,a,$CLJS.vE,t7(((e-d)/a).toFixed(5))],null)],null));break a;default:throw Error(["No matching clause: ",$CLJS.p.h(f)].join(""));}if($CLJS.n(a))return $CLJS.I(a,0,null),a=$CLJS.I(a,1,null),
a=$CLJS.O(a),a=$CLJS.J.g(a,$CLJS.vE),new $CLJS.h(null,3,[$CLJS.vE,a,$CLJS.sL,c,$CLJS.vL,c+a],null)}return null;default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}}else return null};z7=function(a){var b=$CLJS.Yz.h($CLJS.k1(a));b=b.h?b.h($CLJS.s7):b.call(null,$CLJS.s7);if($CLJS.n(b))a:for(b=a;;)if($CLJS.td($CLJS.q4.g(b,-1))&&$CLJS.td($CLJS.F5.g(b,-1))){if(b=$CLJS.Zk.j(b,$CLJS.eL,$CLJS.rd),!$CLJS.y($CLJS.eL.h(b))){b=null;break a}}else break a;else b=null;return $CLJS.n(b)?b:a};
A7=function(a,b){var c=z7(a);if($CLJS.E.g(a,c))return b;for(;;){if($CLJS.E.g(a,c))return $CLJS.Ok.l(b,$CLJS.tW,$CLJS.H([$CLJS.zW]));var d=$CLJS.x0,e=d.j,f=a;var k=a;var l=$CLJS.S1(k,-1);k=$CLJS.n(l)?$CLJS.pW(k,l):null;d=e.call(d,f,-2,k);b=$CLJS.d2.v(a,-2,$CLJS.aW(b),d);if($CLJS.n(b))a=$CLJS.Zk.j(a,$CLJS.eL,$CLJS.rd);else return null}};
Mra=function(a,b,c){var d=function(){if($CLJS.n($CLJS.f7(b))){var e=$CLJS.y7(a,b,c);if($CLJS.n(e)){var f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.sL);f=$CLJS.J.g(f,$CLJS.vL);var k=$CLJS.e5(b,null);e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.D5(k,e),$CLJS.C5(k,f)],null)}else e=null}else e=null;return $CLJS.n(e)?e:new $CLJS.P(null,1,5,$CLJS.Q,[(0,$CLJS.j3)(b,c)],null)}();return $CLJS.eb(function(e,f){return $CLJS.k3.j(e,-1,f)},a,d)};
$CLJS.B7=function(a,b){b=$CLJS.O(b);var c=$CLJS.J.g(b,$CLJS.U5),d=$CLJS.J.g(b,$CLJS.tL);b=$CLJS.AW.l(a,-1,$CLJS.Ok,$CLJS.H([$CLJS.SF,$CLJS.RF,$CLJS.yR,$CLJS.VR,$CLJS.LE]));b=$CLJS.eb(function(f,k){var l=$CLJS.O(k);k=$CLJS.J.g(l,$CLJS.Xi);l=$CLJS.J.g(l,$CLJS.Ij);return Mra(f,k,l)},b,function(){return function l(k){return new $CLJS.oe(null,function(){for(var m=k;;)if(m=$CLJS.y(m)){if($CLJS.Ad(m)){var t=$CLJS.lc(m),u=$CLJS.D(t),v=$CLJS.re(u);a:for(var x=0;;)if(x<u){var A=$CLJS.kd(t,x);$CLJS.Uk.g($CLJS.FK.h($CLJS.Xi.h(A)),
$CLJS.YK)&&v.add(A);x+=1}else{t=!0;break a}return t?$CLJS.ue($CLJS.we(v),l($CLJS.mc(m))):$CLJS.ue($CLJS.we(v),null)}v=$CLJS.z(m);if($CLJS.Uk.g($CLJS.FK.h($CLJS.Xi.h(v)),$CLJS.YK))return $CLJS.fe(v,l($CLJS.Lc(m)));m=$CLJS.Lc(m)}else return null},null,null)}(d)}());var e=function(){var f=$CLJS.id(c);return $CLJS.n(f)?$CLJS.nH(function(k){return $CLJS.E.g($CLJS.n5(k),f)},$CLJS.q4.g(a,-1)):null}();return $CLJS.eb(function(f,k){return $CLJS.k3.j(f,-1,k)},b,$CLJS.n(e)?function(){var f=$CLJS.z(e);switch(f instanceof
$CLJS.M?f.T:null){case "sum-where":case "count-where":case "share":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.id(e)],null);case "metric":return $CLJS.l3.g($CLJS.R.j($CLJS.IY.h((0,$CLJS.mY)($CLJS.Q1($CLJS.u7(new $CLJS.h(null,2,[$CLJS.rj,$CLJS.yF,$CLJS.yF,$CLJS.u7($CLJS.pZ.h($CLJS.K_(a,$CLJS.id(e))))],null)),$CLJS.rj,$CLJS.yF))),$CLJS.EP,$CLJS.EP.h(a)),-1);default:return null}}():null)};$CLJS.N5.m(null,$CLJS.M6,function(a,b,c){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.G6);b=$CLJS.J.g(b,$CLJS.xZ);return new $CLJS.h(null,3,[$CLJS.rj,$CLJS.M6,$CLJS.G6,a,$CLJS.xZ,b],null)});
$CLJS.M5.m(null,$CLJS.M6,function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e){return $CLJS.B7(z7(c),$CLJS.Zk.j(e,$CLJS.tL,function(f){return function m(l){return new $CLJS.oe(null,function(){for(;;){var t=$CLJS.y(l);if(t){var u=t;if($CLJS.Ad(u)){var v=$CLJS.lc(u),x=$CLJS.D(v),A=$CLJS.re(x);return function(){for(var G=0;;)if(G<x){var K=$CLJS.kd(v,
G);$CLJS.ve(A,$CLJS.Zk.j(K,$CLJS.Xi,function(){return function(S){return A7(c,S)}}(G,K,v,x,A,u,t)));G+=1}else return!0}()?$CLJS.ue($CLJS.we(A),m($CLJS.mc(u))):$CLJS.ue($CLJS.we(A),null)}var C=$CLJS.z(u);return $CLJS.fe($CLJS.Zk.j(C,$CLJS.Xi,function(){return function(G){return A7(c,G)}}(C,u,t)),m($CLJS.Lc(u)))}return null}},null,null)}(f)}))}a.A=3;a.B=function(c){var d=$CLJS.z(c);c=$CLJS.B(c);var e=$CLJS.z(c);c=$CLJS.B(c);var f=$CLJS.z(c);c=$CLJS.Lc(c);return b(d,e,f,c)};a.l=b;return a}());