var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.schema.common.js");require("./metabase.mbql.predicates.js");require("./metabase.mbql.schema.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var hV;$CLJS.cV=function(a){return a instanceof Error?a.message:null};
$CLJS.dV=function(a,b,c){if($CLJS.ae(c)){var d=$CLJS.Te.g($CLJS.U,$CLJS.jf.g(a,c));return b.h?b.h(d):b.call(null,d)}return $CLJS.Af(c)?(d=new $CLJS.Zf(function(){var e=$CLJS.Eb(c);return a.h?a.h(e):a.call(null,e)}(),function(){var e=$CLJS.Fb(c);return a.h?a.h(e):a.call(null,e)}()),b.h?b.h(d):b.call(null,d)):$CLJS.Dd(c)?(d=$CLJS.nh($CLJS.jf.g(a,c)),b.h?b.h(d):b.call(null,d)):$CLJS.yd(c)?(d=$CLJS.eb(function(e,f){return $CLJS.ce.g(e,a.h?a.h(f):a.call(null,f))},c,c),b.h?b.h(d):b.call(null,d)):$CLJS.ud(c)?
(d=$CLJS.fg.g($CLJS.jd(c),$CLJS.jf.g(a,c)),b.h?b.h(d):b.call(null,d)):b.h?b.h(c):b.call(null,c)};$CLJS.fV=function(a){function b(c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return"string"===typeof d?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ah.h(d),c],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null)}return $CLJS.eV(function(c){return $CLJS.xd(c)?$CLJS.fg.g($CLJS.N,$CLJS.jf.g(b,c)):c},a)};
$CLJS.gV=function(a){var b=new $CLJS.Ea;for(a=$CLJS.y(a);;)if(null!=a)b=b.append($CLJS.p.h($CLJS.z(a))),a=$CLJS.B(a);else return b.toString()};$CLJS.iV=function(a,b,c){a=$CLJS.xA(a,/''/,"'");var d=$CLJS.It(a,hV);d=$CLJS.E.g($CLJS.D(d),1)?new $CLJS.P(null,2,5,$CLJS.Q,[a,""],null):d;a=$CLJS.ph(/.*\{0\}.*/,a);return $CLJS.nt.ngettext($CLJS.nt.msgid($CLJS.ql(d),$CLJS.n(a)?c:""),$CLJS.xA($CLJS.xA(b,/''/,"'"),hV,$CLJS.p.h(c)),c)};
$CLJS.jV=function(a,b){return $CLJS.eb(function(c,d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.Hd(a,e)?$CLJS.R.j(c,d,$CLJS.J.g(a,e)):c},$CLJS.Te.j($CLJS.Ok,a,$CLJS.Yg(b)),b)};$CLJS.kV=function(a,b,c){return $CLJS.xd(c)?$CLJS.kf(function(d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);e=$CLJS.ce.g(b,e);return a.g?a.g(e,d):a.call(null,e,d)},$CLJS.H([c])):$CLJS.wd(c)?$CLJS.kf($CLJS.We(a,$CLJS.z(c)instanceof $CLJS.M?$CLJS.ce.g(b,$CLJS.z(c)):b),$CLJS.H([c])):null};
$CLJS.eV=function eV(a,b){return $CLJS.dV($CLJS.We(eV,a),a,b)};hV=/\{0\}/;$CLJS.lV=new $CLJS.M(null,"unique-alias-fn","unique-alias-fn",32555420);$CLJS.mV=new $CLJS.M(null,"binning-strategy","binning-strategy",2063329158);$CLJS.nV=new $CLJS.M("clojure.core.match","not-found","clojure.core.match/not-found",1553053780);$CLJS.oV=new $CLJS.M(null,"name-key-fn","name-key-fn",-1634839199);var Uka=$CLJS.Jg([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LI,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YI,$CLJS.RI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YI,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XI,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EI,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bJ,null],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.YI,$CLJS.II],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YI,$CLJS.WI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KI,null],null)],[$CLJS.Di,$CLJS.RG,$CLJS.iJ,$CLJS.OI,$CLJS.xi,$CLJS.Lj,$CLJS.nj,$CLJS.sk,$CLJS.Ji,$CLJS.OI,$CLJS.jJ,$CLJS.OG]),qV;$CLJS.gh($CLJS.jf.g($CLJS.z,$CLJS.Yg(Uka)));var pV,Vka=$CLJS.Ye($CLJS.N),Wka=$CLJS.Ye($CLJS.N),Xka=$CLJS.Ye($CLJS.N),Yka=$CLJS.Ye($CLJS.N),Zka=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));
pV=new $CLJS.fi($CLJS.Gh.g("metabase.mbql.util","negate*"),$CLJS.z,Zka,Vka,Wka,Xka,Yka);pV.m(null,$CLJS.os,function(a){$CLJS.I(a,0,null);return $CLJS.I(a,1,null)});pV.m(null,$CLJS.rs,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);return $CLJS.fg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ms],null),$CLJS.jf.g(pV,a))});pV.m(null,$CLJS.ms,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);return $CLJS.fg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.rs],null),$CLJS.jf.g(pV,a))});
pV.m(null,$CLJS.Rr,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uG,b,a],null)});pV.m(null,$CLJS.uG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rr,b,a],null)});pV.m(null,$CLJS.Kr,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qr,b,a],null)});
pV.m(null,$CLJS.Or,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,b,a],null)});pV.m(null,$CLJS.Mr,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Or,b,a],null)});pV.m(null,$CLJS.Qr,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Kr,b,a],null)});
pV.m(null,$CLJS.EF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Or,b,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Kr,b,a],null)],null)});pV.m(null,$CLJS.bG,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,a],null)});pV.m(null,$CLJS.UF,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,a],null)});
pV.m(null,$CLJS.nG,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,a],null)});
qV=function qV(a){return $CLJS.xd(a)?$CLJS.Ge($CLJS.fg.g($CLJS.N,function(){return function e(d){return new $CLJS.oe(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.re(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=qV.h?qV.h(u):qV.call(null,u);null!=u&&m.add(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null));t+=1}else{k=!0;break a}return k?$CLJS.ue($CLJS.we(m),e($CLJS.mc(f))):$CLJS.ue($CLJS.we(m),null)}k=$CLJS.z(f);
m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=qV.h?qV.h(k):qV.call(null,k);if(null!=k)return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[m,k],null),e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(a)}())):$CLJS.wd(a)?$CLJS.Ge($CLJS.fg.g($CLJS.jd(a),$CLJS.mf($CLJS.Xa,$CLJS.jf.g(qV,a)))):a};
$CLJS.rV=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var k=Array(arguments.length-2);f<k.length;)k[f]=arguments[f+2],++f;f=new $CLJS.w(k,0,null)}return b.call(this,c,d,f)}function b(c,d,e){var f=$CLJS.I(c,0,null),k=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);d=$CLJS.Ge(qV($CLJS.Te.j(d,c,e)));return $CLJS.n(d)?new $CLJS.P(null,3,5,$CLJS.Q,[f,k,d],null):$CLJS.E.g(f,$CLJS.cG)?new $CLJS.P(null,3,5,$CLJS.Q,[f,k,null],null):new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}a.A=2;a.B=function(c){var d=
$CLJS.z(c);c=$CLJS.B(c);var e=$CLJS.z(c);c=$CLJS.Lc(c);return b(d,e,c)};a.l=b;return a}();