var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var X3,Z3,$3,a4,hqa;X3=function(a,b,c){var d=$CLJS.pW(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.SF);d=$CLJS.nH($CLJS.Wk.j($CLJS.dh([c]),$CLJS.sE,$CLJS.hd),d);if(!$CLJS.n(d))throw $CLJS.ji($CLJS.bE("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.ik,c,$CLJS.yF,a,$CLJS.qL,b],null));return d};$CLJS.Y3=function(a){return $CLJS.R1($CLJS.pG,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};
Z3=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);$3=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);a4=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);hqa=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.r0.m(null,$CLJS.SF,function(a,b){var c=$CLJS.Ge($CLJS.SF.h($CLJS.pW(a,b)));return $CLJS.n(c)?$CLJS.T1($CLJS.DE("and"),function(){return function f(e){return new $CLJS.oe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.re(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.P_.v(a,b,v,$CLJS.Q_);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.ue($CLJS.we(t),f($CLJS.mc(k))):$CLJS.ue($CLJS.we(t),null)}t=$CLJS.z(k);return $CLJS.fe($CLJS.P_.v(a,
b,t,$CLJS.Q_),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):null});$CLJS.u0.m(null,$CLJS.SF,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.kB);e=$CLJS.J.g(e,$CLJS.Ii);c=$CLJS.I(c,2,null);c=X3(a,b,c);return $CLJS.nl.l($CLJS.H([$CLJS.M_.j(a,b,c),new $CLJS.h(null,2,[$CLJS.FK,$CLJS.YK,$CLJS.S0,$CLJS.sE.h($CLJS.hd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.kB,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Ii,e],null):null]))});
$CLJS.o0.m(null,$CLJS.SF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.P_.v(a,b,X3(a,b,c),d)});$CLJS.jF($3,a4);
for(var b4=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jx,$CLJS.dG],null)),c4=null,d4=0,e4=0;;)if(e4<d4){var iqa=c4.X(null,e4);$CLJS.jF(iqa,$3);e4+=1}else{var f4=$CLJS.y(b4);if(f4){var g4=f4;if($CLJS.Ad(g4)){var h4=$CLJS.lc(g4),jqa=$CLJS.mc(g4),kqa=h4,lqa=$CLJS.D(h4);b4=jqa;c4=kqa;d4=lqa}else{var mqa=$CLJS.z(g4);$CLJS.jF(mqa,$3);b4=$CLJS.B(g4);c4=null;d4=0}e4=0}else break}
$CLJS.o0.m(null,$3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.P_.v(a,b,c,d),e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.bE("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.bE("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.DE("Count");case "cum-count":return $CLJS.DE("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.p0.m(null,$3,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.T:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.u0.m(null,$3,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.ci($CLJS.u0,a4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.ak,$CLJS.NC)});$CLJS.o0.m(null,$CLJS.LF,function(){return $CLJS.DE("Case")});
$CLJS.p0.m(null,$CLJS.LF,function(){return"case"});$CLJS.jF(Z3,a4);for(var i4=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.XF,$CLJS.zG,$CLJS.HF,$CLJS.pk,$CLJS.qG,$CLJS.go,$CLJS.sG,$CLJS.pG,$CLJS.IF],null)),j4=null,k4=0,l4=0;;)if(l4<k4){var nqa=j4.X(null,l4);$CLJS.jF(nqa,Z3);l4+=1}else{var m4=$CLJS.y(i4);if(m4){var n4=m4;if($CLJS.Ad(n4)){var o4=$CLJS.lc(n4),oqa=$CLJS.mc(n4),pqa=o4,qqa=$CLJS.D(o4);i4=oqa;j4=pqa;k4=qqa}else{var rqa=$CLJS.z(n4);$CLJS.jF(rqa,Z3);i4=$CLJS.B(n4);j4=null;k4=0}l4=0}else break}
$CLJS.p0.m(null,Z3,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.T:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.o0.m(null,Z3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.P_.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "avg":return $CLJS.bE("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.bE("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.bE("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.bE("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.bE("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.bE("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.bE("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.bE("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.bE("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.o0.m(null,$CLJS.OF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.bE("{0}th percentile of {1}",$CLJS.H([c,$CLJS.P_.v(a,b,e,d)]))});$CLJS.p0.m(null,$CLJS.OF,function(){return"percentile"});
$CLJS.jF($CLJS.OF,a4);$CLJS.o0.m(null,$CLJS.xG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.bE("Sum of {0} matching condition",$CLJS.H([$CLJS.P_.v(a,b,e,d)]))});$CLJS.p0.m(null,$CLJS.xG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.q0.j(a,b,d))].join("")});$CLJS.jF($CLJS.xG,a4);$CLJS.o0.m(null,$CLJS.jG,function(){return $CLJS.DE("Share of rows matching condition")});
$CLJS.p0.m(null,$CLJS.jG,function(){return"share"});$CLJS.jF($CLJS.jG,a4);$CLJS.o0.m(null,$CLJS.lG,function(){return $CLJS.DE("Count of rows matching condition")});$CLJS.p0.m(null,$CLJS.lG,function(){return"count-where"});$CLJS.jF($CLJS.lG,a4);
$CLJS.u0.m(null,a4,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.nl.l;d=$CLJS.n(d)?$CLJS.lm($CLJS.M_.j(a,b,d),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.RK],null)):null;var f=$CLJS.ci($CLJS.u0,$CLJS.ei);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.nl,$CLJS.H([d,a]))});
$CLJS.sqa=function(){function a(d){return $CLJS.R1($CLJS.Jx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.R1($CLJS.Jx,$CLJS.Df)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.ZV.m(null,$CLJS.SF,function(a){return a});
$CLJS.p4=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.YB(f),$CLJS.JK))f=$CLJS.aW(f);else return $CLJS.V1(d,e,$CLJS.SF,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.q4=function(){function a(d,e){return $CLJS.Ge($CLJS.SF.h($CLJS.pW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.r4=function(){function a(d,e){var f=$CLJS.Ge($CLJS.SF.h($CLJS.pW(d,e)));return null==f?null:$CLJS.fg.j($CLJS.Df,$CLJS.jf.h(function(k){var l=$CLJS.M_.j(d,e,k),m=$CLJS.R.l,t=$CLJS.kB.h(l);return m.call($CLJS.R,$CLJS.Q1(l,$CLJS.Ii,$CLJS.n(t)?t:$CLJS.Fj),$CLJS.FK,$CLJS.YK,$CLJS.H([$CLJS.S0,$CLJS.sE.h($CLJS.hd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.o0.m(null,$CLJS.LG,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.HG);return $CLJS.gE.h(a.o?a.o():a.call(null))});$CLJS.v0.m(null,$CLJS.LG,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.J.g(d,$CLJS.WF);b=$CLJS.J.g(d,$CLJS.HG);c=$CLJS.J.g(d,$CLJS.GG);d=$CLJS.J.g(d,$CLJS.wZ);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.m0,$CLJS.RA(a),$CLJS.H([$CLJS.k0,c]));return null!=d?$CLJS.R.j(a,$CLJS.X_,d):a});
$CLJS.tqa=function(){function a(d,e){var f=function(){var m=$CLJS.Yz.h($CLJS.k1(d));return $CLJS.n(m)?m:$CLJS.ch}(),k=$CLJS.pW(d,e),l=$CLJS.z0.j(d,e,k);return $CLJS.Ge($CLJS.fg.j($CLJS.Df,$CLJS.Wk.j($CLJS.lf(function(m){m=$CLJS.JG.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.dm(function(m){m=$CLJS.O(m);var t=$CLJS.J.g(m,$CLJS.GG),u=$CLJS.J.g(m,$CLJS.EG);if($CLJS.Ua(t))return m;if($CLJS.E.g(u,$CLJS.ys))return $CLJS.R.j(m,$CLJS.mW,l);t=$CLJS.Ge($CLJS.N0(function(v){return $CLJS.g_(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.mW,t):null}),$CLJS.jf.h(function(m){return $CLJS.R.j(m,$CLJS.Qt,$CLJS.LG)})),$CLJS.MG))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.uqa=function(){function a(d,e){return $CLJS.$V(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WF.h(d),$CLJS.N,$CLJS.bW.h(e)],null))}function b(d){if($CLJS.Ua($CLJS.GG.h(d)))return $CLJS.$V(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WF.h(d),$CLJS.N],null));var e=$CLJS.WF.h(d);e=$CLJS.fa.g?$CLJS.fa.g("aggregation operator %s requires an argument",e):$CLJS.fa.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.ji(e,new $CLJS.h(null,1,[hqa,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();