var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var FI,HI,JI,VI,ZI,gJ;$CLJS.EI=new $CLJS.M(null,"get-month","get-month",-369374731);FI=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.GI=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);HI=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.II=new $CLJS.M(null,"iso","iso",-1366207543);JI=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.KI=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.LI=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.MI=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.NI=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.OI=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.PI=new $CLJS.M(null,"datetime-diff","datetime-diff",1133112155);$CLJS.QI=new $CLJS.M(null,"interval","interval",1708495417);$CLJS.RI=new $CLJS.M(null,"us","us",746429226);$CLJS.SI=new $CLJS.M(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.TI=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.UI=new $CLJS.M(null,"now","now",-1650525531);VI=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.WI=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.XI=new $CLJS.M(null,"get-day","get-day",127568857);$CLJS.YI=new $CLJS.M(null,"get-week","get-week",-888059349);
ZI=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.$I=new $CLJS.M(null,"datetime-add","datetime-add",209603411);$CLJS.aJ=new $CLJS.M(null,"get-minute","get-minute",1468086343);$CLJS.bJ=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.cJ=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.dJ=new $CLJS.M(null,"temporal-extract","temporal-extract",1781686405);$CLJS.eJ=new $CLJS.M(null,"get-hour","get-hour",622714059);$CLJS.fJ=new $CLJS.M(null,"datetime-subtract","datetime-subtract",275307697);gJ=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.hJ=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);$CLJS.iJ=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.jJ=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.kJ=new $CLJS.M(null,"target","target",253001721);$CLJS.qF($CLJS.QI,$CLJS.H([$CLJS.at,$CLJS.XC,$CLJS.zi,$CLJS.VG]));$CLJS.FE.m(null,$CLJS.NI,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.GE(a);return $CLJS.vd(a)?(a=$CLJS.Zt.g(a,new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.MC,null,$CLJS.lD,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.z(a):a):a});$CLJS.jF($CLJS.NI,$CLJS.WE);
for(var lJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$I,$CLJS.fJ],null)),mJ=null,nJ=0,oJ=0;;)if(oJ<nJ){var pJ=mJ.X(null,oJ);$CLJS.qF(pJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OE],null),$CLJS.zi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VG],null)]));$CLJS.jF(pJ,$CLJS.NI);oJ+=1}else{var qJ=$CLJS.y(lJ);if(qJ){var rJ=qJ;if($CLJS.Ad(rJ)){var sJ=$CLJS.lc(rJ),cha=$CLJS.mc(rJ),dha=sJ,eha=$CLJS.D(sJ);lJ=cha;mJ=dha;nJ=eha}else{var tJ=$CLJS.z(rJ);$CLJS.qF(tJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.OE],null),$CLJS.zi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VG],null)]));$CLJS.jF(tJ,$CLJS.NI);lJ=$CLJS.B(rJ);mJ=null;nJ=0}oJ=0}else break}
for(var uJ=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.LI,$CLJS.EI,$CLJS.XI,$CLJS.eJ,$CLJS.aJ,$CLJS.KI,$CLJS.hJ],null)),vJ=null,wJ=0,xJ=0;;)if(xJ<wJ){var fha=vJ.X(null,xJ);$CLJS.qF(fha,$CLJS.H([$CLJS.at,$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OE],null)],null)]));xJ+=1}else{var yJ=$CLJS.y(uJ);if(yJ){var zJ=yJ;if($CLJS.Ad(zJ)){var AJ=$CLJS.lc(zJ),gha=$CLJS.mc(zJ),hha=AJ,iha=$CLJS.D(AJ);uJ=gha;vJ=hha;wJ=iha}else{var jha=$CLJS.z(zJ);$CLJS.qF(jha,$CLJS.H([$CLJS.at,
$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OE],null)],null)]));uJ=$CLJS.B(zJ);vJ=null;wJ=0}xJ=0}else break}$CLJS.qF($CLJS.PI,$CLJS.H([$CLJS.at,$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SG],null)]));
for(var BJ=$CLJS.y(new $CLJS.bh(null,new $CLJS.h(null,8,[$CLJS.hJ,null,$CLJS.aJ,null,$CLJS.eJ,null,$CLJS.bJ,null,$CLJS.KI,null,$CLJS.EI,null,$CLJS.LI,null,$CLJS.XI,null],null),null)),CJ=null,DJ=0,EJ=0;;)if(EJ<DJ){var kha=CJ.X(null,EJ);$CLJS.qF(kha,$CLJS.H([$CLJS.at,$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OE],null)],null)]));EJ+=1}else{var FJ=$CLJS.y(BJ);if(FJ){var GJ=FJ;if($CLJS.Ad(GJ)){var HJ=$CLJS.lc(GJ),lha=$CLJS.mc(GJ),mha=HJ,nha=$CLJS.D(HJ);
BJ=lha;CJ=mha;DJ=nha}else{var oha=$CLJS.z(GJ);$CLJS.qF(oha,$CLJS.H([$CLJS.at,$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OE],null)],null)]));BJ=$CLJS.B(GJ);CJ=null;DJ=0}EJ=0}else break}$CLJS.X(gJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.js,$CLJS.II,$CLJS.RI,$CLJS.WI],null));
$CLJS.sF($CLJS.YI,$CLJS.H([$CLJS.at,$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gJ],null)],null)],null)],null)]));
$CLJS.X($CLJS.cJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,$CLJS.lE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,2,[$CLJS.ht,"valid timezone ID",$CLJS.kt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ij);return["invalid timezone ID: ",$CLJS.Nh.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.oA($CLJS.Jd,$CLJS.DI.tz.names())),$CLJS.uI],null)],null));
$CLJS.sF($CLJS.GI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sz,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cJ],null)],null)],null)],
null)]));$CLJS.jF($CLJS.GI,$CLJS.NI);$CLJS.qF($CLJS.UI,$CLJS.H([$CLJS.at,$CLJS.PC]));$CLJS.X(JI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.ht,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Se(function(b){return $CLJS.lC(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MC,$CLJS.lD],null))}],null)],null));
$CLJS.X(FI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kB,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JI],null)],null)],null)],null));
$CLJS.DG.g($CLJS.MI,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Kj,new $CLJS.h(null,1,[$CLJS.ht,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.MI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Kj,new $CLJS.h(null,1,[$CLJS.ht,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.sI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.ei],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UG],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Kj,new $CLJS.h(null,1,[$CLJS.ht,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.Lv],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.CI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.ei],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.PG],null)],null)],null)],null)],null)],null));
$CLJS.FE.m(null,$CLJS.MI,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.Lv)?$CLJS.E.g(a,$CLJS.ei)?$CLJS.lD:$CLJS.n($CLJS.iF($CLJS.UG,a))?$CLJS.MC:$CLJS.lD:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.oh($CLJS.pI,b))?$CLJS.MC:$CLJS.n($CLJS.oh($CLJS.qI,b))?$CLJS.MC:null:null;if($CLJS.n(a))return a;b=$CLJS.GE(b);b=$CLJS.vd(b)?$CLJS.fg.j($CLJS.ch,$CLJS.lf(function(c){return $CLJS.lC(c,$CLJS.bk)}),b):b;return $CLJS.vd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.z(b):b});$CLJS.X(ZI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.Lv],null),$CLJS.zi],null));
$CLJS.sF($CLJS.SI,$CLJS.H([$CLJS.at,$CLJS.lD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ti,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VG],null)],null)],null)],null)]));
$CLJS.qF($CLJS.xy,$CLJS.H([$CLJS.at,$CLJS.LD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$E],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TG],null)]));$CLJS.X(HI,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.js,$CLJS.RG,$CLJS.Di,$CLJS.sk,$CLJS.OI,$CLJS.iJ,$CLJS.jJ,$CLJS.Lj,$CLJS.Ji,$CLJS.xi,$CLJS.nj,$CLJS.OG],null));$CLJS.X(VI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.js,$CLJS.II,$CLJS.RI,$CLJS.WI],null));
$CLJS.sF($CLJS.dJ,$CLJS.H([$CLJS.at,$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ti,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VI],null)],null)],
null)],null)]));