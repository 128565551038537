var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.breakout.js");require("./metabase.lib.expression.js");require("./metabase.lib.field.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Ora,Pra,C7,Qra,Rra,Sra,Tra,Ura,Vra,Wra,D7,E7,F7,Xra,G7;
Ora=function(a){return function(b){var c=$CLJS.af($CLJS.ch);return function(){function d(l,m){var t=a.h?a.h(m):a.call(null,m);if($CLJS.Hd($CLJS.q(c),t))return l;c.Dd(null,$CLJS.ce.g(c.Qb(null),t));return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);
};k.o=f;k.h=e;k.g=d;return k}()}};Pra=function(a,b,c){return $CLJS.fg.j($CLJS.Df,$CLJS.aE(function(d){return $CLJS.z0.v(a,b,d,new $CLJS.h(null,2,[$CLJS.j0,c,$CLJS.d0,!1],null))}),$CLJS.nN.h($CLJS.pW(a,b)))};C7=function(a,b,c){return $CLJS.fg.j($CLJS.Df,$CLJS.aE(function(d){return $CLJS.x0.v(a,b,d,c)}),$CLJS.nN.h($CLJS.pW(a,b)))};$CLJS.J3=function(a,b){return $CLJS.eb(function(c,d){return $CLJS.AW.l(c,d,$CLJS.R,$CLJS.H([$CLJS.j7,$CLJS.x0.j(c,d,$CLJS.pW(c,d))]))},a,$CLJS.lu(0,$CLJS.oW(a,b)))};
Qra=function(a,b){a=$CLJS.pW(a,b);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.Qt),d=$CLJS.J.g(a,$CLJS.yV);b=$CLJS.j7.h(a);if($CLJS.n(b))return b;var e=$CLJS.YU.h(a);if($CLJS.n(e)&&$CLJS.n(function(){var k=$CLJS.E.g(c,$CLJS.MV);return k?k:d}())){var f=function(){var k=c instanceof $CLJS.M?c.T:null;switch(k){case "mbql.stage/native":return $CLJS.EZ;case "mbql.stage/mbql":return $CLJS.yZ;default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}();return $CLJS.Ge(function(){return function m(l){return new $CLJS.oe(null,
function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.re(v);a:for(var A=0;;)if(A<v){var C=$CLJS.kd(u,A);C=$CLJS.nl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.gZ,$CLJS.T.h(C),$CLJS.uZ,$CLJS.T.h(C)],null),C,new $CLJS.h(null,1,[$CLJS.FK,f],null)]));x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.ue($CLJS.we(x),m($CLJS.mc(t))):$CLJS.ue($CLJS.we(x),null)}x=$CLJS.z(t);return $CLJS.fe($CLJS.nl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.gZ,$CLJS.T.h(x),$CLJS.uZ,$CLJS.T.h(x)],
null),x,new $CLJS.h(null,1,[$CLJS.FK,f],null)])),m($CLJS.Lc(t)))}return null}},null,null)}($CLJS.mW.h(e))}())}return null};
Rra=function(a,b,c){return $CLJS.Ge(function(){return function f(e){return new $CLJS.oe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.re(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v),A=t,C=$CLJS.R,G=C.l,K=x,S=$CLJS.tA($CLJS.gZ,$CLJS.T)(x);x=$CLJS.w1(a,x);x=c.h?c.h(x):c.call(null,x);C=G.call(C,K,$CLJS.FK,$CLJS.SK,$CLJS.H([$CLJS.gZ,S,$CLJS.uZ,x]));A.add(C);v+=1}else return!0}()?$CLJS.ue($CLJS.we(t),f($CLJS.mc(k))):$CLJS.ue($CLJS.we(t),
null)}var u=$CLJS.z(k);return $CLJS.fe($CLJS.R.l(u,$CLJS.FK,$CLJS.SK,$CLJS.H([$CLJS.gZ,$CLJS.tA($CLJS.gZ,$CLJS.T)(u),$CLJS.uZ,function(){var v=$CLJS.w1(a,u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Lc(k)))}return null}},null,null)}($CLJS.G5.g(a,b))}())};
Sra=function(a,b,c){return $CLJS.Ge(function(){return function f(e){return new $CLJS.oe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.re(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v),A=t,C=$CLJS.R,G=C.l,K=x,S=$CLJS.T.h(x);x=$CLJS.T.h(x);x=c.h?c.h(x):c.call(null,x);C=G.call(C,K,$CLJS.FK,$CLJS.YK,$CLJS.H([$CLJS.gZ,S,$CLJS.uZ,x]));A.add(C);v+=1}else return!0}()?$CLJS.ue($CLJS.we(t),f($CLJS.mc(k))):$CLJS.ue($CLJS.we(t),null)}var u=
$CLJS.z(k);return $CLJS.fe($CLJS.R.l(u,$CLJS.FK,$CLJS.YK,$CLJS.H([$CLJS.gZ,$CLJS.T.h(u),$CLJS.uZ,function(){var v=$CLJS.T.h(u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Lc(k)))}return null}},null,null)}($CLJS.r4.g(a,b))}())};
Tra=function(a,b,c){var d=$CLJS.pW(a,b);if($CLJS.n(d)){d=$CLJS.O(d);var e=$CLJS.J.g(d,$CLJS.LE);return $CLJS.Ge(function(){return function l(k){return new $CLJS.oe(null,function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.Ad(m)){var t=$CLJS.lc(m),u=$CLJS.D(t),v=$CLJS.re(u);return function(){for(var K=0;;)if(K<u){var S=$CLJS.kd(t,K),V=$CLJS.I(S,0,null),Y=function(){var ta=V;ta=ta instanceof $CLJS.M?ta.T:null;switch(ta){case "field":return $CLJS.DZ;case "expression":return $CLJS.oZ;default:throw Error(["No matching clause: ",
$CLJS.p.h(ta)].join(""));}}(),ha=$CLJS.M_.j(a,b,S);$CLJS.ve(v,$CLJS.R.l(ha,$CLJS.FK,Y,$CLJS.H([$CLJS.gZ,$CLJS.q0.j(a,b,ha),$CLJS.uZ,function(){var ta=$CLJS.w1(a,ha);return c.h?c.h(ta):c.call(null,ta)}()])));K+=1}else return!0}()?$CLJS.ue($CLJS.we(v),l($CLJS.mc(m))):$CLJS.ue($CLJS.we(v),null)}var x=$CLJS.z(m),A=$CLJS.I(x,0,null),C=function(){var K=A;K=K instanceof $CLJS.M?K.T:null;switch(K){case "field":return $CLJS.DZ;case "expression":return $CLJS.oZ;default:throw Error(["No matching clause: ",$CLJS.p.h(K)].join(""));
}}(),G=$CLJS.M_.j(a,b,x);return $CLJS.fe($CLJS.R.l(G,$CLJS.FK,C,$CLJS.H([$CLJS.gZ,$CLJS.q0.j(a,b,G),$CLJS.uZ,function(){var K=$CLJS.w1(a,G);return c.h?c.h(K):c.call(null,K)}()])),l($CLJS.Lc(m)))}return null}},null,null)}(e)}())}return null};Ura=function(a,b,c){return $CLJS.Ge($CLJS.fg.j($CLJS.Df,$CLJS.aE(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)}),new $CLJS.P(null,2,5,$CLJS.Q,[Rra,Sra],null)))};
Vra=function(a,b,c){var d=$CLJS.S1(a,b);return $CLJS.n(d)?$CLJS.Ge(function(){return function k(f){return new $CLJS.oe(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.re(t);return function(){for(var A=0;;)if(A<t){var C=$CLJS.kd(m,A);var G=$CLJS.tA($CLJS.uZ,$CLJS.gZ)(C);G=$CLJS.n(G)?G:$CLJS.q0.j(a,b,C);C=$CLJS.Ok.l($CLJS.nl.l($CLJS.H([C,new $CLJS.h(null,3,[$CLJS.FK,$CLJS.hZ,$CLJS.gZ,G,$CLJS.uZ,c.h?c.h(G):c.call(null,G)],null),$CLJS.n($CLJS.B1.h(C))?
$CLJS.lm(C,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.B1],null)):null])),$CLJS.tW,$CLJS.H([$CLJS.kE]));u.add(C);A+=1}else return!0}()?$CLJS.ue($CLJS.we(u),k($CLJS.mc(l))):$CLJS.ue($CLJS.we(u),null)}var v=$CLJS.z(l),x=function(){var A=$CLJS.tA($CLJS.uZ,$CLJS.gZ)(v);return $CLJS.n(A)?A:$CLJS.q0.j(a,b,v)}();return $CLJS.fe($CLJS.Ok.l($CLJS.nl.l($CLJS.H([v,new $CLJS.h(null,3,[$CLJS.FK,$CLJS.hZ,$CLJS.gZ,x,$CLJS.uZ,c.h?c.h(x):c.call(null,x)],null),$CLJS.n($CLJS.B1.h(v))?$CLJS.lm(v,new $CLJS.P(null,1,5,$CLJS.Q,
[$CLJS.B1],null)):null])),$CLJS.tW,$CLJS.H([$CLJS.kE])),k($CLJS.Lc(l)))}return null}},null,null)}($CLJS.x0.j(a,d,$CLJS.pW(a,d)))}()):null};Wra=function(a,b,c,d){return $CLJS.n(c)?(c=$CLJS.J_(a,c),$CLJS.n(c)?$CLJS.Ge($CLJS.z0.v(a,b,c,d)):null):null};
D7=function(a,b,c){return $CLJS.Ge(function(){return function f(e){return new $CLJS.oe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.re(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v),A=t;var C=$CLJS.kB.h(x);var G=$CLJS.R.l,K=x,S=$CLJS.T.h(x);x=$CLJS.T.h(x);x=c.h?c.h(x):c.call(null,x);C=$CLJS.Q1(G.call($CLJS.R,K,$CLJS.FK,$CLJS.oZ,$CLJS.H([$CLJS.gZ,S,$CLJS.uZ,x])),$CLJS.Ii,$CLJS.n(C)?C:$CLJS.Fj);A.add(C);v+=1}else return!0}()?$CLJS.ue($CLJS.we(t),
f($CLJS.mc(k))):$CLJS.ue($CLJS.we(t),null)}var u=$CLJS.z(k);return $CLJS.fe(function(){var v=$CLJS.kB.h(u),x=$CLJS.R.l,A=u,C=$CLJS.T.h(u);var G=$CLJS.T.h(u);G=c.h?c.h(G):c.call(null,G);return $CLJS.Q1(x.call($CLJS.R,A,$CLJS.FK,$CLJS.oZ,$CLJS.H([$CLJS.gZ,C,$CLJS.uZ,G])),$CLJS.Ii,$CLJS.n(v)?v:$CLJS.Fj)}(),f($CLJS.Lc(k)))}return null}},null,null)}($CLJS.ioa.g(a,b))}())};
E7=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.j0);return $CLJS.Yk.g(function(f){return $CLJS.Ok.l(f,$CLJS.JZ,$CLJS.H([$CLJS.tW,$CLJS.zW,$CLJS.PK]))},function(){var f=Vra(a,b,e);if($CLJS.n(f))return f;f=$CLJS.pW(a,b);f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.PO),l=$CLJS.J.g(f,$CLJS.yV),m=$CLJS.n(k)?function(){var t=$CLJS.H_(a,k);return $CLJS.z0.v(a,b,t,d)}():null;if($CLJS.n(m))return m;l=$CLJS.n(l)?Wra(a,b,l,$CLJS.R.j(d,$CLJS.d0,!1)):null;return $CLJS.n(l)?l:function v(u){return new $CLJS.oe(null,
function(){for(;;){var x=$CLJS.y(u);if(x){if($CLJS.Ad(x)){var A=$CLJS.lc(x),C=$CLJS.D(A),G=$CLJS.re(C);return function(){for(var S=0;;)if(S<C){var V=$CLJS.kd(A,S),Y=G,ha=$CLJS.R,ta=ha.l,Ta=V,Cb=$CLJS.T.h(V);V=$CLJS.T.h(V);V=e.h?e.h(V):e.call(null,V);ha=ta.call(ha,Ta,$CLJS.FK,$CLJS.EZ,$CLJS.H([$CLJS.gZ,Cb,$CLJS.uZ,V]));Y.add(ha);S+=1}else return!0}()?$CLJS.ue($CLJS.we(G),v($CLJS.mc(x))):$CLJS.ue($CLJS.we(G),null)}var K=$CLJS.z(x);return $CLJS.fe($CLJS.R.l(K,$CLJS.FK,$CLJS.EZ,$CLJS.H([$CLJS.gZ,$CLJS.T.h(K),
$CLJS.uZ,function(){var S=$CLJS.T.h(K);return e.h?e.h(S):e.call(null,S)}()])),v($CLJS.Lc(x)))}return null}},null,null)}($CLJS.mW.h($CLJS.YU.h(f)))}())};
F7=function(a,b){var c=$CLJS.I(a,0,null);$CLJS.I(a,1,null);var d=$CLJS.I(a,2,null);switch(c instanceof $CLJS.M?c.T:null){case "field":if("number"===typeof d||"string"===typeof d)return $CLJS.E.g(d,$CLJS.dj.h(b));throw $CLJS.ji("unknown type of :field ref in lib.stage/ref-to?",new $CLJS.h(null,2,[$CLJS.JR,a,$CLJS.Xi,b],null));case "expression":return $CLJS.E.g(d,$CLJS.T.h(b));default:throw $CLJS.ji("unknown clause in lib.stage/ref-to?",new $CLJS.h(null,2,[$CLJS.JR,a,$CLJS.Xi,b],null));}};
Xra=function(a,b,c){var d=$CLJS.RF.h($CLJS.pW(a,b));return $CLJS.n(d)?function k(f){return new $CLJS.oe(null,function(){for(;;){var l=$CLJS.y(f);if(l){var m=l;if($CLJS.Ad(m)){var t=$CLJS.lc(m),u=$CLJS.D(t),v=$CLJS.re(u);return function(){for(var A=0;;)if(A<u){var C=$CLJS.kd(t,A);$CLJS.ve(v,function(){var G=$CLJS.nH(function(V,Y){return function(ha){return F7(ha,Y)}}(A,C,t,u,v,m,l,d,d),d);if($CLJS.n(G)){var K=$CLJS.f7(G);G=$CLJS.y2(G);G=$CLJS.O(G);G=$CLJS.J.g(G,$CLJS.ti);var S=C;K=$CLJS.n(K)?$CLJS.e5(S,
K):S;return $CLJS.n(G)?$CLJS.p3(K,G):K}return C}());A+=1}else return!0}()?$CLJS.ue($CLJS.we(v),k($CLJS.mc(m))):$CLJS.ue($CLJS.we(v),null)}var x=$CLJS.z(m);return $CLJS.fe(function(){var A=$CLJS.nH(function(K){return function(S){return F7(S,K)}}(x,m,l,d,d),d);if($CLJS.n(A)){var C=$CLJS.f7(A);A=$CLJS.y2(A);A=$CLJS.O(A);A=$CLJS.J.g(A,$CLJS.ti);var G=x;C=$CLJS.n(C)?$CLJS.e5(G,C):G;return $CLJS.n(A)?$CLJS.p3(C,A):C}return x}(),k($CLJS.Lc(m)))}return null}},null,null)}(c):c};
G7=new $CLJS.M("metabase.lib.stage","stage","metabase.lib.stage/stage",1448689281);$CLJS.Ppa={};$CLJS.jF($CLJS.FV,G7);$CLJS.jF($CLJS.MV,G7);$CLJS.e1.m(null,$CLJS.FV,function(a){return $CLJS.d1(a,new $CLJS.h(null,2,[$CLJS.SF,$CLJS.We($CLJS.Yk,$CLJS.e1),$CLJS.TV,$CLJS.We($CLJS.Yk,$CLJS.e1)],null))});$CLJS.u0.m(null,G7,function(){throw $CLJS.ji("You can't calculate a metadata map for a stage! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.y0.m(null,G7,function(a,b,c,d){d=$CLJS.O(d);c=$CLJS.J.g(d,$CLJS.j0);var e=$CLJS.J.g(d,$CLJS.d0);a=$CLJS.J3(a,b);var f=$CLJS.O(d);var k=$CLJS.J.g(f,$CLJS.j0),l=$CLJS.J.g(f,$CLJS.h0),m=$CLJS.J.g(f,$CLJS.n0);f=$CLJS.hf.l(E7(a,b,f),$CLJS.n(m)?D7(a,b,k):null,$CLJS.H([$CLJS.n(l)?Pra(a,b,k):null]));k=$CLJS.hf.g;d=$CLJS.n(e)?(e=$CLJS.Ua($CLJS.yV.h($CLJS.pW(a,b))))?e:$CLJS.l0.h(d):e;return Xra(a,b,k.call($CLJS.hf,f,$CLJS.n(d)?$CLJS.x1(a,b,f,c):null))});
$CLJS.w0.m(null,G7,function(a,b,c,d){c=$CLJS.O(d);d=$CLJS.J.g(c,$CLJS.j0);var e=Qra(a,b);if($CLJS.n(e))return e;a=$CLJS.J3(a,b);e=Ura(a,b,d);var f=Tra(a,b,d);return $CLJS.n(e)?$CLJS.fg.g(e,f):$CLJS.n(f)?($CLJS.nh(f),$CLJS.fg.j($CLJS.Df,Ora(function(k){return $CLJS.Ok.l(k,$CLJS.HZ,$CLJS.H([$CLJS.FK,$CLJS.S0,$CLJS.uZ]))}),$CLJS.hf.g(f,C7(a,b,c)))):$CLJS.hf.l(E7(a,b,new $CLJS.h(null,2,[$CLJS.d0,!1,$CLJS.j0,d],null)),D7(a,b,d),$CLJS.H([C7(a,b,c)]))});$CLJS.o0.m(null,$CLJS.MV,function(){return $CLJS.DE("Native query")});
var H7=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PO,$CLJS.yV,$CLJS.nN],null),I7=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.SF,$CLJS.RF,$CLJS.TV,$CLJS.yR,$CLJS.VR],null);
$CLJS.o0.m(null,$CLJS.FV,function(a,b,c,d){var e=$CLJS.J3(a,b);a=$CLJS.Ge(function(){var f=$CLJS.fg.j($CLJS.N,$CLJS.Wk.g($CLJS.kC,$CLJS.jf.h(function(l){return new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.Ana.j(e,b,l)],null)})),new $CLJS.P(null,2,5,$CLJS.Q,[H7,I7],null)),k=$CLJS.tt(" + ",$CLJS.Xk.g($CLJS.yA,$CLJS.jf.g(f,H7)));f=$CLJS.jf.g(f,I7);return $CLJS.tt(", ",$CLJS.Xk.g($CLJS.yA,$CLJS.fe(k,f)))}());if($CLJS.n(a))return a;a=$CLJS.S1(e,b);return $CLJS.n(a)?$CLJS.P_.v(e,a,$CLJS.pW(e,a),d):null});