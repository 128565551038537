var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");
'use strict';var Ut,Oba,Pba,Xt,Qba,$t,au;
$CLJS.Tt=function(a,b,c){return function(){function d(t,u,v){return a.N?a.N(b,c,t,u,v):a.call(null,b,c,t,u,v)}function e(t,u){return a.v?a.v(b,c,t,u):a.call(null,b,c,t,u)}function f(t){return a.j?a.j(b,c,t):a.call(null,b,c,t)}function k(){return a.g?a.g(b,c):a.call(null,b,c)}var l=null,m=function(){function t(v,x,A,C){var G=null;if(3<arguments.length){G=0;for(var K=Array(arguments.length-3);G<K.length;)K[G]=arguments[G+3],++G;G=new $CLJS.w(K,0,null)}return u.call(this,v,x,A,G)}function u(v,x,A,C){return $CLJS.Te.l(a,
b,c,v,x,$CLJS.H([A,C]))}t.A=3;t.B=function(v){var x=$CLJS.z(v);v=$CLJS.B(v);var A=$CLJS.z(v);v=$CLJS.B(v);var C=$CLJS.z(v);v=$CLJS.Lc(v);return u(x,A,C,v)};t.l=u;return t}();l=function(t,u,v,x){switch(arguments.length){case 0:return k.call(this);case 1:return f.call(this,t);case 2:return e.call(this,t,u);case 3:return d.call(this,t,u,v);default:var A=null;if(3<arguments.length){A=0;for(var C=Array(arguments.length-3);A<C.length;)C[A]=arguments[A+3],++A;A=new $CLJS.w(C,0,null)}return m.l(t,u,v,A)}throw Error("Invalid arity: "+
arguments.length);};l.A=3;l.B=m.B;l.o=k;l.h=f;l.g=e;l.j=d;l.l=m.l;return l}()};Ut=function(a,b){return $CLJS.E.g(a,b)?new $CLJS.P(null,3,5,$CLJS.Q,[null,null,a],null):new $CLJS.P(null,3,5,$CLJS.Q,[a,b,null],null)};Oba=function(a){return $CLJS.y(a)?$CLJS.eb(function(b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return $CLJS.R.j(b,d,c)},$CLJS.Ef($CLJS.ef($CLJS.Te.g($CLJS.Sk,$CLJS.Yg(a)),null)),a):null};
Pba=function(a,b,c){var d=$CLJS.J.g(a,c),e=$CLJS.J.g(b,c),f=$CLJS.Vt(d,e),k=$CLJS.I(f,0,null),l=$CLJS.I(f,1,null);f=$CLJS.I(f,2,null);a=$CLJS.Hd(a,c);b=$CLJS.Hd(b,c);d=a&&b&&(null!=f||null==d&&null==e);return new $CLJS.P(null,3,5,$CLJS.Q,[!a||null==k&&d?null:$CLJS.Fe([c,k]),!b||null==l&&d?null:$CLJS.Fe([c,l]),d?$CLJS.Fe([c,f]):null],null)};
Xt=function(a,b){var c=$CLJS.jf.g,d=Wt.j,e=$CLJS.zd(a)?a:$CLJS.Ef(a),f=$CLJS.zd(b)?b:$CLJS.Ef(b);a=$CLJS.D(a);b=$CLJS.D(b);return $CLJS.Ef(c.call($CLJS.jf,Oba,d.call(Wt,e,f,$CLJS.mh(0,a>b?a:b))))};Qba=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ge($CLJS.Yt.g(a,b)),$CLJS.Ge($CLJS.Yt.g(b,a)),$CLJS.Ge($CLJS.Zt.g(a,b))],null)};
$t=function(a){if(null!=a&&null!=a.ih)a=a.ih(a);else{var b=$t[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=$t._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("EqualityPartition.equality-partition",a);}return a};au=function(a,b){if(null!=a&&null!=a.hh)a=a.hh(a,b);else{var c=au[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=au._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("Diff.diff-similar",a);}return a};
$CLJS.Vt=function(a,b){return $CLJS.E.g(a,b)?new $CLJS.P(null,3,5,$CLJS.Q,[null,null,a],null):$CLJS.E.g($t(a),$t(b))?au(a,b):Ut(a,b)};$CLJS.Zt=function Zt(a){switch(arguments.length){case 1:return Zt.h(arguments[0]);case 2:return Zt.g(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Zt.l(arguments[0],arguments[1],new $CLJS.w(c.slice(2),0,null))}};$CLJS.Zt.h=function(a){return a};
$CLJS.Zt.g=function(a,b){for(;;)if($CLJS.D(b)<$CLJS.D(a)){var c=a;a=b;b=c}else return $CLJS.eb(function(d,e){return function(f,k){return $CLJS.Hd(e,k)?f:$CLJS.Pk.g(f,k)}}(a,b),a,a)};$CLJS.Zt.l=function(a,b,c){a=$CLJS.Pt(function(d){return-$CLJS.D(d)},$CLJS.ce.l(c,b,$CLJS.H([a])));return $CLJS.eb($CLJS.Zt,$CLJS.z(a),$CLJS.Lc(a))};$CLJS.Zt.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return this.l(b,a,c)};$CLJS.Zt.A=2;
$CLJS.Yt=function Yt(a){switch(arguments.length){case 1:return Yt.h(arguments[0]);case 2:return Yt.g(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Yt.l(arguments[0],arguments[1],new $CLJS.w(c.slice(2),0,null))}};$CLJS.Yt.h=function(a){return a};$CLJS.Yt.g=function(a,b){return $CLJS.D(a)<$CLJS.D(b)?$CLJS.eb(function(c,d){return $CLJS.Hd(b,d)?$CLJS.Pk.g(c,d):c},a,a):$CLJS.eb($CLJS.Pk,a,b)};
$CLJS.Yt.l=function(a,b,c){return $CLJS.eb($CLJS.Yt,a,$CLJS.ce.g(c,b))};$CLJS.Yt.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return this.l(b,a,c)};$CLJS.Yt.A=2;var bu=new $CLJS.M(null,"atom","atom",-397043653);var Wt=function Wt(a){switch(arguments.length){case 2:return Wt.g(arguments[0],arguments[1]);case 3:return Wt.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};Wt.g=function(a,b){return Wt.j(a,b,$CLJS.St.g($CLJS.Yg(a),$CLJS.Yg(b)))};Wt.j=function(a,b,c){return $CLJS.eb(function(d,e){return $CLJS.nh($CLJS.jf.j($CLJS.nl,d,e))},new $CLJS.P(null,3,5,$CLJS.Q,[null,null,null],null),$CLJS.jf.g($CLJS.Tt(Pba,a,b),c))};Wt.A=3;
$t["null"]=function(){return bu};$t.string=function(){return bu};$t.number=function(){return bu};$t.array=function(){return $CLJS.Ej};$t["function"]=function(){return bu};$t["boolean"]=function(){return bu};
$t._=function(a){return(null!=a?a.C&1024||$CLJS.Bc===a.Wg||(a.C?0:$CLJS.$a($CLJS.Bb,a)):$CLJS.$a($CLJS.Bb,a))?$CLJS.ok:(null!=a?a.C&4096||$CLJS.Bc===a.$g||(a.C?0:$CLJS.$a($CLJS.Gb,a)):$CLJS.$a($CLJS.Gb,a))?$CLJS.ej:(null!=a?a.C&16777216||$CLJS.Bc===a.qf||(a.C?0:$CLJS.$a($CLJS.Wb,a)):$CLJS.$a($CLJS.Wb,a))?$CLJS.Ej:bu};au["null"]=function(a,b){return Ut(a,b)};au.string=function(a,b){return Ut(a,b)};au.number=function(a,b){return Ut(a,b)};au.array=function(a,b){return Xt(a,b)};
au["function"]=function(a,b){return Ut(a,b)};au["boolean"]=function(a,b){return Ut(a,b)};au._=function(a,b){var c=function(){var d=$t(a);d=d instanceof $CLJS.M?d.T:null;switch(d){case "atom":return Ut;case "set":return Qba;case "sequential":return Xt;case "map":return Wt;default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}}();return c.g?c.g(a,b):c.call(null,a,b)};