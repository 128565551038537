var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var EE,JE,pfa,qfa,rfa,sfa,tfa,ufa;
$CLJS.CE=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return new $CLJS.Zz([$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-4",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(b),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),
$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a())].join("").toLowerCase())};$CLJS.DE=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.bE(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)};EE=function(a){return $CLJS.zd(a)&&$CLJS.z(a)instanceof $CLJS.M};
$CLJS.GE=function(a){var b=function(){var c=EE(a);return c?(c=$CLJS.xd($CLJS.hd(a)))?(c=$CLJS.Ii.h($CLJS.hd(a)),$CLJS.n(c)?c:$CLJS.kB.h($CLJS.hd(a))):c:c}();return $CLJS.n(b)?b:$CLJS.FE.h(a)};
JE=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,2,[$CLJS.ht,"valid MBQL clause",$CLJS.kt,function(c){c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.Ij);return["invalid MBQL clause: ",$CLJS.Nh.l($CLJS.H([c]))].join("")}],null),$CLJS.Ue(EE)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.HE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.ht,b],null),function(c){c=$CLJS.GE(c);
return IE(c,a)}],null)],null)};$CLJS.KE=new $CLJS.M(null,"right-join","right-join",-56349359);pfa=new $CLJS.M("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);qfa=new $CLJS.M("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);rfa=new $CLJS.M("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);$CLJS.LE=new $CLJS.M(null,"fields","fields",-1932066230);
$CLJS.ME=new $CLJS.M(null,"expr","expr",745722291);$CLJS.NE=new $CLJS.M("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.OE=new $CLJS.M("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.PE=new $CLJS.M(null,"alias","alias",-2039751630);$CLJS.QE=new $CLJS.M("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);
$CLJS.RE=new $CLJS.M("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);$CLJS.SE=new $CLJS.M("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.TE=new $CLJS.M("mbql.clause","field","mbql.clause/field",1497292735);sfa=new $CLJS.M("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);$CLJS.UE=new $CLJS.M(null,"left-join","left-join",-672831855);
$CLJS.VE=new $CLJS.M("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);$CLJS.WE=new $CLJS.M("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);$CLJS.XE=new $CLJS.M("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);tfa=new $CLJS.r("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);
ufa=new $CLJS.M("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);$CLJS.YE=new $CLJS.M(null,"inner-join","inner-join",659431740);$CLJS.ZE=new $CLJS.M("metabase.lib.schema.expression","emptyable","metabase.lib.schema.expression/emptyable",941775581);$CLJS.HE=new $CLJS.M("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);
$CLJS.$E=new $CLJS.M("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.aF=new $CLJS.M(null,"full-join","full-join",1305476385);$CLJS.bF=new $CLJS.M("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);$CLJS.cF=new $CLJS.M("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);$CLJS.FE=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.$B],null),$CLJS.mj,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.fi($CLJS.Gh.g("metabase.lib.schema.expression","type-of-method"),function(f){var k=$CLJS.YB(f);return $CLJS.E.g(k,$CLJS.jB)?$CLJS.ab(f):k},e,a,b,c,d)}();
$CLJS.X(qfa,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.SE],null),$CLJS.uE],null));$CLJS.FE.m(null,$CLJS.ei,function(a){throw $CLJS.ji($CLJS.bE("{0}: Don''t know how to determine the type of {1}",$CLJS.H([tfa,$CLJS.Nh.l($CLJS.H([a]))])),new $CLJS.h(null,1,[$CLJS.ME,a],null));});$CLJS.FE.m(null,$CLJS.WE,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.GE(a)});
var IE=function IE(a,b){return $CLJS.vd(a)?$CLJS.Se(function(d){return IE.g?IE.g(d,b):IE.call(null,d,b)},a):$CLJS.vd(b)?$CLJS.Se(function(d){return IE.g?IE.g(a,d):IE.call(null,a,d)},b):$CLJS.E.g(a,$CLJS.SE)?!0:$CLJS.lC(a,b)};$CLJS.X($CLJS.QE,JE($CLJS.Qj,"expression returning a boolean"));$CLJS.X($CLJS.$E,JE($CLJS.jk,"expression returning a string"));$CLJS.X($CLJS.cF,JE($CLJS.Bj,"expression returning an integer"));$CLJS.X(rfa,JE($CLJS.ID,"expression returning a non-integer real number"));
$CLJS.X($CLJS.bF,JE($CLJS.Uj,"expression returning a number"));$CLJS.X(sfa,JE($CLJS.MC,"expression returning a date"));$CLJS.X(ufa,JE($CLJS.LD,"expression returning a time"));$CLJS.X(pfa,JE($CLJS.lD,"expression returning a date time"));$CLJS.X($CLJS.OE,JE($CLJS.bk,"expression returning a date, time, or date time"));$CLJS.dF=new $CLJS.bh(null,new $CLJS.h(null,4,[$CLJS.Uj,null,$CLJS.jk,null,$CLJS.bk,null,$CLJS.Qj,null],null),null);$CLJS.X($CLJS.XE,JE($CLJS.dF,"an expression that can be compared with :\x3e or :\x3c"));
var vfa=new $CLJS.bh(null,new $CLJS.h(null,7,[$CLJS.vC,null,$CLJS.Uj,null,$CLJS.jk,null,$CLJS.bD,null,$CLJS.bk,null,$CLJS.Qj,null,$CLJS.GD,null],null),null);$CLJS.X($CLJS.ZE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$E],null),JE($CLJS.bD,"expression returning a BSONID")],null));$CLJS.X($CLJS.VE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,JE(vfa,"an expression that can appear in :\x3d or :!\x3d")],null));
$CLJS.X($CLJS.RE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,JE($CLJS.Fj,"any type of expression")],null));
$CLJS.X($CLJS.NE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.go,1],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RE],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Kj,$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kE,$CLJS.ek],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.ys],null)],null)],null)],null));