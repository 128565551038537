var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var Nfa,Ofa,Pfa,Qfa,Rfa,Sfa,Tfa;$CLJS.NG=new $CLJS.M(null,"second","second",-444702010);$CLJS.OG=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);$CLJS.PG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.QG=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.RG=new $CLJS.M(null,"year-of-era","year-of-era",682445876);
Nfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);Ofa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.SG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);
$CLJS.TG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);$CLJS.UG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);Pfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);
Qfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);Rfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);Sfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);
$CLJS.VG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);$CLJS.WG=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.XG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);
$CLJS.YG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);Tfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var ZG=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Ji,$CLJS.Lj,$CLJS.Jj,$CLJS.ri,$CLJS.sk,$CLJS.Di,$CLJS.li,$CLJS.RG],null),$G=$CLJS.gh(ZG),Ufa,Vfa,Wfa;$CLJS.X(Rfa,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.ht,"Valid date extraction unit"],null)],null),$G));var aH=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Rt,$CLJS.tj,$CLJS.Cj,$CLJS.qi,$CLJS.li],null),bH=$CLJS.gh(aH);
$CLJS.X(Qfa,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.ht,"Valid date truncation unit"],null)],null),bH));$CLJS.cH=$CLJS.fg.j($CLJS.Df,$CLJS.pl.o(),$CLJS.hf.g(aH,ZG));Ufa=$CLJS.gh($CLJS.cH);$CLJS.X($CLJS.UG,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.ht,"Valid date bucketing unit"],null)],null),Ufa));var dH=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OG,$CLJS.nj,$CLJS.xi],null),eH=$CLJS.gh(dH);
$CLJS.X(Pfa,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.ht,"Valid time extraction unit"],null)],null),eH));var fH=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.QG,$CLJS.NG,$CLJS.Yi,$CLJS.wk],null),gH=$CLJS.gh(fH);$CLJS.X(Tfa,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.ht,"Valid time truncation unit"],null)],null),gH));$CLJS.hH=$CLJS.fg.j($CLJS.Df,$CLJS.pl.o(),$CLJS.hf.g(fH,dH));Vfa=$CLJS.gh($CLJS.hH);
$CLJS.X(Ofa,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.ht,"Valid time bucketing unit"],null)],null),Vfa));$CLJS.iH=$CLJS.fg.j($CLJS.Df,$CLJS.pl.o(),$CLJS.hf.l(fH,aH,$CLJS.H([dH,ZG])));Wfa=$CLJS.gh($CLJS.iH);$CLJS.X($CLJS.PG,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.ht,"Valid datetime bucketing unit"],null)],null),$CLJS.iH));var Xfa=$CLJS.ce.g(Wfa,$CLJS.ei);
$CLJS.X($CLJS.XG,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.ht,"Valid temporal bucketing unit"],null)],null),Xfa));$CLJS.jH=$CLJS.St.g(bH,gH);$CLJS.X($CLJS.SG,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.ht,"Valid datetime truncation unit"],null)],null),$CLJS.jH));$CLJS.kH=$CLJS.St.g($G,eH);$CLJS.X(Nfa,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.ht,"Valid datetime extraction unit"],null)],null),$CLJS.kH));
var lH=$CLJS.ce.g(bH,$CLJS.li);$CLJS.X($CLJS.YG,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.ht,"Valid date interval unit"],null)],null),lH));$CLJS.X($CLJS.TG,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.ht,"Valid time interval unit"],null)],null),gH));var Yfa=$CLJS.St.g(lH,gH);$CLJS.X($CLJS.VG,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.ht,"Valid datetime interval unit"],null)],null),Yfa));
$CLJS.X(Sfa,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rr,$CLJS.WG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ti,$CLJS.XG],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ei,new $CLJS.h(null,1,[$CLJS.Cs,!0],null),$CLJS.xs],null)],null));